import React from 'react';
import Form from '../../common/form';
import intl from '../../../services/intl-service';
import { azuriranjeUplatnihMjestaSchema } from './azuriranje-prodajnog-mjesta-validacije';
import CustomAlertNoForm from '../../common/custom-alert-no-form';
import InfoVrijemeUpisa from '../../common/info-vrijeme-upisa';
import { teritorijalnaJedinicaService } from '../../../services/SierraLeone/a01-teritorijalna-jedinica-service';
import DDL from '../../common/ant-ddl-translate';
import { toastSuccessSave } from '../../../services/toast-service';
import { toastError } from '../../../services/toast-service';
import { Checkbox } from 'antd';
import FramerConditionalCollapsableContainer from './../../common/framer-conditional-collapsable-container';
import { prodajnoMjestoService as service } from './../../../services/a02-prodajno-mjesto-service';
import Openstreet from '../../common/openstreet/openstreet';
import OpenstreetInfopanel from './openstreet-infopanel';
import { connect } from 'react-redux';
import { ucitajProdajnaMjesta } from '../../../store/SierraLeone/A02-prodajna-mjesta';
import { AnimatePresence } from 'framer-motion';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { getUserId } from './../../../services/auth-service';

const ddlOptions = [
   { text: 'No', value: 0 },
   { text: 'Yes', value: 1 }
];

const [lat, lng] = [8.4793156225307, -13.268909454345705];
const initValidationData = {
   adresaProdajnogMjesta: '',
   aktivnost: 0,
   brojTelefona: '',
   brojTerminala: '',
   geografskaDuzina: lng,
   geografskaSirina: lat,
   kategorija: '',
   nazivProdajnogMjesta: '',
   sifraProdajnogMjesta: 0,
   sifraTeritorijalneJediniceN1: 0,
   tipLokala: '',
   zabranaIsplateBingo: 0,
   zabranaIsplateKeno: 0,
   zabranaProdajeBingo: 0,
   zabranaProdajeKeno: 0,
   showSifraProdajnogMjestaAlert: false
};

const translationKey = 'prodajnaMjesta';

class AzuriranjeProdajnogMjestaSierraLeone extends Form {
   state = {
      validationData: { ...initValidationData },
      prodajnaMjesta: [],
      grad: '',
      addMode: true,
      adresa: '',
      lat: lat,
      lng: lng,
      teritorijalneJediniceNivo1: [],
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      operaterUpisa: '',
      showConfirm: false,
      showOpenstreet: false
   };

   schema = azuriranjeUplatnihMjestaSchema;

   // dohvatim teritorijalne jedinice Nivo1 koje  korisnik može mijenjati.
   async componentDidMount() {
      const prodajnaMjesta = await this.loadProdajnaMjesta();
      if (prodajnaMjesta.length === 0) this.setSifraProdajnogMjesta(1);

      const { data: response } = await teritorijalnaJedinicaService.list(1);
      const teritorijalneJediniceNivo1 = response.data;
      this.setState({ prodajnaMjesta, teritorijalneJediniceNivo1 });
   }

   loadProdajnaMjesta = async () => {
      const { data: responseProdajnaMjesta } = await service.list();
      const { prodajnaMjesta } = responseProdajnaMjesta;
      this.setState({ prodajnaMjesta });
      return prodajnaMjesta;
   };

   setSifraProdajnogMjesta = (broj) => {
      const { sifraProdajnogMjesta } = this.state.validationData;
      if (sifraProdajnogMjesta) return;
      const validationData = { ...this.state.validationData };
      validationData.sifraProdajnogMjesta = broj;
      this.setState({ validationData });
   };

   doSubmit = async () => {
      this.setState({ showConfirm: true });
   };

   getNewItemId = () => {
      const { prodajnaMjesta } = this.state;
      if (prodajnaMjesta.length === 0) return 1;

      const sifreProdajnihMjesta = prodajnaMjesta.map(
         (m) => m.sifraProdajnogMjesta
      );
      return Math.max(...sifreProdajnihMjesta) + 1;
   };

   doSubmitOnConfirm = async () => {
      const { addMode } = this.state;
      const postData = { ...this.state.validationData };
      // if (addMode) postData.sifraProdajnogMjesta = this.getNewItemId();

      const { data: response } = await service.addOrUpdate(postData);
      const { isSuccess, msg } = response;

      if (isSuccess) {
         await this.loadProdajnaMjesta();
         toastSuccessSave();

         const validationData = { ...initValidationData };
         this.setState({ validationData });
      } else toastError(msg);

      this.setState({ showConfirm: false, addMode: false });
   };

   handleSifraPMchange = async (e) => {
      const { sifraProdajnogMjesta } = this.state.validationData;
      if (!sifraProdajnogMjesta || sifraProdajnogMjesta < 1) return;

      const { data: response } = await service.get(sifraProdajnogMjesta);
      const nazivProdajnogMjesta = response.data?.nazivProdajnogMjesta;

      if (nazivProdajnogMjesta === undefined) return;

      const addMode = nazivProdajnogMjesta ? false : true;
      const { data: validationData } = response;
      const lat = validationData.geografskaSirina;
      const lng = validationData.geografskaDuzina;
      this.setState({ validationData, addMode, lat, lng });
   };

   handleDDLSelected = (e, propertyName) => {
      const { value } = e;
      const validationData = { ...this.state.validationData };
      validationData[propertyName] = value;

      this.setState({
         validationData
      });
   };

   handleAktivnoCheck = ({ target: input }) => {
      const validationData = { ...this.state.validationData };
      const value = input.checked;
      validationData.aktivnost = value ? 1 : 0;
      this.setState({ validationData });
   };

   handleOpenstreetClick = ({ latlng, address }) => {
      const lat = latlng.lat;
      const lng = latlng.lng;

      const grad = address.City;
      const adresa = address.Address;

      this.setState({ grad, adresa, lat, lng });
   };

   handleConfirmOpenstreet = () => {
      const { lat, lng } = this.state;

      const validationData = { ...this.state.validationData };
      validationData.geografskaSirina = lat;
      validationData.geografskaDuzina = lng;

      this.setState({ validationData, showOpenstreet: false });
   };

   handleCancelOpenstreet = () => {
      const { geografskaSirina, geografskaDuzina } = this.state.validationData;

      const lat = geografskaSirina;
      const lng = geografskaDuzina;

      this.setState({ lat, lng, showOpenstreet: false });
   };

   handleProdajnoMjestoSelected = ({ value }) => {
      if (!value) {
         const addMode = true;
         const validationData = { ...initValidationData };
         this.setState({ validationData, addMode, lat, lng });
         return;
      }
      const validationData = { ...this.state.validationData };
      validationData.sifraProdajnogMjesta = value;
      const addMode = false;

      this.setState({ validationData, addMode }, () => {
         this.showData();
      });
   };

   showData = () => {
      const { prodajnaMjesta } = this.state;
      const { sifraProdajnogMjesta } = this.state.validationData;
      const data = prodajnaMjesta.find(
         (pm) => pm.sifraProdajnogMjesta === sifraProdajnogMjesta
      );

      const { datumUpisa, vrijemeUpisa, operaterUpisa } = data;

      const validationData = { ...data };
      delete validationData.datumUpisa;
      delete validationData.vrijemeUpisa;
      delete validationData.operaterUpisa;

      // privremeni smjestaj koordinata koje cuvaju vrijednost kliknutu na mapi
      // podešava se jer tu vrijednost čita OpenstreetInfopanel
      const lat = validationData.geografskaSirina;
      const lng = validationData.geografskaDuzina;

      this.setState({
         lat,
         lng,
         datumUpisa,
         vrijemeUpisa,
         operaterUpisa,
         validationData
      });
   };

   /*
      Kod inicijalnog korištenja aplikacije prikazuje se obrazac za unos
      Prvododanom se dodjeljuje sifraProdajnogMjesta = 1
   */
   naziviProdajnihMjesta = () => {
      const { prodajnaMjesta } = this.state;

      if (!prodajnaMjesta) return [];

      return prodajnaMjesta.map((pm) => ({
         text: `${pm.nazivProdajnogMjesta} [${pm.sifraProdajnogMjesta}]`,
         value: pm.sifraProdajnogMjesta
      }));
   };

   jeNovoProdajnoMjesto = () => {};

   render() {
      const {
         validationData,
         datumUpisa,
         vrijemeUpisa,
         showConfirm,
         showOpenstreet,
         teritorijalneJediniceNivo1,
         showSifraProdajnogMjestaAlert,
         addMode,
         lat,
         lng,
         grad,
         adresa,
         operaterUpisa
      } = this.state;
      const {
         sifraProdajnogMjesta,
         sifraTeritorijalneJediniceN1,
         zabranaProdajeKeno,
         zabranaIsplateKeno,
         zabranaProdajeBingo,
         zabranaIsplateBingo,
         aktivnost
      } = validationData;

      // this.schema = addMode
      //    ? azuriranjeUplatnihMjestaAddSchema
      //    : azuriranjeUplatnihMjestaSchema;

      return (
         <>
            <AnimatePresence>
               {showSifraProdajnogMjestaAlert && (
                  <CustomAlertNoForm
                     title={intl.getTranslate('common.warning')}
                     btnOk='Ok'
                     alertMode={true}
                     onOk={() =>
                        this.setState({ showSifraProdajnogMjestaAlert: false })
                     }
                  >
                     <div>
                        {intl.getTranslate('prodajnaMjesta.sifraPMwarning')}
                     </div>
                  </CustomAlertNoForm>
               )}
            </AnimatePresence>
            <CustomAlertNoForm
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               btnCancel={intl.getTranslate('common.cancel')}
               btnOk={
                  addMode
                     ? intl.getTranslate('common.save')
                     : intl.getTranslate('common.update')
               }
               onOk={this.doSubmitOnConfirm}
            >
               <div>
                  {addMode
                     ? intl.getTranslate('prodajnaMjesta.confirmMessageAdd')
                     : intl.getTranslate('prodajnaMjesta.confirmMessageUpdate')}
               </div>
            </CustomAlertNoForm>

            <CustomAlertNoForm
               title={intl.getTranslate(
                  'prodajnaMjesta.titleOdaberiKoordinate'
               )}
               showCondition={showOpenstreet}
               onCancel={this.handleCancelOpenstreet}
               onOk={this.handleConfirmOpenstreet}
               btnOk='Ok'
            >
               <Openstreet
                  onOpenstreetClick={this.handleOpenstreetClick}
                  center={[lat, lng]}
               />
               <hr />
               <OpenstreetInfopanel data={{ grad, adresa, lat, lng }} />
            </CustomAlertNoForm>

            <form onSubmit={this.handleFormSubmit} className='form' noValidate>
               <div className='row'>
                  <div className='col-4'>
                     <DDL
                        allowClear={true}
                        options={this.naziviProdajnihMjesta()}
                        translationKey='prodajnaMjesta'
                        translationSubKey='odaberiProdajnoMjesto'
                        onChange={this.handleProdajnoMjestoSelected}
                        selectedOption={sifraProdajnogMjesta}
                        placeholder={intl.getTranslate(
                           'prodajnaMjesta.odaberiProdajnoMjestoPlaceholder'
                        )}
                     />
                  </div>
               </div>

               <FramerConditionalCollapsableContainer
                  // condition={(!addMode && sifraProdajnogMjesta) || addMode}
                  condition={true}
                  height='55rem'
               >
                  <div className='row'>
                     <div className='col-4'>
                        <Checkbox
                           onChange={this.handleAktivnoCheck}
                           checked={aktivnost ? true : false}
                           style={{ fontSize: '1.4rem' }}
                        >
                           {intl.getTranslate(
                              'prodajnaMjesta.aktivnoProdajnoMjesto'
                           )}
                        </Checkbox>
                     </div>
                  </div>
                  <hr />

                  <div className='row'>
                     {/* LIJEVI STUPAC - obrazac */}
                     <div className='col-8'>
                        <div className='row'>
                           <div className='col'>
                              {this.formInput({
                                 name: 'sifraProdajnogMjesta',
                                 translationKey,
                                 isNumber: true,
                                 fn: this.handleSifraPMchange,
                                 debounceDelay: 500
                              })}

                              {this.formInput({
                                 name: 'nazivProdajnogMjesta',
                                 translationKey
                              })}

                              {this.formInput({
                                 name: 'adresaProdajnogMjesta',
                                 translationKey
                              })}
                              <DDL
                                 options={teritorijalneJediniceNivo1}
                                 translationKey='prodajnaMjesta'
                                 translationSubKey='sifraTeritorijalneJediniceN1'
                                 onChange={this.handleDDLSelected}
                                 text='nazivMjesta'
                                 value='sifraMjesta'
                                 selectedOption={sifraTeritorijalneJediniceN1}
                              />
                           </div>
                           <div className='col'>
                              {this.formInput({
                                 name: 'brojTelefona',
                                 translationKey,
                                 attributes: { type: 'tel' }
                              })}

                              {this.formInput({
                                 name: 'tipLokala',
                                 translationKey,
                                 isNumber: true
                              })}

                              {this.formInput({
                                 name: 'brojTerminala',
                                 translationKey,
                                 isNumber: true
                              })}

                              {this.formInput({
                                 name: 'kategorija',
                                 translationKey,
                                 isNumber: true
                              })}
                           </div>
                        </div>
                        <hr />
                        <div className='row'>
                           <div className='col'>
                              <DDL
                                 options={ddlOptions}
                                 translationKey='prodajnaMjesta'
                                 translationSubKey='zabranaProdajeBingo'
                                 onChange={this.handleDDLSelected}
                                 selectedOption={zabranaProdajeBingo}
                              />
                           </div>
                           <div className='col'>
                              <DDL
                                 options={ddlOptions}
                                 translationKey='prodajnaMjesta'
                                 translationSubKey='zabranaIsplateBingo'
                                 onChange={this.handleDDLSelected}
                                 selectedOption={zabranaIsplateBingo}
                              />
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col'>
                              <DDL
                                 options={ddlOptions}
                                 translationKey='prodajnaMjesta'
                                 translationSubKey='zabranaProdajeKeno'
                                 onChange={this.handleDDLSelected}
                                 selectedOption={zabranaProdajeKeno}
                              />
                           </div>
                           <div className='col'>
                              <DDL
                                 options={ddlOptions}
                                 translationKey='prodajnaMjesta'
                                 translationSubKey='zabranaIsplateKeno'
                                 onChange={this.handleDDLSelected}
                                 selectedOption={zabranaIsplateKeno}
                              />
                           </div>
                        </div>
                     </div>
                     {/* DESNI STUPAC - koordinate */}
                     <div className='col-4'>
                        {this.formInput({
                           name: 'geografskaSirina',
                           translationKey,
                           isNumber: true
                        })}

                        {this.formInput({
                           name: 'geografskaDuzina',
                           translationKey,
                           isNumber: true
                        })}
                        <div className='mb-4'>
                           <Openstreet
                              onOpenstreetClick={null}
                              center={[lat, lng]}
                              disabled={true}
                              style={{ height: '22rem' }}
                           />
                        </div>
                        <button
                           className='button cancel w-100'
                           type='button'
                           onClick={() =>
                              this.setState({
                                 showOpenstreet: true
                              })
                           }
                        >
                           <FaMapMarkerAlt className='mr-2' />
                           {intl.getTranslate(
                              'prodajnaMjesta.btnOdaberiKoordinate'
                           )}
                           <FaMapMarkerAlt className='ml-2' />
                        </button>
                     </div>
                  </div>

                  <hr />
                  {!addMode && (
                     <InfoVrijemeUpisa
                        datumUpisa={datumUpisa}
                        vrijemeUpisa={vrijemeUpisa}
                        operaterUpisa={operaterUpisa}
                     />
                  )}
                  {addMode
                     ? this.formSubmit({
                          label: `${intl.getTranslate(
                             'prodajnaMjesta.saveAdd'
                          )}`
                       })
                     : this.formSubmit({
                          label: `${intl.getTranslate(
                             'prodajnaMjesta.saveUpdate'
                          )}`
                       })}
               </FramerConditionalCollapsableContainer>
            </form>
         </>
      );
   }
}

const mapStateToProps = ({ prodajnaMjesta: svaProdajnaMjesta }) => ({
   prodajnaMjesta: svaProdajnaMjesta.prodajnaMjesta,
   isLoading: svaProdajnaMjesta.isLoading
});

const mapDispatchToProps = (dispatch) => ({
   ucitajProdajnaMjesta: () => dispatch(ucitajProdajnaMjesta())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AzuriranjeProdajnogMjestaSierraLeone);

// https://stackoverflow.com/questions/14220321/how-to-return-the-response-from-an-asynchronous-call
