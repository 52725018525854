import React from 'react';
import Form from '../../common/form';
import { pregledSvihKenoListicaSchema } from './pregled-svih-keno-listica-validacije';
import { pregledSvihKenoListicaService as service } from '../../../services/SierraLeone/ke05-pregled-svih-keno-listica-service';
import { DataLoader } from '../../../services/data-loader-service';
import intl from '../../../services/intl-service';
import { AnimatePresence } from 'framer-motion';
import CustomAlertNoFormContainer from '../../common/custom-alert-container';
import Datepicker from '../../common/ant-datepicker';
import DDL from '../../common/ant-ddl-translate';
import {
   dateObjectToInteger,
   dayDifferenceInt,
   formatDateToInt,
   formatTimeToInt,
   getMomentObjFromNumber,
   todayInt
} from '../../../services/form-components-service';
import {
   formatToMomentDate,
   integerToMomentDate,
   getTimeStringFromMomentObject
} from './../../../services/form-components-service';
import moment from 'moment';
import AntTimepicker from './../../common/ant-timepicker';
import DataTable from './../../common/table/datatable';
import KenoBrojeviTable from './../KE03/keno-brojevi-table';
import { connect } from 'react-redux';
import { ucitajProdajnaMjesta } from '../../../store/SierraLeone/A02-prodajna-mjesta';
import NoDataMessage from './../../common/no-data-message';

const ddlOptions = [
   { text: 'ALL TICKETS', value: 0 },
   { text: 'WINNING', value: 1 },
   { text: 'WINNING, NOT CASHED OUT', value: 2 },
   { text: 'WINNING, CASHED OUT', value: 3 },
   { text: 'NON-WINNING', value: 4 },
   { text: 'CANCELLED', value: 5 }
];

const columns = [
   {
      sortable: true,
      center: true,
      label: intl.getTranslate('pregledSvihKenoListica.dataTable.kolo'),
      path: 'kolo'
   },
   {
      sortable: true,
      center: true,
      label: intl.getTranslate('pregledSvihKenoListica.dataTable.brojlistica'),
      path: 'brojlistica'
   },
   {
      sortable: true,
      center: true,
      label: intl.getTranslate(
         'pregledSvihKenoListica.dataTable.imePrezimeUplate'
      ),
      path: 'imePrezimeUplate'
   },
   {
      sortable: true,
      center: true,
      label: intl.getTranslate(
         'pregledSvihKenoListica.dataTable.operativniBrojUplate'
      ),
      path: 'operativniBrojUplate'
   },
   {
      sortable: true,
      center: true,
      label: intl.getTranslate(
         'pregledSvihKenoListica.dataTable.sifraProdajnogMjestaUplate'
      ),
      path: 'nazivProdajnogMjestaUplate'
   },
   {
      sortable: true,
      center: true,
      label: intl.getTranslate('pregledSvihKenoListica.dataTable.datumUplate'),
      content: (item) => {
         return `${item.datumUplate}-${item.vrijemeUplate}`;
      },
      key: 'datumUplate'
   },
   {
      sortable: true,
      center: true,
      label: intl.getTranslate(
         'pregledSvihKenoListica.dataTable.operativniBrojIsplate'
      ),
      path: 'operativniBrojIsplate'
   },
   {
      sortable: true,
      center: true,
      label: intl.getTranslate(
         'pregledSvihKenoListica.dataTable.sifraProdajnogMjestaIsp'
      ),
      path: 'nazivProdajnogMjestaIsplate'
   },
   {
      sortable: true,
      center: true,
      label: intl.getTranslate('pregledSvihKenoListica.dataTable.datumIsplate'),
      content: (item) => {
         return `${item.datumIsplate}-${item.vrijemeIsplate}`;
      },
      key: 'datumIsplate'
   },
   {
      currency: true,
      style: { textAlign: 'right' },
      label: intl.getTranslate('pregledSvihKenoListica.dataTable.iznosUplate'),
      path: 'iznosUplate',
      isDecimal: true
   },
   {
      currency: true,
      style: { textAlign: 'right' },
      label: intl.getTranslate('pregledSvihKenoListica.dataTable.iznosIsplate'),
      path: 'iznosIsplate',
      isDecimal: true
   },
   {
      currency: true,
      style: { textAlign: 'right' },
      label: intl.getTranslate('pregledSvihKenoListica.dataTable.iznosDobitka'),
      path: 'iznosDobitka',
      isDecimal: true
   },
   {
      currency: true,
      style: { textAlign: 'right' },
      label: intl.getTranslate(
         'pregledSvihKenoListica.dataTable.iznosJackpota'
      ),
      path: 'iznosJackpota',
      isDecimal: true
   },
   {
      currency: true,
      center: true,
      label: intl.getTranslate('pregledSvihKenoListica.dataTable.iznosPoreza'),
      path: 'iznosPoreza',
      isDecimal: true
   },
   {
      key: 'odigraniBrojevi',
      label: intl.getTranslate(
         'pregledSvihKenoListica.dataTable.odigraniBrojevi'
      ),
      center: true,
      // width: '10rem',
      content: (item) => {
         return <KenoBrojeviTable data={item['odigraniBrojevi']} />;
      }
   }
];

class PregledSvihKenoListica extends Form {
   state = {
      validationData: {
         odDatuma: dayDifferenceInt(-7),
         doDatuma: todayInt(),
         odVremena: 1,
         doVremena: 235959,
         odKola: 0,
         doKola: 0,
         odProdajnogMjesta: 0,
         doProdajnogMjesta: 0,
         odProdavaca: 0,
         doProdavaca: 0,
         statusListica: 0
      },
      data: null,
      showData: false,
      odigraniBrojevi: [],
      dobitniBrojevi: [],
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: ''
   };

   schema = pregledSvihKenoListicaSchema;

   componentDidMount() {
      const { ucitajProdajnaMjesta } = this.props;
      ucitajProdajnaMjesta();
   }

   doSubmit = async () => {
      this.loadData();
   };

   getProdajnoMjesto = (id) =>
      this.props.prodajnaMjesta.find((p) => p.sifraProdajnogMjesta === id) ??
      {};

   formatTableData = (response) => {
      return response.map((d) => {
         const pmUplataId = d.sifraProdajnogMjestaUplate;
         const pmIsplataId = d.sifraProdajnogMjestaIsp;

         const pmUplataNaziv =
            this.getProdajnoMjesto(pmUplataId).nazivProdajnogMjesta ?? '';
         const pmIsplataNaziv =
            this.getProdajnoMjesto(pmIsplataId).nazivProdajnogMjesta ?? '';

         return {
            ...d,
            nazivProdajnogMjestaUplate: `[${pmUplataId}] ${pmUplataNaziv}`,
            nazivProdajnogMjestaIsplate: `[${pmIsplataId}] ${pmIsplataNaziv}`
         };
      });
   };

   onDataLoad = ({ data }) => {
      this.setState({ data: this.formatTableData(data), showData: true });
   };

   onDataError = () => this.setState({ data: null });

   loadData = async () => {
      const paramsObj = this.state.validationData;
      const params = Object.keys(paramsObj).map((key) => paramsObj[key]);

      DataLoader(service.get, params, this.onDataLoad, this.onDataError);
   };

   handleDatumOdChange = (e) => {
      if (!e) return;

      const { validationData } = this.state;
      validationData.odDatuma = formatDateToInt(e.format('DD.MM.YYYY'));

      this.setState({ validationData });
   };

   handleDatumDoChange = (e) => {
      if (!e) return;

      const { validationData } = this.state;
      validationData.doDatuma = formatDateToInt(e.format('DD.MM.YYYY'));

      this.setState({ validationData });
   };

   handleOdVremenaChange = (momentObj) =>
      this.handleTimeChange('odVremena', momentObj);

   handleDoVremenaChange = (momentObj) =>
      this.handleTimeChange('doVremena', momentObj);

   handleTimeChange = (prop, momentObj) => {
      const timeString = getTimeStringFromMomentObject(momentObj);
      const { validationData } = this.state;
      validationData[prop] = formatTimeToInt(timeString);

      this.setState({ validationData });
   };

   handleStatusListicaSelected = (e) => {
      const statusListica = e.value;
      const validationData = { ...this.state.validationData };
      validationData.statusListica = statusListica;

      this.setState({ validationData });
   };

   handleOnEditClick = (item) => {
      // setOdabranTerminal(item);
      // setShowUredi(true);
   };

   handleCloseDataClick = (item) => {
      this.setState({ showData: false });
   };

   render() {
      let { showData, data, validationData, dobitniBrojevi } = this.state;
      const { odDatuma, doDatuma, odVremena, doVremena, statusListica } =
         validationData;

      dobitniBrojevi.sort((a, b) => a.broj - b.broj);

      if (data) {
         data = data.map((d) => ({
            ...d,
            iznosUplate: d.iznosUplate / 100,
            iznosDobitka: d.iznosDobitka / 100,
            iznosIsplate: d.iznosIsplate / 100,
            iznosPoreza: d.iznosPoreza / 100,
            iznosJackpota: d.iznosJackpota / 100
         }));
      }

      return (
         <>
            <AnimatePresence>
               {showData && (
                  <CustomAlertNoFormContainer
                     className='pregled-svih-keno-listica-data-table'
                     title={intl.getTranslate(
                        'pregledSvihKenoListica.confirmTitle'
                     )}
                     style={{ width: '170rem' }}
                     onBackdropClick={this.handleCloseDataClick}
                  >
                     <NoDataMessage
                        messageKey='pregledSvihKenoListica.noDataMessage'
                        data={data}
                     />

                     {data.length > 0 && (
                        <DataTable
                           columns={columns}
                           data={data}
                           pageSize={10}
                           printEnable={true}
                           searchLabel={intl.getTranslate(
                              'pregledSvihKenoListica.pretraga'
                           )}
                           searchLabelPlaceholder={intl.getTranslate(
                              'pregledSvihKenoListica.pretragaPlaceholder'
                           )}
                           searchColumns={['kolo', 'imePrezimeUplate']}
                           onRowClick={this.handleOnEditClick}
                           sumColumns={[
                              'iznosUplate',
                              'iznosIsplate',
                              'iznosDobitka',
                              'iznosJackpota',
                              'iznosPoreza'
                           ]}
                        />
                     )}
                     <hr />
                     <button
                        className='button save'
                        onClick={this.handleCloseDataClick}
                     >
                        {intl.getTranslate('pregledSvihKenoListica.closeData')}
                     </button>
                  </CustomAlertNoFormContainer>
               )}
            </AnimatePresence>
            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='pregled-keno-listica'>
                  <div className='row'>
                     <div className='col-4'>
                        <Datepicker
                           label={intl.getTranslate(
                              'pregledSvihKenoListica.odDatuma'
                           )}
                           onChange={this.handleDatumOdChange}
                           defaultDate={integerToMomentDate(odDatuma)}
                        />
                        <Datepicker
                           label={intl.getTranslate(
                              'pregledSvihKenoListica.doDatuma'
                           )}
                           onChange={this.handleDatumDoChange}
                           defaultDate={integerToMomentDate(doDatuma)}
                        />
                     </div>
                     <div className='col-4'>
                        <AntTimepicker
                           label={intl.getTranslate(
                              'pregledSvihKenoListica.odVremena'
                           )}
                           onChange={this.handleOdVremenaChange}
                           value={odVremena}
                        />
                        <AntTimepicker
                           label={intl.getTranslate(
                              'pregledSvihKenoListica.doVremena'
                           )}
                           onChange={this.handleDoVremenaChange}
                           value={doVremena}
                        />
                     </div>
                     <div className='col-4'>
                        <DDL
                           options={ddlOptions}
                           translationKey='pregledSvihKenoListica'
                           translationSubKey='statusListica'
                           onChange={this.handleStatusListicaSelected}
                           selectedOption={statusListica}
                        />
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'odKola',
                           'pregledSvihKenoListica',
                           null,
                           true
                        )}
                        {this.renderTranslateInput(
                           'doKola',
                           'pregledSvihKenoListica',
                           null,
                           true
                        )}
                     </div>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'odProdajnogMjesta',
                           'pregledSvihKenoListica',
                           null,
                           true
                        )}
                        {this.renderTranslateInput(
                           'doProdajnogMjesta',
                           'pregledSvihKenoListica',
                           null,
                           true
                        )}
                     </div>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'odProdavaca',
                           'pregledSvihKenoListica',
                           null,
                           true
                        )}
                        {this.renderTranslateInput(
                           'doProdavaca',
                           'pregledSvihKenoListica',
                           null,
                           true
                        )}
                     </div>
                  </div>
                  <hr />
                  {this.renderSubmitButton('SHOW')}
               </div>
            </form>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   prodajnaMjesta: state.prodajnaMjesta.prodajnaMjesta
});

const mapDispatchToProps = (dispatch) => ({
   ucitajProdajnaMjesta: () => dispatch(ucitajProdajnaMjesta())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(PregledSvihKenoListica);
