import React from 'react';
import intl from '../../services/intl-service';

const InfoVrijemeUpisa = ({
   datumUpisa,
   vrijemeUpisa,
   operaterUpisa,
   prefixText = intl.getTranslate('common.vrijemeUpisa')
}) => {
   if (!datumUpisa) return null;
   return (
      <div className='info-vrijeme-upisa'>
         <div className='info'>
            {prefixText}
            <span className='ml-2'>
               {datumUpisa} - {vrijemeUpisa}
            </span>
            - AGENT ID:
            <span className='ml-2'>{operaterUpisa}</span>
         </div>
      </div>
   );
};

export default InfoVrijemeUpisa;
