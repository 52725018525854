import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from '../../services/auth-service';
import PageContainer from './animated-page-container';

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) => {
            if (!getCurrentUser())
               return (
                  <PageContainer>
                     <Redirect to='/login' />
                  </PageContainer>
               );
            //return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
            //window.location = `${process.env.PUBLIC_URL}/login`;
            return Component ? <Component {...props} /> : render(props);
         }}
      />
   );
};

export default ProtectedRoute;
