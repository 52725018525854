import React, { Component } from 'react';
import Avatar from 'react-avatar-edit';
import { injectIntl } from 'react-intl';

/*
   https://github.com/kirill3333/react-avatar
   npm i react-avatar-edit
   
    Ako želim staviti vrijednosti na vanjski state 
    moram prebaciti onCrop level iznad
*/

class AvatarEditor extends Component {
   state = {
      preview: null,
      src: null
   };

   onClose = (e) => {
      this.props.onAvatarCancel();
      this.setState({ preview: null });
   };

   onCrop = (preview) => {
      //preview je base64 cropane slike
      this.setState({ preview });
      const { onAvatarSet } = this.props;
      onAvatarSet(preview);
   };

   onBeforeFileLoad = (elem) => {
      //   if (elem.target.files[0].size > 71680) {
      //      alert('File is too big!');
      //      elem.target.value = '';
      //   }
   };

   getTranslation = (id) => {
      const { intl } = this.props;

      return intl.formatMessage({
         id
      });
   };

   render() {
      return (
         <div className='avatar-editor'>
            <div>
               <Avatar
                  width={200}
                  height={200}
                  borderStyle={{
                     border: '1px dashed #ccc',
                     borderRadius: '5px'
                  }}
                  labelStyle={{
                     fontWeight: 100,
                     textAlign: 'center',
                     display: 'block'
                  }}
                  label={this.getTranslation('registerUser.avatarOdabirSlike')}
                  shadingOpacity='.9'
                  shadingColor='black'
                  backgroundColor='red'
                  imageWidth={450}
                  onCrop={this.onCrop}
                  onClose={this.onClose}
                  onBeforeFileLoad={this.onBeforeFileLoad}
                  src={this.state.src}
               />
            </div>
         </div>
      );
   }

   getImgPreviewText = () => {
      if (this.state.preview) return '';
      else return <p>Image preview</p>;
   };
}

export default injectIntl(AvatarEditor);
