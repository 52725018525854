import { LANGUAGES } from '../languages';

const translationObject = {
   [LANGUAGES.CROATIAN]: {
      pozdrav: 'Dobar dan',
      placeholder: 'Web adresa: {path} :)',
      common: {
         loading: 'Učitavam podatke...',
         error: 'Dogodila se greška',
         cancel: 'Odustani',
         edit: 'Ažuriraj',
         save: 'Spremi',
         pretraga: 'Pretraga',
         login: 'Prijava',
         notFound: '404 - traženi resurs nije pronađen',
         notFoundLink: 'Idi na početnu stranicu',
         errorLoading: 'Podaci se nisu učitali',
         confirmTitle: 'Potvrdite ažuriranje',
         datepicker: 'Odaberite datum',
         podaciUspjesnoSpremljeni: 'Podaci su uspješno spremljeni',
         podaciUspjesnoAzurirani: 'Podaci su uspješno ažurirani',
         greskaPrilikomAzuriranja:
            'Dogodila se greška prilikom ažuriranja podataka',
         ddlPlaceholder: 'Započnite unos ili odaberite opciju',
         ddlNotFound: 'Ne pronalazim traženu opciju',
         pretragaPlaceholder: 'Započnite unos pojma koji vas zanima',
         returToHomepage: '< Povratak na početnu stranicu',
         azurirati: 'Ažurirati',
         operaterUpisa: 'Operater upisa',
         datumUpisa: 'Datum upisa',
         vrijemeUpisa: 'Vrijeme upisa',
         gubitakPodataka:
            'Ako napustite ovu stranicu izgubit ćete unesene podatke. Želite li zaista napustiti ovu stranicu?',
         ddl: {
            aktivan: 'Aktivan',
            neaktivan: 'Neaktivan',
            da: 'Da',
            ne: 'Ne',
            ['agent']: 'Agent',
            ['-----']: '-----',
            ['.....']: '.....',
            ['pos manager']: 'POS Manager',
            ['lanac']: 'Lanac',
            ['operater']: 'Operater',
            ['serviser']: 'Serviser'
         }
      },
      navigacija: {
         pregled: 'Pregled',
         azuriranje: 'Ažuriranje',
         cachFlow: 'Tok novca',
         keno: 'Keno',
         quick: 'Brzo',
         login: 'Prijava',
         registerUser: 'Registracija korisnika',
         dodajZastupnika: 'Dodavanje zastupnika',
         zastupnici: 'Zastupnici',
         zastupniciAzuriranje: 'Ažuriranje',
         zastupniciDodavanje: 'Dodavanje',
         pregledListica: 'Pregled listića',
         postavke: 'Postavke'
      },
      login: {
         username: 'Korisničko ime',
         usernamePlaceholder: 'username@domain.com',
         usernameRequiredValidator: 'je obavezno',
         usernameEmailErrorValidator: 'Pogrešna e-mail adresa',
         password: 'Lozinka',
         passwordPlaceholder: ' ',
         passwordValidator: 'je obavezno',
         naslov: 'Prijava u sustav',
         btnLogin: 'Prijava'
      },
      naseljenaMjesta: {
         pregledMjesta: 'Pregled mjesta',
         dodajMjesto: 'Dodavanje mjesta',
         btnAddNew: 'Dodaj novo mjesto',
         navigacijaLabel: 'Mjesta',
         pretraga: 'Pretraga prema nazivu mjesta',
         pretragaPlaceholder: 'Započnite unos naziva mjesta',
         sifraMjesta: 'Šifra mjesta',
         sifraMjestaPlaceholder: 'Unesite šifru mjesta (npr. 10000)...',
         sifraMjestaValidator: 'je peteroznamenkasti broj',
         nazivMjesta: 'Naziv mjesta',
         nazivMjestaPlaceholder: 'Unesite naziv mjesta',
         nazivMjestaValidator: ' je obavezan unos',
         confirmTitle: 'Ažuriranje naziva mjesta',
         confirmMessage: 'Ažurirati naziv mjesta',
         addMessage: 'Dodati mjesto',
         preusmjeravanje: 'Preusmjeravanje na listu mjesta...'
      },
      teritorijalnaJedinica: {
         naslov: 'Dodavanje i ažuriranje teritorijalne jedinice',
         nivo: 'Nivo',
         sifraMjesta: 'Šifra teritorijalne jedinice',
         sifraMjestaPlaceholder: 'Unesite šifru [1-99999]',
         sifraMjestaValidator: 'je broj [1-99999]',
         sifraNadredjenogMjesta: 'Šifra nadređene teritorijalne jedinice',
         sifraNadredjenogMjestaPlaceholder:
            'Unesite šifru nadređene teritorijalne jedinice [1-99999]',
         sifraNadredjenogMjestaValidator: 'je broj [1-99999]',
         nazivMjesta: 'Naziv teritorijalne jedinice',
         nazivMjestaPlaceholder: 'Unesite naziv mjesta',
         nazivMjestaValidator: 'je obavezan (max 64 znaka)',
         nadredjenaTeritorijalnaJedinica: 'Nadređena teritorijalna jedinica',
         confirmMessage: 'Ažurirati podatke teritorijalne jedinice?'
      },
      opcina: {
         pregledOpcinaNaslov: 'Pregled općina',
         dodajOpcinu: 'Dodaj općinu',
         btnAddNew: 'Dodaj novu općinu',
         confirmDodajTitle: 'Dodavanje općine',
         confirmDodajMessage: 'Dodati općinu',
         azuriranjeOpcine: 'Ažuriranje općine',
         pretraga: 'Pretraga prema nazivu općine',
         pretragaPlaceholder: 'Započnite unos naziva općine',
         navigacijaLabel: 'Općina',
         sifraOpcine: 'Šifra općine',
         sifraOpcinePlaceholder:
            'Unesite šifru općine, broj u rasponu [1-9500]...',
         sifraOpcineValidator: 'broj u rasponu [1-9500]',
         nazivOpcine: 'Naziv općine',
         nazivOpcinePlaceholder: 'Unesite naziv općine',
         nazivOpcineValidator: 'je obavezno polje',
         confirmTitle: 'Ažuriranje naziva općine',
         confirmMessage: 'Ažurirati naziv općine',
         preusmjeravanje: 'Preusmjeravanje na listu općina...'
      },
      regija: {
         naslovPregled: 'Pregled regija',
         naslovDodavanje: 'Dodavanje regije',
         azuriranjeRegije: 'Ažuriranje regije',
         navigacijaLabel: 'Regija',
         btnAddNew: 'Dodaj novu regiju',
         pretraga: 'Pretraga po nazivu regije',
         pretragaPlaceholder: 'Započnite unos naziva regije',
         sifraRegije: 'Šifra regije',
         sifraRegijePlaceholder:
            'Unesite šifru regije, broj u rasponu [1-99]...',
         sifraRegijeValidator:
            'je obavezna brojčana vrijednost u rasponu [1-99]',
         nazivRegije: 'Naziv regije',
         nazivRegijePlaceholder: 'Unesite naziv regije',
         nazivRegijeValidator: 'je obavezno polje',
         confirmTitle: 'Ažuriranje naziva regije',
         confirmMessage: 'Ažurirati naziv regije',
         confirmAddingTitle: 'Potvrdite dodavanje',
         confirmAddingMessage: 'Dodati regiju',
         preusmjeravanje: 'Preusmjeravanje na listu regija...'
      },
      terminal: {
         naslov: 'Ažuriranje aktivnosti terminala',
         navigacijaLabel: 'Terminali',
         pregledTerminalaTitle: 'Pregled terminala',
         pretraga: 'Pretraga prema broju terminala',
         pretragaPlaceholder: 'Unesite broj terminala...',
         broj: 'Broj terminala',
         aktivnost: 'Aktivnost terminala',
         brojTerminalaPlaceholder: 'Unesite broj terminala [1-9999]...',
         brojTerminalaValidator: 'je brojčana vrijednost u rasponu [1-9999]',
         confirmTitle: 'Ažuriranje aktivnosti terminala',
         confirmMessage: 'Ažurirati aktivnost terminala',
         operaterUpisa: 'Operater upisa',
         datumUpisa: 'Datum upisa',
         vrijemeUpisa: 'Vrijeme upisa',
         aktivan: 'Aktivan',
         neaktivan: 'Neaktivan'
      },
      terminalAktivnost: {
         naslov: 'Pregled aktivnosti terminala',
         navigacijaLabel: 'Aktivnost terminala',
         ddlPrikaziTerminale: 'Prikaži',
         search: 'Pretraži prema operativnom broju',
         searchPlaceholder: 'Unesite operativni broj',
         noData: 'Nema podataka prema zadanim parametrima',
         operativniBroj: 'Operativni broj',
         zastupnik: 'Zastupnik',
         prodajnoMjesto: 'Prodajno mjesto',
         adresa: 'Adresa',
         brojTerminala: 'Terminal',
         datumPrijaveOdjave: 'Datum',
         vrijemePrijaveOdjave: 'Vrijeme',
         statusPrijaveOdjave: 'Status',
         prijava: 'Aktivan',
         odjava: 'Neaktivan',
         vrijeme: 'Vrijeme',
         odDatuma: 'Datum od',
         doDatuma: 'Datum do',
         filtarPrikaza: {
            0: 'SVI terminali',
            1: 'Aktivni terminali',
            2: 'Neaktivni terminali',
            3: 'Terminali koji ne rade'
         }
      },
      terminalPripadnost: {
         naslov: 'Ažuriranje pripadnosti terminala',
         navigacijaLabel: 'Pripadnost terminala',
         brojTerminala: 'Broj terminala',
         brojTerminalaPlaceholder: 'Vrijednost u rasponu [1-9999]...',
         sifraProdajnogMjesta: 'Šifra prodajnog mjesta',
         gradAdresaProdajnogMjesta: 'Grad/Adresa',
         indikator: 'Indikator',
         potrebanDownload: 'Potreban download',
         sifraProdajnogMjestaPlaceholder: 'Vrijednost u rasponu [1-9999]...',
         brojTerminalaError: 'je obavezna vrijednost [1-9999]',
         sifraProdajnogMjestaError: 'je obavezna vrijednost [1-9999]',
         indikatorValidator: 'je obavezna brojčana vrijednost',
         confirmMessage: 'Ažurirati pripadnost terminala'
      },
      prodajnaMjesta: {
         naslov: 'Ažuriranje prodajnih mjesta',
         navigacijaLabel: 'Prodajna mjesta',
         sifraProdajnogMjesta: 'Šifra prodajnog mjesta',
         sifraProdajnogMjestaPlaceholder: 'Brojčana vrijednost [1-9999]',
         sifraProdajnogMjestaValidator: 'je obavezna vrijednost [1-9999]',
         sifraNaseljenogMjesta: 'Poštanki broj mjesta',
         sifraNaseljenogMjestaPlaceholder: 'Brojčana vrijednost [10000-99999]',
         sifraNaseljenogMjestaValidator: 'je u rasponu [10000-99999]',
         nazivProdajnogMjesta: 'Naziv',
         nazivProdajnogMjestaPlaceholder: 'Maksimalno 21 znak',
         nazivProdajnogMjestaValidator: ' - maksimalna dužina je 21 znak',
         adresaProdajnogMjesta: 'Adresa',
         adresaProdajnogMjestaPlaceholder: 'Maksimalno 21 znak',
         adresaProdajnogMjestaValidator: ' - maksimalna dužina je 21 znak',
         aktivnostProdajnogMjesta: 'Aktivnost',
         aktivnostProdajnogMjestaPlaceholder: 'Brojčana vrijednost [0-3]',
         aktivnostProdajnogMjestaValidator: 'je broj u rasponu [0-3]',
         pozivniBrojTelefona: 'Pozivni broj telefona',
         pozivniBrojTelefonaPlaceholder: 'Brojčana vrijednost [0-999]',
         pozivniBrojTelefonaValidator: 'je broj u rasponu [0-999]',
         brojTelefona: 'Broj telefona',
         brojTelefonaPlaceholder: 'Brojčana vrijednost [0-9999999]',
         brojTelefonaValidator: 'je broj u rasponu [0-9999999]',
         sifraOpcine: 'Šifra općine',
         sifraOpcinePlaceholder: 'Brojčana vrijednost [0-9500]',
         sifraOpcineValidator: 'je broj u rasponu [0-9500]',
         sifraRegije: 'Šifra regije',
         sifraRegijePlaceholder: 'Brojčana vrijednost [0-99]',
         sifraRegijeValidator: 'je broj u rasponu [0-99]',
         tipLokala: 'Tip lokala',
         tipLokalaPlaceholder: 'Brojčana vrijednost [0-999]',
         tipLokalaValidator: 'je broj u rasponu [0-999]',
         brojTerminala: 'Broj terminala',
         brojTerminalaPlaceholder: 'Brojčana vrijednost [0-99]',
         brojTerminalaValidator: 'je broj u rasponu [0-99]',
         kategorijaProdajnogMjesta: 'Kategorija',
         kategorijaProdajnogMjestaPlaceholder: 'Brojčana vrijednost [0-49]',
         kategorijaProdajnogMjestaValidator: 'je broj u rasponu [0-49]',
         zabranaProdajeBingo: 'Zabrana prodaje Bingo',
         zabranaIsplateBingo: 'Zabrana isplate Bingo',
         confirmMessage: 'Ažurirati podatke prodajnog mjesta?'
      },
      pregledListica: {
         naslov: 'Pregled listića',
         navigacijaLabel: 'Listići',
         operativniBroj: 'Operativni broj',
         operativniBrojPlaceholder: 'Brojčana vrijednost [1-9999]',
         operativniBrojValidator: 'je broj u rasponu [1-9999]',
         godina: 'Godina',
         godinaPlaceholder: 'Unesite godinu',
         godinaValidator: 'je brojčana vrijednost',
         sedmica: 'Sedmica',
         sedmicaPlaceholder: 'Brojčana vrijednost [1-53]',
         sedmicaValidator: 'je broj u rasponu [1-53]',
         dan: 'Dan',
         danPlaceholder: 'Brojčana vrijednost [1-7]',
         danValidator: 'je broj u rasponu [1-7]',
         brojListica: 'Broj listića',
         brojListicaPlaceholder: 'Brojčana vrijednost [1-9999]',
         brojListicaValidator: 'je broj u rasponu [1-9999]',
         slog: 'Slog',
         slogPlaceholder: 'Unesite broj...',
         slogValidator: 'je broj',
         kombinacija: 'Kombinacija',
         btnSlog: 'Slog',
         btnListic: 'Listić',
         btnPrikaziUplate: 'Prikaži uplate',
         btnPrikaziIsplate: 'Prikaži isplate',
         btnZatvoriUplateIsplate: 'Zatvori',
         podaciUplate: {
            naslov: 'Podaci uplate',
            operativniBroj: 'Operativni broj',
            godinaSedmicaDanBrojListica: 'Sedmica/dan/broj listića',
            prodajnoMjesto: 'Prodajno mjesto',
            brojTerminala: 'Broj terminala',
            sifraIgre: 'Šifra igre',
            kodIgre: 'Kod igre',
            kolo: 'Kolo igre listića',
            pretplata: 'Pretplata za kola',
            indikatorStorna: 'Indikator storna/Operater',
            brojKombinacija: 'Broj kombinacija',
            ukupnaUplata: 'Ukupna uplata',
            vrijemeUplateNaTerminalu: 'Vrijeme uplate na terminalu',
            vrijemeUplateNaServeru: 'Vrijeme spremanja',
            vrijemeStorna: 'Vrijeme storna',
            brojBingoListica: 'Broj Bingo listića'
         },
         podaciIsplate: {
            naslov: 'Podaci isplate',
            igra: 'Igra',
            indikatorDobitka: 'Indikator dobitka',
            indikatorZastare: 'Indikator zastare',
            dobici: 'Dobici',
            dobitak: 'Dobitak',
            iznosDobitkaUkupno: 'Ukupan iznos dobitaka',
            iznosPoreza: 'Iznos poreza',
            iznosIsplacenIgracu: 'Iznos dobitka isplaćen igraču',
            operaterIsplate: 'Operater isplate',
            datumiVrijemeIsplate: 'Datum i vrijeme isplate',
            brojTerminalaIsplate: 'Broj terminala isplate'
         }
      },
      pregledKumulativa: {
         naslov: 'Pregled kumulativa',
         navigacijaLabel: 'Kumulativi',
         igra: 'Igra',
         filter: 'Filter prikaza',
         operativniBroj: 'Operativni broj zastupnika',
         operativniBrojPlaceholder: 'Brojčana vrijednost [0-9999]',
         operativniBrojValidator: 'je broj u rasponu [0-9999]',
         nepostojeciOPBR: 'Za traženi operativni broj zastupnika nema podataka',
         brojListica: 'Broj listića',
         brojKombinacija: 'Broj kombinacija',
         uplata: 'Uplata',
         isplata: 'Isplata',
         storno: 'Storniranih listića',
         termin: {
            sve: 'Sve',
            ponedjeljak: 'Ponedjeljak',
            utorak: 'Utorak',
            srijeda: 'Srijeda',
            četvrtak: 'Četvrtak',
            petak: 'Petak',
            subota: 'Subota',
            nedjelja: 'Nedjelja',
            tekucaSedmica: 'Tekuća sedmica',
            tekuciObracun: 'Tekući obračun',
            tekuceKolo: 'Tekuće kolo',
            prethodnaSedmica: 'Prethodna sedmica',
            prethodniObracun: 'Prethodni obračun',
            prethodnoKolo: 'Prethodno kolo'
         }
      },
      zastupnici: {
         navigacijaLabel: 'Zastupnici',
         pretraga: 'Pretraga',
         pretragaPlaceholder:
            'Započnite unos prezimena, imena ili operativnog broja',
         listaZastupnika: 'Pregled zastupnika',
         praznaLista: 'Nema unesenih zastpnika',
         dodajZastupnika: 'Dodavanje zastupnika',
         detaljiZastupnikaTitle: 'Detalji zastupnika',
         operativniBrojZastupnikaAkronim: 'OPBZ',
         operativniBrojZastupnika: 'Operativni broj zastupnika',
         operativniBrojZastupnikaValidator: ' je broj u rasponu [1-9999]',
         operativniBrojZastupnikaPlaceholder: '1-9999',
         title: 'Titula',
         titlePlaceholder: ' ',
         titleValidator: 'Maksimalno 7 znakova',
         ime: 'Ime',
         imePlaceholder: 'Unesite ime',
         imeValidator: ' je obavezno (max 31 znak)',
         prezime: 'Prezime',
         prezimePlaceholder: 'Unesite prezime',
         prezimeValidator: ' je obavezno (max 31 znak)',
         skracenoPrezimeIme: 'Skraćeno prezime i ime',
         skracenoPrezimeImePlaceholder: 'Unesite skraćeno prezime i ime',
         skracenoPrezimeImeValidator: 'Maksimalno 31 znak',
         prezimeIme: 'Prezime i ime',
         prezimeImePlaceholder: 'Unesite prezime i ime',
         prezimeImeValidator: ' je obavezno (max 21 znak)',
         imeNadredjenogZastupnika: 'Ime nadređenog zastupnika',
         imeNadredjenogZastupnikaPlaceholder: ' ',
         prezimeNadredjenogZastupnika: 'Prezime nadređenog zastupnika',
         prezimeNadredjenogZastupnikaPlaceholder: ' ',
         imePrezimeNadredjenogZastupnika: 'Nadređeni zastupnik',
         tipZastupnika: 'Tip zastupnika',
         kategorija: 'Kategorija',
         kategorijaPlaceholder: '1-9999',
         kategorijaValidator: ' je broj u rasponu [1-9999]',
         operativniBrojNadredjenogZastupnika: 'OPB nadređenog zastupnika',
         operativniBrojNadredjenogZastupnikaTH: 'OPB nadređenog zastupnika',
         operativniBrojNadredjenogZastupnikaPlaceholder:
            'Unesite operativni broj nadređenog zastupnika',
         operativniBrojNadredjenogZastupnikaValidator:
            ' je brojčana vrijednost',
         prezimeImeNadredjenogZastupnika: 'Prezime i ime nadređenog zastupnika',
         prezimeImeNadredjenogZastupnikaPlaceholder: ' ',
         prezimeImeNadredjenogZastupnikaValidator: ' je obavezan unos',
         tipNadredjenogZastupnika: 'Tip nadređenog zastupnika',
         aktivnostZastupnika: 'Aktivnost zastupnika',
         datumRodjenja: 'Datum rođenja',
         telefon: 'Telefon',
         telefonPlaceholder: ' ',
         telefonValidator: ' - maksimalno 31 znak',
         sifraProdajnogMjesta: 'Šifra prodajnog mjesta',
         sifraProdajnogMjestaPlaceholder: 'Unesite šifru prodajnog mjesta',
         sifraProdajnogMjestaValidator: ' je broj [1-9999]',
         gradProdajnogMjesta: 'Grad prodajnog mjesta',
         gradProdajnogMjestaPlaceholder: ' ',
         adresaProdajnogMjesta: 'Adresa prodajnog mjesta',
         adresaProdajnogMjestaPlaceholder: ' ',
         grupaKorisnika: 'Grupa korisnika',
         grupaKorisnikaPlaceholder: 'Unesite grupu korisnika',
         grupaKorisnikaValidator: ' je broj u rasponu [0-50]',
         password: 'Lozinka',
         passwordPlaceholder: 'Unesite lozinku',
         passwordValidator: '  je broj u rasponu [1-999999]',
         postotakProvizije: 'Postotak provizije',
         postotakProvizijePlaceholder: 'Unesite postotak provizije',
         postotakProvizijeValidator: ' je broj u rasponu [1-9999]',
         pravoIsplateVelikihDobitaka: 'Pravo isplate velikih dobitaka',
         depozit: 'Depozit',
         depozitPlaceholder: 'Unesite depozit',
         depozitValidator: '...',
         napomena: 'Napomena',
         nazivProdajnogMjesta: 'Opis prodajnog mjesta',
         nazivProdajnogMjestaPlaceholder: ' ',
         pravoUplateBingo: 'Bingo pravo uplate',
         pravoIsplateBingo: 'Bingo pravo isplate',
         pravoUplateKeno: 'Keno pravo uplate',
         pravoIsplateKeno: 'Keno pravo isplate',
         brojTerminala: 'Broj terminala',
         datumPrijave: 'Datum prijave',
         vrijemePrijave: 'Vrijeme prijave',
         operaterUpisa: 'Operater upisa',
         datumUpisa: 'Datum upisa',
         vrijemeUpisa: 'Vrijeme zadnjeg upisa',
         vrijemeZadnjePrijave: 'Vrijeme zadnje prijave',
         vrijemeZadnjeOdjave: 'Vrijeme zadnje odjave',
         pravoUplateBingoIsplatePlaceholder: ' ',
         pravoUplateBingoIsplateNotFoundPlaceholder: ' ',
         uredjivanjeTitle: 'Ažuriranje podataka zastupnika',
         igra: 'Igra',
         confirmTitle: 'Dodavanje zastupnika',
         confirmMessage: 'Dodati zastupnika',
         dodajNovog: 'Dodaj novog zastupnika',
         preusmjeravanje: 'Preusmjeravanje na listu zastupnika...'
      },
      azuriranjeObavijesti: {
         naslov: 'Ažuriranje obavijesti za prodavače',
         navigacijaLabel: 'Obavijesti za prodavače',
         redakLabel: 'Redak',
         redakLabelPlaceholder: ' ',
         confirmMessage: 'Ažurirati obavijest za prodavače'
      },
      updating: {
         azuriranjeMjesta: {
            sifraMjesta: '10000',
            telefon: '1234567',
            nazivMjesta: 'Zagreb'
         }
      },
      registerUser: {
         registerUserHeader: 'Registracija novog korisnika',
         name: 'Ime',
         username: 'Korisničko ime',
         password: 'Lozinka',
         passwordPlaceholder: 'Unesite e-mail adresu...',
         registerButton: 'Registriraj se',
         avatarHeader: 'Odabir avatara',
         avatarOdabirSlike: 'Odaberite sliku'
      },
      settings: {
         choseLanguage: 'Jezik',
         croatian: 'HRVATSKI',
         english: 'ENGLESKI',
         themeLabel: 'Tema',
         darkTheme: 'Tamno',
         lightTheme: 'Svijetlo'
      }
   }
};
export default translationObject;
