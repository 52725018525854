export const getSierraLeoneCurrencyData = (jsonData) => {
   let returnValue = {};
   jsonData.forEach((obj, i, arr) => {
      for (const key in obj) {
         const element = obj[key];

         if (
            typeof element === 'string' &&
            element.toLowerCase() === 'sijera leone'
         ) {
            // prestani forEach-at dalje...
            arr.length = i + 1;
            let [
               zemlja,
               naziv,
               slovnaOznaka,
               brojcanaOznaka,
               dolar,
               euro,
               kuna
            ] = getSierraLeoneData(obj);

            returnValue = {
               zemlja,
               naziv,
               slovnaOznaka,
               brojcanaOznaka,
               dolar,
               euro,
               kuna
            };
         }
      }
   });
   return returnValue;
};

const getSierraLeoneData = (obj) => {
   return Object.entries(obj).map((record, index) => record[1]);
};
