import React from 'react';
import Form from '../common/form';
import { loginSchema } from './login-validacije';
import intl from '../../services/intl-service';
import { withRouter } from 'react-router';
import { applyRootElementBackgroundColor } from '../../services/app-service';
import { authService, saveUserData } from '../../services/auth-service';
import { setJWTtoHeader } from '../../services/http';
import { connect } from 'react-redux';
import { ucitajXLSX } from '../../store/SierraLeone/xlsx';

const gotoAfterLogin = `${process.env.REACT_APP_AFTER_LOGIN_URL}`;

class Login extends Form {
   state = {
      validationData: {
         operativniBroj: '',
         password: ''
      },
      errors: {}
   };

   schema = loginSchema;

   showError = (obj) => {
      const errors = { ...this.state.errors, ...obj };
      this.setState({ errors });
   };

   doSubmit = async () => {
      const { operativniBroj, password } = this.state.validationData;
      const { data: response } = await authService.login(
         operativniBroj,
         password
      );

      if (response.isSuccess !== 1) {
         this.showError({
            operativniBroj: intl.getTranslate('login.invalidAgentId')
         });
         return;
      }
      if (!response.data.pravoLogiranja) {
         this.showError({
            password: intl.getTranslate('login.invalidPassword')
         });
         return;
      }
      const { data: userdata } = response;

      saveUserData(userdata);
      setJWTtoHeader(userdata.token);

      this.gotoLandingPage();
   };

   gotoLandingPage = () => this.props.history.push(gotoAfterLogin);

   async componentDidMount() {
      applyRootElementBackgroundColor();
      this.props.ucitajXLSX();
   }

   render() {
      return (
         <form onSubmit={this.handleFormSubmit} className='form'>
            <div className='row'>
               <div className='col'>
                  {this.formInput({
                     name: 'operativniBroj',
                     translationKey: 'login',
                     isNumber: true
                  })}
               </div>
            </div>
            <div className='row'>
               <div className='col'>
                  {this.formInput({
                     name: 'password',
                     translationKey: 'login',
                     attributes: { type: 'password' }
                  })}
               </div>
            </div>
            <hr />
            {this.formSubmit({
               label: `${intl.getTranslate('login.btnLogin')}`
            })}
         </form>
      );
   }
}

const mapStateToProps = (state) => ({
   xlsx: state.common.xlsx
});

const mapDispatchToProps = (dispatch) => ({
   ucitajXLSX: () => dispatch(ucitajXLSX())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
