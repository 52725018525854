import React from 'react';
import Form from '../../common/form';
import intl from '../../../services/intl-service';
import { azuriranjeProvizijeSchema } from './azuriranje-provizije-validacije';
import CustomAlertNoForm from '../../common/custom-alert-no-form';
import InfoVrijemeUpisa from '../../common/info-vrijeme-upisa';
import { toastSuccessSave } from '../../../services/toast-service';
import { toastError } from '../../../services/toast-service';
import FramerConditionalCollapsableContainer from '../../common/framer-conditional-collapsable-container';
import { a03postoService as service } from './../../../services/SierraLeone/A03POSTO-service';
import { connect } from 'react-redux';
import PostotakProvizijeInfoTable from './azuriranje-provizije-info-table';
import FramerConditionalOpacityContainer from './../../common/framer-conditional-opacity-container';
import PregledAzuriranjaProvizije from './pregled-azuriranja-provizije';
import Datepicker from './../../common/ant-datepicker';
import {
   formatDateToInt,
   todayInt
} from '../../../services/form-components-service';
import DDL from '../../common/ant-ddl-translate';
import { ucitajSveZastupnike } from '../../../store/zastupnici';
import { getUserId } from './../../../services/auth-service';

const translationKey = 'azuriranjeProvizije';
class AzuriranjeProvizije extends Form {
   state = {
      validationData: {
         operativniBroj: '',
         postotakProvizijeKeno: 0
      },
      responseData: {},
      pregledZaDatum: todayInt(),
      dataReloaded: false,
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      showConfirm: false,
      showDetails: false
   };

   schema = azuriranjeProvizijeSchema;

   doSubmit = async () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const postData = { ...this.state.validationData };

      const { data: response } = await service.addOrUpdate(postData);
      const { isSuccess, msg } = response;

      if (isSuccess) {
         toastSuccessSave();
         this.resetForm();
      } else toastError(msg);

      this.setState({ showConfirm: false });
   };

   resetForm = () => {
      const validationData = { ...this.state.validationData };
      validationData.operativniBroj = '';
      this.setState({ showDetails: false, validationData });
   };

   loadData = async () => {
      const { operativniBroj } = this.state.validationData;
      const { data: response } = await service.get(operativniBroj);

      if (!response.data) {
         toastError(response.msg);
         this.setState({ showDetails: false });
         return;
      }

      this.setState({ showDetails: true });

      const responseData = { ...response.data };
      const validationData = { ...this.state.validationData };
      validationData.postotakProvizijeKeno = responseData.postotakProvizijeKeno;

      this.setState({ validationData, responseData });
   };

   handleOperativniBrojChange = async ({ target: input }) => {
      const validationData = { ...this.state.validationData };
      if (input.value === '') {
         validationData.operativniBroj = '';
         this.setState({ validationData, showDetails: false });
         return;
      }
      if (isNaN(input.value)) return;
      const operativniBroj = Number(input.value);
      if (operativniBroj >= 1 && operativniBroj <= 9_99999) {
         this.loadData();
      } else {
         validationData.operativniBroj = '';
         this.setState({ validationData, showDetails: false });
      }
   };

   handleDateChanged = (e) => {
      if (!e) return;

      const pregledZaDatum = formatDateToInt(e.format('DD.MM.YYYY'));
      this.setState({ pregledZaDatum });
   };

   handleDDLcahnge = (e) => {
      const validationData = { ...this.state.validationData };
      validationData.operativniBroj = e.value;
      this.setState({ validationData }, () => {
         this.loadData();
      });
   };

   getDDLconvertedZastupnici = (e) => {
      const { sviZastupnici } = this.props;
      return sviZastupnici.map((z) => ({
         id: z.operativniBrojZastupnika,
         punoIme: `${z.ime} ${z.prezime} [${z.operativniBrojZastupnika}]`
      }));
   };

   render() {
      const {
         validationData,
         datumUpisa,
         vrijemeUpisa,
         showConfirm,
         responseData,
         showDetails,
         pregledZaDatum,
         dataReloaded
      } = this.state;

      //ako se desi refresh stranice...
      const { sviZastupnici } = this.props;
      if (sviZastupnici.length === 0 && !dataReloaded) {
         this.props.ucitajSveZastupnike();
         // this.setState({ dataReloaded: true });
      }

      const { postotakProvizijeKeno, operativniBroj } = validationData;
      const noData = this.getDDLconvertedZastupnici().length === 0;

      return (
         <>
            <CustomAlertNoForm
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               btnCancel={intl.getTranslate('common.cancel')}
               onOk={this.doSubmitOnConfirm}
            >
               <div>
                  {intl.getTranslate('azuriranjeProvizije.confirmMessage')}
               </div>
            </CustomAlertNoForm>

            <form onSubmit={this.handleFormSubmit} className='form' noValidate>
               <div className='row'>
                  {/* LIJEVI STUPAC */}
                  <div className='col border-right'>
                     <div className='row'>
                        <div className='col'>
                           {noData && (
                              <span>
                                 {intl.getTranslate(
                                    'azuriranjeProvizije.noData'
                                 )}
                              </span>
                           )}
                           {!noData && (
                              <DDL
                                 options={this.getDDLconvertedZastupnici()}
                                 translationKey='azuriranjeProvizije'
                                 translationSubKey='zastupnik'
                                 onChange={this.handleDDLcahnge}
                                 selectedOption={operativniBroj}
                                 text='punoIme'
                                 value='id'
                              />
                           )}
                        </div>
                        <div className='col'>
                           <FramerConditionalOpacityContainer
                              condition={showDetails}
                              delay={0.2}
                           >
                              {this.formInput({
                                 name: 'postotakProvizijeKeno',
                                 translationKey,
                                 isNumber: true,
                                 lblSufix: `${(
                                    postotakProvizijeKeno / 100
                                 ).toFixed(2)}%`
                              })}
                           </FramerConditionalOpacityContainer>
                        </div>
                     </div>
                     <FramerConditionalCollapsableContainer
                        condition={showDetails}
                        delay={0.2}
                        height='25rem'
                     >
                        <div className='row'>
                           <div className='col'>
                              <PostotakProvizijeInfoTable data={responseData} />
                           </div>
                        </div>
                        <hr className='mt-4' />
                        <InfoVrijemeUpisa
                           datumUpisa={datumUpisa}
                           vrijemeUpisa={vrijemeUpisa}
                        />
                        {this.formSubmit({
                           label: `${intl.getTranslate('common.update')}`
                        })}
                     </FramerConditionalCollapsableContainer>
                  </div>
                  {/* DESNI STUPAC */}
                  <div className='col'>
                     {!noData && (
                        <>
                           <Datepicker
                              label={intl.getTranslate(
                                 'azuriranjeProvizije.pregledZaDatum'
                              )}
                              onChange={this.handleDateChanged}
                              placeholder={intl.getTranslate(
                                 'azuriranjeProvizije.pregledPlaceholder'
                              )}
                           />
                           <PregledAzuriranjaProvizije date={pregledZaDatum} />
                        </>
                     )}
                  </div>
               </div>
            </form>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   statickiPodaci: state.zastupnici.statickiPodaci,
   sviZastupnici: state.zastupnici.sviZastupnici
});

const mapDispatchToProps = (dispatch) => ({
   ucitajSveZastupnike: () => dispatch(ucitajSveZastupnike())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AzuriranjeProvizije);
