import React, { Component } from 'react';
import Joi from 'joi-browser';
import Input from './input';
import { DatePicker } from 'antd';
import SelectAnt from './select-ant';
import 'moment/locale/hr';
import locale from 'antd/es/date-picker/locale/hr_HR';
import intl from '../../services/intl-service';
import DOMPurify from 'dompurify';
import validator from 'validator';
import { debounceFormInput } from './../../services/utilities-service';

class Form extends Component {
   state = {
      validationData: {},
      errors: {},
      debounceDelay: 0
   };

   /*
      Validacija svih svojstava validationData objekta, cijele forme...
   */
   validateForm = () => {
      //validiraj
      const objectToValidate = this.state.validationData;
      const options = {
         abortEarly: false,
         allowUnknown: true
      };
      const result = Joi.validate(objectToValidate, this.schema, options);
      // console.log(result);

      //ako je sve ok...
      if (!result.error) return null;

      //dodijeli greške errors objektu
      const errors = {};
      for (const item of result.error.details) {
         errors[item.path[0]] = item.message;
      }

      return errors;
   };

   /*
    Validacija jednog od svojstava validationData objekta
 */
   validateProperty = ({ name, value }) => {
      //validira se samo jedno svojstvo validationData objekta
      const objectToValidate = { [name]: value };
      //shemu uzimam sa postojece sheme za validationData objekt
      const schema = { [name]: this.schema[name] };

      const result = Joi.validate(objectToValidate, schema, {
         allowUnknown: true
      });
      return result.error ? result.error.details[0].message : null;
   };

   handleFormSubmit = (e) => {
      e.preventDefault();

      const errors = this.validateForm();
      //console.log('errors', errors);
      this.setState({ errors: errors || {} });

      this.doSubmit();
   };

   executeAfterStateUpdateFn = debounceFormInput((e, fn) => {
      fn(e);
   });

   /*
      Eventualni dodatni eventhandler se izvršava nakon
      ažuriranja state-a
   */
   handleInputChange = ({ target: input, e, fn }) => {
      const errors = { ...this.state.errors };
      const errorMessage = this.validateProperty(input);

      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];

      const validationData = { ...this.state.validationData };

      const cleanString = DOMPurify.sanitize(input.value);
      const isEmptyString = validator.isEmpty(cleanString);
      const inputType = input.attributes.type.value;

      if (inputType === 'text') validationData[input.id] = cleanString;
      else if (inputType === 'number')
         validationData[input.id] = isEmptyString ? '' : Number(cleanString);
      else validationData[input.id] = input.value;

      this.setState({ validationData, errors }, () => {
         // if (fn !== null) fn(e);
         if (fn !== null) this.executeAfterStateUpdateFn(e, fn);
      });
   };

   renderSubmitButton = (label, rest, onClick) => (
      <button
         autoFocus
         className='button save'
         type='submit'
         disabled={this.validateForm()}
         onClick={onClick}
         {...rest}
      >
         {label}
      </button>
   );

   formSubmit = ({ label, rest, onClick }) => (
      <button
         autoFocus
         className='button save'
         type='submit'
         disabled={this.validateForm()}
         onClick={onClick}
         {...rest}
      >
         {label}
      </button>
   );

   /*
      fn
      daje mogucnost da se ubaci funkcija koja će se izvršit uz
      ovu klasični [handleInputChange] event handler
   */
   renderInput = (name, label, attributes, fn = null, lblSufix) => {
      const { validationData, errors } = this.state;
      //DOVRŠI autoFocus
      return (
         <Input
            id={name}
            name={name}
            label={label}
            {...attributes}
            value={validationData[name]}
            error={errors[name]}
            onChange={(e) => {
               e.e = e;
               e.fn = fn;
               this.handleInputChange(e);
            }}
            lblSufix={lblSufix}
         />
      );
   };

   formInput = ({
      name,
      translationKey,
      fn = null,
      isNumber = false,
      label = '',
      placeholder = '',
      lblSufix = '',
      debounceDelay = 0,
      attributes = {}
   }) => {
      const { validationData, errors } = this.state;

      const _placeholder =
         placeholder !== ''
            ? placeholder
            : intl.getTranslate(`${translationKey}.${name}Placeholder`);

      const allowNegativeValues =
         attributes && attributes.allowNegativeValues ? true : false;

      const _label =
         label === '' ? intl.getTranslate(`${translationKey}.${name}`) : label;

      return (
         <Input
            id={name}
            name={name}
            label={_label}
            placeholder={_placeholder ? _placeholder : ''}
            isNumber={isNumber}
            {...attributes}
            value={validationData[name]}
            allowNegativeValues={allowNegativeValues}
            error={errors[name]}
            onChange={(e) => {
               e.e = e;
               e.fn = fn;
               e.debounceDelay = debounceDelay;
               this.handleInputChange(e);
            }}
            lblSufix={lblSufix}
         />
      );
   };

   renderTranslateInput = (
      name,
      translationKey,
      fn = null,
      isNumber,
      lblSufix,
      attributes = {}
   ) => {
      const { validationData, errors } = this.state;

      const placeholder = intl.getTranslate(
         `${translationKey}.${name}Placeholder`
      );

      const allowNegativeValues =
         attributes && attributes.allowNegativeValues ? true : false;

      return (
         <Input
            id={name}
            name={name}
            label={intl.getTranslate(`${translationKey}.${name}`)}
            placeholder={placeholder ? placeholder : ''}
            isNumber={isNumber}
            {...attributes}
            value={validationData[name]}
            allowNegativeValues={allowNegativeValues}
            error={errors[name]}
            onChange={(e) => {
               e.e = e;
               e.fn = fn;
               this.handleInputChange(e);
            }}
            lblSufix={lblSufix}
         />
      );
   };

   renderDatePicker = (label, onChange, rest, dateValidationMessage) => {
      return (
         <div className='form-group'>
            <label className='mr-1'>{label}</label>
            <DatePicker
               format='DD.MM.YYYY'
               locale={locale}
               style={{ borderRadius: '.2rem', width: '100%' }}
               onChange={onChange}
               {...rest}
            />
            {dateValidationMessage && (
               <div className='alert alert-primary' style={{ fontWeight: 700 }}>
                  {dateValidationMessage}
               </div>
            )}
         </div>
      );
   };

   renderSelectAnt = (
      label,
      text,
      value,
      placeholder,
      notFoundContent,
      selectOptions,
      onChange,
      onSearch,
      attributes,
      requiredLabel
   ) => {
      return (
         <SelectAnt
            label={label}
            text={text}
            value={value}
            placeholder={placeholder}
            notFoundContent={notFoundContent}
            selectOptions={selectOptions}
            onChange={onChange}
            onSearch={onSearch}
            attributes={attributes}
            requiredLabel={requiredLabel}
         />
      );
   };

   renderTranslateSelect = (
      labelTranslationSubKey,
      translationKey,
      selectOptions,
      onChange,
      attributes,
      hideLabel = false,
      requiredLabel
   ) => {
      const placeholder = intl.getTranslate(`common.ddlPlaceholder`);
      const notFound = intl.getTranslate(`common.ddlNotFound`);

      return (
         <SelectAnt
            label={intl.getTranslate(
               `${translationKey}.${labelTranslationSubKey}`
            )}
            text='text'
            value='value'
            placeholder={placeholder}
            notFoundContent={notFound}
            selectOptions={selectOptions}
            onChange={onChange}
            onSearch={null}
            hideLabel={hideLabel}
            attributes={attributes}
            requiredLabel={requiredLabel}
         />
      );
   };

   resetForm = (initValidationDataPropertyName) => {
      setTimeout(() => {
         const validationData = { ...this.state.validationData };
         validationData[initValidationDataPropertyName] = '';
         this.setState({ validationData });
      }, 1000);
   };
}

export default Form;
