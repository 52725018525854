import React from 'react';
import Form from '../common/form';
import { injectIntl } from 'react-intl';
import intl from '../../services/intl-service';
import CustomAlertNoForm from '../common/custom-alert-no-form';
import { azuriranjeObavijestiSchema } from './azuriranje-obavijesti-validacije';
import { azuriranjeObavijestiService as service } from './../../services/azuriranje-obavijesti-service';
import { AnimatePresence } from 'framer-motion';
import { toastError, toastSuccess } from './../../services/toast-service';
import { DataLoader } from '../../services/data-loader-service';
//Debounce: https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js

class AzuriranjeObavijesti extends Form {
   state = {
      validationData: {},
      data: null,
      errors: {},
      showConfirm: false
   };

   schema = azuriranjeObavijestiSchema;

   onDataLoad = (response) => {
      const { data } = response;
      const validationData = { ...data };

      this.setState({ validationData });
   };

   componentDidMount() {
      DataLoader(service.get, null, this.onDataLoad);
   }

   handleRedakChange = async ({ target: input }) => {
      // eventhandler
   };

   doSubmit = () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const { validationData } = this.state;

      const { data: response } = await service.addOrUpdate(validationData);

      if (response.isSuccess === 1) toastSuccess();
      else toastError();

      this.setState({ showConfirm: false });
   };

   render() {
      const { validationData, showConfirm } = this.state;

      /*
         Ispiši sve ključeve objhekta
         entries() vrača polje polja, svaki član polja je polje [ključ, vrijednost]
      */
      const renderData = Object.entries(validationData);

      return (
         <>
            <AnimatePresence>
               {showConfirm && (
                  <CustomAlertNoForm
                     showCondition={true}
                     onCancel={() => this.setState({ showConfirm: false })}
                     onOk={() => this.doSubmitOnConfirm()}
                  >
                     <div>
                        {intl.getTranslate(
                           'azuriranjeObavijesti.confirmMessage'
                        )}
                        {'?'}
                        <span className='azuriranje-obavijesti-confirm-highlight'>
                           {renderData.map((d) => (
                              <div key={`confirm${d[0]}${d[1]}`}>{d[1]}</div>
                           ))}
                        </span>
                     </div>
                  </CustomAlertNoForm>
               )}
            </AnimatePresence>

            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-opcinu'>
                  {renderData && renderData.length === 0 && (
                     <span>Data error...</span>
                  )}
                  {renderData && renderData.length > 0 && (
                     <>
                        {renderData.map((redak, i) => (
                           <div className='row' key={`redak${i + 1}`}>
                              <div className='col-4'>
                                 {this.renderInput(
                                    `${
                                       i < 9
                                          ? 'redak0' + (i + 1)
                                          : 'redak' + (i + 1)
                                    }`,
                                    '',
                                    {
                                       placeholder: `Redak ${i + 1}`
                                    },
                                    this.handleRedakChange
                                 )}
                              </div>
                           </div>
                        ))}
                        <hr />
                        {this.renderSubmitButton(
                           `${intl.getTranslate('common.update')}`
                        )}
                     </>
                  )}
               </div>
            </form>
         </>
      );
   }
}

export default injectIntl(AzuriranjeObavijesti);
