import React from 'react';
import Form from '../../common/form';
import { dodavanjeZastupnikaSchema } from './zastupnik-dodavanje-validacije';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ucitajStatickePodatkeZastupnika } from '../../../store/zastupnici';
import DDL from '../../common/ant-ddl-translate';
import AntDatepicker from '../../common/ant-datepicker';
import intl from '../../../services/intl-service';
import CustomAlertNoForm from '../../common/custom-alert-no-form';
import { AnimatePresence } from 'framer-motion';
import { zastupniciService } from '../../../services/zastupnici-service';
import {
   formatDateToInt,
   getTranslatedDDLoptions
} from '../../../services/form-components-service';
import {
   toastSuccessSave,
   toastError,
   toastInfo
} from '../../../services/toast-service';
import { getToasterIntervals } from '../../../services/app-service';
import { Prompt, withRouter } from 'react-router';
import { FALSE } from 'sass';
import { paths } from '../../../routes/routes';

const translationKey = 'zastupnici';
class DodajZastupnika extends Form {
   state = {
      validationData: {
         adresaProdajnogMjesta: '',
         aktivnostZastupnika: 1,
         brojTerminalaZaduzen: 0,
         datumRodjenja: 0,
         depozit: 0,
         gradProdajnogMjesta: '',
         grupaKorisnika: 0,
         ime: '',
         kategorija: 0,
         napomena: '',
         nazivProdajnogMjesta: '',
         operaterUpisa: 14,
         operativniBrojNadredjenogZastupnika: 0,
         operativniBrojZastupnika: '',
         osobniID: '',
         password: 0,
         postotakProvizije: 0,
         postotakProvizijeBingo: 0,
         postotakProvizijeKeno: 0,
         pravoIsplateBingo: 0,
         pravoIsplateKeno: 0,
         pravoIsplateVelikihDobitaka: 0,
         pravoUplateBingo: 0,
         pravoUplateKeno: 0,
         prezime: '',
         sifraProdajnogMjesta: 0,
         skracenoPrezimeIme: '',
         telefon: '',
         tipNadredjenogZastupnika: 0,
         tipZastupnika: 0,
         title: ''
      },
      nadredjeniZastupniciSvi: [],
      nadredjeniZastupnici: [],
      prodajnaMjesta: [],
      errors: {},
      isEnteringFormValues: false,
      showConfirm: false,
      showOPBRalert: false
   };

   schema = dodavanjeZastupnikaSchema;

   componentDidMount() {
      const { ucitajStatickePodatkeZastupnika } = this.props;
      ucitajStatickePodatkeZastupnika();
      this.loadNadredjeniZastupnici();
      this.loadProdajnaMjesta();
   }

   loadProdajnaMjesta = async () => {
      const { data: response } = await zastupniciService.getProdajnaMjestaSva();

      const prodajnaMjesta = response.map((pm) => ({
         ...pm,
         naziv: `[${pm.sifra}] ${pm.naziv}`
      }));
      this.setState({ prodajnaMjesta });
   };

   /*
      Nardređeni zastupnik - tip je uvijek za 1 veci od tipa zastupnika
      1. Dohvati sve
      2. Filtriraj po tipu
      3. Definiraj punoIme
      4. Ažuriraj prikaz tipa nadređenog zastupnika
   */
   loadNadredjeniZastupnici = async () => {
      const { data: response } =
         await zastupniciService.getNadredjeniZastupnici();

      this.setState({ nadredjeniZastupniciSvi: response }, () => {
         this.filtrirajNadredjeneZastupnike();
      });
   };

   filtrirajNadredjeneZastupnike = () => {
      const { nadredjeniZastupniciSvi: data } = this.state;
      const { tipZastupnika } = this.state.validationData;

      // ako nije odabran tip zastupnika nema niti nadređenog zastupnika
      if (tipZastupnika === 0) {
         const validationData = { ...this.state.validationData };
         validationData.operativniBrojNadredjenogZastupnika = 0;
         validationData.tipNadredjenogZastupnika = 0;
         this.setState({ validationData });
      }

      const filtriraniPoTipu = data.filter(
         (nz) => nz.tip === tipZastupnika + 1
      );

      const nadredjeniZastupnici = filtriraniPoTipu.map((nz) => {
         return {
            ...nz,
            punoIme: `[${nz.operativniBroj}] ${nz.ime} ${nz.prezime}`
         };
      });
      this.setState({ nadredjeniZastupnici });
   };

   azurirajTipNadredjenogZastupnika = (opbrNardedjenog) => {
      const { nadredjeniZastupniciSvi: data } = this.state;
      const found = data.find((x) => x.operativniBroj === opbrNardedjenog);

      if (found) {
         const validationData = { ...this.state.validationData };
         validationData.tipNadredjenogZastupnika = found.tip;
         this.setState({ validationData });
      }
   };

   handleDatumRodjenjaChange = (e) => {
      if (!e) return;

      const { validationData } = this.state;
      validationData.datumRodjenja = formatDateToInt(e.format('DD.MM.YYYY'));

      this.setState({ validationData });
   };

   handleDDLSelected = (e, propertyName) => {
      const { value } = e;
      const validationData = { ...this.state.validationData };
      validationData[propertyName] = value;

      this.setState(
         {
            validationData
         },
         () => {
            switch (propertyName) {
               case 'tipZastupnika':
                  this.filtrirajNadredjeneZastupnike();
                  break;
               case 'operativniBrojNadredjenogZastupnika':
                  this.azurirajTipNadredjenogZastupnika(value);
                  break;
               case 'sifraProdajnogMjesta':
                  this.showDetails_ProdajnoMjesto(value);
                  break;
               default:
                  break;
            }
         }
      );
   };

   showDetails_ProdajnoMjesto = (sifra) => {
      const { prodajnaMjesta } = this.state;
      const pm = prodajnaMjesta.find((p) => p.sifra === sifra);

      const validationData = { ...this.state.validationData };
      validationData.adresaProdajnogMjesta = pm.adresa;
      validationData.gradProdajnogMjesta = pm.grad;

      this.setState({ validationData });
   };

   checkIfUserExsists = async ({ target: input }) => {
      const operativniBroj = parseInt(input.value);
      if (isNaN(operativniBroj)) return;

      const { isSuccess } = await zastupniciService.getZastupnik({
         operativniBroj
      });

      const inputDOM = this.opbr.current.inputElement.current;

      if (isSuccess) {
         this.setState({ showOPBRalert: true });
         inputDOM.focus();
      }
   };

   doSubmit = () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      this.setState({ isEnteringFormValues: false });
      const { successAutoClose, redirectAutoClose, redirectDelay } =
         getToasterIntervals();

      const { validationData: newZastupnik } = this.state;

      // konverzija tipa podataka za backend
      newZastupnik.password = newZastupnik.password.toString();
      newZastupnik.telefon = newZastupnik.telefon.toString();

      const response = await zastupniciService.addOrUpdate(newZastupnik);

      if (response.isSuccess === 1) {
         toastSuccessSave(
            intl.getTranslate('common.podaciUspjesnoSpremljeni'),
            {
               autoClose: successAutoClose,
               onClose: () => this.props.history.push(paths.overview_agents)
            }
         );
         toastInfo(intl.getTranslate('zastupnici.preusmjeravanje'), {
            autoClose: redirectAutoClose,
            delay: redirectDelay
         });
      } else toastError(response.msg);

      this.setState({ showConfirm: false });
   };

   handleFocus = () => {
      this.setState({ isEnteringFormValues: true });
   };

   opbr = React.createRef();

   render() {
      const {
         validationData,
         nadredjeniZastupnici,
         prodajnaMjesta,
         showConfirm,
         showOPBRalert
      } = this.state;
      const {
         ime,
         operativniBrojNadredjenogZastupnika: opbrNardedjenog,
         pravoIsplateBingo,
         pravoIsplateKeno,
         pravoIsplateVelikihDobitaka,
         pravoUplateBingo,
         pravoUplateKeno,
         postotakProvizijeBingo,
         postotakProvizijeKeno,
         prezime,
         sifraProdajnogMjesta,
         tipNadredjenogZastupnika,
         tipZastupnika,
         aktivnostZastupnika
      } = validationData;
      const { zastupnici } = this.props;
      const { statickiPodaci } = zastupnici;

      //  Vrijednosti DDL opcija vucem iz statickih podataka
      let {
         tipZastupnika: tz,
         tipNadredjenogZastupnika: tnz,
         aktivnostZastupnika: az,
         pravoIsplateVelikihDobitaka: pivd,
         pravoUplateBingo: pub,
         pravoIsplateBingo: pib,
         pravoIsplateKeno: pik,
         pravoUplateKeno: puk
      } = statickiPodaci;

      tz = getTranslatedDDLoptions(tz);
      tnz = getTranslatedDDLoptions(tnz);
      az = getTranslatedDDLoptions(az);
      pivd = getTranslatedDDLoptions(pivd);
      pub = getTranslatedDDLoptions(pub);
      pib = getTranslatedDDLoptions(pib);
      pik = getTranslatedDDLoptions(pik);
      puk = getTranslatedDDLoptions(puk);

      return (
         <div className='add-agent'>
            <Prompt
               when={this.state.isEnteringFormValues}
               message={(location) =>
                  `${intl.getTranslate('common.gubitakPodataka')}`
               }
            />
            <AnimatePresence>
               {showOPBRalert && (
                  <CustomAlertNoForm
                     title={intl.getTranslate('common.warning')}
                     btnOk='Ok'
                     alertMode={true}
                     onOk={() => this.setState({ showOPBRalert: false })}
                  >
                     <div>{intl.getTranslate('zastupnici.opbrWarning')}</div>
                  </CustomAlertNoForm>
               )}
            </AnimatePresence>
            <AnimatePresence>
               {showConfirm && (
                  <CustomAlertNoForm
                     title={intl.getTranslate('zastupnici.confirmTitle')}
                     btnOk={intl.getTranslate('common.save')}
                     showCondition={true}
                     onCancel={() => this.setState({ showConfirm: false })}
                     onOk={() => this.doSubmitOnConfirm()}
                  >
                     <div>
                        {intl.getTranslate('zastupnici.confirmMessage')}{' '}
                        <span className='confirm-highlight'>{`${ime} ${prezime}`}</span>
                        ?
                     </div>
                  </CustomAlertNoForm>
               )}
            </AnimatePresence>
            <form onSubmit={this.handleFormSubmit} onFocus={this.handleFocus}>
               <div className='dodaj-zastupnika form'>
                  <div className='row'>
                     <div className='col'>
                        {this.formInput({
                           name: 'operativniBrojZastupnika',
                           translationKey,
                           isNumber: true,
                           attributes: {
                              onBlur: this.checkIfUserExsists,
                              ref: this.opbr
                           }
                        })}
                     </div>
                     <div className='col'></div>
                     <div className='col'></div>
                     <div className='col'></div>
                     <div className='col'></div>
                  </div>

                  <div className='row'>
                     <div className='col'>
                        {this.formInput({
                           name: 'title',
                           translationKey
                        })}
                        {this.formInput({
                           name: 'ime',
                           translationKey
                        })}
                        {this.formInput({
                           name: 'prezime',
                           translationKey
                        })}
                        {this.formInput({
                           name: 'skracenoPrezimeIme',
                           translationKey
                        })}
                     </div>

                     <div className='col'>
                        <AntDatepicker
                           label={intl.getTranslate('zastupnici.datumRodjenja')}
                           onChange={this.handleDatumRodjenjaChange}
                           defaultDate={null}
                        />

                        {this.formInput({
                           name: 'telefon',
                           translationKey,
                           attributes: { maxLength: '19' }
                        })}

                        {this.formInput({
                           name: 'osobniID',
                           translationKey
                        })}

                        {this.formInput({
                           name: 'brojTerminalaZaduzen',
                           translationKey,
                           isNumber: true
                        })}
                     </div>

                     <div className='col'>
                        <DDL
                           options={az}
                           translationKey='zastupnici'
                           translationSubKey='aktivnostZastupnika'
                           onChange={this.handleDDLSelected}
                           selectedOption={aktivnostZastupnika}
                        />

                        <DDL
                           options={tz}
                           translationKey='zastupnici'
                           translationSubKey='tipZastupnika'
                           onChange={this.handleDDLSelected}
                           selectedOption={tipZastupnika}
                        />

                        <DDL
                           options={nadredjeniZastupnici}
                           translationKey='zastupnici'
                           translationSubKey='operativniBrojNadredjenogZastupnika'
                           onChange={this.handleDDLSelected}
                           selectedOption={opbrNardedjenog}
                           text='punoIme'
                           value='operativniBroj'
                           showLoadingIcon={false}
                        />

                        <DDL
                           options={tnz}
                           translationKey='zastupnici'
                           translationSubKey='tipNadredjenogZastupnika'
                           onChange={this.handleDDLSelected}
                           selectedOption={tipNadredjenogZastupnika}
                           attributes={{ disabled: true }}
                        />
                     </div>

                     <div className='col'>
                        <DDL
                           options={prodajnaMjesta}
                           translationKey='zastupnici'
                           translationSubKey='sifraProdajnogMjesta'
                           onChange={this.handleDDLSelected}
                           selectedOption={sifraProdajnogMjesta}
                           text='naziv'
                           value='sifra'
                        />

                        {this.formInput({
                           name: 'adresaProdajnogMjesta',
                           translationKey,
                           isNumber: false,
                           attributes: { disabled: true }
                        })}

                        {this.formInput({
                           name: 'gradProdajnogMjesta',
                           translationKey,
                           isNumber: false,
                           attributes: { disabled: true }
                        })}
                     </div>

                     <div className='col'>
                        {this.formInput({
                           name: 'grupaKorisnika',
                           translationKey,
                           isNumber: true
                        })}

                        {this.formInput({
                           name: 'password',
                           translationKey
                        })}

                        {this.formInput({
                           name: 'depozit',
                           translationKey,
                           isNumber: true
                        })}

                        {this.formInput({
                           name: 'napomena',
                           translationKey
                        })}
                     </div>
                  </div>

                  <hr />

                  <div className='row'>
                     <div className='col-4'>
                        {this.formInput({
                           name: 'kategorija',
                           translationKey,
                           isNumber: true
                        })}
                     </div>
                     <div className='col-4'>
                        <DDL
                           options={pivd}
                           translationKey='zastupnici'
                           translationSubKey='pravoIsplateVelikihDobitaka'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoIsplateVelikihDobitaka}
                        />
                     </div>
                  </div>

                  <div className='row'>
                     <div className='col-4'>
                        <DDL
                           options={pub}
                           translationKey='zastupnici'
                           translationSubKey='pravoUplateBingo'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoUplateBingo}
                        />
                     </div>
                     <div className='col-4'>
                        <DDL
                           options={pib}
                           translationKey='zastupnici'
                           translationSubKey='pravoIsplateBingo'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoIsplateBingo}
                        />
                     </div>
                     <div className='col-4'>
                        {this.formInput({
                           name: 'postotakProvizijeBingo',
                           translationKey,
                           isNumber: true,
                           lblSufix: `${(postotakProvizijeBingo / 100).toFixed(
                              2
                           )}%`
                        })}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-4'>
                        <DDL
                           options={puk}
                           translationKey='zastupnici'
                           translationSubKey='pravoUplateKeno'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoUplateKeno}
                        />
                     </div>
                     <div className='col-4'>
                        <DDL
                           options={pik}
                           translationKey='zastupnici'
                           translationSubKey='pravoIsplateKeno'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoIsplateKeno}
                        />
                     </div>
                     <div className='col-4'>
                        {this.formInput({
                           name: 'postotakProvizijeKeno',
                           translationKey,
                           isNumber: true,
                           lblSufix: `${(postotakProvizijeKeno / 100).toFixed(
                              2
                           )}%`
                        })}
                     </div>
                  </div>

                  <hr />

                  <div style={{ textAlign: 'right' }}>
                     {this.formSubmit({
                        label: `${intl.getTranslate('common.save')}`
                     })}
                  </div>
               </div>
            </form>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   zastupnici: state.zastupnici
});

const mapDispatchToProps = (dispatch) => ({
   ucitajStatickePodatkeZastupnika: () =>
      dispatch(ucitajStatickePodatkeZastupnika())
});

export default withRouter(
   injectIntl(connect(mapStateToProps, mapDispatchToProps)(DodajZastupnika))
);
