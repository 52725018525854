import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import NotFound from './components/not-found';
// eslint-disable-next-line
import { I18nProvider, LANGUAGES } from './i18n';
import { AnimatePresence } from 'framer-motion';
// eslint-disable-next-line
import TestPage from './pages/test-page';
import LoginPage from './pages/login-page';
import RegisterPage from './pages/register-page';
import Setup from './components/setup';
import Navbar from './components/navbar/navbar';
import { ToastContainer } from 'react-toastify';
import Homepage from './pages/homepage';
import Page from './pages/the-page';
import InitPage from './pages/the-init-page';
import { initPageRoutes } from './routes/routes-init-pages';
import { pageRoutes } from './routes/routes';
import {
   getUsedTheme,
   getUsedLanguage,
   applyRootElementBackgroundColor,
   filteredPublishRoutes
} from './services/app-service';
import ErrorBoundaries from './components/common/error-boundaries';
import Footer from './components/footer';
// eslint-disable-next-line
import PrintPageClass from './components/playground/print-class-component';
import PrintExample from './components/playground/print-class-component-example';
import BackgroundLine from './components/common/background-line';
import PageContainer from './components/common/animated-page-container';
import ProtectedRoute from './components/common/protected-route';
import Logout from './components/login/logout';
import { checkIfIdleTimeHasExpired } from './services/logout-service';

import { useHistory, withRouter } from 'react-router';
import Print from './components/common/print/print';

const App = () => {
   const [language, setLanguage] = useState(getUsedLanguage());
   const [theme, setTheme] = useState('light');
   const [settingsVisible, setSettingsVisible] = useState(false);
   const history = useHistory();

   useEffect(() => {
      setLanguage(getUsedLanguage());
      setTheme(getUsedTheme());

      checkIfIdleTimeHasExpired(history);
      applyRootElementBackgroundColor();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const location = useLocation();

   const appCSS =
      getUsedTheme() === 'dark' ? 'app dark-theme' : 'app light-theme';

   // console.log('window.devicePixelRatio', window.devicePixelRatio);

   // ako je podešen pogled na 125%
   if (window.devicePixelRatio === 1.25) {
      // document.querySelector('html').style.fontSize = '45%';
      document.querySelector('body').classList.add('zoom-125');
   }

   return (
      <>
         <ErrorBoundaries>
            <ToastContainer
               position='bottom-center'
               autoClose={3000}
               hideProgressBar={false}
               toastClassName='myToast'
               theme={getUsedTheme() === 'dark' ? 'light' : 'dark'}
               // closeButton='none'
            />
            <I18nProvider language={getUsedLanguage()}>
               <Navbar
                  settingsVisible={settingsVisible}
                  setSettingsVisible={setSettingsVisible}
               />
               <Setup
                  visible={settingsVisible}
                  setSettingsVisible={setSettingsVisible}
                  setLanguage={setLanguage}
                  language={language}
                  setTheme={setTheme}
                  theme={theme}
               />
               <div className={appCSS}>
                  <AnimatePresence exitBeforeEnter>
                     <Switch location={location} key={location.pathname}>
                        <Route path='/loginpage' component={LoginPage} />
                        <Route path='/register-user' component={RegisterPage} />
                        {/* <Route path='/aaa' component={TestPage} /> */}
                        <Route path='/aaa' component={PrintExample} />
                        <Route path='/print' component={Print} />
                        <Route path='/not-found/:url?' component={NotFound} />

                        {initPageRoutes.map((r, i) => (
                           <Route
                              key={`init${i}`}
                              path={r.path}
                              render={(props) => (
                                 <InitPage
                                    submenuOptions={r.submenuOptions}
                                    {...props}
                                 />
                              )}
                           />
                        ))}

                        {filteredPublishRoutes(pageRoutes).map((r, i) =>
                           !r.protected ? (
                              <Route
                                 key={`page${i}`}
                                 path={r.path}
                                 render={(props) => (
                                    <Page
                                       component={r.component[0].name}
                                       title={r.component[0].title}
                                       additionalTitleLink={
                                          r.component[0].additionalTitleLink
                                       }
                                       style={r.style}
                                       {...props}
                                    />
                                 )}
                              />
                           ) : (
                              <ProtectedRoute
                                 key={`page${i}`}
                                 path={r.path}
                                 render={(props) => (
                                    <Page
                                       component={r.component[0].name}
                                       title={r.component[0].title}
                                       additionalTitleLink={
                                          r.component[0].additionalTitleLink
                                       }
                                       style={r.style}
                                       {...props}
                                    />
                                 )}
                              />
                           )
                        )}
                        <Route path='/logout' component={Logout} />
                        <ProtectedRoute path='/' component={Homepage} exact />
                        <PageContainer>
                           <Redirect to='/not-found' />
                        </PageContainer>
                     </Switch>
                     <BackgroundLine />
                  </AnimatePresence>
               </div>
               {process.env.REACT_APP_SHOW_FOOTER === 'true' && <Footer />}
            </I18nProvider>
         </ErrorBoundaries>
      </>
   );
};

export default withRouter(App);
