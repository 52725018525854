import React from 'react';
import { NavLink } from 'react-router-dom';
import PageContainer from './common/animated-page-container';
import { getUsedTheme } from './../services/app-service';
import intl from './../services/intl-service';
import UseAnimations from 'react-useanimations';
import alertTriangle from 'react-useanimations/lib/alertTriangle';
import { withRouter } from 'react-router';

const NotFound = (props) => {
    const theme = getUsedTheme();
    const baseClass = 'not-found not-found-';
    const themeClass = theme === 'dark' ? 'dark' : 'light';
    const cssClass = `${baseClass}${themeClass}`;

    const { url } = props.match.params;

    return (
        <PageContainer className={cssClass}>
            <UseAnimations
                animation={alertTriangle}
                size={56}
                style={{ padding: 100 }}
                strokeColor='#f00'
                speed={1}
            />
            <h1>{intl.getTranslate('common.notFound')}</h1>
            {url && <h2>{decodeURIComponent(url)}</h2>}
            <h3>
                <NavLink to='/'>
                    {intl.getTranslate('common.notFoundLink')}
                </NavLink>
            </h3>
        </PageContainer>
    );
};

export default withRouter(NotFound);
