import intl from '../../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjeTeritorijalneJediniceSchema = {
   sifraMjesta: Joi.number()
      .integer()
      .min(1)
      .max(99999)
      .required()
      .label(intl.getTranslate('teritorijalnaJedinica.sifraMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'teritorijalnaJedinica.sifraMjestaValidator'
            )}`
         };
      }),
   nazivMjesta: Joi.string()
      .max(63)
      .required()
      .label(intl.getTranslate('teritorijalnaJedinica.nazivMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'teritorijalnaJedinica.nazivMjestaValidator'
            )}`
         };
      }),
   sifraNadredjenogMjesta: Joi.number()
      .integer()
      .min(1)
      .max(99999)
      .required()
      .label(intl.getTranslate('teritorijalnaJedinica.sifraNadredjenogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'teritorijalnaJedinica.sifraNadredjenogMjestaValidator'
            )}`
         };
      })
};
