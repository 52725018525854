import http from '../http';
import api from '../../config/SierraLeone/KE06-keno-kumulativi.json';

export const service = {
   get: (odDatuma, doDatuma, operativniBroj) =>
      http.post(`${api.get}`, {
         odDatuma,
         doDatuma,
         operativniBroj
      })
};
