import http from './http';
import api from '../config/m-o-r-config.json';
import { getOperaterUpisaObject } from './auth-service';

export const mjestoService = {
   list: () => http.post(`${api.getMjestaSva}`),
   get: (sifraMjesta) => http.post(`${api.getMjesto}`, { sifraMjesta }),
   addOrUpdate: (mjesto) =>
      http.post(`${api.postMjesto}`, { ...mjesto, ...getOperaterUpisaObject() })
};

export const opcinaService = {
   list: () => http.post(`${api.getOpcinaSve}`),
   get: (sifraOpcine) => http.post(`${api.getOpcina}`, { sifraOpcine }),
   addOrUpdate: (opcina) =>
      http.post(`${api.postOpcina}`, { ...opcina, ...getOperaterUpisaObject() })
};

export const regijaService = {
   list: () => http.post(`${api.getRegijeSve}`),
   get: (sifraRegije) =>
      http.post(`${api.getRegija}`, {
         sifraRegije,
         ...getOperaterUpisaObject()
      }),
   addOrUpdate: (regija) => http.post(`${api.postRegija}`, regija)
};
