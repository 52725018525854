import React, { useEffect, useState } from 'react';
import {
   getConvertedZastupnikData,
   getTextFromValue_Zastupnici,
   zastupniciService
} from '../../../services/zastupnici-service';
import CustomAlert from '../../common/custom-alert';
import DetaljiZastupnika from './detalji-zastupnika';
import { AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import UrediZastupnika from './uredi-zastupnika';
import { ucitajSveZastupnike } from '../../../store/zastupnici';
import { toast } from 'react-toastify';
import CustomAlertNoForm from '../../common/custom-alert-container';
import axios from 'axios';
import {
   toastError,
   toastLoading,
   toastSuccessSave
} from '../../../services/toast-service';
import intl from '../../../services/intl-service';
import DataTable from '../../common/table/datatable';
import ButtonAddNew from '../../common/btn-add-new';
import ButtonEditTableRow from '../../common/btn-edit-table-row';
import { paths } from './../../../routes/routes';
import DDL from '../../common/ant-ddl-translate';

const PregledZastupnika = (props) => {
   let source;

   const [odabraniZastupnik, setOdabraniZastupnik] = useState({});
   const [showDetaljiZastupnika, setShowDetaljiZastupnika] = useState(false);
   const [showUredi, setShowUredi] = useState(false);
   const [additionalFilterId, setAdditionalFilterId] = useState(0);
   const [agents, setAgents] = useState([]);

   const columns = [
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('zastupnici.operativniBrojZastupnikaAkronim'),
         path: 'operativniBrojZastupnika'
      },
      {
         sortable: true,
         label: intl.getTranslate('zastupnici.punoIme'),
         path: 'punoIme'
      },
      {
         sortable: true,
         label: intl.getTranslate('zastupnici.tipZastupnika'),
         path: 'tipZastupnika',
         key: 'zastupnici',
         content: (item) => (
            <span>
               {getTextFromValue_Zastupnici(
                  item.tipZastupnika,
                  'tipZastupnika'
               )}
            </span>
         )
      },
      {
         sortable: true,
         label: intl.getTranslate('zastupnici.imePrezimeNadredjenogZastupnika'),
         path: 'imePrezimeNadredjenogZastupnika',
         key: 'zastupnici',
         content: (item) => (
            <span>{`${item.imeNadredjenogZastupnika} ${item.prezimeNadredjenogZastupnika} [${item.operativniBrojNadredjenogZastupnika}]`}</span>
         )
      },
      {
         sortable: true,
         label: intl.getTranslate('zastupnici.tipNadredjenogZastupnika'),
         path: 'tipNadredjenogZastupnika',
         key: 'zastupnici',
         content: (item) => (
            <span>
               {getTextFromValue_Zastupnici(
                  item.tipNadredjenogZastupnika,
                  'tipNadredjenogZastupnika'
               )}
            </span>
         )
      },
      {
         sortable: true,
         label: intl.getTranslate('zastupnici.aktivnostZastupnika'),
         path: 'aktivnostZastupnika',
         key: 'aktivnostZastupnika',
         content: (item) => (
            <span>
               {getTextFromValue_Zastupnici(
                  item.aktivnostZastupnika,
                  'aktivnostZastupnika'
               )}
            </span>
         )
      },
      {
         label: intl.getTranslate('zastupnici.gradProdajnogMjesta'),
         path: 'gradProdajnogMjesta'
      },
      {
         label: intl.getTranslate('zastupnici.adresaProdajnogMjesta'),
         path: 'adresaProdajnogMjesta'
      },
      {
         key: 'btnEdit',
         width: '10rem',
         content: (item) => (
            <ButtonEditTableRow
               onClick={handleOnZastupnikEditClick}
               item={item}
            />
         )
      }
   ];

   const additionalFilterOptions = [
      {
         text: 'All',
         value: 0
      },
      {
         text: 'Active',
         value: 1
      },
      {
         text: 'Inactive',
         value: 2
      }
   ];

   const dispatch = useDispatch();
   const { sviZastupnici } = useSelector((state) => state.zastupnici);

   const loadData = () => {
      toastLoading();
      source = axios.CancelToken.source();

      dispatch(ucitajSveZastupnike(source.token))
         .then((data) => {
            toast.dismiss();
         })
         .catch((error) => {
            toastError();
         });
   };

   useEffect(() => {
      setAgents(
         sviZastupnici.map((z) => ({
            ...z,
            punoIme: `${z.ime} ${z.prezime}`
         }))
      );
   }, [sviZastupnici]);

   useEffect(() => {
      loadData();
      setAgents(
         sviZastupnici.map((z) => ({
            ...z,
            punoIme: `${z.ime} ${z.prezime}`
         }))
      );
      return () => {
         if (source) {
            source.cancel('CancelRequest unmount');
         }
         //bez dependency varijabli cleanup fn() se izvršava kada se komponenta unmounta
         toast.dismiss();
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleOnZastupnikClick = (data) => {
      setOdabraniZastupnik(data);
      setShowDetaljiZastupnika(true);
   };

   const handleOnZastupnikEditClick = (data) => {
      setOdabraniZastupnik(data);
      setShowUredi(true);
   };

   const handleAzurirajZastupnika = async (data) => {
      const postData = { ...data };
      const convertedData = getConvertedZastupnikData(postData);

      const response = await zastupniciService.addOrUpdate(convertedData);
      const { isSuccess, msg } = response;

      if (isSuccess) {
         toastSuccessSave();
         loadData();
      } else toastError(msg);

      setShowUredi(false);
   };

   const handleCancelAzuriranje = () => {
      setShowUredi(false);
   };

   let zastupnici = sviZastupnici.map((z) => ({
      ...z,
      punoIme: `${z.ime} ${z.prezime}`
   }));

   const handleAdditionalFilterChange = ({ text, value }) => {
      setAdditionalFilterId(value);
      //0-all, 1- active, 2-inactive
      switch (value) {
         case 0:
            setAgents(
               sviZastupnici.map((z) => ({
                  ...z,
                  punoIme: `${z.ime} ${z.prezime}`
               }))
            );
            break;
         case 1:
            setAgents(
               sviZastupnici
                  .filter((a) => a.aktivnostZastupnika === 1)
                  .map((z) => ({
                     ...z,
                     punoIme: `${z.ime} ${z.prezime}`
                  }))
            );
            break;
         case 2:
            setAgents(
               sviZastupnici
                  .filter((a) => a.aktivnostZastupnika === 0)
                  .map((z) => ({
                     ...z,
                     punoIme: `${z.ime} ${z.prezime}`
                  }))
            );
            break;
         default:
            break;
      }
      console.log(zastupnici);
   };

   return (
      <div className='agents-overview'>
         <AnimatePresence>
            {showUredi && (
               <CustomAlertNoForm
                  title={intl.getTranslate('zastupnici.uredjivanjeTitle')}
                  style={{ width: '140rem' }}
               >
                  <UrediZastupnika
                     zastupnik={odabraniZastupnik}
                     onSubmit={handleAzurirajZastupnika}
                     onCancel={handleCancelAzuriranje}
                  />
               </CustomAlertNoForm>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {showDetaljiZastupnika && (
               <CustomAlert
                  title={intl.getTranslate('zastupnici.detaljiZastupnikaTitle')}
                  btnOk='Cancel'
                  onOk={() => {
                     setShowDetaljiZastupnika(false);
                  }}
                  btnCancel={intl.getTranslate('common.update')}
                  onCancel={() => {
                     setShowDetaljiZastupnika(false);
                     setShowUredi(true);
                  }}
                  //btnCancelStyle={{ backgroundColor: '#007bff' }}
                  //alertMode={true}
                  style={{ width: '100rem' }}
               >
                  <DetaljiZastupnika zastupnik={odabraniZastupnik} />
               </CustomAlert>
            )}
         </AnimatePresence>

         {!sviZastupnici && <p>Data read error...</p>}
         {sviZastupnici && (
            <DataTable
               columns={columns}
               pageSize={15}
               data={agents}
               searchLabel={intl.getTranslate('zastupnici.pretraga')}
               searchLabelPlaceholder={intl.getTranslate(
                  'zastupnici.pretragaPlaceholder'
               )}
               searchColumns={['operativniBrojZastupnika', 'punoIme']}
               searchDebounceDelay={50}
               onRowClick={handleOnZastupnikClick}
               printEnable={true}
               printColumns={columns.slice(0, -1)}
               printHeader='Pregled zastupnika'
               btnAdd={
                  <ButtonAddNew
                     title={intl.getTranslate('zastupnici.dodajNovog')}
                     path={paths.manage_addAgent.path}
                  />
               }
               additionalFilter={
                  <DDL
                     options={additionalFilterOptions}
                     translationKey='zastupnici'
                     translationSubKey='additionalFilter'
                     onChange={handleAdditionalFilterChange}
                     selectedOption={additionalFilterId}
                     showLoadingIcon={false}
                  />
               }
            />
         )}
      </div>
   );
};

export default PregledZastupnika;

// https://stackoverflow.com/questions/39630620/react-intl-how-to-use-formattedmessage-in-input-placeholder/39753280
