import React from 'react';
import intl from '../../services/intl-service';
import { v4 as uuid } from 'uuid';

/**
 * Prosljeđuje se objekt sa podacima
 * Traži se translationKey za svako svojstvo objekta
 * */

function ShowTableData({ data, translationKey = 'pregledKenoListica' }) {
   if (!data) return null;
   if (Object.keys(data).length === 0) return null;

   return (
      <div className='table-overview-container'>
         <table className='table table-striped table-sm'>
            <tbody>
               {Object.keys(data).map((key) => (
                  <tr key={uuid()}>
                     <td style={{ width: '25rem' }}>
                        {intl.getTranslate(`${translationKey}.${key}`)}
                     </td>
                     <td>{data[key] !== '' ? data[key] : '-'}</td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
}

export default ShowTableData;
