import React from 'react';
import { v4 as uuid } from 'uuid';

function KenoBrojevi({ data }) {
   return (
      <div className='keno-odigrani-brojevi-container'>
         {data.map((d) => (
            <div
               className='odigrani-broj'
               style={{
                  background: d.dobitni ? '#5aaaff' : null,
                  color: d.dobitni ? '#fff' : null
               }}
               key={uuid()}
            >
               {d.broj}
            </div>
         ))}
      </div>
   );
}

export default KenoBrojevi;
