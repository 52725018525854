import React from 'react';
import { v4 as uuid } from 'uuid';

function KenoBrojeviTable({ data }) {
   return (
      <div className='keno-odigrani-brojevi-table-container'>
         {data.map((d) => {
            const cssclass = d.dobitni
               ? 'odigrani-broj dobitni'
               : 'odigrani-broj';
            return (
               <div className={cssclass} key={uuid()}>
                  {d.broj}
               </div>
            );
         })}
      </div>
   );
}

export default KenoBrojeviTable;
