import React from 'react';
import { NavLink } from 'react-router-dom';

import LinkUnderline from './navbar-link-underline';

const NavbarLink = ({ text, to, subpaths = [] }) => {
   return (
      <>
         <NavLink to={to} className='nav-link'>
            {text}
         </NavLink>
         <LinkUnderline path={to} subpaths={subpaths} />
      </>
   );
};

export default NavbarLink;
