import guid from '../../utils/guid';

const initState = [
   {
      id: guid(),
      name: 'Pero',
      surname: 'Perić',
      years: 21,
      gender: 'Male',
      driverLicence: true
   }
];

//  ACTION TYPES
const USER_ADD = 'addUser';
const USER_REMOVE = 'removeUser';

//  ACTIONS - EVENTS
export const addUser = (
   user = {
      id: guid(),
      name: 'Generic',
      surname: 'User',
      years: 999,
      gender: 'Z',
      driverLicence: true
   }
) => ({
   type: USER_ADD,
   payload: {
      user
   }
});

export const removeUser = (id) => ({
   type: USER_REMOVE,
   payload: {
      id
   }
});

//REDUCER - EVENT HANDLER
export default function reducer(state = initState, action) {
   switch (action.type) {
      case USER_ADD:
         return [...state, action.payload.user];
      case USER_REMOVE:
         return state.filter((user) => user.id !== action.payload.id);
      default:
         return state;
   }
}
