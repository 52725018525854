import { motion } from 'framer-motion';
import React from 'react';
import { bezier1, bezier2 } from './../../services/framer-motion-service';
//https://www.framer.com/docs/animate-presence/

const FramerConditionalCollapsableContainer = ({
   condition,
   height,
   delay = 0,
   duration = 0.5,
   children
}) => {
   return (
      <motion.div
         style={{ overflow: 'hidden' }}
         initial={{ height: 0, opacity: 0 }}
         animate={{
            height: condition ? height : 0,
            opacity: condition ? 1 : 0
         }}
         transition={{ duration, delay, easings: [bezier1, bezier2] }}
      >
         {children}
      </motion.div>
   );
};

export default FramerConditionalCollapsableContainer;
