import React from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/hr_HR';
import moment from 'moment';
import intl from '../../services/intl-service';

const AntDatepicker = ({
    label,
    defaultDate = moment(new Date()),
    onChange,
    validationMessage = '',
    placeholder = intl.getTranslate('common.datepicker'),
    rest
}) => {
    const handleOnChange = (e) => {
        if (!e) return;
        onChange(e);
    };

    return (
        <div className='form-group'>
            <label style={{ fontWeight: 'bold' }} className='mr-1'>
                {label}
            </label>
            <DatePicker
                format='DD.MM.YYYY'
                locale={locale}
                style={{ borderRadius: '.2rem', width: '100%' }}
                onChange={handleOnChange}
                defaultValue={defaultDate}
                placeholder={placeholder}
                {...rest}
            />
            {validationMessage && (
                <div
                    className='alert alert-primary'
                    style={{ fontWeight: 700 }}
                >
                    {validationMessage}
                </div>
            )}
        </div>
    );
};

export default AntDatepicker;
