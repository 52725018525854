import React from 'react';
import { motion } from 'framer-motion';

const animationSpeedShow = 0.25;
const animationSpeedExit = 0.1;

const pageAnimation = {
   hidden: {
      opacity: 0,
      y: 30
   },
   visible: {
      opacity: 1,
      y: 0,
      transition: {
         duration: animationSpeedShow
      }
   },
   //što se dešava s komponentom kada se unmounta, kada se odlazi sa neke stranice
   exit: {
      opacity: 0,
      y: 30,
      transition: {
         duration: animationSpeedExit,
         ease: 'easeOut'
      }
   }
};

const PageContainer = ({ children, className, style }) => {
   return (
      <motion.div
         className={className ? className : 'page'}
         style={style ? style : ''}
         variants={pageAnimation}
         initial='hidden'
         animate='visible'
         exit='exit'
      >
         {children}
      </motion.div>
   );
};

export default PageContainer;
