import intl from '../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjeRegijeSchema = {
   nazivRegije: Joi.string()
      .required()
      .label(intl.getTranslate('regija.nazivRegije'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'regija.nazivRegijeValidator'
            )}`
         };
      }),
   sifraRegije: Joi.number()
      .integer()
      .min(1)
      .max(99)
      .required()
      .label(intl.getTranslate('regija.sifraRegije'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'regija.sifraRegijeValidator'
            )}`
         };
      })
};
