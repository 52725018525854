import { LoadingOutlined } from '@ant-design/icons';
import intl from '../services/intl-service';
import { toast } from 'react-toastify';

const toastLoadingBody = (txt) => (
    <div className='custom-toast-container'>
        <div>
            <span className='mr-3 toast-loading-icon'>
                <LoadingOutlined style={{ verticalAlign: 0 }} />
            </span>
            {txt}
        </div>
    </div>
);

const toastAxiosBody = (name, message, txt) => (
    <div className='custom-toast-container'>
        <div>
            <span className='name mr-2'>{name}</span>
            <span className='message'>{message}</span>
        </div>
        <div className='axios-url'>{txt}</div>
    </div>
);
const toastBody = (txt) => <div className='custom-toast-container'>{txt}</div>;

export const toastLoading = (
    msg = intl.getTranslate('common.loading'),
    settings
) =>
    toast.info(toastLoadingBody(msg), {
        autoClose: false,
        progress: false,
        ...settings
    });

export const toastInfo = (msg, settings) =>
    toast.info(toastBody(msg), { ...settings });

export const toastSuccess = (
    msg = intl.getTranslate('common.podaciUspjesnoAzurirani'),
    settings
) => toast.success(toastBody(msg), { ...settings });

export const toastSuccessSave = (
    msg = intl.getTranslate('common.podaciUspjesnoSpremljeni'),
    settings
) => toast.success(toastBody(msg), { ...settings });

export const toastError = (msg = intl.getTranslate('common.error'), settings) =>
    toast.error(toastBody(msg), { ...settings });

export const toastAxiosError = ({ name, message, config }, settings) =>
    toast.error(toastAxiosBody(name, message, config.url ? config.url : ''), {
        autoClose: false,
        ...settings
    });
