import intl from '../../../services/intl-service';
import Joi from 'joi-browser';

export const pregledSvihKenoListicaSchema = {
   odDatuma: Joi.number()
      .integer()
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.odDatuma'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledKenoListica.odDatumaValidator'
            )}`
         };
      }),
   doDatuma: Joi.number()
      .integer()
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.doDatuma'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledKenoListica.doDatumaValidator'
            )}`
         };
      }),
   odVremena: Joi.number()
      .integer()
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.odVremena'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledKenoListica.odVremenaValidator'
            )}`
         };
      }),
   doVremena: Joi.number()
      .integer()
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.doVremena'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledKenoListica.doVremenaValidator'
            )}`
         };
      }),
   odKola: Joi.number()
      .integer()
      .min(0)
      .max(999_999_999)
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.odKola'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledSvihKenoListica.odKolaValidator'
            )}`
         };
      }),
   doKola: Joi.number()
      .integer()
      .min(0)
      .max(999_999_999)
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.doKola'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledSvihKenoListica.doKolaValidator'
            )}`
         };
      }),
   odProdajnogMjesta: Joi.number()
      .integer()
      .min(0)
      .max(999_999)
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.odProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledSvihKenoListica.odProdajnogMjestaValidator'
            )}`
         };
      }),
   doProdajnogMjesta: Joi.number()
      .integer()
      .min(0)
      .max(999_999)
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.doProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledSvihKenoListica.doProdajnogMjestaValidator'
            )}`
         };
      }),
   odProdavaca: Joi.number()
      .integer()
      .min(0)
      .max(99_999)
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.odProdavaca'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledSvihKenoListica.odProdavacaValidator'
            )}`
         };
      }),
   doProdavaca: Joi.number()
      .integer()
      .min(0)
      .max(99_999)
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.doProdavaca'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledSvihKenoListica.doProdavacaValidator'
            )}`
         };
      }),
   statusListica: Joi.number()
      .integer()
      .min(0)
      .max(5)
      .required()
      .label(intl.getTranslate('pregledSvihKenoListica.statusListica'))
};
