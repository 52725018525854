import React from 'react';
import Form from '../common/form';
import Joi from 'joi-browser';
import RadioCheckboxButtons from '../common/radio-checkbox-buttons';
import { v4 as guid } from 'uuid';
import { connect } from 'react-redux';
import { addUser, removeUser } from '../../store/playground/users';
import swal from 'sweetalert';

/*
    podaci koje treba validirati idu u validationData
    validationData svojstva se mapiraju na schema objekt

    ostali podaci se neovisno pohranjuju na state objektu
*/

class Users extends Form {
   state = {
      user: {
         id: '',
         gender: 'Female',
         driverLicence: true
      },
      validationData: {
         name: '',
         surname: '',
         years: 0
      },
      errors: {}
   };

   schema = {
      name: Joi.string().required().label('User name'),
      surname: Joi.string().required().label('Surname'),
      years: Joi.number().min(18).label('Years')
   };

   styles = {
      component: {
         background: '#007bff',
         padding: '0 10px',
         color: 'white',
         fontWeight: 100
      }
   };

   genderOptions = [
      {
         label: 'Male',
         value: 'Male'
      },
      {
         label: 'Female',
         value: 'Female',
         defaultChecked: true
      }
   ];

   driverLicenceOptions = [
      {
         label: 'Driver licence',
         value: true,
         defaultChecked: true
      }
   ];

   render() {
      const { users } = this.props;
      return (
         <div className='users'>
            <h1>
               Users <span style={this.styles.component}>component</span>
            </h1>
            <hr />
            <form onSubmit={this.handleFormSubmit}>
               <div className='row'>
                  <div className='col'>
                     <fieldset>
                        <legend>Add a new user</legend>
                        {this.renderInput('name', 'Name', {
                           placeholder: 'Enter user name...',
                           autoFocus: true,
                           className: 'form-control form-control-sm'
                        })}
                        {this.renderInput('surname', 'Last name', {
                           placeholder: 'Surname, max 30 letters...'
                        })}
                        {this.renderInput('years', 'Years', {
                           type: 'number'
                        })}

                        <div>
                           <label>Gender</label>
                           <RadioCheckboxButtons
                              groupName='gender'
                              type='radio'
                              options={this.genderOptions}
                              onChange={this.handleGenderChange}
                           />
                        </div>
                        <div>
                           <RadioCheckboxButtons
                              groupName='driverLicence'
                              type='checkbox'
                              options={this.driverLicenceOptions}
                              onChange={this.handleDriverLicenceChange}
                           />
                        </div>
                        <hr />
                        {this.renderSubmitButton('Add user')}
                     </fieldset>
                  </div>
                  <div className='col'>
                     <fieldset>
                        <legend>User list [{users.length}]</legend>
                        <ul style={{ padding: 0 }}>
                           {users.map((user) => (
                              <li key={user.id} style={{ listStyle: 'none' }}>
                                 <button
                                    type='button'
                                    className='btn btn-danger btn-sm m-1'
                                    onClick={() => {
                                       this.handleRemoveUser(user);
                                    }}
                                 >
                                    Remove
                                 </button>
                                 {`${user.name} ${user.surname} [${user.years},
                                     gender:${user.gender}, driver licence:${
                                    user.driverLicence ? 'YES' : 'NO'
                                 }]`}
                              </li>
                           ))}
                        </ul>
                     </fieldset>
                  </div>
               </div>
            </form>
         </div>
      );
   }

   doSubmit = () => {
      const { addUser } = this.props;
      const { user, validationData } = this.state;
      const newUser = { ...user, ...validationData };
      newUser.id = guid();

      //dodaj u store
      addUser(newUser);
   };

   handleGenderChange = ({ target }) => {
      const user = { ...this.state.user };
      user.gender = target.value;
      this.setState({ user });
   };

   handleDriverLicenceChange = ({ target }) => {
      const user = { ...this.state.user };
      user.driverLicence = target.checked ? true : false;
      this.setState({ user });
   };

   handleRemoveUser = (user) => {
      //    if (window.confirm(`Remove ${user.name} ${user.surname}?`))
      //       this.handleRemoveUser(user.id);
      const { removeUser } = this.props;

      swal({
         title: 'Are you shure?',
         text: `Remove ${user.name} ${user.surname}?`,
         icon: 'warning',
         buttons: {
            remove: {
               text: 'Remove',
               value: 'remove'
            },
            cancel: 'Cancel'
         }
      }).then((value) => {
         switch (value) {
            case 'remove':
               swal({
                  title: `REMOVED`,
                  text: `${user.name} ${user.surname} is removed!`,
                  icon: 'success',
                  timer: 1500,
                  buttons: {}
               });
               removeUser(user.id);
               break;
            default:
               swal({
                  title: 'Canceled',
                  text: `${user.name} ${user.surname} is not removed!`,
                  icon: 'error',
                  timer: 1500,
                  buttons: {}
               });
         }
      });
   };

   componentDidMount() {
      const { users } = this.props;
      this.setState({ users });
   }
}

const mapStateToProps = (state) => ({
   users: state.users
});

const mapDispatchToProps = (dispatch) => ({
   addUser: (user) => dispatch(addUser(user)),
   removeUser: (id) => dispatch(removeUser(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
