import React from 'react';
import { Pie, measureTextWidth } from '@ant-design/plots';
import { getFormatedValue } from '../../services/app-service';
import intl from '../../services/intl-service';

const AntPieChart = ({
   data,
   angleField = 'value',
   colorField = 'text',
   radius = 0.8,
   innerRadius = 0,
   theme = 'default',
   currency = null,
   width = '100%',
   height = 300
}) => {
   const config = {
      data,
      angleField,
      colorField,
      radius,
      innerRadius,
      theme,
      style: {
         height,
         width
      },
      tooltip: {
         formatter: (data) => {
            return {
               name: data[colorField],
               value: currency
                  ? `${getFormatedValue(data[angleField])}${currency}`
                  : data[angleField]
            };
         }
      },
      statistic: {
         title: {
            offsetY: 0,
            customHtml: (container, view) =>
               intl.getTranslate('kenoKumulativiSE.uplata'),
            style: {
               fontSize: '1.6rem'
            }
         },
         content: {
            offsetY: 4,
            style: {
               fontSize: '1.8rem'
            },
            customHtml: (container, view, datum, data) => {
               const { width } = container.getBoundingClientRect();
               const initial = 0;
               const uplata = data.reduce(
                  (previous, current) => previous + current.iznosUplate,
                  initial
               );
               return `${getFormatedValue(uplata)}${currency}`;
            }
         }
      },
      label: {
         type: 'outer',
         content: ({ imePrezime, datum, percent }) => {
            return `${imePrezime ?? datum} ${(percent * 100).toFixed(2)}%`;
         },
         style: { fontWeight: '400' }
      },
      interactions: [
         {
            type: 'pie-legend-active'
         },
         {
            type: 'element-active'
         }
      ]
   };
   return <Pie {...config} />;
};
export default AntPieChart;
