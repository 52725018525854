import intl from '../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjeNaseljenogMjestaSchema = {
   sifraMjesta: Joi.number()
      .integer()
      .min(10000)
      .max(99999)
      .required()
      .label(intl.getTranslate('naseljenaMjesta.sifraMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'naseljenaMjesta.sifraMjestaValidator'
            )}`
         };
      }),
   nazivMjesta: Joi.string()
      .required()
      .label('Naziv mjesta')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezno`
         };
      })
};
