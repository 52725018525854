import http from './http';
import api from '../config/a02-prodajno-mjesto-config.json';
import { getOperaterUpisaObject } from './auth-service';

export const prodajnoMjestoService = {
   list: () => http.post(`${api.getProdajnaMjestaSva}`),
   get: (sifraProdajnogMjesta) =>
      http.post(`${api.get}`, { sifraProdajnogMjesta }),
   addOrUpdate: (prodajnoMjesto) =>
      http.post(`${api.post}`, {
         ...prodajnoMjesto,
         ...getOperaterUpisaObject()
      })
};
