import React, { useEffect, useState } from 'react';
import { isPublishVersion } from '../../services/app-service';
import { DataLoader } from '../../services/data-loader-service';
import intl from '../../services/intl-service';
import DataTable from '../common/table/datatable';
import { publishVersions } from '../../services/app-service';
import { AnimatePresence } from 'framer-motion';
import CustomAlertNoFormContainer from '../common/custom-alert-container';
import ButtonAddNew from '../common/btn-add-new';
import { regijaService } from './../../services/m-o-r-service';
import UrediRegiju from './uredi-regiju';
import ButtonEditTableRow from '../common/btn-edit-table-row';

const PregledRegija = (props) => {
   const columns = [
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('regija.sifraRegije'),
         path: 'sifraRegije'
      },
      {
         sortable: true,
         center: false,
         label: intl.getTranslate('regija.nazivRegije'),
         path: 'nazivRegije'
      },
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('common.operaterUpisa'),
         path: 'operaterUpisa'
      },
      {
         sortable: true,
         label: intl.getTranslate('common.datumUpisa'),
         path: 'datumUpisa'
      },
      {
         sortable: true,
         label: intl.getTranslate('common.vrijemeUpisa'),
         path: 'vrijemeUpisa'
      },
      {
         key: 'btnEdit',
         width: '10rem',
         content: (item) => {
            if (isPublishVersion(publishVersions.BiH)) return null;
            return (
               <ButtonEditTableRow onClick={handleOnEditClick} item={item} />
            );
         }
      }
   ];

   const [data, setData] = useState([]);
   const [showUredi, setShowUredi] = useState(false);
   const [odabranaRegija, setOdabranaRegija] = useState(null);

   const onDataLoad = (response) => {
      const { regije } = response;
      setData(regije);
   };

   const loadData = () => DataLoader(regijaService.list, null, onDataLoad);

   useEffect(() => {
      loadData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleOnEditClick = (mjesto) => {
      setOdabranaRegija(mjesto);
      setShowUredi(true);
   };

   const handleAfterAzuriranja = () => {
      loadData();
      setShowUredi(false);
   };
   const handleCancel = () => {
      setShowUredi(false);
   };

   return (
      <>
         <AnimatePresence>
            {showUredi && (
               <CustomAlertNoFormContainer
                  title={intl.getTranslate('regija.confirmTitle')}
                  style={{ width: '80rem' }}
               >
                  <UrediRegiju
                     odabranaRegija={odabranaRegija}
                     onSubmit={handleAfterAzuriranja}
                     onCancel={handleCancel}
                  />
               </CustomAlertNoFormContainer>
            )}
         </AnimatePresence>
         <DataTable
            columns={columns}
            data={data}
            searchLabel={intl.getTranslate('regija.pretraga')}
            searchLabelPlaceholder={intl.getTranslate(
               'regija.pretragaPlaceholder'
            )}
            searchColumn='nazivRegije'
            onRowClick={handleOnEditClick}
            btnAdd={
               <ButtonAddNew
                  title={intl.getTranslate('regija.btnAddNew')}
                  path='/add-region'
               />
            }
         />
      </>
   );
};

export default PregledRegija;
