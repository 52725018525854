import Joi from 'joi-browser';

export const azuriranjeObavijestiSchema = {
   redak01: Joi.any().optional(),
   redak02: Joi.any().optional(),
   redak03: Joi.any().optional(),
   redak04: Joi.any().optional(),
   redak05: Joi.any().optional(),
   redak06: Joi.any().optional(),
   redak07: Joi.any().optional(),
   redak08: Joi.any().optional(),
   redak09: Joi.any().optional(),
   redak10: Joi.any().optional(),
   redak11: Joi.any().optional(),
   redak12: Joi.any().optional(),
   redak13: Joi.any().optional(),
   redak14: Joi.any().optional()
};
