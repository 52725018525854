import { paths } from '../../../routes/routes';
import { publishVersions } from './../../../services/app-service';
import {
   faGlobe,
   faGlobeAfrica,
   faFlag,
   faTh,
   faChartLine,
   faTicketAlt,
   faUserFriends,
   faTasks,
   faDiceD6,
   faDiceD20,
   faSitemap
} from '@fortawesome/free-solid-svg-icons';

export const menuPregled = [
   {
      path: paths.overview_agents.path,
      links: [
         {
            label: paths.overview_agents.linklabel,
            icon: faUserFriends
         }
      ]
   },
   {
      path: paths.overview_Cities.path,
      links: [
         {
            label: paths.overview_Cities.linklabel,
            icon: faGlobeAfrica,
            publishVersion: publishVersions.BiH
         }
      ]
   },
   {
      path: paths.overview_County.path,
      links: [
         {
            label: paths.overview_County.linklabel,
            icon: faGlobe,
            publishVersion: publishVersions.BiH
         }
      ]
   },
   {
      path: paths.overview_Region.path,
      links: [
         {
            label: paths.overview_Region.linklabel,
            icon: faFlag,
            publishVersion: publishVersions.BiH
         }
      ]
   },
   {
      path: paths.overview_manageTerminalActivity.path,
      links: [
         {
            label: paths.overview_manageTerminalActivity.linklabel,
            icon: faTh
         }
      ]
   },
   {
      path: paths.overview_terminals.path,
      links: [
         {
            label: paths.overview_terminals.linklabel,
            icon: faTasks
         }
      ]
   },
   {
      path: paths.overview_tickets.path,
      links: [
         {
            label: paths.overview_tickets.linklabel,
            icon: faTicketAlt,
            publishVersion: publishVersions.BiH
         }
      ]
   },
   {
      path: paths.overview_kenoSingleTicket.path,
      links: [
         {
            label: paths.overview_kenoSingleTicket.linklabel,
            icon: faTicketAlt,
            publishVersion: publishVersions.SierraLeone
         }
      ]
   },
   {
      path: paths.overview_kenoAllTickets.path,
      links: [
         {
            label: paths.overview_kenoAllTickets.linklabel,
            icon: faDiceD6,
            publishVersion: publishVersions.SierraLeone
         }
      ]
   },
   {
      path: paths.overview_kenoDrawsPerDay.path,
      links: [
         {
            label: paths.overview_kenoDrawsPerDay.linklabel,
            icon: faDiceD20,
            publishVersion: publishVersions.SierraLeone
         }
      ]
   },
   {
      path: paths.overview_financialReports.path,
      links: [
         {
            label: paths.overview_financialReports.linklabel,
            icon: faChartLine
         }
      ]
   },
   {
      path: paths.overview_salesNetwork.path,
      links: [
         {
            label: paths.overview_salesNetwork.linklabel,
            icon: faSitemap
         }
      ]
   }
];
