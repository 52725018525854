import React from 'react';
import intl from '../../services/intl-service';

const PodaciUplata = ({ data, onClick }) => {
   if (!data) return null;

   const {
      operativniBroj,
      godinaSedmicaDanBrojListica,
      prodajnoMjesto,
      brojTerminala,
      sifraIgre,
      kodIgre,
      kolo,
      pretplata,
      indikatorStorna,
      operaterStorna,
      brojKombinacija,
      ukupnaUplata,
      vrijemeUplateNaTerminalu,
      datumUplateNaServeru,
      vrijemeUplateNaServeru,
      datumStorna,
      vrijemeStorna,
      brojBingoListica
   } = data;

   //pretplata za kola

   return (
      <div className='table-overview-container'>
         {/* <h2>{intl.getTranslate('pregledListica.podaciUplate.naslov')}</h2> */}
         <table className='table table-striped table-sm'>
            <tbody>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.operativniBroj'
                     )}
                  </td>
                  <td>{operativniBroj}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.godinaSedmicaDanBrojListica'
                     )}
                  </td>
                  <td>{godinaSedmicaDanBrojListica}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.prodajnoMjesto'
                     )}
                  </td>
                  <td>{prodajnoMjesto}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.brojTerminala'
                     )}
                  </td>
                  <td>{brojTerminala}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.sifraIgre'
                     )}
                  </td>
                  <td>{sifraIgre}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate('pregledListica.podaciUplate.kodIgre')}
                  </td>
                  <td>{kodIgre}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate('pregledListica.podaciUplate.kolo')}
                  </td>
                  <td>{kolo}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.pretplata'
                     )}
                  </td>
                  <td>{pretplata}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.indikatorStorna'
                     )}
                  </td>
                  <td>
                     {indikatorStorna}/{operaterStorna}
                  </td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.brojKombinacija'
                     )}
                  </td>
                  <td>{brojKombinacija}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.ukupnaUplata'
                     )}
                  </td>
                  <td>{ukupnaUplata}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.vrijemeUplateNaTerminalu'
                     )}
                  </td>
                  <td>{vrijemeUplateNaTerminalu}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.vrijemeUplateNaServeru'
                     )}
                  </td>
                  <td>
                     {datumUplateNaServeru} - {vrijemeUplateNaServeru}
                  </td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.vrijemeStorna'
                     )}
                  </td>
                  <td>
                     {datumStorna} - {vrijemeStorna}
                  </td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciUplate.brojBingoListica'
                     )}
                  </td>
                  <td>{brojBingoListica}</td>
               </tr>
            </tbody>
         </table>
         <button
            autoFocus
            className='button save mt-3 float-right'
            onClick={onClick}
            type='button'
         >
            {intl.getTranslate('pregledListica.btnZatvoriUplateIsplate')}
         </button>
      </div>
   );
};

export default PodaciUplata;
