import React from 'react';
import { Bar } from '@ant-design/plots';
import { getFormatedValue } from '../../services/app-service';

const AntGroupedBarChart = ({
   data,
   width = '100%',
   height = 200,
   currency = null,
   xField = 'value',
   yField = 'datum',
   groupField = 'label'
}) => {
   const config = {
      data,
      isGroup: true,
      xField,
      yField,
      color: ['#f6c022', '#6395f9'],
      seriesField: groupField,
      style: {
         height,
         width
      },
      marginRatio: 0,
      label: {
         content: ({ value }) => '',
         // 'left', 'middle', 'right'
         position: 'middle',
         layout: [
            {
               type: 'interval-adjust-position'
            },
            {
               type: 'interval-hide-overlap'
            },
            {
               type: 'adjust-color'
            }
         ]
      },
      tooltip: {
         formatter: (data) => {
            return {
               name: data[groupField],
               value: currency
                  ? `${getFormatedValue(data[xField])}${currency}`
                  : data[xField]
            };
         }
      }
   };

   return <Bar {...config} />;
};

export default AntGroupedBarChart;
