import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

/*
    https://medium.com/@Yuschick/translating-placeholder-alt-title-text-with-react-intl-a99d31f4194c
    kako bi mogao koristiti prijevode na atributima komponenti potrebno je 
    wrappat komponentu sa HOC injectIntl
*/

class LokalizacijaClassComponent extends Component {
   state = {};
   render() {
      const { intl } = this.props;
      return (
         <div className='lokalizacija-class-component'>
            <input
               type='text'
               name='bla'
               id='bla'
               value={intl.formatMessage({
                  id: 'registerUser.registerUserHeader'
               })}
               onChange={() => {}}
            />
         </div>
      );
   }
}

export default injectIntl(LokalizacijaClassComponent);
