import intl from '../../services/intl-service';
import Joi from 'joi-browser';

export const pregledKumulativaSchema = {
   operativniBroj: Joi.number()
      .integer()
      .min(0)
      .max(9999)
      .required()
      .label(intl.getTranslate('pregledKumulativa.operativniBroj'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledKumulativa.operativniBrojValidator'
            )}`
         };
      })
};
