import React from 'react';
import Form from '../../common/form';
import { pregledKenoListicaSchema } from './pregled-keno-listica-validacije';
import { pregledKenoListicaService as service } from './../../../services/SierraLeone/ke03-pregled-keno-listica-service';
import { DataLoader } from './../../../services/data-loader-service';
import intl from '../../../services/intl-service';
import FramerConditionalCollapsableContainer from './../../common/framer-conditional-collapsable-container';
import ShowTableData from '../../common/show-table-data';
import KenoBrojevi from './keno-brojevi';

//Debounce: https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js

class PregledKenoListica extends Form {
   state = {
      validationData: {
         kolo: '',
         brojListica: ''
      },
      data: null,
      odigraniBrojevi: [],
      dobitniBrojevi: [],
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: ''
   };

   schema = pregledKenoListicaSchema;

   doSubmit = async () => {
      this.loadData();
   };

   onDataLoad = ({ data }) => {
      const { dobitniBrojevi, odigraniBrojevi } = data;
      this.setState({ dobitniBrojevi, odigraniBrojevi });

      delete data.dobitniBrojevi;
      delete data.odigraniBrojevi;

      this.setState({ data });
   };

   onDataError = () => this.setState({ data: null });

   loadData = async () => {
      const { kolo, brojListica } = this.state.validationData;
      if (!kolo || !brojListica) return;

      DataLoader(
         service.get,
         [kolo, brojListica],
         this.onDataLoad,
         this.onDataError
      );
   };

   showUplate = () => this.setState({ showPodaciUplate: true });
   showIsplate = () => this.setState({ showPodaciIsplate: true });

   render() {
      const { data, odigraniBrojevi, dobitniBrojevi } = this.state;
      const { brojListica } = this.state.validationData;

      dobitniBrojevi.sort((a, b) => a.broj - b.broj);

      return (
         <>
            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='pregled-keno-listica'>
                  <div className='row'>
                     <div className='col'>
                        <div className='row'>
                           <div className='col-6'>
                              {this.formInput({
                                 name: 'kolo',
                                 translationKey: 'pregledKenoListica',
                                 isNumber: true,
                                 fn: () => {
                                    this.loadData();
                                 },
                                 debounceDelay: 500
                              })}

                              {this.formInput({
                                 name: 'brojListica',
                                 translationKey: 'pregledKenoListica',
                                 isNumber: true,
                                 fn: () => {
                                    this.loadData();
                                 },
                                 debounceDelay: 250
                              })}
                           </div>
                        </div>
                        {/* <hr />
                        {this.renderSubmitButton(
                           `${intl.getTranslate(
                              'pregledKenoListica.btnPrikazKenoListica'
                           )}`
                        )} */}
                        <FramerConditionalCollapsableContainer
                           condition={data}
                           height='42rem'
                           duration={1}
                        >
                           <div className='brojevi'>
                              <h2>
                                 {intl.getTranslate(
                                    'pregledKenoListica.h2OdigraniBrojevi'
                                 )}
                              </h2>
                              <hr />
                              {<KenoBrojevi data={odigraniBrojevi} />}
                              <h2>
                                 {intl.getTranslate(
                                    'pregledKenoListica.h2DobitniBrojevi'
                                 )}
                              </h2>
                              <hr />
                              {<KenoBrojevi data={dobitniBrojevi} />}
                           </div>
                        </FramerConditionalCollapsableContainer>
                     </div>
                     <div className={data ? 'col border-left' : 'col'}>
                        <FramerConditionalCollapsableContainer
                           condition={data}
                           height='62rem'
                           duration={0.8}
                        >
                           <ShowTableData
                              data={data}
                              translationKey='pregledKenoListica'
                           />
                        </FramerConditionalCollapsableContainer>
                     </div>
                  </div>
               </div>
            </form>
         </>
      );
   }
}

export default PregledKenoListica;
