import React from 'react';
import Form from '../common/form';
import { injectIntl } from 'react-intl';
import { azuriranjeNaseljenogMjestaSchema } from './naseljeno-mjesto-validacije';
import intl from '../../services/intl-service';
import { mjestoService } from '../../services/m-o-r-service';
import CustomAlertNoForm from '../common/custom-alert-no-form';
import { toastError, toastInfo } from '../../services/toast-service';
import InfoVrijemeUpisa from '../common/info-vrijeme-upisa';
import FramerConditionalCollapsableContainer from '../common/framer-conditional-collapsable-container';
import { DataLoader } from '../../services/data-loader-service';
import { withRouter } from 'react-router';
import { toastSuccessSave } from './../../services/toast-service';
import { getToasterIntervals } from '../../services/app-service';
import { paths } from '../../routes/routes';
//Debounce: https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js

class DodajMjesto extends Form {
   state = {
      validationData: {
         sifraMjesta: '',
         nazivMjesta: ''
      },
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      showConfirm: false
   };

   schema = azuriranjeNaseljenogMjestaSchema;

   componentDidMount() {
      const { odabranoMjesto } = this.props;
      if (!odabranoMjesto) return;

      const validationData = { ...this.state.validationData };
      const { sifraMjesta, nazivMjesta } = odabranoMjesto;

      validationData.sifraMjesta = sifraMjesta;
      validationData.nazivMjesta = nazivMjesta;

      this.setState({ validationData });
   }

   doSubmit = () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const { successAutoClose, redirectAutoClose, redirectDelay } =
         getToasterIntervals();

      const { sifraMjesta, nazivMjesta } = this.state.validationData;
      const azuriraniPodaci = { sifraMjesta, nazivMjesta, operaterUpisa: 14 };

      const { data: response } = await mjestoService.addOrUpdate(
         azuriraniPodaci
      );

      if (response.isSuccess === 1) {
         toastSuccessSave(
            intl.getTranslate('common.podaciUspjesnoSpremljeni'),
            {
               autoClose: successAutoClose,
               onClose: () => this.props.history.push(paths.overview_Cities)
            }
         );
         toastInfo(intl.getTranslate('naseljenaMjesta.preusmjeravanje'), {
            autoClose: redirectAutoClose,
            delay: redirectDelay
         });
         this.resetForm('sifraMjesta');
      } else toastError();

      this.setState({ showConfirm: false });
   };

   onDataLoad = (response) => {
      const { nazivMjesta, datumUpisa, vrijemeUpisa } = response;
      const validationData = { ...this.state.validationData };
      validationData.nazivMjesta = nazivMjesta;

      this.setState({ validationData, datumUpisa, vrijemeUpisa });
   };

   ucitajPodatkeMjesta = (sifraMjesta) => {
      DataLoader(mjestoService.get, [sifraMjesta], this.onDataLoad);
   };

   handleSifraMjestaChange = async ({ target: input }) => {
      if (isNaN(input.value)) return;

      const sifraMjesta = Number(input.value);
      if (sifraMjesta >= 10000 && sifraMjesta <= 99999)
         this.ucitajPodatkeMjesta(sifraMjesta);
   };

   render() {
      const { validationData, showConfirm, datumUpisa, vrijemeUpisa } =
         this.state;
      const { sifraMjesta, nazivMjesta } = validationData;
      const unesenaJeSifraMjesta = sifraMjesta >= 10000 && sifraMjesta <= 99999;

      return (
         <>
            <CustomAlertNoForm
               title={intl.getTranslate('naseljenaMjesta.addMessage')}
               btnOk={intl.getTranslate('common.save')}
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               onOk={() => this.doSubmitOnConfirm()}
            >
               <div>
                  {intl.getTranslate('naseljenaMjesta.addMessage')}{' '}
                  <span className='confirm-highlight'>{nazivMjesta}</span>?
               </div>
            </CustomAlertNoForm>
            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-naseljeno-mjesto'>
                  <div className='row'>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'sifraMjesta',
                           'naseljenaMjesta',
                           this.handleSifraMjestaChange,
                           true
                        )}
                     </div>
                  </div>
                  <FramerConditionalCollapsableContainer
                     condition={unesenaJeSifraMjesta}
                     height='18rem'
                  >
                     <div className='row'>
                        <div className='col-4'>
                           {this.renderTranslateInput(
                              'nazivMjesta',
                              'naseljenaMjesta'
                           )}
                        </div>
                     </div>

                     <hr />

                     <InfoVrijemeUpisa
                        datumUpisa={datumUpisa}
                        vrijemeUpisa={vrijemeUpisa}
                     />

                     {this.renderSubmitButton(
                        `${intl.getTranslate('common.save')}`
                     )}
                  </FramerConditionalCollapsableContainer>
               </div>
            </form>
         </>
      );
   }
}

export default withRouter(injectIntl(DodajMjesto));
