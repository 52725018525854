import React, { useState } from 'react';
import translate from '../../i18n/translate';
import { AnimatePresence } from 'framer-motion';
import MenuDropdown from './menu-dropdown';
import NavbarLink from './../common/navbar-link/navbar-link';

const NavbarDropdownLink = ({ to, translationKey, submenu, subpaths }) => {
   const [showDDL, setShowDDL] = useState(false);

   const onMouseEnter = () => {
      setShowDDL(true);
   };
   const onMouseLeave = () => {
      setShowDDL(false);
   };

   return (
      <li
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
         onClick={() => setShowDDL(false)}
      >
         <NavbarLink
            text={translate(translationKey)}
            to={to}
            subpaths={subpaths}
         />
         <AnimatePresence>
            {showDDL && (
               <MenuDropdown
                  header={translate(translationKey)}
                  dropdownOptions={submenu}
                  onOptionClick={() => setShowDDL(false)}
               />
            )}
         </AnimatePresence>
      </li>
   );
};

export default NavbarDropdownLink;
