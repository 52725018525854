const idleTimeMinutes = 10;
export const idleTimeout = idleTimeMinutes * 60 * 1000;

export const idleTimeHasExpired = () => {
   if (!sessionStorage.getItem('timestamp')) return false;

   const lastActionTimestamp = Number(sessionStorage.getItem('timestamp'));
   const now = new Date().getTime();
   const timespan = now - lastActionTimestamp;
   return timespan > idleTimeout;
};

let history;

/*
   provjeravam kod učitavanja da li je istekao idleTime i ako jest
   radim redirekt na logout
   U tom trenutku brišem timestamp kako kod redirekta ne bi ušao
   u loop
*/
export const checkIfIdleTimeHasExpired = (_history) => {
   history = _history;
   if (idleTimeHasExpired()) {
      sessionStorage.removeItem('timestamp');
      logout(history);
   }
};

export const getCurrentURLPath = () => {
   const url = window.location.href;
   const arr = url.split('/');
   return arr[arr.length - 1];
};

export const logout = () => {
   if (getCurrentURLPath() !== 'login') history.push(`/logout`);
   //if (getCurrentURLPath() !== 'login') window.location = `/#/logout`;
};

let time = null;
export const resetTimer = () => {
   sessionStorage.setItem('timestamp', new Date().getTime());
   clearTimeout(time);
   time = setTimeout(logout, idleTimeout);
};

(() => {
   document.onmousemove = resetTimer;
   document.onkeypress = resetTimer;
})();
