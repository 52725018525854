import intl from '../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjeProdajnogMjestaSchema = {
   sifraProdajnogMjesta: Joi.number()
      .integer()
      .min(1)
      .max(9999)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.sifraProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.sifraProdajnogMjestaValidator'
            )}`
         };
      }),
   sifraNaseljenogMjesta: Joi.number()
      .integer()
      .min(10000)
      .max(99999)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.sifraNaseljenogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.sifraNaseljenogMjestaValidator'
            )}`
         };
      }),
   nazivProdajnogMjesta: Joi.string()
      .max(21)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.nazivProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.nazivProdajnogMjestaValidator'
            )}`
         };
      }),
   adresaProdajnogMjesta: Joi.string()
      .max(21)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.adresaProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.adresaProdajnogMjestaValidator'
            )}`
         };
      }),
   aktivnostProdajnogMjesta: Joi.number()
      .integer()
      .min(0)
      .max(3)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.aktivnostProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.aktivnostProdajnogMjestaValidator'
            )}`
         };
      }),
   pozivniBrojTelefona: Joi.number()
      .integer()
      .min(0)
      .max(999)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.pozivniBrojTelefona'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.pozivniBrojTelefonaValidator'
            )}`
         };
      }),
   brojTelefona: Joi.number()
      .integer()
      .min(0)
      .max(9999999)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.brojTelefona'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.brojTelefonaValidator'
            )}`
         };
      }),
   sifraOpcine: Joi.number()
      .integer()
      .min(0)
      .max(9500)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.sifraOpcine'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.sifraOpcineValidator'
            )}`
         };
      }),
   sifraRegije: Joi.number()
      .integer()
      .min(0)
      .max(99)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.sifraRegije'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.sifraRegijeValidator'
            )}`
         };
      }),
   tipLokala: Joi.number()
      .integer()
      .min(0)
      .max(999)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.tipLokala'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.tipLokalaValidator'
            )}`
         };
      }),
   brojTerminala: Joi.number()
      .integer()
      .min(0)
      .max(99)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.brojTerminala'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.brojTerminalaValidator'
            )}`
         };
      }),
   kategorijaProdajnogMjesta: Joi.number()
      .integer()
      .min(0)
      .max(49)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.kategorijaProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.kategorijaProdajnogMjestaValidator'
            )}`
         };
      })
};
