import React from 'react';
import intl from '../../services/intl-service';

const ButtonEditTableRow = ({ onClick, item }) => {
   return (
      <button
         className='button edit'
         onClick={(e) => {
            e.stopPropagation();
            onClick(item);
         }}
      >
         {intl.getTranslate('common.edit')}
      </button>
   );
};

export default ButtonEditTableRow;
