import React, { Component } from 'react';
import ErrorMessage from './error-message';

class ErrorBoundaries extends Component {
   state = { hasError: false, error: '', componentStack: '' };

   componentDidCatch(error, info) {
      const { componentStack: cs } = info;
      const componentStack = cs.split('\n');

      console.log('%cERROR', 'color:yellow;font-size:40px;background:black');
      componentStack.forEach((cs) => {
         console.log(`%c${cs}`, 'color:yellow;background:black');
      });

      this.setState({ hasError: true, error, componentStack });
   }

   render() {
      const { hasError, componentStack } = this.state;
      if (hasError) {
         return <ErrorMessage componentStack={componentStack} />;
      }
      return this.props.children;
   }
}

export default ErrorBoundaries;
