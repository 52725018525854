import intl from '../../services/intl-service';
import Joi from 'joi-browser';

export const pregledListicaSchema = {
   operativniBroj: Joi.number()
      .integer()
      .min(1)
      .max(9999)
      .required()
      .label(intl.getTranslate('pregledListica.operativniBroj'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledListica.operativniBrojValidator'
            )}`
         };
      }),
   godina: Joi.number()
      .integer()
      .min(2000)
      .max(2021)
      .required()
      .label(intl.getTranslate('pregledListica.godina'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledListica.godinaValidator'
            )}`
         };
      }),
   sedmica: Joi.number()
      .integer()
      .min(1)
      .max(53)
      .required()
      .label(intl.getTranslate('pregledListica.sedmica'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledListica.sedmicaValidator'
            )}`
         };
      }),
   dan: Joi.number()
      .integer()
      .min(1)
      .max(7)
      .required()
      .label(intl.getTranslate('pregledListica.dan'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledListica.danValidator'
            )}`
         };
      }),
   brojListica: Joi.number()
      .integer()
      .min(1)
      .max(9999)
      .required()
      .label(intl.getTranslate('pregledListica.brojListica'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledListica.brojListicaValidator'
            )}`
         };
      }),
   slog: Joi.number()
      .integer()
      .min(1)
      .required()
      .label(intl.getTranslate('pregledListica.brojListica'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledListica.brojListicaValidator'
            )}`
         };
      })
};
