import React, { useEffect, useState } from 'react';
import { pregledAktivnostiTerminalaService as service } from '../../services/a21-terminal-service';
import intl from '../../services/intl-service';
import { withRouter } from 'react-router';
// eslint-disable-next-line
import { getPodaciZaComboBox } from '../../services/a21-terminal-service';
import moment from 'moment';
import { formatDateToInt } from './../../services/form-components-service';
import AntDatepicker from '../common/ant-datepicker';
import AntDropDownListTranslate from '../common/ant-ddl-translate';
import { getDDLoptionText } from './../../services/common-services';
import { DataLoader } from '../../services/data-loader-service';
import DataTable from './../common/table/datatable';
import { useSelector, useDispatch } from 'react-redux';
import { ucitajProdajnaMjesta } from '../../store/SierraLeone/A02-prodajna-mjesta';
import NoDataMessage from './../common/no-data-message';
import { paths } from './../../routes/routes';
import { getRouteWithoutParams } from './../../services/route-service';

/*
    label - naslov kolumne
    path - svojstvo objekta cije podatke treba prikazati
*/
const AktivnostTerminala = (props) => {
   const columns = [
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('terminalAktivnost.operativniBroj'),
         path: 'operativniBroj',
         width: '10rem'
      },
      {
         sortable: true,
         label: intl.getTranslate('terminalAktivnost.zastupnik'),
         path: 'prezimeIme'
      },
      {
         label: intl.getTranslate('terminalAktivnost.prodajnoMjesto'),
         path: 'nazivProdajnogMjesta'
      },
      {
         label: intl.getTranslate('terminalAktivnost.adresa'),
         path: 'adresa'
      },
      {
         center: true,
         label: intl.getTranslate('terminalAktivnost.brojTerminala'),
         path: 'brojTerminala'
      },
      {
         sortable: true,
         label: intl.getTranslate('terminalAktivnost.datumPrijaveOdjave'),
         content: (item) =>
            `${item.datumPrijaveOdjave}-${item.vrijemePrijaveOdjave}`,
         key: 'datumPrijaveOdjave'
      },
      {
         label: intl.getTranslate('terminalAktivnost.statusPrijaveOdjave'),
         path: 'statusPrijaveOdjave',
         content: (item) => (
            <span
               style={{
                  color: item.statusPrijaveOdjave === 0 ? 'red' : 'green'
               }}
            >
               {item.statusPrijaveOdjave === 0
                  ? intl.getTranslate('terminalAktivnost.odjava')
                  : intl.getTranslate('terminalAktivnost.prijava')}
            </span>
         )
      },
      {
         path: 'btnManage',
         style: { textAlign: 'right' },
         content: (item) => (
            <button
               className='button edit'
               onClick={(e) => {
                  // e.stopPropagation();
                  props.history.push(
                     `${getRouteWithoutParams(
                        paths.manage_terminalLocation.path
                     )}${item.brojTerminala}`
                  );
               }}
            >
               {intl.getTranslate(
                  'terminalAktivnost.azurirajLokacijuTerminala'
               )}
            </button>
         )
      }
   ];

   //const odDatumInit = moment(new Date()).subtract(365, 'day');
   const dana = 365;
   const dateOffset = 24 * 60 * 60 * 1000 * dana; //godina
   const initODdate = new Date();
   initODdate.setTime(initODdate.getTime() - dateOffset);

   // ComponentDidMount
   const [CDM, setCDM] = useState(false);
   const [allData, setAllData] = useState([]);
   const [ddlData, setDDLData] = useState([]);
   const [datumOD, setDatumOD] = useState(moment(initODdate));
   const [datumODpostFormat, setDatumODpostFormat] = useState(0);
   const [datumDO, setDatumDO] = useState(moment(new Date()));
   const [datumDOpostFormat, setDatumDOpostFormat] = useState(0);
   const [terminalValue, setTerminalValue] = useState(0);

   const dispatch = useDispatch();
   const prodajnaMjesta = useSelector(
      (state) => state.prodajnaMjesta.prodajnaMjesta
   );

   const getProdajnoMjesto = (id) =>
      prodajnaMjesta.find((p) => p.sifraProdajnogMjesta === id) ?? {};

   const formatTableData = (response) => {
      return response.map((d) => {
         const pmId = d.sifraProdajnogMjesta;
         const pmNaziv = getProdajnoMjesto(pmId).nazivProdajnogMjesta ?? '';

         return {
            ...d,
            nazivProdajnogMjesta: `[${pmId}] ${pmNaziv}`
         };
      });
   };

   const translateFiltarPrikaza = (value) =>
      intl.getTranslate(`terminalAktivnost.filtarPrikaza.${value}`);

   const onDataLoad = (response) => {
      const { terminali } = response;
      if (terminali) setAllData(terminali);
   };

   const loadTerminalData = async () => {
      if (!CDM) return;

      DataLoader(
         service.get,
         [
            datumODpostFormat,
            datumDOpostFormat,
            terminalValue ? terminalValue : 0
         ],
         onDataLoad
      );
   };

   const loadData = async () => {
      let tipPrikazaTerminala = await service.staticData();

      // text vrijednosti pročitaj iz prijevoda
      tipPrikazaTerminala = tipPrikazaTerminala.map((a) => ({
         ...a,
         text: translateFiltarPrikaza(a.value)
      }));

      setDDLData(tipPrikazaTerminala);
      loadTerminalData();
   };

   /*
      Prilikom učitavanja, datepicker vrijednosti (trenutni datum)
      formatiraj za slanje upita na API
      */
   const setDatesFormat = () => {
      const _od = datumOD.format('DD.MM.YYYY');
      const _do = datumDO.format('DD.MM.YYYY');

      setDatumODpostFormat(formatDateToInt(_od));
      setDatumDOpostFormat(formatDateToInt(_do));
   };

   useEffect(() => {
      loadData();
      setDatesFormat();
      if (prodajnaMjesta.length === 0) dispatch(ucitajProdajnaMjesta());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   // Kada se komponenta inicijalizira učitaj podatke
   useEffect(() => {
      if (CDM) loadTerminalData();
   }, [CDM]); // eslint-disable-line react-hooks/exhaustive-deps

   // Kada promijeni DDL opcija
   useEffect(() => {
      loadTerminalData();
   }, [terminalValue]); // eslint-disable-line react-hooks/exhaustive-deps

   // Kada promijeni vrijednost datepickera
   useEffect(() => {
      setDatesFormat();
   }, [datumOD, datumDO]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (datumODpostFormat !== 0 && datumDOpostFormat !== 0 && !CDM) {
         setCDM(true);
      }

      loadTerminalData();
   }, [datumODpostFormat, datumDOpostFormat]); // eslint-disable-line react-hooks/exhaustive-deps

   // Kada se učitaju podaci podesi DDL na prvu opciju
   useEffect(() => {
      setTerminalValue(ddlData.length !== 0 ? ddlData[0].value : null);
   }, [ddlData]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <>
         <div className='row form mb-5'>
            <div className='col'>
               <AntDropDownListTranslate
                  translationKey='terminalAktivnost'
                  translationSubKey='ddlPrikaziTerminale'
                  options={ddlData}
                  onChange={({ text, value }) => setTerminalValue(value)}
                  attributes={{
                     value: getDDLoptionText(terminalValue, ddlData)
                  }}
               />
            </div>
            <div className='col'>
               <AntDatepicker
                  label={intl.getTranslate('terminalAktivnost.odDatuma')}
                  defaultDate={datumOD}
                  onChange={(e) => setDatumOD(e)}
               />
            </div>
            <div className='col'>
               <AntDatepicker
                  label={intl.getTranslate('terminalAktivnost.doDatuma')}
                  onChange={(e) => setDatumDO(e)}
               />
            </div>
         </div>

         <NoDataMessage messageKey='terminalAktivnost.noData' data={allData} />

         {allData && allData.length > 0 && (
            <DataTable
               columns={columns}
               pageSize={15}
               data={formatTableData(allData)}
               searchBackground={false}
               searchLabel={intl.getTranslate('terminalAktivnost.search')}
               searchLabelPlaceholder={intl.getTranslate(
                  'terminalAktivnost.searchPlaceholder'
               )}
               searchColumns={['operativniBroj', 'prezimeIme']}
            />
         )}
      </>
   );
};

export default withRouter(AktivnostTerminala);
