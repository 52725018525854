import React, { useEffect, useState } from 'react';
import { isPublishVersion } from '../../services/app-service';
import { DataLoader } from '../../services/data-loader-service';
import intl from '../../services/intl-service';
import { mjestoService } from '../../services/m-o-r-service';
import DataTable from './../common/table/datatable';
import { publishVersions } from './../../services/app-service';
import { AnimatePresence } from 'framer-motion';
import CustomAlertNoFormContainer from './../common/custom-alert-container';
import UrediNaseljenoMjesto from './uredi-naseljeno-mjesto';
import ButtonAddNew from '../common/btn-add-new';
import ButtonEditTableRow from '../common/btn-edit-table-row';

const PregledMjesta = (props) => {
   const columns = [
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('naseljenaMjesta.sifraMjesta'),
         path: 'sifraMjesta'
      },
      {
         sortable: true,
         center: false,
         label: intl.getTranslate('naseljenaMjesta.nazivMjesta'),
         path: 'nazivMjesta'
      },
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('common.operaterUpisa'),
         path: 'operaterUpisa'
      },
      {
         sortable: true,
         label: intl.getTranslate('common.datumUpisa'),
         path: 'datumUpisa'
      },
      {
         sortable: true,
         label: intl.getTranslate('common.vrijemeUpisa'),
         path: 'vrijemeUpisa'
      },
      {
         key: 'btnEdit',
         width: '10rem',
         content: (item) => {
            if (isPublishVersion(publishVersions.BiH)) return null;
            return (
               <ButtonEditTableRow onClick={handleOnEditClick} item={item} />
            );
         }
      }
   ];

   const [data, setData] = useState([]);
   const [showUredi, setShowUredi] = useState(false);
   const [odabranoMjesto, setOdabranoMjesto] = useState(null);

   const onDataLoad = (response) => {
      const { mjesta } = response;
      setData(mjesta);
   };

   const loadData = () => DataLoader(mjestoService.list, null, onDataLoad);

   useEffect(() => {
      loadData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleOnEditClick = (mjesto) => {
      setOdabranoMjesto(mjesto);
      setShowUredi(true);
   };

   const handleAfterAzurirajMjesto = () => {
      loadData();
      setShowUredi(false);
   };
   const handleCancel = () => {
      setShowUredi(false);
   };

   return (
      <>
         <AnimatePresence>
            {showUredi && (
               <CustomAlertNoFormContainer
                  title={intl.getTranslate('naseljenaMjesta.confirmMessage')}
                  style={{ width: '80rem' }}
               >
                  <UrediNaseljenoMjesto
                     odabranoMjesto={odabranoMjesto}
                     onSubmit={handleAfterAzurirajMjesto}
                     onCancel={handleCancel}
                  />
               </CustomAlertNoFormContainer>
            )}
         </AnimatePresence>
         <DataTable
            columns={columns}
            data={data}
            searchLabel={intl.getTranslate('naseljenaMjesta.pretraga')}
            searchLabelPlaceholder={intl.getTranslate(
               'naseljenaMjesta.pretragaPlaceholder'
            )}
            searchColumn='nazivMjesta'
            onRowClick={handleOnEditClick}
            btnAdd={
               <ButtonAddNew
                  title={intl.getTranslate('naseljenaMjesta.btnAddNew')}
                  path='/add-populated-area'
               />
            }
         />
      </>
   );
};

export default PregledMjesta;
