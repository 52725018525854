import React from 'react';
import { motion } from 'framer-motion';
import { backgroundLineEase } from './../../services/framer-motion-service';

const BackgroundLine = (props) => {
   return (
      <svg
         className='background-line'
         viewBox='0 0 2361 573'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'
      >
         <motion.path
            initial={{
               opacity: 0,
               pathLength: 0,
               pathOffset: 1,
               strokeWidth: 20,
               strokeOpacity: 0.1
            }}
            animate={{ pathLength: 1, pathOffset: 0, opacity: 1 }}
            transition={{ duration: 1.5, ease: backgroundLineEase }}
            d='M15.5 494.5C95.6667 414 432.3 128.8 765.5 142C1182 158.5 1364.5 552 1745.5 557.5C2050.3 561.9 2324 201.5 2346 16'
            stroke='#1ee8f5'
         />
      </svg>
   );
};

export default BackgroundLine;
