import { paths } from '../../../routes/routes';
import { publishVersions } from './../../../services/app-service';
import {
   faStoreAlt,
   faTasks,
   faFlag,
   faGlobe,
   faGlobeAfrica,
   faUserFriends,
   faCommentDollar,
   faHouseUser,
   faExclamation
} from '@fortawesome/free-solid-svg-icons';
import { getRouteWithoutParams } from './../../../services/route-service';

export const menuAzuriranja = [
   {
      path: paths.manage_addAgent.path,
      links: [
         {
            label: paths.manage_addAgent.linklabel,
            icon: faUserFriends
         }
      ]
   },
   {
      path: paths.manage_addUpdateTerritorialUnit.path,
      links: [
         {
            label: paths.manage_addUpdateTerritorialUnit.linklabel,
            icon: faGlobe,
            publishVersion: publishVersions.SierraLeone
         }
      ]
   },
   {
      path: paths.manage_posShops.path,
      links: [
         {
            label: paths.manage_posShops.linklabel,
            icon: faStoreAlt
         },
         {
            label: paths.manage_posShops.linklabel,
            icon: faGlobe,
            publishVersion: publishVersions.SierraLeone
         }
      ]
   },
   {
      path: paths.manage_City.path,
      links: [
         {
            label: paths.manage_City.linklabel,
            icon: faGlobeAfrica,
            publishVersion: publishVersions.BiH
         }
      ]
   },
   {
      path: paths.manage_county.path,
      links: [
         {
            label: paths.manage_county.linklabel,
            icon: faGlobe,
            publishVersion: publishVersions.BiH
         }
      ]
   },
   {
      path: paths.manage_Region.path,
      links: [
         {
            label: paths.manage_Region.linklabel,
            icon: faFlag,
            publishVersion: publishVersions.BiH
         }
      ]
   },
   {
      path: paths.manage_updateSalesFees.path,
      links: [
         {
            label: paths.manage_updateSalesFees.linklabel,
            icon: faCommentDollar,
            publishVersion: publishVersions.SierraLeone
         }
      ]
   },
   {
      path: paths.manage_updatePosSuperiorManagers.path,
      links: [
         {
            label: paths.manage_updatePosSuperiorManagers.linklabel,
            icon: faHouseUser,
            publishVersion: publishVersions.SierraLeone
         }
      ]
   },
   {
      path: paths.manage_manageTerminalActivityForm.path,
      links: [
         {
            label: paths.manage_manageTerminalActivityForm.linklabel,
            icon: faTasks
         }
      ]
   },
   {
      path: getRouteWithoutParams(paths.manage_terminalLocation.path),
      links: [
         {
            label: paths.manage_terminalLocation.linklabel,
            icon: faTasks
         }
      ]
   },
   {
      path: paths.manage_updatePosNotofocation.path,
      links: [
         {
            label: paths.manage_updatePosNotofocation.linklabel,
            icon: faExclamation
         }
      ]
   }
];
