import intl from '../../../services/intl-service';
import Joi from 'joi-browser';

export const pregledKenoListicaSchema = {
   brojListica: Joi.number()
      .integer()
      .min(1)
      .max(99_999)
      .required()
      .label(intl.getTranslate('pregledKenoListica.brojListica'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledKenoListica.brojListicaValidator'
            )}`
         };
      }),
   kolo: Joi.number()
      .integer()
      .min(1)
      .max(9_999_999)
      .required()
      .label(intl.getTranslate('pregledKenoListica.kolo'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'pregledKenoListica.koloValidator'
            )}`
         };
      })
};
