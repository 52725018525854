import http from './http';
import api from '../config/azuriranje-obavijesti-config.json';
import { getOperaterUpisaObject } from './auth-service';

export const azuriranjeObavijestiService = {
   get: () => http.post(`${api.getObavijest}`),
   addOrUpdate: (obavijest) =>
      http.post(`${api.postObavijest}`, {
         ...obavijest,
         ...getOperaterUpisaObject()
      })
};
