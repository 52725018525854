import React from 'react';
import Form from '../common/form';
import { toast } from 'react-toastify';
import { pregledListicaSchema } from './pregled-listica-validacije';
import PodaciUplata from './podaci-uplata';
import PodaciIsplata from './podaci-isplata';
import BingoKombinacija from './bingo-kombinacija';
import { pregledListicaService as service } from './../../services/a16-pregled-listica';
import { toastError, toastSuccess } from '../../services/toast-service';
import { DataLoader } from './../../services/data-loader-service';
import intl from './../../services/intl-service';
import FramerConditionalCollapsableContainer from './../common/framer-conditional-collapsable-container';
import { AnimatePresence } from 'framer-motion';
import CustomAlertContainer from '../common/custom-alert-container';

//Debounce: https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js

class PregledListica extends Form {
   state = {
      validationData: {
         operativniBroj: 2002,
         godina: 2021,
         sedmica: 27,
         dan: 2,
         brojListica: 47,
         slog: 157
      },
      podaciUplata: null,
      podaciIsplata: null,
      kombinacije: [],
      showPodaciUplate: false,
      showPodaciIsplate: false,
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: ''
   };

   schema = pregledListicaSchema;

   doSubmit = async () => {};

   componentDidMount() {
      try {
         this.ucitajSlog();
      } catch (ex) {
         toast.error(toastError(ex));
      }
   }

   onSlogDataLoad = (response) => {
      const { podaciUplata, podaciIsplata, kombinacije } = response;
      this.setState({ podaciUplata, podaciIsplata, kombinacije });
   };

   ucitajSlog = async () => {
      const { godina, sedmica, slog } = this.state.validationData;
      const _slog = { godina, sedmica, slog };

      DataLoader(service.getSlog, [_slog], this.onSlogDataLoad);
   };

   ucitajListic = async () => {
      const { operativniBroj, godina, sedmica, dan, brojListica } =
         this.state.validationData;
      const _listic = { operativniBroj, godina, sedmica, dan, brojListica };
      const { data: response } = await service.getListic(_listic);
      const { isSuccess, msg } = response;

      if (isSuccess === 1) toastSuccess();
      else toastError(msg);

      const { podaciUplata, podaciIsplata, kombinacije } = response;
      this.setState({ podaciUplata, podaciIsplata, kombinacije });
   };

   showUplate = () => this.setState({ showPodaciUplate: true });
   showIsplate = () => this.setState({ showPodaciIsplate: true });

   render() {
      const {
         podaciUplata,
         podaciIsplata,
         kombinacije,
         showPodaciUplate,
         showPodaciIsplate
      } = this.state;

      return (
         <>
            <AnimatePresence>
               {showPodaciUplate && (
                  <CustomAlertContainer
                     title={intl.getTranslate(
                        'pregledListica.podaciUplate.naslov'
                     )}
                     style={{ width: '80rem' }}
                  >
                     <PodaciUplata
                        data={podaciUplata}
                        onClick={() => {
                           this.setState({ showPodaciUplate: false });
                        }}
                     />
                  </CustomAlertContainer>
               )}
            </AnimatePresence>
            <AnimatePresence>
               {showPodaciIsplate && (
                  <CustomAlertContainer
                     title={intl.getTranslate(
                        'pregledListica.podaciIsplate.naslov'
                     )}
                     style={{ width: '80rem' }}
                  >
                     <PodaciIsplata
                        data={podaciIsplata}
                        onClick={() => {
                           this.setState({ showPodaciIsplate: false });
                        }}
                     />
                  </CustomAlertContainer>
               )}
            </AnimatePresence>
            <div className='pregled-listica'>
               <form onSubmit={this.handleFormSubmit} className='form'>
                  <div className='row'>
                     <div className='col-3'>
                        <div className='row'>
                           <div className='col'>
                              {this.renderTranslateInput(
                                 'operativniBroj',
                                 'pregledListica',
                                 null,
                                 true
                              )}
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col'>
                              {this.renderTranslateInput(
                                 'brojListica',
                                 'pregledListica',
                                 null,
                                 true
                              )}
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col'>
                              {this.renderTranslateInput(
                                 'slog',
                                 'pregledListica',
                                 null,
                                 true
                              )}
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col'>
                              {this.renderTranslateInput(
                                 'godina',
                                 'pregledListica',
                                 null,
                                 true
                              )}
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col'>
                              {this.renderTranslateInput(
                                 'sedmica',
                                 'pregledListica',
                                 null,
                                 true
                              )}
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col'>
                              {this.renderTranslateInput(
                                 'dan',
                                 'pregledListica',
                                 null,
                                 true
                              )}
                           </div>
                        </div>
                     </div>
                     <div className='col'>
                        <FramerConditionalCollapsableContainer
                           condition={kombinacije && kombinacije.length > 0}
                           height='100%'
                        >
                           <div className='row kombinacije-container'>
                              {kombinacije &&
                                 kombinacije.length > 0 &&
                                 kombinacije.map((kombinacija, i) => (
                                    <BingoKombinacija
                                       key={`kombinacija${i}`}
                                       index={i + 1}
                                       numbers={kombinacija}
                                    />
                                 ))}
                           </div>
                        </FramerConditionalCollapsableContainer>
                     </div>
                  </div>

                  <hr />

                  <div className='buttons'>
                     <div className='submit-buttons'>
                        <button
                           className='button save'
                           onClick={this.ucitajSlog}
                           disabled={this.validateForm()}
                        >
                           {intl.getTranslate('pregledListica.btnSlog')}
                        </button>
                        <button
                           className='button save'
                           onClick={this.ucitajListic}
                           disabled={this.validateForm()}
                        >
                           {intl.getTranslate('pregledListica.btnListic')}
                        </button>
                     </div>
                     <div className='report-buttons'>
                        <button
                           className='button cancel'
                           onClick={this.showUplate}
                        >
                           {intl.getTranslate(
                              'pregledListica.btnPrikaziUplate'
                           )}
                        </button>
                        <button
                           className='button cancel'
                           onClick={this.showIsplate}
                        >
                           {intl.getTranslate(
                              'pregledListica.btnPrikaziIsplate'
                           )}
                        </button>
                     </div>
                  </div>
               </form>
            </div>
         </>
      );
   }
}

export default PregledListica;
