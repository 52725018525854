import React from 'react';
import { TimePicker } from 'antd';
import { getMomentObjFromNumber } from './../../services/form-components-service';

function AntTimepicker({
   label,
   value,
   onChange,
   validationMessage = '',
   rest
}) {
   const handleOnChange = (e) => {
      if (!e) return;
      onChange(e);
   };

   return (
      <div className='form-group'>
         <label style={{ fontWeight: 'bold' }} className='mr-1'>
            {label}
         </label>
         <TimePicker
            style={{ borderRadius: '.2rem', width: '100%' }}
            onChange={handleOnChange}
            value={value ? getMomentObjFromNumber(value) : null}
            {...rest}
         />
         {validationMessage && (
            <div className='alert alert-primary' style={{ fontWeight: 700 }}>
               {validationMessage}
            </div>
         )}
      </div>
   );
}

export default AntTimepicker;
