import intl from '../../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjeProvizijeSchema = {
   operativniBroj: Joi.number()
      .integer()
      .min(1)
      .max(99999)
      .required()
      .label(intl.getTranslate('azuriranjeProvizije.operativniBroj'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'azuriranjeProvizije.operativniBrojValidator'
            )}`
         };
      }),
   postotakProvizijeKeno: Joi.number()
      .integer()
      .min(0)
      .required()
      .label(intl.getTranslate('azuriranjeProvizije.postotakProvizijeKeno'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'azuriranjeProvizije.postotakProvizijeKenoValidator'
            )}`
         };
      })
};
// https://stackoverflow.com/questions/11704267/in-javascript-how-to-conditionally-add-a-member-to-an-object
