import React from 'react';
import intl from '../../services/intl-service';
import { v4 as uuid } from 'uuid';

const BingoKombinacija = ({ index, numbers }) => {
   //const numbers = _.range(1, 28);
   return (
      <div className='col  d-flex justify-content-center'>
         <div className='bingo-kombinacija'>
            <div className='header'>
               {`${intl.getTranslate('pregledListica.kombinacija')} ${index}`}
            </div>
            <div className='numbers'>
               {numbers.map((numObj) => {
                  const css = numObj
                     ? numObj.dobitni
                        ? 'number winning-number'
                        : 'number'
                     : 'number';
                  return (
                     <div key={uuid()} className={css}>
                        {numObj === null ? <span>&middot;</span> : numObj.broj}
                     </div>
                  );
               })}
            </div>
         </div>
      </div>
   );
};

export default BingoKombinacija;
