import React from 'react';
import { motion } from 'framer-motion';
import { getUsedTheme } from './../../services/app-service';

const DialogTitle = ({ title }) => {
   const cssClass = getUsedTheme() === 'light' ? 'theme-light' : 'theme-dark';

   return (
      <motion.h1
         className={cssClass}
         initial={{ opacity: 0, x: -30 }}
         animate={{ opacity: 1, x: 0 }}
         transition={{ ease: 'easeOut', delay: 0.1, duration: 0.5 }}
      >
         {title}
      </motion.h1>
   );
};

export default DialogTitle;
