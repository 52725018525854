import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';

class PrintExample extends Component {
   render() {
      return (
         <div>
            <ReactToPrint
               trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return <button>Print this out!</button>;
               }}
               content={() => this.ref}
            />

            <div ref={(el) => (this.ref = el)} className='print'>
               <div className='header'>HEADER</div>
               <div className='body'>{this.props.body}</div>
               <div className='footer'>FOOTER</div>
            </div>
            {/* <PrintPageClass
               ref={(el) => (this.ref = el)}
               body={<PrintTableData />}
            /> */}
         </div>
      );
   }
}
export default PrintExample;
