import React from 'react';
import Form from '../common/form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import intl from '../../services/intl-service';
import { toast } from 'react-toastify';
import { upravljanjeTerminalimaSchema } from './upravljanje-terminalima-validacije';
import { aktivnostTerminalaService as service } from '../../services/a09-terminal-service';
import { toastError } from '../../services/toast-service';
import { ucitajAktivnostiTerminala } from '../../store/common';
import CustomAlertNoForm from '../common/custom-alert-no-form';
import InfoVrijemeUpisa from '../common/info-vrijeme-upisa';
import { withRouter } from 'react-router-dom';
import { DataLoader } from '../../services/data-loader-service';
import { toastSuccess } from '../../services/toast-service';
import CancelButton from '../common/cancel-button';
import { getTranslatedDDLoptions } from '../../services/form-components-service';
import DDL from '../common/ant-ddl-translate';
import FramerConditionalCollapsableContainer from './../common/framer-conditional-collapsable-container';

class UpravljanjeTerminalima extends Form {
   state = {
      validationData: {
         brojTerminala: '',
         aktivnost: 0
      },
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      operaterUpisa: '',
      showConfirm: false
   };

   schema = upravljanjeTerminalimaSchema;

   doSubmit = () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const { updateMode } = this.props;
      const { datumUpisa } = this.state;
      const { brojTerminala, aktivnost } = this.state.validationData;
      const azuriraniPodaci = { brojTerminala, aktivnost, operaterUpisa: 9001 };
      const { data: response } = await service.addOrUpdate(azuriraniPodaci);

      if (response.isSuccess === 1) {
         toastSuccess(
            updateMode
               ? intl.getTranslate('terminalAktivnost.updateSuccess')
               : datumUpisa
               ? intl.getTranslate('terminalAktivnost.updateSuccess')
               : intl.getTranslate('terminalAktivnost.addSuccess')
         );
         if (updateMode) this.props.onSubmit();
         this.setState({ showConfirm: false });
      } else toastError();
   };

   onDataLoad = (response) => {
      const validationData = { ...this.state.validationData };
      const {
         brojTerminala,
         aktivnost,
         datumUpisa,
         vrijemeUpisa,
         operaterUpisa,
         isSuccess,
         msg
      } = response;

      if (isSuccess === 1) {
         validationData.brojTerminala = brojTerminala;
         validationData.aktivnost = aktivnost;

         this.setState({
            validationData,
            operaterUpisa,
            datumUpisa,
            vrijemeUpisa
         });
      } else {
         toast.error(msg);
      }
   };

   ucitajPodatkeTerminala = async (_brojTerminala) => {
      DataLoader(service.get, [_brojTerminala], this.onDataLoad);
   };

   handleBrojTerminalaChange = async ({ target: input }) => {
      if (isNaN(input.value) || input.value <= 0) return;

      const brojTerminala = Number(input.value);
      if (brojTerminala >= 0 && brojTerminala <= 9999)
         this.ucitajPodatkeTerminala(brojTerminala);
   };

   handleAktivnostChange = ({ value }) => {
      const validationData = { ...this.state.validationData };
      validationData.aktivnost = value;

      this.setState({
         validationData
      });
   };

   componentDidMount() {
      const { ucitajAktivnostiTerminala } = this.props;
      ucitajAktivnostiTerminala();

      const { odabranTerminal } = this.props;
      if (!odabranTerminal) return;

      const validationData = { ...this.state.validationData };
      const { brojTerminala, aktivnost } = odabranTerminal;

      validationData.brojTerminala = brojTerminala;
      validationData.aktivnost = aktivnost;

      this.setState({ validationData });
   }

   render() {
      const {
         validationData,
         showConfirm,
         datumUpisa,
         operaterUpisa,
         vrijemeUpisa
      } = this.state;
      let { aktivnostTerminala: ddlOpcije, updateMode } = this.props;
      ddlOpcije = getTranslatedDDLoptions(ddlOpcije);
      const { aktivnost, brojTerminala } = validationData;

      // u podacima ima vrijednosti koje nisu 0 || 1
      // radim provjeru da spriječim rušenje
      const aktivnostPronadjena =
         ddlOpcije.length > 0
            ? ddlOpcije.find((a) => a.value === aktivnost)
            : false;

      const aktivnostText = aktivnostPronadjena
         ? ddlOpcije.find((a) => a.value === aktivnost).text
         : '';

      const ddlAttributes = {
         value: aktivnostPronadjena ? aktivnostText : null
      };

      const getConfirmMessage = () => {
         /*
            ili se uređuju podaci na pregledu terminala
            ili sam na stranici za ažuriranje, na broju terminala koji je vec upisan
         */
         if (updateMode || (!updateMode && datumUpisa))
            return intl.getTranslate('terminalAktivnost.updateConfirmMessage');

         //na stranici sam za ažuriranje, unosim nepostojeći broj terminala
         if (!updateMode && !datumUpisa)
            return intl.getTranslate('terminalAktivnost.addConfirmMessage');
      };

      return (
         <>
            <CustomAlertNoForm
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               onOk={() => this.doSubmitOnConfirm()}
               btnOk={
                  updateMode
                     ? intl.getTranslate('common.update')
                     : datumUpisa
                     ? intl.getTranslate('common.update')
                     : intl.getTranslate('common.add')
               }
            >
               {
                  <div>
                     {getConfirmMessage()}{' '}
                     <span className='confirm-highlight'>{`${brojTerminala}-${aktivnostText}`}</span>
                     ?
                  </div>
               }
            </CustomAlertNoForm>
            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-opcinu'>
                  <div className='row'>
                     <div className='col-4'>
                        {this.formInput({
                           name: 'brojTerminala',
                           label: intl.getTranslate('terminal.broj'),
                           isNumber: true,
                           placeholder: intl.getTranslate(
                              'terminal.brojTerminalaPlaceholder'
                           ),
                           fn: this.handleBrojTerminalaChange,
                           debounceDelay: 250,
                           attributes: {
                              disabled: updateMode ? 'disabled' : false
                           }
                        })}
                     </div>
                  </div>

                  <FramerConditionalCollapsableContainer
                     condition={brojTerminala > 0}
                     duration={updateMode ? 0 : 0.5}
                     height={updateMode ? '13rem' : '17rem'}
                  >
                     <div className='row'>
                        <div className='col-4'>
                           <DDL
                              options={ddlOpcije}
                              translationKey='terminal'
                              translationSubKey='aktivnost'
                              onChange={this.handleAktivnostChange}
                              selectedOption={aktivnost}
                           />
                        </div>
                     </div>

                     <hr />

                     <InfoVrijemeUpisa
                        datumUpisa={datumUpisa}
                        vrijemeUpisa={vrijemeUpisa}
                        operaterUpisa={operaterUpisa}
                     />

                     <div className='form-buttons'>
                        <span>
                           {this.formSubmit({
                              label: updateMode
                                 ? `${intl.getTranslate(
                                      'terminalAktivnost.submitUpdate'
                                   )}`
                                 : datumUpisa || vrijemeUpisa
                                 ? `${intl.getTranslate(
                                      'terminalAktivnost.submitUpdate'
                                   )}`
                                 : `${intl.getTranslate(
                                      'terminalAktivnost.submitSave'
                                   )}`
                           })}
                           {updateMode && (
                              <CancelButton onClick={this.props.onCancel} />
                           )}
                        </span>
                     </div>
                  </FramerConditionalCollapsableContainer>
               </div>
            </form>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   aktivnostTerminala: state.common.aktivnostTerminala
});

const mapDispatchToProps = (dispatch) => ({
   ucitajAktivnostiTerminala: () => dispatch(ucitajAktivnostiTerminala())
});

export default withRouter(
   injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(UpravljanjeTerminalima)
   )
);
