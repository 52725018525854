import React from 'react';
import intl from '../../../services/intl-service';
import { getTextFromValue_Zastupnici } from '../../../services/zastupnici-service';

const PostotakProvizijeInfoTable = ({ data }) => {
   const {
      operativniBroj: opbr,
      ime,
      prezime,
      tip: tipValue,
      imeNadredjenog,
      prezimeNadredjenog,
      tipNadredjenog: nadredjeniValue,
      postotakProvizijeKeno
   } = data;

   const tipZastupnika = getTextFromValue_Zastupnici(tipValue, 'tipZastupnika');
   const tipNadredjenog = getTextFromValue_Zastupnici(
      nadredjeniValue,
      'tipNadredjenogZastupnika'
   );

   const zastupnik = `${ime} ${prezime}, ${tipZastupnika}`;
   const nadredjeni = `${imeNadredjenog} ${prezimeNadredjenog}, ${tipNadredjenog}`;

   return (
      <div className='row mt-5'>
         <div className='col'>
            <table className='table table-striped table-sm tbl-azuriranje-provizije'>
               <tbody>
                  <tr>
                     <td>
                        {intl.getTranslate(
                           'azuriranjeProvizije.operativniBroj'
                        )}
                     </td>
                     <td>{opbr}</td>
                  </tr>
                  <tr>
                     <td>
                        {intl.getTranslate('azuriranjeProvizije.imePrezime')}
                     </td>
                     <td>{zastupnik}</td>
                  </tr>
                  <tr>
                     <td>
                        {intl.getTranslate(
                           'azuriranjeProvizije.postotakProvizije'
                        )}
                     </td>
                     <td style={{ color: 'dodgerblue' }}>
                        {(postotakProvizijeKeno / 100).toFixed(2)}%
                     </td>
                  </tr>
                  <tr>
                     <td>
                        {intl.getTranslate('azuriranjeProvizije.nadredjeni')}
                     </td>
                     <td>{nadredjeni}</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   );
};

export default PostotakProvizijeInfoTable;
