import http from '../http';
import api from '../../config/SierraLeone/A03NADREDJENI.json';
import { getOperaterUpisaObject } from '../auth-service';

export const a03nadredjeniService = {
   get: (operativniBroj) => http.post(`${api.get}`, { operativniBroj }),
   getUpisani: (datumUpisa) => http.post(`${api.getUpisani}`, { datumUpisa }),
   addOrUpdate: (zastupnik) =>
      http.post(`${api.post}`, { ...zastupnik, ...getOperaterUpisaObject() })
};
