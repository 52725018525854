import * as XLSX from 'xlsx';
import { getSierraLeoneCurrencyData } from './../../services/SierraLeone/currency-service';

const month = new Date().getMonth() + 1;
const year = new Date().getFullYear();

const currencyBaseURL = 'https://www.hnb.hr/documents/20182/4089707/';
const currencyFileName = `egz-${month}-${year}.xlsx`;

const initialState = {
   currencyData: {},
   isLoading: false
};

export const setIsLoading = () => ({
   type: 'IS_LOADING',
   payload: {
      isLoading: true
   }
});

export const ucitajXLSX = () => async (dispatch, getState) => {
   try {
      let wb = XLSX.read(
         await (
            await fetch(`${currencyBaseURL}${currencyFileName}`)
         ).arrayBuffer()
      );

      const worksheet = wb.Sheets[wb.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const currencyData = getSierraLeoneCurrencyData(jsonData);

      const payload = {
         currencyData,
         isLoading: false
      };

      dispatch({ type: 'LOAD_WORKBOOK', payload });
   } catch (ex) {
      console.log('XLSX error');

      const currencyData = {
         zemlja: 'Sijera Leone',
         naziv: 'leone',
         slovnaOznaka: 'SLL',
         brojcanaOznaka: 694,
         dolar: 13039.6309,
         euro: 13878.7419,
         kuna: 0.0005
      };

      const payload = {
         currencyData,
         isLoading: false
      };

      dispatch({ type: 'LOAD_WORKBOOK', payload });
   }
};

//  REDUCER
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case 'LOAD_WORKBOOK':
         return {
            ...state,
            ...action.payload
         };
      default:
         return state;
   }
}
