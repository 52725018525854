import axios from 'axios';
import api from '../config/api-config.json';

/*
    USER
{
	"username": "test@user.com",
	"password": "12345",
	"name": "Test User",
	"img": ""
}
*/

export const registerUser = (user) => async (dispatch, getState) => {
   try {
      const { data: registeredUserData } = await axios.post(
         api.registerUser,
         user
      );

      const payload = {
         user: registeredUserData
      };
      dispatch({ type: 'REGISTER_USER', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         const payload = {
            user,
            error: ex.response.data.Message
         };
         dispatch({ type: 'SET_ERROR', payload });
      }
   }
};

export const setError = (error) => ({
   type: 'SET_ERROR',
   payload: {
      error
   }
});

export const deleteError = () => ({
   type: 'DELETE_ERROR'
});

//  REDUCER
export default function reducer(state = { user: {}, error: '' }, action) {
   switch (action.type) {
      case 'REGISTER_USER':
         return { user: {}, error: '' };
      case 'SET_ERROR':
         return {
            user: { ...action.payload.user },
            error: action.payload.error
         };
      case 'DELETE_ERROR':
         return { user: { ...state.user }, error: '' };
      default:
         return state;
   }
}
