import http from './http';
import api from '../config/zastupnici-config.json';
import intl from './intl-service';
import { formatDateToInt } from './form-components-service';
import { getOperaterUpisaObject } from './auth-service';

export const zastupniciService = {
   list: () =>
      http
         .postData(`${api.getZastupniciSvi}`)
         .then((response) => response.zastupnici),
   staticData: () =>
      http
         .postData(`${api.getPodaciZaComboBox}`)
         .then((response) => response.statickiPodaci),
   addOrUpdate: (payload) =>
      http.postData(`${api.update}`, {
         ...payload,
         ...getOperaterUpisaObject()
      }),
   getZastupnik: (payload) => http.postData(`${api.getZastupnik}`, payload),
   getStablo: () => http.post(`${api.getStablo}`),
   getNadredjeniZastupnici: () =>
      http.postData(`${api.getNadredjeniZastupniciSvi}`),
   getProdajnaMjestaSva: () => http.postData(`${api.getProdajnaMjestaSva}`),
   getNadredjeniZastupnik: (opbrNadredjenogZastupnika) => {
      const url = `${api.nadredjeniZastupnik}`;
      const payload = {
         opbrNadredjeniZastupnik: Number(opbrNadredjenogZastupnika)
      };

      return http.postData(url, payload);
   }
};

/*
   [id]:vrijednost koja se traži
   [searchProp]:naziv svojstva po kojem se traži
   [data]: kolekcija u kojoj se traži
   [props]: polje[] naziva svojstava čije vrijednosti će se konkatenirati na nađenom objektu

   return: konkatenirane vrijednosti
*/
export const getItemPropsToString = (id, searchProp, data, props) => {
   const item = data.find((d) => d[searchProp] === id);
   if (!item) return '';

   let returnStr = '';
   props.forEach((prop, index, props) => {
      const lastProp = props.length - 1 === index;
      returnStr += lastProp ? item[prop] : `${item[prop]} `;
   });

   return returnStr;
};

export const getSumiranePodatkePoZastupniku = (data) => {
   let uniqueIdValues = [...new Set(data.map((x) => x.operativniBroj))];

   const uniques = uniqueIdValues.map((id, index) => {
      const zastupniciById = data.filter((item) => item.operativniBroj === id);

      let dobitnihListica = 0,
         isplacenoListica = 0,
         iznosDobitka = 0,
         iznosIsplate = 0,
         iznosJackpota = 0,
         iznosPoreza = 0,
         iznosUplate = 0,
         storniranoListica = 0,
         uplacenoListica = 0,
         iznosUplateDolar = 0,
         iznosUplateEuro = 0,
         iznosUplateKuna = 0;

      zastupniciById.forEach((zastupnik) => {
         dobitnihListica += zastupnik.dobitnihListica;
         isplacenoListica += zastupnik.isplacenoListica;
         iznosDobitka += zastupnik.iznosDobitka;
         iznosIsplate += zastupnik.iznosIsplate;
         iznosJackpota += zastupnik.iznosJackpota;
         iznosPoreza += zastupnik.iznosPoreza;
         iznosUplate += zastupnik.iznosUplate;
         storniranoListica += zastupnik.storniranoListica;
         uplacenoListica += zastupnik.uplacenoListica;
         iznosUplateDolar += zastupnik.iznosUplateDolar;
         iznosUplateEuro += zastupnik.iznosUplateEuro;
         iznosUplateKuna += zastupnik.iznosUplateKuna;
      });

      const zastupnik = data.find((item) => item.operativniBroj === id);

      return {
         dobitnihListica,
         isplacenoListica,
         iznosDobitka,
         iznosIsplate,
         iznosJackpota,
         iznosPoreza,
         iznosUplate,
         storniranoListica,
         uplacenoListica,
         iznosUplateDolar,
         iznosUplateEuro,
         iznosUplateKuna,
         operativniBroj: id,
         imePrezime: zastupnik.imePrezime,
         nadredjeni: zastupnik.nadredjeni,
         nadnadredjeni: zastupnik.nadnadredjeni
      };
   });

   return uniques.sort((a, b) => b.iznosUplate - a.iznosUplate);
};

/*
// https://stackoverflow.com/questions/38460949/what-is-the-best-way-to-access-redux-store-outside-a-react-component
Iz staticnih kolekcija vezanih za zastupnika
Fn odlazi na store i iz zadane kolekcije [property] za zadanu vrijednost vraća tekstualnu vrijednost
*/
export const getTextFromValue_Zastupnici = (value, property) => {
   const state = window.store.getState();
   const arr = state.zastupnici.statickiPodaci[property];

   // eslint-disable-next-line
   const found = arr.find((a) => a.value == value);

   if (found) {
      let translationKey = 'common.ddl';
      return intl.getTranslate(`${translationKey}.${found.text.toLowerCase()}`);
   } else return '-';
};

export const getConvertedZastupnikData = (zastupnik) => {
   zastupnik.datumRodjenja = zastupnik.datumRodjenja
      ? formatDateToInt(zastupnik.datumRodjenja)
      : 0;
   zastupnik.operativniBrojNadredjenogZastupnika = Number(
      zastupnik.operativniBrojNadredjenogZastupnika
   );
   zastupnik.brojTerminalaZaduzen = Number(zastupnik.brojTerminalaZaduzen);
   zastupnik.telefon = zastupnik.telefon.toString();
   zastupnik.password = zastupnik.password.toString();
   zastupnik.postotakProvizije = Number(zastupnik.postotakProvizije);
   zastupnik.depozit = Number(zastupnik.depozit);
   return zastupnik;
};

export const getNadredjeniZastupnikTooltipText = (
   ime,
   prezime,
   tipNadredjenogZastupnika
) => {
   const tbl = (
      <table className='tbl-nadredjeni-zastupnik-tooltip'>
         <tbody>
            <tr>
               <td>Ime</td>
               <td>{ime === '' ? '-' : ime}</td>
            </tr>
            <tr>
               <td>Prezime</td>
               <td>{prezime === '' ? '-' : prezime}</td>
            </tr>
            <tr>
               <td>Tip</td>
               <td>{tipNadredjenogZastupnika}</td>
            </tr>
         </tbody>
      </table>
   );
   return tbl;
};

export const getProdajnoMjestoTooltipText = (adresa, grad, opis) => {
   const tbl = (
      <table className='tbl-nadredjeni-zastupnik-tooltip'>
         <tbody>
            <tr>
               <td>Adresa</td>
               <td>{adresa === '' ? '-' : adresa}</td>
            </tr>
            <tr>
               <td>Grad</td>
               <td>{grad === '' ? '-' : grad}</td>
            </tr>
            <tr>
               <td>Opis</td>
               <td>{opis}</td>
            </tr>
         </tbody>
      </table>
   );
   return tbl;
};
