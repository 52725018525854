import React, { Component } from 'react';

/*
    atributi:
        groupName,
        options

    options: 
        [{
            label: 'xxx',
            value: 'y',
            defaultChecked: true - opcionalno
        }]
*/

class RadioCheckboxButtons extends Component {
   state = {};
   render() {
      const { options, groupName, type, onChange } = this.props;
      const style = {
         marginBottom: '.1rem',
         fontWeight: 700
      };

      return (
         <div className='radio-buttons'>
            {options.map((option, index) => (
               <div
                  key={`${groupName}_${index}`}
                  className='form-check form-check-inline'
               >
                  <input
                     className='form-check-input'
                     type={type}
                     name={groupName}
                     id={option.label}
                     value={option.value}
                     defaultChecked={option.defaultChecked}
                     onChange={onChange}
                  />
                  <label
                     className='form-check-label'
                     htmlFor={option.label}
                     style={style}
                  >
                     {option.label}
                  </label>
               </div>
            ))}
         </div>
      );
   }
}

export default RadioCheckboxButtons;
