import React from 'react';
import { motion } from 'framer-motion';
import intl from '../../services/intl-service';
import ReactDOM from 'react-dom';

const CustomAlertNoForm = (props) => {
   const {
      onOk,
      onCancel,
      title = intl.getTranslate('common.confirmTitle'),
      btnOk = intl.getTranslate('common.update'),
      btnCancel = intl.getTranslate('common.cancel'),
      btnOkDisabled,
      alertMode = false,
      style,
      btnOkStyle,
      btnCancelStyle,
      showCondition = true
   } = props;

   if (!showCondition) return null;

   return (
      <>
         {ReactDOM.createPortal(
            <motion.div
               className='custom-alert'
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 0.25 }}
               exit={{ opacity: 0 }}
            >
               <motion.div
                  className='custom-alert-popup'
                  style={style ? style : {}}
                  initial={{ opacity: 1, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  transition={{
                     duration: 2,
                     delay: 0,
                     type: 'spring',
                     stiffness: 200
                  }}
               >
                  <div className='custom-alert-title'>{title}</div>
                  <div className='custom-alert-content'>{props.children}</div>
                  <hr />
                  <div className='custom-alert-footer'>
                     <motion.button
                        initial={{ opacity: btnOkDisabled ? 0.5 : 1 }}
                        animate={{ opacity: btnOkDisabled ? 0.5 : 1 }}
                        style={btnOkStyle ? btnOkStyle : {}}
                        className='button save'
                        disabled={btnOkDisabled}
                        autoFocus
                        type='button'
                        onClick={() => onOk()}
                     >
                        {btnOk}
                     </motion.button>
                     {!alertMode && (
                        <button
                           className='button cancel'
                           onClick={onCancel}
                           style={btnCancelStyle ? btnCancelStyle : {}}
                           type='button'
                        >
                           {btnCancel}
                        </button>
                     )}
                  </div>
               </motion.div>
            </motion.div>,
            document.querySelector('#confirm')
         )}
      </>
   );
};

export default CustomAlertNoForm;
