import React from 'react';
import { NavLink } from 'react-router-dom';
import AnimatedPageLink from './animated-page-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import translate from './../../i18n/translate';
import { getUsedTheme } from './../../services/app-service';

const PageLinks = ({ linksCollection }) => {
   let linkCSSClass = getUsedTheme() === 'dark' ? `theme-dark` : `theme-light`;
   const cssClass = `page-link ${linkCSSClass}`;

   return (
      <div className='page-links-container'>
         {linksCollection.map((item, index) => (
            <NavLink to={item.path} key={index}>
               <AnimatedPageLink index={index} className={cssClass}>
                  <div className='page-link-header'>
                     <div className='icon'>
                        <FontAwesomeIcon icon={item.links[0].icon} />
                     </div>
                  </div>
                  <div className='page-link-footer'>
                     {translate(item.links[0].label)}
                  </div>
               </AnimatedPageLink>
            </NavLink>
         ))}
      </div>
   );
};

export default PageLinks;
