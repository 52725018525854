import React from 'react';
// eslint-disable-next-line
import AppSettingsLanguage from './common/app-settings-language';
import AppSettingsTheme from './common/app-settings-theme';
import { motion } from 'framer-motion';
import { enableMultilanguage, getUsedTheme } from './../services/app-service';

const Setup = (props) => {
   const {
      setTheme,
      theme,
      setSettingsVisible,
      setLanguage,
      language,
      visible
   } = props;

   const onThemeChange = ({ target }) => {
      const selectedTheme = target.value;
      setTheme(selectedTheme);
      localStorage.setItem('theme', selectedTheme);
      setSettingsVisible(false);
   };

   // setLanguage, language se proslijeđuju kroz props
   const onLanguageChange = ({ target }) => {
      const selectedLanguage = target.value;
      setLanguage(selectedLanguage);
      localStorage.setItem('language', selectedLanguage);
      window.location.reload();
   };

   let currentTheme = getUsedTheme() === 'dark' ? `theme-dark` : `theme-light`;
   const cssClass = `setup-container ${currentTheme}`;

   return (
      <motion.div
         className={cssClass}
         initial={{ height: 0, opacity: 0 }}
         animate={{
            height: visible ? '4rem' : 0,
            opacity: visible ? 1 : 0,
            transition: { duration: 0.2 }
         }}
      >
         <AppSettingsTheme currentTheme={theme} onChange={onThemeChange} />

         {enableMultilanguage && (
            <AppSettingsLanguage
               currentLanguage={language}
               onChange={onLanguageChange}
            />
         )}
      </motion.div>
   );
};

export default Setup;
