import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Datepicker from '../../common/ant-datepicker';
import intl from '../../../services/intl-service';
import { formatDateToInt } from '../../../services/form-components-service';
import { pregledKolaPoDanuService as service } from '../../../services/SierraLeone/ke10-pregled-kola-po-danu-service';
import { DataLoader } from '../../../services/data-loader-service';
import DataTable from '../../common/table/datatable';
import { todayInt } from './../../../services/form-components-service';
import NoDataMessage from './../../common/no-data-message';

const danas = moment(new Date());

const PregledKolaPoDanu = (props) => {
   const [odDatuma, setOdDatuma] = useState(todayInt());
   const [data, setData] = useState([]);

   const columns = [
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('pregledKolaPoDanu.dataTable.kolo'),
         path: 'kolo',
         width: '6rem'
      },
      {
         center: true,
         label: intl.getTranslate(
            'pregledKolaPoDanu.dataTable.datumPocetkaKola'
         ),
         path: 'pocetak',
         width: '14rem'
      },
      {
         center: true,
         label: intl.getTranslate(
            'pregledKolaPoDanu.dataTable.vrijemePocetkaKola'
         ),
         path: 'izvlacenje',
         width: '14rem'
      },
      {
         label: '',
         path: '-'
      }
   ];

   useEffect(() => {
      DataLoader(service.get, [odDatuma, odDatuma], onDataLoad);
   }, [odDatuma]);

   const onDataLoad = ({ data }) => {
      const formatedData = data.map((d) => ({
         ...d,
         pocetak: `${d.datumPocetkaKola} - ${d.vrijemePocetkaKola}`,
         izvlacenje: `${d.datumPocetkaIzvlacenja} - ${d.vrijemePocetkaIzvlacenja}`
      }));
      setData(formatedData);
   };

   const handleDatumOdChange = (e) => {
      if (!e) return;
      setOdDatuma(formatDateToInt(e.format('DD.MM.YYYY')));
   };

   return (
      <>
         <div className='row form mb-5'>
            <div className='col-3'>
               <Datepicker
                  label={intl.getTranslate('pregledKolaPoDanu.datum')}
                  onChange={handleDatumOdChange}
                  defaultDate={danas}
               />
            </div>
         </div>

         <NoDataMessage
            messageKey='pregledKolaPoDanu.noDataMessage'
            data={data}
         />

         {data.length > 0 && (
            <DataTable
               columns={columns}
               data={data}
               pageSize={13}
               hideSearch={false}
               searchBackground={false}
               searchLabel={intl.getTranslate('pregledKolaPoDanu.pretraga')}
               searchLabelPlaceholder={intl.getTranslate(
                  'pregledKolaPoDanu.pretragaPlaceholder'
               )}
               searchColumns={['kolo']}
               style={{ minHeight: '10rem' }}
            />
         )}
      </>
   );
};

export default PregledKolaPoDanu;
