import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import DDL from '../../common/ant-ddl-translate';

class Print extends Component {
   constructor(props) {
      super(props);
      this.state = {
         pageOrientation: 1
      };
   }

   handleDDLSelected = (e) => {
      const { value } = e;
      const pageOrientation = value;
      this.setState({ pageOrientation });
   };

   render() {
      const { header, body, footer, children, onClose } = this.props;
      const { pageOrientation } = this.state;
      const options = [
         { text: 'Portrait', value: 0 },
         { text: 'Landscape', value: 1 }
      ];
      return (
         <>
            {pageOrientation === 1 && (
               <style type='text/css' media='print'>
                  {' @page { size: landscape; } '}
               </style>
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
               <DDL
                  options={options}
                  translationKey='common'
                  translationSubKey='printOrientation'
                  onChange={this.handleDDLSelected}
                  selectedOption={pageOrientation}
               />
               <ReactToPrint
                  documentTitle='Nirvana report'
                  onAfterPrint={() => {
                     if (onClose) onClose();
                  }}
                  trigger={() => (
                     <button type='button' className='button btn-print-pdf'>
                        <FontAwesomeIcon
                           icon={faFilePdf}
                           className='mr-3'
                           style={{ fontSize: '2rem' }}
                        />
                        <span style={{ marginTop: '.25rem' }}>Print</span>
                     </button>
                  )}
                  content={() => this.ref}
               />
               <div style={{ display: 'none' }}>
                  <div ref={(el) => (this.ref = el)} className='print'>
                     {header && <div className='print-header'>{header}</div>}
                     {body || <div className='print-body'>{children}</div>}
                     {footer && <div className='print-footer'>{footer}</div>}
                  </div>
               </div>
            </div>
         </>
      );
   }
}
export default Print;
