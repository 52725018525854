import React, { useState } from 'react';
import TableHeader from './table-header';
import TableBody from './table-body';
import Pagination from '../pagination';
import { paginate } from '../../../services/paginate';
import intl from '../../../services/intl-service';
import SearchTerm from './search';
import _ from 'lodash';
import ButtonPrint from '../../common/btn-print';
import { AnimatePresence } from 'framer-motion';
import CustomAlert from './../custom-alert';
import Print from './../print/print';
import FramerConditionalOpacityContainer from './../framer-conditional-opacity-container';
import NoDataMessage from './../no-data-message';
import TableFooter from './table-footer';

/*
   printEnable={true}
   printColumns={columns.slice(0, -1)} *navodim samo ako se razlikuje od inicijalne columns kolekcije
   printHeader='Pregled zastupnika'

   Ako za neku kolumnu treba ispis sume vrijednosti onda navodim svojstvo:
   sumColumns=['column.path','column.path',...]
*/

const DataTable = ({
   columns,
   data,
   style,
   readonly,
   hideSearch = false,
   hideNoDataMessage = false,
   markSearchTerm = true,
   searchColumns = [],
   searchLabel = intl.getTranslate('common.pretraga'),
   searchLabelPlaceholder = '',
   searchBackground = true,
   searchDebounceDelay = 50,
   markRowOnClick = false,
   markedRowStyle = {},
   onRowClick,
   pageSize = 19,
   btnAdd,
   printEnable = false,
   printHeader = '',
   printColumns = null,
   sumColumns = [],
   additionalFilter
}) => {
   const [currentPage, setCurrentPage] = useState(1);
   const [sortColumn, setSortColumn] = useState({ path: '', order: 'asc' });
   const [searchTerm, setSearchTerm] = useState('');
   const [showPrint, setShowPrint] = useState(false);

   const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
   };

   const raiseSort = (path) => {
      const sc = { ...sortColumn };
      if (sc.path === path) sc.order = sc.order === 'asc' ? 'desc' : 'asc';
      else {
         sc.path = path;
         sc.order = 'asc';
      }

      setSortColumn(sc);
   };
   /*
      Filtriranje podataka na osnovu znakova u polju pretraga
      Napravim novi property sa svim podacima iz searchColumns te pretražujem po njemu
   */
   data = data.map((d) => {
      const _obj = {
         ...d,
         searchData: ''
      };
      if (!searchColumns) return _obj;
      if (searchColumns.length === 0) return _obj;

      let searchData = '';
      searchColumns.forEach((column) => {
         searchData += `${d[column]} `;
      });

      return {
         ...d,
         searchData
      };
   });
   const filtriraniPodaci = data.filter((c) => {
      return c.searchData
         .toLowerCase()
         .includes(searchTerm.toLocaleLowerCase());
   });

   const filteredData = searchTerm.length > 0 ? filtriraniPodaci : data;

   const sortedData = _.orderBy(
      filteredData,
      [sortColumn.path],
      [sortColumn.order]
   );

   const paginatedData = paginate(sortedData, currentPage, pageSize);
   const noSearchData = paginatedData.length === 0 && searchTerm.length > 0;

   const itemsCount =
      searchTerm.length !== 0 ? filteredData.length : data.length;

   const handleOnPrintClick = () => {
      setShowPrint(true);
   };

   const closePrintDialog = () => {
      setShowPrint(false);
   };

   return (
      <>
         <AnimatePresence>
            {showPrint && (
               <CustomAlert
                  title={intl.getTranslate('common.print')}
                  printMode={true}
                  onCancel={() => {
                     setShowPrint(false);
                  }}
                  style={{
                     width: '50rem',
                     height: '22rem',
                     overflow: 'hidden'
                  }}
               >
                  <Print onClose={closePrintDialog} header={printHeader}>
                     <table className='table table-striped table-sm'>
                        <TableHeader
                           columns={printColumns ?? columns}
                           onSort={raiseSort}
                           sortColumn={sortColumn}
                        />
                        <TableBody
                           data={sortedData}
                           columns={printColumns ?? columns}
                           readonly={readonly}
                           onRowClick={onRowClick}
                           markedRowStyle={markedRowStyle}
                           printingData={true}
                        />
                        {sumColumns.length > 0 && (
                           <TableFooter
                              data={paginatedData}
                              allData={data}
                              columns={columns}
                              sumColumns={sumColumns}
                           />
                        )}
                     </table>
                  </Print>
               </CustomAlert>
            )}
         </AnimatePresence>

         <div className='table-overview-container'>
            {!hideSearch && (
               <FramerConditionalOpacityContainer condition={data.length > 0}>
                  <div className={searchBackground ? 'form mb-5' : ''}>
                     <div className='row'>
                        <div className='col-3'>
                           <SearchTerm
                              searchTerm={searchTerm}
                              setSearchTerm={setSearchTerm}
                              data={data}
                              filteredData={filteredData}
                              setCurrentPage={setCurrentPage}
                              noSearchDataCondition={noSearchData}
                              label={searchLabel}
                              placeholder={searchLabelPlaceholder}
                              debounceDelay={searchDebounceDelay}
                           />
                           {additionalFilter}
                        </div>
                        <div className='col' style={{ textAlign: 'right' }}>
                           {btnAdd}
                           {printEnable && (
                              <ButtonPrint onClick={handleOnPrintClick} />
                           )}
                        </div>
                     </div>
                  </div>
               </FramerConditionalOpacityContainer>
            )}

            {!hideNoDataMessage && (
               <NoDataMessage messageKey='common.noData' data={data} />
            )}

            <FramerConditionalOpacityContainer
               condition={!noSearchData && data.length > 0}
            >
               <>
                  <table className='table table-striped table-sm'>
                     <TableHeader
                        columns={columns}
                        onSort={raiseSort}
                        sortColumn={sortColumn}
                     />
                     <TableBody
                        data={paginatedData}
                        columns={columns}
                        searchTerm={searchTerm}
                        markSearchTerm={markSearchTerm}
                        searchColumns={searchColumns}
                        readonly={readonly}
                        onRowClick={onRowClick}
                        markRowOnClick={markRowOnClick}
                        markedRowStyle={markedRowStyle}
                     />
                     {sumColumns.length > 0 && (
                        <TableFooter
                           data={paginatedData}
                           allData={data}
                           columns={columns}
                           sumColumns={sumColumns}
                           hidePaginateSum={
                              filteredData.length <= pageSize &&
                              searchTerm.length === 0
                           }
                        />
                     )}
                  </table>
                  <Pagination
                     onPageChange={handlePageChange}
                     itemsCount={itemsCount}
                     pageSize={pageSize}
                     currentPage={currentPage}
                  />
               </>
            </FramerConditionalOpacityContainer>
         </div>
      </>
   );
};

export default DataTable;
