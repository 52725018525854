import React from 'react';
import BackgroundLine from './background-line';
import intl from '../../services/intl-service';

const ErrorMessage = ({ componentStack }) => {
   return (
      <div className='error-message'>
         <h1>{intl.getTranslate('common.error')}</h1>
         {componentStack.map((cs, i) => (
            <div
               key={`stack${i}`}
               style={{ backgroundColor: i % 2 === 0 ? '#efefef' : 'white' }}
            >
               {cs.trim()}
            </div>
         ))}
         <button
            className='mt-5'
            onClick={() => {
               window.location.href = '/';
            }}
         >
            {intl.getTranslate('common.returToHomepage')}
         </button>
         <BackgroundLine />{' '}
      </div>
   );
};

export default ErrorMessage;
//  https://dev.to/projectescape/programmatic-navigation-in-react-3p1l
