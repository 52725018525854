import React from 'react';
import intl from '../../services/intl-service';

const CancelButton = ({ onClick }) => {
   return (
      <button type='button' className='button cancel' onClick={onClick}>
         {`${intl.getTranslate('common.cancel')}`}
      </button>
   );
};

export default CancelButton;
