import React from 'react';
import AvatarEditor from './avatar-editor';

const AvatarEditorPopup = (props) => {
   return (
      <div className='avatar-editor'>
         <div
            className='avatar-editor-backdrop'
            onClick={props.onBackDropClick}
         ></div>
         <div
            className='avatar-editor-container'
            onClick={(e) => {
               //eventhandler da se spriječi probijanje do backdropa
            }}
         >
            <h2>Avatar editor</h2>
            <AvatarEditor
               onAvatarSet={props.onAvatarSet}
               onAvatarCancel={props.onAvatarCancel}
            />
            <button
               type='button'
               className='btn btn-secondary'
               onClick={props.onBackDropClick}
            >
               OK
            </button>
         </div>
      </div>
   );
};

export default AvatarEditorPopup;
