import React from 'react';
import PageContainer from './../components/common/animated-page-container';

const LoginPage = (props) => {
   return (
      <PageContainer className='updating'>
         <h1>LoginPage</h1>
      </PageContainer>
   );
};

export default LoginPage;
