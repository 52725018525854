import React from 'react';
import intl from '../../services/intl-service';
import FramerConditionalCollapsableContainer from './framer-conditional-collapsable-container';

const NoDataMessage = ({ messageKey, data }) => {
   return (
      <FramerConditionalCollapsableContainer
         condition={data.length === 0}
         height='2.5rem'
      >
         <span style={{ fontSize: '1.4rem' }}>
            {intl.getTranslate(messageKey)}
         </span>
      </FramerConditionalCollapsableContainer>
   );
};

export default NoDataMessage;
