import { toast } from 'react-toastify';
import { toastLoading, toastError } from './toast-service';

export const DataLoader = async (
   loadingFn,
   loadingParams,
   onLoad,
   onError = null
) => {
   let toastId;
   const interval = setTimeout(() => {
      toastId = toastLoading();
   }, 100);

   try {
      const { data: response } = loadingParams
         ? await loadingFn(...loadingParams)
         : await loadingFn();
      onLoad(response);

      const { isSuccess, msg } = response;
      if (isSuccess === 1) toast.dismiss(toastId);
      else toastError(`isSuccess !== 1 - ${msg}`);
   } catch (ex) {
      if (onError) onError(ex);
      else toastError();
   } finally {
      clearInterval(interval);
   }
};
