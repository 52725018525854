import React from 'react';
import Form from '../../common/form';
import intl from '../../../services/intl-service';
import { azuriranjeNadredjenogSchema } from './azuriranje-nadredjenog-validacije';
import CustomAlertNoForm from '../../common/custom-alert-no-form';
import InfoVrijemeUpisa from '../../common/info-vrijeme-upisa';
import { toastSuccessSave } from '../../../services/toast-service';
import { toastError } from '../../../services/toast-service';
import FramerConditionalCollapsableContainer from '../../common/framer-conditional-collapsable-container';
import { a03nadredjeniService as service } from '../../../services/SierraLeone/A03NADREDJENI-service';
import { connect } from 'react-redux';
import FramerConditionalOpacityContainer from '../../common/framer-conditional-opacity-container';
import Datepicker from '../../common/ant-datepicker';
import {
   formatDateToInt,
   todayInt
} from '../../../services/form-components-service';
import DDL from '../../common/ant-ddl-translate';
import { ucitajSveZastupnike } from '../../../store/zastupnici';
import NadredjeniInfoTable from './azuriranje-nadredjenog-info-table';
import PregledAzuriranjaNadredjenog from './pregled-azuriranja-nadredjenog';
import { getUserId } from './../../../services/auth-service';

class AzuriranjeNadredjenog extends Form {
   state = {
      validationData: {
         operativniBroj: '',
         operativniBrojNadredjenog: ''
      },
      detaljiZastupnika: {},
      pregledZaDatum: todayInt(),
      dataReloaded: false,
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      showConfirm: false,
      showDetails: false
   };

   schema = azuriranjeNadredjenogSchema;

   doSubmit = async () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const postData = { ...this.state.validationData };

      const { data: response } = await service.addOrUpdate(postData);
      const { isSuccess, msg } = response;

      if (isSuccess) {
         toastSuccessSave();
         this.resetForm();
      } else toastError(msg);

      this.setState({ showConfirm: false });
   };

   resetForm = () => {
      const validationData = { ...this.state.validationData };
      validationData.operativniBroj = '';
      validationData.operativniBrojNadredjenog = '';
      this.setState({ validationData });
   };

   loadData = async () => {
      const { operativniBroj } = this.state.validationData;
      const { data: response } = await service.get(operativniBroj);

      if (!response.data) {
         toastError(response.msg);
         this.setState({ showDetails: false });
         return;
      }

      this.setState({ showDetails: true });

      const detaljiZastupnika = { ...response.data };
      const validationData = { ...this.state.validationData };
      validationData.operativniBrojNadredjenog =
         detaljiZastupnika.operativniBrojNadredjenog;

      this.setState({ validationData, detaljiZastupnika });
   };

   handleDateChanged = (e) => {
      if (!e) return;

      const pregledZaDatum = formatDateToInt(e.format('DD.MM.YYYY'));
      this.setState({ pregledZaDatum });
   };

   handleDDLcahnge = (e, id) => {
      const validationData = { ...this.state.validationData };

      switch (id) {
         case 'zastupnik':
            validationData.operativniBroj = e.value;
            this.setState({ validationData }, () => {
               this.loadData();
            });
            break;
         case 'nadredjeniZastupnik':
            validationData.operativniBrojNadredjenog = e.value;
            this.setState({ validationData });
            break;
         default:
            break;
      }
   };

   getDDLconvertedZastupnici = (e) => {
      const { sviZastupnici } = this.props;
      return sviZastupnici.map((z) => ({
         id: z.operativniBrojZastupnika,
         punoIme: `${z.ime} ${z.prezime} [${z.operativniBrojZastupnika}]`
      }));
   };

   getDDLconvertedNadredjeniZastupnici = (e) => {
      const { sviZastupnici } = this.props;
      const { detaljiZastupnika } = this.state;
      const { tip } = detaljiZastupnika;

      return sviZastupnici
         .map((z) => ({
            id: z.operativniBrojZastupnika,
            punoIme: `${z.ime} ${z.prezime} [${z.operativniBrojZastupnika}]`,
            tip: z.tipZastupnika
         }))
         .filter((z) => z.tip === tip + 1);
   };

   componentDidMount() {
      const { sviZastupnici, ucitajSveZastupnike } = this.props;
      if (sviZastupnici.length === 0) {
         ucitajSveZastupnike();
      }
   }

   render() {
      const {
         validationData,
         datumUpisa,
         vrijemeUpisa,
         showConfirm,
         detaljiZastupnika,
         showDetails,
         pregledZaDatum
      } = this.state;

      //ako se desi refresh stranice...
      // const { sviZastupnici } = this.props;
      // if (sviZastupnici.length === 0 && !dataReloaded) {
      //    this.props.ucitajSveZastupnike();
      //    this.setState({ dataReloaded: true });
      // }
      const { operativniBrojNadredjenog, operativniBroj } = validationData;
      const noData = this.getDDLconvertedZastupnici().length === 0;

      return (
         <>
            <CustomAlertNoForm
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               btnCancel={intl.getTranslate('common.cancel')}
               onOk={this.doSubmitOnConfirm}
            >
               <div>
                  {intl.getTranslate('azuriranjeNadredjenog.confirmMessage')}
               </div>
            </CustomAlertNoForm>

            <form onSubmit={this.handleFormSubmit} className='form' noValidate>
               <div className='row'>
                  {/* LIJEVI STUPAC */}
                  <div className='col border-right'>
                     <div className='row'>
                        <div className='col'>
                           {noData && (
                              <span>
                                 {intl.getTranslate(
                                    'azuriranjeNadredjenog.noData'
                                 )}
                              </span>
                           )}
                           {!noData && (
                              <DDL
                                 options={this.getDDLconvertedZastupnici()}
                                 translationKey='azuriranjeNadredjenog'
                                 translationSubKey='zastupnik'
                                 onChange={this.handleDDLcahnge}
                                 selectedOption={operativniBroj}
                                 text='punoIme'
                                 value='id'
                              />
                           )}
                        </div>
                        <div className='col'>
                           <FramerConditionalOpacityContainer
                              condition={showDetails}
                              delay={0.2}
                           >
                              <DDL
                                 options={this.getDDLconvertedNadredjeniZastupnici()}
                                 translationKey='azuriranjeNadredjenog'
                                 translationSubKey='nadredjeniZastupnik'
                                 onChange={this.handleDDLcahnge}
                                 selectedOption={operativniBrojNadredjenog}
                                 showLoadingIcon={false}
                                 text='punoIme'
                                 value='id'
                              />
                           </FramerConditionalOpacityContainer>
                        </div>
                     </div>
                     <FramerConditionalCollapsableContainer
                        condition={showDetails}
                        delay={0.2}
                        height='25rem'
                     >
                        <div className='row'>
                           <div className='col'>
                              <NadredjeniInfoTable data={detaljiZastupnika} />
                           </div>
                        </div>
                        <hr className='mt-4' />
                        <InfoVrijemeUpisa
                           datumUpisa={datumUpisa}
                           vrijemeUpisa={vrijemeUpisa}
                        />
                        {this.renderSubmitButton(
                           `${intl.getTranslate('common.update')}`
                        )}
                     </FramerConditionalCollapsableContainer>
                  </div>
                  {/* DESNI STUPAC */}
                  <div className='col'>
                     {!noData && (
                        <>
                           <Datepicker
                              label={intl.getTranslate(
                                 'azuriranjeNadredjenog.pregledZaDatum'
                              )}
                              onChange={this.handleDateChanged}
                              placeholder={intl.getTranslate(
                                 'azuriranjeNadredjenog.pregledPlaceholder'
                              )}
                           />
                           <PregledAzuriranjaNadredjenog
                              date={pregledZaDatum}
                           />
                        </>
                     )}
                  </div>
               </div>
            </form>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   statickiPodaci: state.zastupnici.statickiPodaci,
   sviZastupnici: state.zastupnici.sviZastupnici
});

const mapDispatchToProps = (dispatch) => ({
   ucitajSveZastupnike: () => dispatch(ucitajSveZastupnike())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AzuriranjeNadredjenog);
