import React from 'react';
import intl from '../../../services/intl-service';

const OpenstreetInfopanel = ({ data }) => {
   const { grad, adresa, lat, lng } = data;
   return (
      <div className='openstreet-koordinate'>
         <div>
            {intl.getTranslate('prodajnaMjesta.openstreetInfoCity')}:
            <span>{`${grad ? grad : '-'}`}</span>
         </div>{' '}
         <div>
            {intl.getTranslate('prodajnaMjesta.openstreetInfoAddress')}:
            <span>{`${adresa ? adresa : '-'}`}</span>
         </div>{' '}
         <div>
            LAT:
            <span>{`${lng}`}</span>
         </div>{' '}
         <div>
            LNG:
            <span>{`${lat}`}</span>
         </div>
      </div>
   );
};

export default OpenstreetInfopanel;
