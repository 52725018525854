import React from 'react';
import Form from '../common/form';
import { injectIntl } from 'react-intl';
import { azuriranjeOpcineSchema } from './opcine-validacije';
import intl from '../../services/intl-service';
import { opcinaService } from '../../services/m-o-r-service';
import { toastError, toastInfo } from '../../services/toast-service';
import CustomAlertNoForm from '../common/custom-alert-no-form';
import InfoVrijemeUpisa from '../common/info-vrijeme-upisa';
import FramerConditionalCollapsableContainer from '../common/framer-conditional-collapsable-container';
import { DataLoader } from '../../services/data-loader-service';
import { withRouter } from 'react-router';
import { toastSuccessSave } from './../../services/toast-service';
import { getToasterIntervals } from '../../services/app-service';
import { paths } from '../../routes/routes';

//Debounce: https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js

class DodajOpcinu extends Form {
   state = {
      validationData: {
         sifraOpcine: '',
         nazivOpcine: ''
      },
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      showConfirm: false
   };

   schema = azuriranjeOpcineSchema;

   doSubmit = async () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const { successAutoClose, redirectAutoClose, redirectDelay } =
         getToasterIntervals();

      const { sifraOpcine, nazivOpcine } = this.state.validationData;
      const azuriraniPodaci = { sifraOpcine, nazivOpcine, operaterUpisa: 14 };
      const { data: response } = await opcinaService.addOrUpdate(
         azuriraniPodaci
      );

      if (response.isSuccess === 1) {
         toastSuccessSave(
            intl.getTranslate('common.podaciUspjesnoSpremljeni'),
            {
               autoClose: successAutoClose,
               onClose: () => this.props.history.push(paths.overview_County)
            }
         );
         toastInfo(intl.getTranslate('opcina.preusmjeravanje'), {
            autoClose: redirectAutoClose,
            delay: redirectDelay
         });
         this.resetForm('sifraOpcine');
      } else toastError();

      this.setState({ showConfirm: false });
   };

   onDataLoad = (response) => {
      const validationData = { ...this.state.validationData };
      const { nazivOpcine, datumUpisa, vrijemeUpisa } = response;
      validationData.nazivOpcine = nazivOpcine;

      this.setState({ validationData, datumUpisa, vrijemeUpisa });
   };

   ucitajPodatkeOpcine = (sifraOpcine) => {
      DataLoader(opcinaService.get, [sifraOpcine], this.onDataLoad);
   };

   handleSifraOpcineChange = async ({ target: input }) => {
      if (isNaN(input.value)) return;

      const sifraOpcine = Number(input.value);
      if (sifraOpcine >= 1 && sifraOpcine <= 9500)
         this.ucitajPodatkeOpcine(sifraOpcine);
   };

   render() {
      const { validationData, showConfirm, datumUpisa, vrijemeUpisa } =
         this.state;
      const { sifraOpcine, nazivOpcine } = validationData;

      const odabranaJeOpcina = sifraOpcine >= 1 && sifraOpcine <= 9500;

      return (
         <>
            <CustomAlertNoForm
               title={intl.getTranslate('opcina.confirmDodajTitle')}
               btnOk={intl.getTranslate('common.save')}
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               onOk={() => this.doSubmitOnConfirm()}
            >
               <div>
                  {intl.getTranslate('opcina.confirmDodajMessage')}{' '}
                  <span className='confirm-highlight'>{nazivOpcine}</span>?
               </div>
            </CustomAlertNoForm>
            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-opcinu'>
                  <div className='row'>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'sifraOpcine',
                           'opcina',
                           this.handleSifraOpcineChange,
                           true
                        )}
                     </div>
                  </div>
                  <FramerConditionalCollapsableContainer
                     condition={odabranaJeOpcina}
                     height='18rem'
                  >
                     <div className='row'>
                        <div className='col-4'>
                           {this.renderTranslateInput('nazivOpcine', 'opcina')}
                        </div>
                     </div>

                     <hr />

                     <InfoVrijemeUpisa
                        datumUpisa={datumUpisa}
                        vrijemeUpisa={vrijemeUpisa}
                     />

                     {this.renderSubmitButton(
                        `${intl.getTranslate('common.save')}`
                     )}
                  </FramerConditionalCollapsableContainer>
               </div>
            </form>
         </>
      );
   }
}

export default withRouter(injectIntl(DodajOpcinu));
