import React, { useState } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import UserInfoPanel from './user-info-panel';
import { getCurrentUser } from '../../services/auth-service';
import { HiLogout } from 'react-icons/hi';

const UserAvatar = (props) => {
   const [showUserInfo, setShowUserInfo] = useState(false);

   if (!getCurrentUser()) return null;
   const { ime, prezime, operativniBroj } = getCurrentUser();

   const onAvatarClick = () => {
      setShowUserInfo(true);
   };
   const onMouseLeave = () => {
      setShowUserInfo(false);
   };

   return (
      <motion.div
         className='user-avatar'
         onMouseEnter={onAvatarClick}
         onMouseLeave={onMouseLeave}
      >
         {/* <Avatar
            style={{ boxShadow: '0 0 1rem #999', backgroundColor: '#901a1c' }}
            // src='https://joeschmoe.io/api/v1/male/joe'
            icon={<UserOutlined />}
         /> */}
         <span className='mr-2'>{`${ime} ${prezime}`}</span>
         <HiLogout />
         <AnimatePresence>
            {showUserInfo && (
               <UserInfoPanel
                  operativniBroj={operativniBroj}
                  setShowUserInfo={setShowUserInfo}
               />
            )}
         </AnimatePresence>
      </motion.div>
   );
};

export default UserAvatar;
