import React from 'react';
import Form from '../common/form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { azuriranjeNaseljenogMjestaSchema } from './naseljeno-mjesto-validacije';
import intl from '../../services/intl-service';
import { mjestoService } from '../../services/m-o-r-service';
import CustomAlertNoForm from '../common/custom-alert-no-form';
import { toastError, toastSuccess } from '../../services/toast-service';
import InfoVrijemeUpisa from './../common/info-vrijeme-upisa';
import { DataLoader } from './../../services/data-loader-service';
import CancelButton from '../common/cancel-button';

//Debounce: https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js

class UrediNaseljenoMjesto extends Form {
   state = {
      validationData: {
         sifraMjesta: '',
         nazivMjesta: ''
      },
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      showConfirm: false
   };

   schema = azuriranjeNaseljenogMjestaSchema;

   componentDidMount() {
      const { odabranoMjesto } = this.props;
      if (!odabranoMjesto) return;

      const validationData = { ...this.state.validationData };
      const { sifraMjesta, nazivMjesta } = odabranoMjesto;

      validationData.sifraMjesta = sifraMjesta;
      validationData.nazivMjesta = nazivMjesta;

      this.setState({ validationData });
   }

   doSubmit = () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const { sifraMjesta, nazivMjesta } = this.state.validationData;
      const azuriraniPodaci = { sifraMjesta, nazivMjesta, operaterUpisa: 14 };

      const { data: response } = await mjestoService.addOrUpdate(
         azuriraniPodaci
      );

      if (response.isSuccess === 1) {
         toastSuccess();
         this.props.onSubmit();
         this.setState({ showConfirm: false });
      } else toastError();
   };

   onDataLoad = (response) => {
      const { nazivMjesta, datumUpisa, vrijemeUpisa } = response;
      const validationData = { ...this.state.validationData };
      validationData.nazivMjesta = nazivMjesta;

      this.setState({ validationData, datumUpisa, vrijemeUpisa });
   };

   ucitajPodatkeMjesta = (sifraMjesta) => {
      DataLoader(mjestoService.get, [sifraMjesta], this.onDataLoad);
   };

   handleSifraMjestaChange = async ({ target: input }) => {
      if (isNaN(input.value)) return;

      const sifraMjesta = Number(input.value);
      if (sifraMjesta >= 10000 && sifraMjesta <= 99999)
         this.ucitajPodatkeMjesta(sifraMjesta);
   };

   render() {
      const { validationData, showConfirm, datumUpisa, vrijemeUpisa } =
         this.state;
      const { nazivMjesta } = validationData;

      return (
         <>
            <CustomAlertNoForm
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               onOk={() => this.doSubmitOnConfirm()}
            >
               <div>
                  {intl.getTranslate('naseljenaMjesta.confirmMessage')}{' '}
                  <span className='confirm-highlight'>{nazivMjesta}</span>?
               </div>
            </CustomAlertNoForm>
            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-naseljeno-mjesto'>
                  <div className='row'>
                     <div className='col-6'>
                        {this.renderTranslateInput(
                           'sifraMjesta',
                           'naseljenaMjesta',
                           this.handleSifraMjestaChange,
                           true,
                           null,
                           { disabled: 'disabled' }
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-6'>
                        {this.renderTranslateInput(
                           'nazivMjesta',
                           'naseljenaMjesta'
                        )}
                     </div>
                  </div>

                  <hr />

                  <InfoVrijemeUpisa
                     datumUpisa={datumUpisa}
                     vrijemeUpisa={vrijemeUpisa}
                  />

                  {this.renderSubmitButton(
                     `${intl.getTranslate('common.edit')}`
                  )}
                  <CancelButton onClick={this.props.onCancel} />
               </div>
            </form>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   zastupnici: state.zastupnici
});

const mapDispatchToProps = (dispatch) => ({
   //saveToStore: (data) => dispatch(setUserToStore(data))
});

export default injectIntl(
   connect(mapStateToProps, mapDispatchToProps)(UrediNaseljenoMjesto)
);
