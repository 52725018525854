import React from 'react';
import translate from '../../../i18n/translate';
import { getTextFromValue_Zastupnici } from './../../../services/zastupnici-service';

const DetaljiZastupnika = ({ zastupnik }) => {
   const {
      adresaProdajnogMjesta,
      aktivnostZastupnika,
      brojTerminala,
      datumRodjenja,
      depozit,
      gradProdajnogMjesta,
      grupaKorisnika,
      ime,
      imeNadredjenogZastupnika,
      napomena,
      operativniBrojNadredjenogZastupnika,
      operativniBrojZastupnika,
      password,
      postotakProvizijeBingo,
      postotakProvizijeKeno,
      pravoIsplateBingo,
      pravoIsplateKeno,
      pravoIsplateVelikihDobitaka,
      pravoUplateBingo,
      pravoUplateKeno,
      prezime,
      prezimeNadredjenogZastupnika,
      sifraProdajnogMjesta,
      telefon,
      tipNadredjenogZastupnika,
      tipZastupnika,
      title
   } = zastupnik;

   return (
      <div className='detalji-zastupnika'>
         <div className='row'>
            <div className='col'>
               <table className='table table-striped table-sm table-details'>
                  <tbody>
                     <tr>
                        <td style={{ width: '25rem' }}>
                           {translate('zastupnici.operativniBrojZastupnika')}
                        </td>
                        <td>{operativniBrojZastupnika}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.title')}</td>
                        <td>{title}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.ime')}</td>
                        <td>{ime}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.prezime')}</td>
                        <td>{prezime}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.tipZastupnika')}</td>
                        <td>
                           {getTextFromValue_Zastupnici(
                              tipZastupnika,
                              'tipZastupnika'
                           )}
                        </td>
                     </tr>
                     <tr>
                        <td>
                           {translate(
                              'zastupnici.operativniBrojNadredjenogZastupnika'
                           )}
                        </td>
                        <td>{operativniBrojNadredjenogZastupnika}</td>
                     </tr>
                     <tr>
                        <td>
                           {translate('zastupnici.imeNadredjenogZastupnika')}
                        </td>
                        <td>{imeNadredjenogZastupnika}</td>
                     </tr>
                     <tr>
                        <td>
                           {translate(
                              'zastupnici.prezimeNadredjenogZastupnika'
                           )}
                        </td>
                        <td>{prezimeNadredjenogZastupnika}</td>
                     </tr>
                     <tr>
                        <td>
                           {translate('zastupnici.tipNadredjenogZastupnika')}
                        </td>
                        <td>
                           {getTextFromValue_Zastupnici(
                              tipNadredjenogZastupnika,
                              'tipNadredjenogZastupnika'
                           )}
                        </td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.aktivnostZastupnika')}</td>
                        <td>
                           {getTextFromValue_Zastupnici(
                              aktivnostZastupnika,
                              'aktivnostZastupnika'
                           )}
                        </td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.datumRodjenja')}</td>
                        <td>{datumRodjenja}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.telefon')}</td>
                        <td>{telefon}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.sifraProdajnogMjesta')}</td>
                        <td>{sifraProdajnogMjesta}</td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <div className='col'>
               <table className='table table-sm table-striped table-details'>
                  <tbody>
                     <tr>
                        <td>{translate('zastupnici.gradProdajnogMjesta')}</td>
                        <td>{gradProdajnogMjesta}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.adresaProdajnogMjesta')}</td>
                        <td>{adresaProdajnogMjesta}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.grupaKorisnika')}</td>
                        <td>{grupaKorisnika}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.password')}</td>
                        <td>{password}</td>
                     </tr>
                     <tr>
                        <td>
                           {translate('zastupnici.pravoIsplateVelikihDobitaka')}
                        </td>
                        <td>
                           {getTextFromValue_Zastupnici(
                              pravoIsplateVelikihDobitaka,
                              'pravoIsplateVelikihDobitaka'
                           )}
                        </td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.depozit')}</td>
                        <td>{depozit}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.napomena')}</td>
                        <td>{napomena}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.pravoUplateBingo')}</td>
                        <td>
                           {getTextFromValue_Zastupnici(
                              pravoUplateBingo,
                              'pravoUplateBingo'
                           )}
                        </td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.pravoIsplateBingo')}</td>
                        <td>
                           {getTextFromValue_Zastupnici(
                              pravoIsplateBingo,
                              'pravoIsplateBingo'
                           )}
                        </td>
                     </tr>
                     <tr>
                        <td style={{ width: '20rem' }}>
                           {translate('zastupnici.postotakProvizijeBingo')}
                        </td>
                        <td>{`${(postotakProvizijeBingo / 100).toFixed(
                           2
                        )}%`}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.pravoUplateKeno')}</td>
                        <td>
                           {getTextFromValue_Zastupnici(
                              pravoUplateKeno,
                              'pravoUplateKeno'
                           )}
                        </td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.pravoIsplateKeno')}</td>
                        <td>
                           {getTextFromValue_Zastupnici(
                              pravoIsplateKeno,
                              'pravoIsplateKeno'
                           )}
                        </td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.postotakProvizijeKeno')}</td>
                        <td>{`${(postotakProvizijeKeno / 100).toFixed(
                           2
                        )}%`}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.brojTerminala')}</td>
                        <td>{brojTerminala}</td>
                     </tr>
                     {/* <tr>
                        <td>{translate('zastupnici.datumPrijave')}</td>
                        <td>{datumPrijaveOdjave}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.vrijemePrijave')}</td>
                        <td>{vrijemePrijaveOdjave}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.operaterUpisa')}</td>
                        <td>{operaterUpisa}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.datumUpisa')}</td>
                        <td>{datumUpisa}</td>
                     </tr>
                     <tr>
                        <td>{translate('zastupnici.vrijemeUpisa')}</td>
                        <td>{vrijemeUpisa}</td>
                     </tr> */}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default DetaljiZastupnika;
