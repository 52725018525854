import React from 'react';
import Form from '../common/form';
import { dodavanjeZastupnikaSchema } from './zastupnik-dodavanje-validacije';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ucitajStatickePodatkeZastupnika } from '../../store/zastupnici';
import AntDropDownListTranslate from '../common/ant-ddl-translate';
import AntDatepicker from '../common/ant-datepicker';
import intl from '../../services/intl-service';
import CustomAlertNoForm from './../common/custom-alert-no-form';
import { AnimatePresence } from 'framer-motion';
import { zastupniciService } from './../../services/zastupnici-service';
import {
   formatDateToInt,
   getTranslatedDDLoptions
} from './../../services/form-components-service';
import {
   toastSuccessSave,
   toastError,
   toastInfo
} from './../../services/toast-service';
import { getToasterIntervals } from '../../services/app-service';
import { Prompt, withRouter } from 'react-router';
import { paths } from '../../routes/routes';

class DodajZastupnika extends Form {
   state = {
      validationData: {
         prezimeIme: '',
         operativniBrojZastupnika: '',
         operativniBrojNadredjenogZastupnika: '',
         prezimeImeNadredjenogZastupnika: '',
         kategorija: '',
         telefon: '',
         sifraProdajnogMjesta: '',
         gradAdresaProdajnogMjesta: '',
         grupaKorisnika: '',
         password: '',
         postotakProvizije: 0,
         depozit: 0,
         napomena: '',
         tipZastupnika: '',
         tipNadredjenogZastupnika: '',
         aktivnostZastupnika: '',
         datumRodjenja: '',
         pravoIsplateVelikihDobitaka: '',
         pravoUplateBingo: 0,
         pravoIsplateBingo: 0,
         operaterUpisa: 14,
         brojTerminala: ''
      },
      errors: {},
      isEnteringFormValues: false,
      showConfirm: false
   };

   schema = dodavanjeZastupnikaSchema;

   componentDidMount() {
      const { ucitajStatickePodatkeZastupnika } = this.props;
      ucitajStatickePodatkeZastupnika();
   }

   handleDatumRodjenjaChange = (e) => {
      if (!e) return;

      const { validationData } = this.state;
      validationData.datumRodjenja = formatDateToInt(e.format('DD.MM.YYYY'));

      this.setState({ validationData });
   };

   handleDDLSelected = (e, propertyName) => {
      const { value } = e;
      const validationData = { ...this.state.validationData };
      validationData[propertyName] = value;

      this.setState({
         validationData
      });
   };

   doSubmit = () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      this.setState({ isEnteringFormValues: false });
      const { successAutoClose, redirectAutoClose, redirectDelay } =
         getToasterIntervals();

      const { validationData: newZastupnik } = this.state;

      // konverzija tipa podataka za backend
      newZastupnik.password = newZastupnik.password.toString();
      newZastupnik.telefon = newZastupnik.telefon.toString();

      const response = await zastupniciService.addOrUpdate(newZastupnik);

      if (response.isSuccess === 1) {
         toastSuccessSave(
            intl.getTranslate('common.podaciUspjesnoSpremljeni'),
            {
               autoClose: successAutoClose,
               onClose: () => this.props.history.push(paths.overview_agents)
            }
         );
         toastInfo(intl.getTranslate('zastupnici.preusmjeravanje'), {
            autoClose: redirectAutoClose,
            delay: redirectDelay
         });
      } else toastError(response.msg);

      this.setState({ showConfirm: false });
   };

   handleFocus = () => {
      this.setState({ isEnteringFormValues: true });
   };

   render() {
      const { validationData, showConfirm } = this.state;
      const { prezimeIme } = validationData;
      const { zastupnici } = this.props;
      const { statickiPodaci } = zastupnici;

      //  Vrijednosti DDL opcija vucem iz statickih podataka
      let {
         tipZastupnika: tz,
         tipNadredjenogZastupnika: tnz,
         aktivnostZastupnika: az,
         pravoIsplateVelikihDobitaka: pivd,
         pravoUplateBingo: pub,
         pravoIsplateBingo: pib
      } = statickiPodaci;

      const { postotakProvizije, depozit } = validationData;

      tz = getTranslatedDDLoptions(tz);
      tnz = getTranslatedDDLoptions(tnz);
      az = getTranslatedDDLoptions(az);
      pivd = getTranslatedDDLoptions(pivd);
      pub = getTranslatedDDLoptions(pub);
      pib = getTranslatedDDLoptions(pib);

      return (
         <>
            <Prompt
               when={this.state.isEnteringFormValues}
               message={(location) =>
                  `${intl.getTranslate('common.gubitakPodataka')}`
               }
            />
            <AnimatePresence>
               {showConfirm && (
                  <CustomAlertNoForm
                     title={intl.getTranslate('zastupnici.confirmTitle')}
                     btnOk={intl.getTranslate('common.save')}
                     showCondition={true}
                     onCancel={() => this.setState({ showConfirm: false })}
                     onOk={() => this.doSubmitOnConfirm()}
                  >
                     <div>
                        {intl.getTranslate('zastupnici.confirmMessage')}{' '}
                        <span className='confirm-highlight'>{prezimeIme}</span>?
                     </div>
                  </CustomAlertNoForm>
               )}
            </AnimatePresence>
            <form onSubmit={this.handleFormSubmit} onFocus={this.handleFocus}>
               <div className='dodaj-zastupnika form'>
                  <div className='row'>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'operativniBrojZastupnika',
                           'zastupnici',
                           null,
                           true
                        )}
                     </div>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'kategorija',
                           'zastupnici',
                           null,
                           true
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col'>
                        {this.renderTranslateInput('prezimeIme', 'zastupnici')}

                        <AntDropDownListTranslate
                           options={tz}
                           translationKey='zastupnici'
                           translationSubKey='tipZastupnika'
                           onChange={this.handleDDLSelected}
                           // attributes={ddlOdabranaIgraAttributes}
                        />

                        {this.renderTranslateInput(
                           'operativniBrojNadredjenogZastupnika',
                           'zastupnici',
                           null,
                           true
                        )}

                        {this.renderTranslateInput(
                           'prezimeImeNadredjenogZastupnika',
                           'zastupnici'
                        )}

                        <AntDropDownListTranslate
                           options={tnz}
                           translationKey='zastupnici'
                           translationSubKey='tipNadredjenogZastupnika'
                           onChange={this.handleDDLSelected}
                        />
                     </div>
                     <div className='col'>
                        <AntDropDownListTranslate
                           options={az}
                           translationKey='zastupnici'
                           translationSubKey='aktivnostZastupnika'
                           onChange={this.handleDDLSelected}
                        />

                        <AntDatepicker
                           label={intl.getTranslate('zastupnici.datumRodjenja')}
                           onChange={this.handleDatumRodjenjaChange}
                           defaultDate={null}
                        />

                        {this.renderTranslateInput(
                           'telefon',
                           'zastupnici',
                           null,
                           false,
                           null,
                           { maxLength: '19' }
                        )}
                        {this.renderTranslateInput(
                           'sifraProdajnogMjesta',
                           'zastupnici',
                           null,
                           true
                        )}
                        {this.renderTranslateInput(
                           'gradAdresaProdajnogMjesta',
                           'zastupnici'
                        )}
                     </div>
                     <div className='col'>
                        {this.renderTranslateInput(
                           'grupaKorisnika',
                           'zastupnici',
                           null,
                           true
                        )}

                        {this.renderTranslateInput('password', 'zastupnici')}
                        {this.renderTranslateInput(
                           'postotakProvizije',
                           'zastupnici',
                           null,
                           true,
                           `${(postotakProvizije / 100).toFixed(2)}%`
                        )}
                        <AntDropDownListTranslate
                           options={pivd}
                           translationKey='zastupnici'
                           translationSubKey='pravoIsplateVelikihDobitaka'
                           onChange={this.handleDDLSelected}
                        />

                        {this.renderTranslateInput(
                           'depozit',
                           'zastupnici',
                           null,
                           true,
                           `${(depozit / 100).toFixed(2)}%`
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col'>
                        {this.renderInput(
                           'napomena',
                           `${intl.getTranslate('zastupnici.napomena')}`
                        )}
                     </div>
                  </div>

                  <div className='row'>
                     <div className='col-4'>
                        <AntDropDownListTranslate
                           options={pub}
                           translationKey='zastupnici'
                           translationSubKey='pravoUplateBingo'
                           onChange={this.handleDDLSelected}
                        />
                     </div>
                     <div className='col-4'>
                        <AntDropDownListTranslate
                           options={pib}
                           translationKey='zastupnici'
                           translationSubKey='pravoIsplateBingo'
                           onChange={this.handleDDLSelected}
                        />
                     </div>
                     <div className='col-4'></div>
                  </div>

                  <hr />

                  <div style={{ textAlign: 'right' }}>
                     {this.renderSubmitButton(
                        `${intl.getTranslate('common.save')}`,
                        null,
                        () => {
                           // console.log('klikčara');
                        }
                     )}
                  </div>
               </div>
            </form>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   zastupnici: state.zastupnici
});

const mapDispatchToProps = (dispatch) => ({
   ucitajStatickePodatkeZastupnika: () =>
      dispatch(ucitajStatickePodatkeZastupnika())
});

export default withRouter(
   injectIntl(connect(mapStateToProps, mapDispatchToProps)(DodajZastupnika))
);
