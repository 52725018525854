import { zastupniciService } from './../services/zastupnici-service';

const initialState = {
   sviZastupnici: [],
   statickiPodaci: {
      tipZastupnika: [],
      tipNadredjenogZastupnika: [],
      aktivnostZastupnika: [],
      pravoIsplateVelikihDobitaka: [],
      pravoUplateBingo: [],
      pravoIsplateBingo: [],
      pravoUplateKeno: [],
      pravoIsplateKeno: []
   },
   isLoading: false
};

export const setIsLoading = () => ({
   type: 'IS_LOADING',
   payload: {
      isLoading: true
   }
});

export const ucitajSveZastupnike =
   (cancelToken) => async (dispatch, getState) => {
      try {
         //const data = await getZastupniciAll(cancelToken);
         //const { statickiPodaci } = data[0].data;
         // const { zastupnici: sviZastupnici } = data[1].data;

         const statickiPodaci = await zastupniciService.staticData();
         const sviZastupnici = await zastupniciService.list();

         const payload = {
            sviZastupnici,
            statickiPodaci,
            isLoading: false
         };

         dispatch({ type: 'UCITAJ_SVE_ZASTUPNIKE', payload });
      } catch (ex) {
         return Promise.reject(ex);
      }
   };

export const ucitajStatickePodatkeZastupnika =
   () => async (dispatch, getState) => {
      try {
         // const { data: response } = await getZastupniciStatickiPodaci();
         // const { statickiPodaci } = response;

         const statickiPodaci = await zastupniciService.staticData();
         const payload = {
            statickiPodaci
         };

         dispatch({ type: 'UCITAJ_STATICKE_PODATKE_ZASTUPNIKA', payload });
      } catch (ex) {
         if (ex && ex.response && ex.response.status === 400) {
            const payload = {
               error: ex.response.data.Message
            };
         }
      }
   };

//  REDUCER
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case 'UCITAJ_SVE_ZASTUPNIKE':
         // const { sviZastupnici, statickiPodaci, isLoading } = action.payload;
         return { ...state, ...action.payload };
      case 'UCITAJ_STATICKE_PODATKE_ZASTUPNIKA':
         const { statickiPodaci } = action.payload;
         return {
            ...state,
            statickiPodaci
         };
      default:
         return state;
   }
}
