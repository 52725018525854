import intl from '../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjeOpcineSchema = {
   sifraOpcine: Joi.number()
      .integer()
      .min(1)
      .max(9500)
      .required()
      .label(intl.getTranslate('opcina.sifraOpcine'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'opcina.sifraOpcineValidator'
            )}`
         };
      }),
   nazivOpcine: Joi.string()
      .required()
      .label(intl.getTranslate('opcina.nazivOpcine'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'opcina.nazivOpcineValidator'
            )}`
         };
      })
};
