import http from './http';
import api from '../config/a10-terminal-config.json';
import { getOperaterUpisaObject } from './auth-service';

export const terminalaService = {
   get: (brojTerminala) => http.post(`${api.getTerminal}`, { brojTerminala }),
   addOrUpdate: (terminal) =>
      http.post(`${api.postTerminal}`, {
         ...terminal,
         ...getOperaterUpisaObject()
      }),
   getNazivProdajnogMjesta: (sifraProdajnogMjesta) =>
      http.post(`${api.getProdajnoMjesto}`, { sifraProdajnogMjesta })
};
