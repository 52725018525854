import { LANGUAGES } from '../i18n';

const initTheme = 'light';
const initLanguage = LANGUAGES.CROATIAN;

export const publishVersions = {
   BiH: 'Bosna i Hercegovina',
   SierraLeone: 'Sierra Leone'
};

const publishFor = publishVersions.SierraLeone;
export const enableMultilanguage = false;

export const getPublishVersion = () => publishFor;

export const isPublishVersion = (version) => getPublishVersion() === version;

export const getUsedTheme = () => {
   const usedTheme = localStorage.getItem('theme');
   if (usedTheme) return usedTheme;

   localStorage.setItem('theme', initTheme);
   return initTheme;
};

export const getUsedLanguage = () => {
   if (!enableMultilanguage) {
      if (isPublishVersion(publishVersions.BiH)) return LANGUAGES.CROATIAN;
      if (isPublishVersion(publishVersions.SierraLeone))
         return LANGUAGES.SIERRALEONE;
   } else {
      const usedLanguage = localStorage.getItem('language');
      if (usedLanguage) return usedLanguage;

      localStorage.setItem('language', initLanguage);
      return initLanguage;
   }
};

export const getToasterIntervals = () => {
   const successAutoClose = +process.env.REACT_APP_SUCCESS_AUTOCLOSE;
   const redirectAutoClose = +process.env.REACT_APP_REDIRECT_AUTOCLOSE;
   const redirectDelay = +process.env.REACT_APP_REDIRECT_DELAY;

   return { successAutoClose, redirectAutoClose, redirectDelay };
};

export const applyRootElementBackgroundColor = () => {
   const theme = getUsedTheme();
   const root = document.querySelector('#root');
   if (theme === 'dark') root.style.backgroundColor = '#0f111a';
   else root.style.backgroundColor = '#f5f5f5';
};

/*
    Filtriraj component polje prema zadanoj publish verziji
    Ako sadrži svojstvo publishVersion onda ostavi komponentu za
    zadani publish
*/
export const filteredPublishRoutes = (pageRoutes) =>
   pageRoutes.map((r) => {
      const _component = [...r.component];
      const component = _component.filter(
         (c) => c.publishVersion === publishFor || !c.publishVersion
      );

      return { ...r, component };
   });

// export const filteredPublishInitPageRoutes = (pageRoutes) =>
//    pageRoutes.map((r) => {
//       const _links = [...r.links];
//       const links = _links.filter(
//          (l) => l.publishVersion === publishFor || !l.publishVersion
//       );

//       return { ...r, links };
//    });

export const filteredPublishSubmenuRoutes = (submenuRoutes) => {
   const filteredSubmenuLinks = submenuRoutes.map((r) => {
      const _links = [...r.links];
      const links = _links.filter(
         (l) => l.publishVersion === publishFor || !l.publishVersion
      );

      return { ...r, links };
   });

   return filteredSubmenuLinks.filter((o) => o.links.length > 0);
};

export const getFormatedValue = (value) => {
   const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
   };
   return `${Number(value).toLocaleString('en', options)}`;
};
