import React from 'react';
import { motion } from 'framer-motion';
import ReactDOM from 'react-dom';

const CustomAlertContainer = (props) => {
   const { title, style, className, onBackdropClick = null } = props;
   const cssClasses = className
      ? `custom-alert-popup ${className}`
      : 'custom-alert-popup';
   return (
      <>
         {ReactDOM.createPortal(
            <motion.div
               className='custom-alert'
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 0.25 }}
               exit={{ opacity: 0 }}
               onClick={() => {
                  if (onBackdropClick) onBackdropClick();
               }}
            >
               <motion.div
                  className={cssClasses}
                  style={style ? style : {}}
                  initial={{ opacity: 1, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  transition={{
                     duration: 2,
                     delay: 0,
                     type: 'spring',
                     stiffness: 200
                  }}
                  onClick={(e) => {
                     e.stopPropagation();
                  }}
               >
                  <div className='custom-alert-title'>{title}</div>
                  <div className='custom-alert-content'>{props.children}</div>
               </motion.div>
            </motion.div>,
            document.querySelector('#confirm')
         )}
      </>
   );
};

export default CustomAlertContainer;
