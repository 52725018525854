import http from './http';
import api from '../config/a09-terminal-config.json';
import { getOperaterUpisaObject } from './auth-service';

export const aktivnostTerminalaService = {
   list: () => http.post(`${api.getTerminaliSvi}`),
   get: (brojTerminala) => http.post(`${api.getTerminal}`, { brojTerminala }),
   addOrUpdate: (terminal) =>
      http.post(`${api.postTerminal}`, {
         ...terminal,
         ...getOperaterUpisaObject()
      }),
   staticData: () =>
      http
         .postData(`${api.getPodaciZaComboBox}`)
         .then((response) => response.statickiPodaci)
};
