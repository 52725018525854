import React from 'react';
import Form from './../../common/form';
import { azuriranjeZastupnikaSchema } from './zastupnik-validacije';
import { connect } from 'react-redux';
import { zastupniciService } from './../../../services/zastupnici-service';
import {
   formatToMomentDate,
   getTranslatedDDLoptions
} from '../../../services/form-components-service';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import InfoVrijemeUpisa from './../../common/info-vrijeme-upisa';
import DDL from '../../common/ant-ddl-translate';
import AntDatepicker from '../../common/ant-datepicker';
import intl from '../../../services/intl-service';
import { AnimatePresence } from 'framer-motion';
import CustomAlertNoForm from './../../common/custom-alert-no-form';
import CancelButton from '../../common/cancel-button';

class UrediZastupnika extends Form {
   constructor(props) {
      super(props);
      const {
         adresaProdajnogMjesta,
         aktivnostZastupnika,
         brojTerminalaZaduzen,
         datumPrijaveOdjave,
         datumRodjenja,
         datumUpisa,
         depozit,
         gradProdajnogMjesta,
         grupaKorisnika,
         ime,
         imeNadredjenogZastupnika,
         kategorija,
         napomena,
         nazivProdajnogMjesta,
         operaterUpisa,
         operativniBrojNadredjenogZastupnika,
         operativniBrojZastupnika,
         osobniID,
         password,
         postotakProvizijeBingo,
         postotakProvizijeKeno,
         pravoIsplateBingo,
         pravoIsplateKeno,
         pravoIsplateVelikihDobitaka,
         pravoUplateBingo,
         pravoUplateKeno,
         prezime,
         prezimeNadredjenogZastupnika,
         sifraProdajnogMjesta,
         skracenoPrezimeIme,
         telefon,
         tipNadredjenogZastupnika,
         tipZastupnika,
         title,
         vrijemePrijaveOdjave,
         vrijemeUpisa
      } = props.zastupnik;

      this.state = {
         validationData: {
            adresaProdajnogMjesta,
            aktivnostZastupnika,
            brojTerminalaZaduzen,
            datumPrijaveOdjave,
            datumRodjenja,
            datumUpisa,
            depozit,
            gradProdajnogMjesta,
            grupaKorisnika,
            ime,
            imeNadredjenogZastupnika,
            kategorija,
            napomena,
            nazivProdajnogMjesta,
            operaterUpisa,
            operativniBrojNadredjenogZastupnika,
            operativniBrojZastupnika,
            osobniID,
            password,
            postotakProvizijeBingo,
            postotakProvizijeKeno,
            pravoIsplateBingo,
            pravoIsplateKeno,
            pravoIsplateVelikihDobitaka,
            pravoUplateBingo,
            pravoUplateKeno,
            prezime,
            prezimeNadredjenogZastupnika,
            sifraProdajnogMjesta,
            skracenoPrezimeIme,
            telefon,
            tipNadredjenogZastupnika,
            tipZastupnika,
            title,
            vrijemePrijaveOdjave,
            vrijemeUpisa
         },
         nadredjeniZastupniciSvi: [],
         nadredjeniZastupnici: [],
         prodajnaMjesta: [],
         errors: {},
         showConfirm: false
      };
   }

   schema = azuriranjeZastupnikaSchema;

   handleDatumRodjenjaChange = (e) => {
      if (!e) return;

      const { validationData } = this.state;
      validationData.datumRodjenja = e.format('DD.MM.YYYY');

      this.setState({ validationData });
   };

   handleTipNadredjenogZastupnikaSelected = async (value, obj) => {
      // console.log(value, obj);
   };

   handleDDLSelected = (e, propertyName) => {
      const { value } = e;
      const validationData = { ...this.state.validationData };
      const numericValue = parseInt(value);
      validationData[propertyName] = numericValue;

      this.setState(
         {
            validationData
         },
         () => {
            switch (propertyName) {
               case 'tipZastupnika':
                  this.filtrirajNadredjeneZastupnike();
                  break;
               case 'operativniBrojNadredjenogZastupnika':
                  this.azurirajTipNadredjenogZastupnika(numericValue);
                  break;
               case 'sifraProdajnogMjesta':
                  this.showDetails_ProdajnoMjesto(numericValue);
                  break;
               default:
                  break;
            }
         }
      );
   };

   showDetails_ProdajnoMjesto = (sifra) => {
      const { prodajnaMjesta } = this.state;
      const pm = prodajnaMjesta.find((p) => p.sifra === sifra);

      if (!pm) return;

      const validationData = { ...this.state.validationData };
      validationData.adresaProdajnogMjesta = pm.adresa;
      validationData.gradProdajnogMjesta = pm.grad;

      this.setState({ validationData });
   };

   componentDidMount() {
      this.loadNadredjeniZastupnici();
      this.loadProdajnaMjesta();
   }

   loadProdajnaMjesta = async () => {
      const { data: prodajnaMjesta } =
         await zastupniciService.getProdajnaMjestaSva();
      this.setState({ prodajnaMjesta });
   };

   /*
      Nardređeni zastupnik - tip je uvijek za 1 veci od tipa zastupnika
      1. Dohvati sve
      2. Filtriraj po tipu
      3. Definiraj punoIme
      4. Ažuriraj prikaz tipa nadređenog zastupnika
   */
   loadNadredjeniZastupnici = async () => {
      const { data: response } =
         await zastupniciService.getNadredjeniZastupnici();

      this.setState({ nadredjeniZastupniciSvi: response }, () => {
         this.filtrirajNadredjeneZastupnike();
      });
   };

   filtrirajNadredjeneZastupnike = () => {
      const { nadredjeniZastupniciSvi: data } = this.state;
      const { tipZastupnika } = this.state.validationData;

      // ako nije odabran tip zastupnika nema niti nadređenog zastupnika
      if (tipZastupnika === 0) {
         const validationData = { ...this.state.validationData };
         validationData.operativniBrojNadredjenogZastupnika = 0;
         validationData.tipNadredjenogZastupnika = 0;
         this.setState({ validationData });
      }

      const filtriraniPoTipu = data.filter(
         (nz) => nz.tip === tipZastupnika + 1
      );

      const nadredjeniZastupnici = filtriraniPoTipu.map((nz) => {
         return {
            ...nz,
            punoIme: `[${nz.operativniBroj}] ${nz.ime} ${nz.prezime}`
         };
      });
      this.setState({ nadredjeniZastupnici });
   };

   azurirajTipNadredjenogZastupnika = (opbrNardedjenog) => {
      const { nadredjeniZastupniciSvi: data } = this.state;
      const found = data.find((x) => x.operativniBroj === opbrNardedjenog);

      if (found) {
         const validationData = { ...this.state.validationData };
         validationData.tipNadredjenogZastupnika = found.tip;
         this.setState({ validationData });
      }
   };

   doSubmit = () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const { onSubmit } = this.props;
      const { validationData } = this.state;

      onSubmit(validationData);
      this.setState({ showConfirm: false });
   };

   render() {
      const userIsAdmin = true;

      const {
         validationData,
         nadredjeniZastupnici,
         prodajnaMjesta,
         showConfirm
      } = this.state;
      const { zastupnici } = this.props;
      const { statickiPodaci } = zastupnici;

      //  Vrijednosti DDL opcija vucem iz statickih podataka
      let {
         tipZastupnika: tz,
         tipNadredjenogZastupnika: tnz,
         aktivnostZastupnika: az,
         pravoIsplateVelikihDobitaka: pivd,
         pravoUplateBingo: pub,
         pravoIsplateBingo: pib,
         pravoIsplateKeno: pik,
         pravoUplateKeno: puk
      } = statickiPodaci;

      tz = getTranslatedDDLoptions(tz);
      tnz = getTranslatedDDLoptions(tnz);
      az = getTranslatedDDLoptions(az);
      pivd = getTranslatedDDLoptions(pivd);
      pub = getTranslatedDDLoptions(pub);
      pib = getTranslatedDDLoptions(pib);
      pik = getTranslatedDDLoptions(pik);
      puk = getTranslatedDDLoptions(puk);

      const {
         aktivnostZastupnika,
         datumRodjenja,
         datumUpisa,
         postotakProvizijeBingo,
         postotakProvizijeKeno,
         ime,
         operaterUpisa,
         operativniBrojNadredjenogZastupnika: opbrNardedjenog,
         pravoIsplateBingo,
         pravoIsplateKeno,
         pravoIsplateVelikihDobitaka,
         pravoUplateBingo,
         pravoUplateKeno,
         prezime,
         sifraProdajnogMjesta,
         tipNadredjenogZastupnika,
         tipZastupnika,
         vrijemeUpisa
      } = validationData;

      const datumRodjenja_dv = datumRodjenja
         ? moment(formatToMomentDate(datumRodjenja))
         : null;

      const dateValidationMessage = null;

      const getProdajnaMjesta = prodajnaMjesta.map((pm) => ({
         text: `[${pm.sifra}] ${pm.naziv}`,
         value: `${pm.sifra}`
      }));

      return (
         <>
            <AnimatePresence>
               {showConfirm && (
                  <CustomAlertNoForm
                     showCondition={true}
                     onCancel={() => this.setState({ showConfirm: false })}
                     onOk={() => this.doSubmitOnConfirm()}
                  >
                     <div>
                        {intl.getTranslate('common.update')}{' '}
                        <span className='confirm-highlight'>{`${ime} ${prezime}`}</span>
                        ?
                     </div>
                  </CustomAlertNoForm>
               )}
            </AnimatePresence>
            <form onSubmit={this.handleFormSubmit}>
               <div className='uredi-zastupnika form'>
                  <div className='row'>
                     <div className='col'>
                        {this.formInput({
                           name: 'operativniBrojZastupnika',
                           translationKey: 'zastupnici',
                           isNumber: true,
                           attributes: { disabled: true }
                        })}
                        {this.formInput({
                           name: 'title',
                           translationKey: 'zastupnici'
                        })}
                        {this.formInput({
                           name: 'ime',
                           translationKey: 'zastupnici'
                        })}
                        {this.formInput({
                           name: 'prezime',
                           translationKey: 'zastupnici'
                        })}
                     </div>
                     <div className='col'>
                        {this.formInput({
                           name: 'skracenoPrezimeIme',
                           translationKey: 'zastupnici'
                        })}

                        <AntDatepicker
                           label={intl.getTranslate('zastupnici.datumRodjenja')}
                           onChange={this.handleDatumRodjenjaChange}
                           defaultDate={datumRodjenja_dv}
                           validationMessage={dateValidationMessage}
                        />

                        {this.formInput({
                           name: 'telefon',
                           translationKey: 'zastupnici'
                        })}

                        {this.renderTranslateInput('osobniID', 'zastupnici')}
                     </div>
                     <div className='col'>
                        <DDL
                           options={az}
                           translationKey='zastupnici'
                           translationSubKey='aktivnostZastupnika'
                           onChange={this.handleDDLSelected}
                           selectedOption={aktivnostZastupnika}
                        />

                        <DDL
                           options={tz}
                           translationKey='zastupnici'
                           translationSubKey='tipZastupnika'
                           onChange={this.handleDDLSelected}
                           selectedOption={tipZastupnika}
                           disabled={userIsAdmin ? false : true}
                        />

                        <DDL
                           options={nadredjeniZastupnici}
                           translationKey='zastupnici'
                           translationSubKey='operativniBrojNadredjenogZastupnika'
                           onChange={this.handleDDLSelected}
                           selectedOption={opbrNardedjenog}
                           text='punoIme'
                           value='operativniBroj'
                           showLoadingIcon={false}
                           disabled={userIsAdmin ? false : true}
                        />

                        <DDL
                           options={tnz}
                           translationKey='zastupnici'
                           translationSubKey='tipNadredjenogZastupnika'
                           onChange={this.handleDDLSelected}
                           selectedOption={tipNadredjenogZastupnika}
                           disabled={userIsAdmin ? false : true}
                        />
                     </div>
                     <div className='col'>
                        <DDL
                           options={getProdajnaMjesta}
                           translationKey='zastupnici'
                           translationSubKey='sifraProdajnogMjesta'
                           onChange={this.handleDDLSelected}
                           selectedOption={sifraProdajnogMjesta}
                        />
                        {this.formInput({
                           name: 'adresaProdajnogMjesta',
                           translationKey: 'zastupnici',
                           attributes: {
                              disabled: true
                           }
                        })}

                        {this.formInput({
                           name: 'gradProdajnogMjesta',
                           translationKey: 'zastupnici',
                           attributes: {
                              disabled: true
                           }
                        })}

                        {this.formInput({
                           name: 'napomena',
                           translationKey: 'zastupnici'
                        })}
                     </div>
                     <div className='col'>
                        {this.formInput({
                           name: 'grupaKorisnika',
                           translationKey: 'zastupnici',
                           isNumber: true
                        })}

                        {this.formInput({
                           name: 'password',
                           translationKey: 'zastupnici',
                           isNumber: true
                        })}

                        {this.formInput({
                           name: 'depozit',
                           translationKey: 'zastupnici',
                           isNumber: true
                        })}

                        {this.formInput({
                           name: 'brojTerminalaZaduzen',
                           translationKey: 'zastupnici',
                           isNumber: true
                        })}
                     </div>
                  </div>

                  <hr />

                  <div className='row'>
                     <div className='col-4'>
                        {this.formInput({
                           name: 'kategorija',
                           translationKey: 'zastupnici',
                           isNumber: true
                        })}
                     </div>
                     <div className='col-4'>
                        <DDL
                           options={pivd}
                           translationKey='zastupnici'
                           translationSubKey='pravoIsplateVelikihDobitaka'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoIsplateVelikihDobitaka}
                        />
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-4'>
                        <DDL
                           options={pub}
                           translationKey='zastupnici'
                           translationSubKey='pravoUplateBingo'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoUplateBingo}
                        />
                     </div>
                     <div className='col-4'>
                        <DDL
                           options={pib}
                           translationKey='zastupnici'
                           translationSubKey='pravoIsplateBingo'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoIsplateBingo}
                        />
                     </div>
                     <div className='col-4'>
                        {this.formInput({
                           name: 'postotakProvizijeBingo',
                           translationKey: 'zastupnici',
                           isNumber: true,
                           lblSufix: `${(postotakProvizijeBingo / 100).toFixed(
                              2
                           )}%`
                        })}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-4'>
                        <DDL
                           options={puk}
                           translationKey='zastupnici'
                           translationSubKey='pravoUplateKeno'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoUplateKeno}
                        />
                     </div>
                     <div className='col-4'>
                        <DDL
                           options={pik}
                           translationKey='zastupnici'
                           translationSubKey='pravoIsplateKeno'
                           onChange={this.handleDDLSelected}
                           selectedOption={pravoIsplateKeno}
                        />
                     </div>
                     <div className='col-4'>
                        {this.formInput({
                           name: 'postotakProvizijeKeno',
                           translationKey: 'zastupnici',
                           isNumber: true,
                           lblSufix: `${(postotakProvizijeKeno / 100).toFixed(
                              2
                           )}%`
                        })}
                     </div>
                  </div>

                  <hr />

                  <InfoVrijemeUpisa
                     datumUpisa={datumUpisa}
                     vrijemeUpisa={vrijemeUpisa}
                     operaterUpisa={operaterUpisa}
                  />

                  <div>
                     {this.formSubmit({
                        label: `${intl.getTranslate('common.update')}`
                     })}
                     <CancelButton
                        onClick={() => {
                           this.props.onCancel();
                        }}
                     />
                  </div>
               </div>
            </form>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   zastupnici: state.zastupnici
});

const mapDispatchToProps = (dispatch) => ({
   //saveToStore: (data) => dispatch(setUserToStore(data))
});

export default injectIntl(
   connect(mapStateToProps, mapDispatchToProps)(UrediZastupnika)
);
