import intl from '../../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjeNadredjenogSchema = {
   operativniBroj: Joi.number()
      .integer()
      .min(1)
      .max(99999)
      .required()
      .label(intl.getTranslate('azuriranjeNadredjenog.operativniBroj'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'azuriranjeNadredjenog.operativniBrojValidator'
            )}`
         };
      }),
   operativniBrojNadredjenog: Joi.number()
      .integer()
      .min(1)
      .max(99999)
      .required()
      .label(
         intl.getTranslate('azuriranjeNadredjenog.operativniBrojNadredjenog')
      )
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'azuriranjeNadredjenog.operativniBrojNadredjenogValidator'
            )}`
         };
      })
};
// https://stackoverflow.com/questions/11704267/in-javascript-how-to-conditionally-add-a-member-to-an-object
