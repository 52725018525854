import intl from '../../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjeZastupnikaSchema = {
   ime: Joi.string()
      .max(31)
      .label(intl.getTranslate('zastupnici.ime'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.imeValidator'
            )}`
         };
      }),
   prezime: Joi.string()
      .max(31)
      .label(intl.getTranslate('zastupnici.prezime'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.prezimeValidator'
            )}`
         };
      }),
   skracenoPrezimeIme: Joi.string()
      .allow('')
      .optional()
      .max(20)
      .label(intl.getTranslate('zastupnici.skracenoPrezimeIme'))
      .error((errors) => {
         return {
            message: `${intl.getTranslate(
               'zastupnici.skracenoPrezimeImeValidator'
            )}`
         };
      }),
   title: Joi.string()
      .allow('')
      .optional()
      .max(7)
      .label(intl.getTranslate('zastupnici.title'))
      .error((errors) => {
         return {
            message: `${intl.getTranslate('zastupnici.titleValidator')}`
         };
      }),
   operativniBrojZastupnika: Joi.number()
      .integer()
      .required()
      .label(
         intl.getTranslate('zastupnici.operativniBrojNadredjenogZastupnika')
      )
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.operativniBrojNadredjenogZastupnikaValidator'
            )}`
         };
      }),
   kategorija: Joi.number()
      .integer()
      .min(0)
      .max(9999)
      .label(intl.getTranslate('zastupnici.kategorija'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.kategorijaValidator'
            )}`
         };
      }),
   operativniBrojNadredjenogZastupnika: Joi.number()
      .integer()
      .min(0)
      .max(99_999)
      .label(
         intl.getTranslate('zastupnici.operativniBrojNadredjenogZastupnika')
      )
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.operativniBrojNadredjenogZastupnikaValidator'
            )}`
         };
      }),
   brojTerminalaZaduzen: Joi.number()
      .integer()
      .min(0)
      .max(99_999)
      .label(intl.getTranslate('zastupnici.brojTerminalaZaduzen'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.brojTerminalaZaduzenValidator'
            )}`
         };
      }),
   telefon: Joi.string()
      .allow('')
      .optional()
      .max(31)
      .label(intl.getTranslate('zastupnici.telefon'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.telefonValidator'
            )}`
         };
      }),
   osobniID: Joi.string()
      .allow('')
      .optional()
      .max(14)
      .label(intl.getTranslate('zastupnici.osobniID'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.osobniIDValidator'
            )}`
         };
      }),
   sifraProdajnogMjesta: Joi.number()
      .integer()
      .min(0)
      .max(9999)
      .label(intl.getTranslate('zastupnici.sifraProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.sifraProdajnogMjestaValidator'
            )}`
         };
      }),
   password: Joi.number()
      .integer()
      .min(0)
      .max(999999)
      .label(intl.getTranslate('zastupnici.password'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.passwordValidator'
            )}`
         };
      }),
   prezimeImeNadredjenogZastupnika: Joi.string()
      .allow('')
      .optional()
      .max(64)
      .label(intl.getTranslate('zastupnici.prezimeImeNadredjenogZastupnika'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.prezimeImeNadredjenogZastupnikaValidator'
            )}`
         };
      }),
   gradAdresaProdajnogMjesta: Joi.any().optional(),
   grupaKorisnika: Joi.number()
      .integer()
      .min(0)
      .max(50)
      .label(intl.getTranslate('zastupnici.grupaKorisnika'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.grupaKorisnikaValidator'
            )}`
         };
      }),
   depozit: Joi.number()
      .integer()
      .min(0)
      .required()
      .label(intl.getTranslate('zastupnici.depozit'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.depozitValidator'
            )}`
         };
      }),
   postotakProvizijeBingo: Joi.number()
      .integer()
      .min(0)
      .max(9999)
      .label(intl.getTranslate('zastupnici.postotakProvizijeBingo'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.postotakProvizijeBingoValidator'
            )}`
         };
      }),
   postotakProvizijeKeno: Joi.number()
      .integer()
      .min(0)
      .max(9999)
      .label(intl.getTranslate('zastupnici.postotakProvizijeKeno'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'zastupnici.postotakProvizijeKenoValidator'
            )}`
         };
      }),
   napomena: Joi.any().optional()
};
