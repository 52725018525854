import React from 'react';
import SelectAnt from './select-ant';
import intl from '../../services/intl-service';

/*
    attributes - objekt s ostalim atributima za Antd Select komponentu {...attributes}
    željeni value na DDL se postavlja na text vrijednost

    translationSubKey u pravilu treba postaviti na ime svojstva uz kojeg se kontrola veže

    selectedOption={getSelectedOptionText()}
    DDL vežem uz odabranu vrijednost(value), međutim da selektiram odgovarajuču
    opciju trebam podesiti text value opcije
*/
const AntDropDownListTranslate = ({
   allowClear = false,
   translationSubKey,
   translationKey,
   options,
   onChange,
   attributes,
   hideLabel = false,
   requiredLabel,
   text = 'text',
   value = 'value',
   selectedOption = null,
   showLoadingIcon = true,
   placeholder = intl.getTranslate(`common.ddlPlaceholder`),
   disabled = false
}) => {
   const notFound = intl.getTranslate(`common.ddlNotFound`);

   const getSelectedOptionText = () => {
      // eslint-disable-line
      // eslint-disable-next-line
      const found = options.find((o) => o[value] == selectedOption);
      return found ? found[text] : null;
   };

   /**
    * ako je kliknuto brisanje polja vrijednosti su prazni stringovi
    */
   const handleOnChange = (value, obj) => {
      onChange(
         {
            text: value ?? '',
            value: obj ? obj.id ?? '' : ''
         },
         translationSubKey
      );
   };

   return (
      <SelectAnt
         label={
            translationKey &&
            translationSubKey &&
            intl.getTranslate(`${translationKey}.${translationSubKey}`)
         }
         allowClear={allowClear}
         text={text}
         value={value}
         placeholder={placeholder}
         notFoundContent={notFound}
         selectOptions={options}
         onChange={handleOnChange}
         onSearch={null}
         showLoadingIcon={showLoadingIcon}
         hideLabel={hideLabel}
         attributes={attributes}
         requiredLabel={requiredLabel}
         selectedOption={getSelectedOptionText()}
         disabled={disabled}
      />
   );
};

export default AntDropDownListTranslate;
