import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { LANGUAGES } from './languages';

/*
   omoguči ugnježđivanje opcija objekata u datotekama s prijevodima
   https://egghead.io/lessons/react-convert-a-hard-coded-string-using-react-intl-formattedmessage
*/
import { flattenMessages } from '../utils/flatten-messages';

//automatski vuce index.js datoteku
import messages from './messages';

const Provider = ({ children, language = LANGUAGES.CROATIAN }) => (
   <IntlProvider
      locale={language}
      textComponent={Fragment}
      messages={flattenMessages(messages[language])}
   >
      {children}
   </IntlProvider>
);

export default Provider;
