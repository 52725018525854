import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import translate from './../../i18n/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const MenuDropdown = ({ dropdownOptions, header, onOptionClick }) => {
   const y = -30;
   return (
      <motion.div
         initial={{ opacity: 0, y }}
         animate={{ opacity: 1, y: 0 }}
         exit={{ opacity: 0, y, transition: { duration: 0.1, delay: 0.3 } }}
         transition={{ delay: 0.2, stiffness: 5000 }}
      >
         <h2>
            {header}
            <FontAwesomeIcon
               className='ml-2'
               style={{ fontSize: '1.4rem', color: '#a390dc' }}
               icon={faAngleRight}
            />
         </h2>
         <ul className='menu-dropdown'>
            {dropdownOptions.map((item, index) => (
               <li key={item.path + index} className='submenu-item'>
                  <NavLink to={item.path} onClick={onOptionClick}>
                     {translate(item.links[0].label)}
                  </NavLink>
               </li>
            ))}
         </ul>
      </motion.div>
   );
};

export default MenuDropdown;
