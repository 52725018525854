import React from 'react';
import Register from '../components/register';
import PageContainer from './../components/common/animated-page-container';

const RegisterPage = (props) => {
   return (
      <PageContainer className='updating'>
         <div className='register-page'>
            <Register />
         </div>
      </PageContainer>
   );
};

export default RegisterPage;
