import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../components/common/animated-page-container';
import { getUsedTheme } from './../services/app-service';
import {
   ucitajSveZastupnike,
   setIsLoading,
   ucitajStatickePodatkeZastupnika
} from './../store/zastupnici';
import FramerConditionalOpacityContainer from './../components/common/framer-conditional-opacity-container';
import intl from '../services/intl-service';

const Homepage = (props) => {
   const theme = getUsedTheme();
   const css = theme === 'dark' ? 'homepage-dark' : 'homepage-light';

   const dispatch = useDispatch();
   const { isLoading } = useSelector((state) => state.zastupnici);

   useEffect(() => {
      dispatch(ucitajStatickePodatkeZastupnika());
      dispatch(setIsLoading());
      dispatch(ucitajSveZastupnike());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <PageContainer className='homepage'>
         <h1 className={css}>Nirvana backoffice</h1>
         <FramerConditionalOpacityContainer condition={isLoading}>
            <p>{intl.getTranslate('common.inicijalizacijaPodataka')}</p>
         </FramerConditionalOpacityContainer>
      </PageContainer>
   );
};

export default Homepage;
