//  https://youtu.be/EoUIS2PxKCs
export const sleep = (duration) =>
   new Promise((resolve) => setTimeout(resolve, duration));

export const randomNumber = (min, max) =>
   Math.floor(Math.random() * (max - min + 1) + min);

export const debounceFormInput = (callback) => {
   let timeout;
   return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
         callback(...args);
      }, args[0].debounceDelay);
   };
};

export const debounce = (callback, delay = 50) => {
   let timeout;
   return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
         callback(...args);
      }, delay);
   };
};

export const getHighlightedText = (text, highlight) => {
   // Split on highlight term and include term into parts, ignore case
   const parts = text.toString().split(new RegExp(`(${highlight})`, 'gi'));
   return (
      <span>
         {' '}
         {parts.map((part, i) => (
            <span
               key={i}
               style={
                  part.toLowerCase() === highlight.toLowerCase()
                     ? {
                          color: 'white',
                          backgroundColor: '#dc136c'
                       }
                     : {}
               }
            >
               {part}
            </span>
         ))}{' '}
      </span>
   );
};

const exportObj = {
   sleep,
   debounce,
   debounceFormInput,
   randomNumber,
   getHighlightedText
};

export default exportObj;
