import React, { useState } from 'react';
import {
   ResponsiveContainer,
   Area,
   Bar,
   Tooltip,
   Line,
   //    AreaChart,
   //    BarChart,
   ComposedChart,
   XAxis,
   YAxis,
   CartesianGrid
} from 'recharts';
import { Legend } from 'recharts/lib/component/Legend';
import { getUsedTheme } from './../../services/app-service';

const Chart = ({
   data,
   values = ['Bingo', 'Joker', 'Jumbo Joker'],
   colors = ['#f33', '#0f0', '#00f', '#f33', '#0f0', '#00f'],
   xAxisDataKey,
   yAxisDataKey,
   height = 400
}) => {
   const [isBarType, setIsBarType] = useState(true);

   const isDarkTheme = getUsedTheme() === 'dark' ? true : false;
   const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
         return (
            <div
               className='chart-tooltip'
               style={{
                  boxShadow: `0 0 1rem ${isDarkTheme ? '#000' : '#fff'}`,
                  background: isDarkTheme ? '#000' : '#fff'
               }}
            >
               <p
                  className='label'
                  style={{ color: isDarkTheme ? 'orange' : '#000' }}
               >
                  {label.toUpperCase()}
               </p>
               <table>
                  <tbody>
                     {payload.map((p, i) => (
                        <tr key={`tooltipTR${i}`}>
                           <td>{p.name}</td>
                           <td
                              style={{
                                 color: isDarkTheme ? 'white' : 'orange'
                              }}
                           >
                              {p.value}
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         );
      }

      return null;
   };
   const axisStyle = { fontSize: '1.1rem', fontWeight: 700 };

   return (
      <ResponsiveContainer width='100%' height={height}>
         <ComposedChart data={data} onClick={() => setIsBarType(!isBarType)}>
            {values.map((v, i) =>
               isBarType ? (
                  <Bar
                     key={`bar${i}`}
                     dataKey={v}
                     fill={colors[i]}
                     stroke={colors[i]}
                  />
               ) : (
                  <Area
                     key={`bar${i}`}
                     dataKey={v}
                     fill={colors[i]}
                     stroke={colors[i]}
                  />
               )
            )}
            <XAxis
               dataKey={xAxisDataKey}
               axisLine={false}
               tickLine={false}
               style={axisStyle}
            />
            <Legend verticalAlign='bottom' height={10} iconSize={12} />
            <YAxis
               dataKey={yAxisDataKey}
               axisLine={false}
               tickLine={false}
               tickCount={11}
               tickFormatter={(number) => `${number.toFixed(2)}`}
               style={axisStyle}
               // label={{ value: 'Index', angle: -90, position: 'insideLeft' }}
            />
            <Tooltip
               content={<CustomTooltip />}
               cursor={{
                  opacity: 1,
                  fill: '#000',
                  zIndex: -100
               }}
            />
            <CartesianGrid opacity={0.5} vertical={false} />
         </ComposedChart>
      </ResponsiveContainer>
   );
};

export default Chart;

// const Chart = () => {
//    const data = [
//       {
//          dan: 'Ponedjeljak',
//          value: 2,
//          value2: 5
//       },
//       {
//          dan: 'Utorak',
//          value: 1,
//          value2: 7
//       },
//       {
//          dan: 'Srijeda',
//          value: 4,
//          value2: 2
//       },
//       {
//          dan: 'Četvrtak',
//          value: 3,
//          value2: 9
//       },
//       {
//          dan: 'Petak',
//          value: 5,
//          value2: 3
//       }
//    ];

//    return (
//       <ResponsiveContainer width='100%' height={400}>
//          <AreaChart data={data}>
//             <defs>
//                <linearGradient id='color' x1='0' y1='0' x2='0' y2='1'>
//                   <stop offset='0%' stopColor='#2451B7' stopOpacity={0.4} />
//                   <stop offset='75%' stopColor='#2451B7' stopOpacity={0.1} />
//                </linearGradient>
//                <linearGradient id='color2' x1='0' y1='0' x2='0' y2='1'>
//                   <stop offset='0%' stopColor='#22b722' stopOpacity={0.4} />
//                   <stop offset='75%' stopColor='#22b722' stopOpacity={0.1} />
//                </linearGradient>
//             </defs>
//             <Area dataKey='value' stroke='#2451b7' fill='url(#color)' />
//             <Area dataKey='value2' stroke='#22b722' fill='url(#color2)' />
//             <XAxis dataKey='dan' axisLine={false} tickLine={false} />
//             <YAxis
//                dataKey='value2'
//                axisLine={false}
//                tickLine={false}
//                tickCount={10}
//                tickFormatter={(number) => `$${number.toFixed(2)}`}
//             />
//             <Tooltip
//    wrapperStyle={{
//       boxShadow: `0 0 1rem ${isDarkTheme ? '#000' : '#fff'}`
//    }}
//    contentStyle={{
//       backgroundColor: isDarkTheme ? '#222' : '#fff',
//       border: 0
//    }}
//    labelStyle={{
//       fontWeight: 900,
//       color: isDarkTheme ? '#fff' : '#000'
//    }} />
//             <CartesianGrid opacity={0.5} vertical={false} />
//          </AreaChart>
//       </ResponsiveContainer>
//    );
// };

// export default Chart;
