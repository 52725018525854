import React, { useEffect, useState } from 'react';
import { aktivnostTerminalaService as service } from '../../services/a09-terminal-service';
import intl from '../../services/intl-service';
import { withRouter } from 'react-router';
import { isPublishVersion, publishVersions } from '../../services/app-service';
import { DataLoader } from '../../services/data-loader-service';
import DataTable from '../common/table/datatable';
import { AnimatePresence } from 'framer-motion';
import CustomAlertNoFormContainer from '../common/custom-alert-container';
import UrediAktivnostTerminala from '../a09-terminali/upravljanje-terminalima';
import ButtonEditTableRow from '../common/btn-edit-table-row';
import { paths } from './../../routes/routes';
import ButtonAddNew from '../common/btn-add-new';
import NoDataMessage from './../common/no-data-message';

/*
    label - naslov kolumne
    path - svojstvo objekta cije podatke treba prikazati
*/
const PregledTerminala = (props) => {
   const columns = [
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('terminal.broj'),
         width: '10rem',
         path: 'brojTerminala'
      },
      {
         sortable: true,
         center: true,
         width: '13rem',
         label: intl.getTranslate('terminal.aktivnost'),
         path: 'aktivnost',
         content: (item) => (
            <span>
               {item.aktivnost === 1
                  ? intl.getTranslate('terminal.aktivan')
                  : intl.getTranslate('terminal.neaktivan')}
            </span>
         )
      },
      {
         sortable: true,
         width: '11rem',
         center: true,
         label: intl.getTranslate('terminal.operaterUpisa'),
         path: 'operaterUpisa'
      },
      {
         center: true,
         width: '15rem',
         label: intl.getTranslate('terminal.datumUpisa'),
         path: 'datum'
      },
      {
         key: 'btnEdit',
         width: '8rem',
         content: (item) => {
            if (isPublishVersion(publishVersions.BiH)) return null;
            return (
               <ButtonEditTableRow onClick={handleOnEditClick} item={item} />
            );
         }
      },
      {
         label: '',
         path: '-'
      }
   ];

   const [data, setData] = useState([]);
   const [showUredi, setShowUredi] = useState(false);
   const [odabranTerminal, setOdabranTerminal] = useState(null);

   const onDataLoad = (response) => {
      const { terminali } = response;
      const terminaliData = terminali.map((t) => ({
         ...t,
         datum: `${t.datumUpisa} - ${t.vrijemeUpisa}`
      }));
      setData(terminaliData);
   };

   const loadData = () => DataLoader(service.list, null, onDataLoad);

   useEffect(() => {
      loadData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleOnEditClick = (item) => {
      setOdabranTerminal(item);
      setShowUredi(true);
   };

   const handleAfterEdit = () => {
      loadData();
      setShowUredi(false);
   };
   const handleCancel = () => {
      setShowUredi(false);
   };

   return (
      <>
         <NoDataMessage messageKey='common.noData' data={data} />

         <AnimatePresence>
            {showUredi && (
               <CustomAlertNoFormContainer
                  title={intl.getTranslate('terminal.confirmTitle')}
                  style={{ width: '80rem' }}
               >
                  <UrediAktivnostTerminala
                     odabranTerminal={odabranTerminal}
                     onSubmit={handleAfterEdit}
                     onCancel={handleCancel}
                     updateMode={true}
                  />
               </CustomAlertNoFormContainer>
            )}
         </AnimatePresence>

         <DataTable
            columns={columns}
            pageSize={15}
            data={data}
            searchLabel={intl.getTranslate('terminal.pretraga')}
            searchLabelPlaceholder={intl.getTranslate(
               'terminal.pretragaPlaceholder'
            )}
            searchColumns={['brojTerminala']}
            onRowClick={handleOnEditClick}
            printEnable={true}
            printColumns={columns.slice(0, -1)}
            printHeader={intl.getTranslate('terminalAktivnost.printHeader')}
            btnAdd={
               <ButtonAddNew
                  title={intl.getTranslate('terminalAktivnost.addNew')}
                  path={paths.manage_manageTerminalActivityForm.path}
               />
            }
         />
      </>
   );
};

export default withRouter(PregledTerminala);
