import React from 'react';
import intl from '../../services/intl-service';
import { v4 as uuid } from 'uuid';

const PodaciIsplata = ({ data, onClick }) => {
   if (!data) return null;

   const {
      igra,
      indikatorDobitka,
      indikatorZastare,
      dobici,
      iznosDobitkaUkupno,
      iznosPoreza,
      iznosIsplacenIgracu,
      operaterIsplate,
      datumIsplate,
      vrijemeIsplate,
      brojTerminalaIsplate
   } = data;

   //pretplata za kola

   return (
      <div className='table-overview-container'>
         {/* <h2>{intl.getTranslate('pregledListica.podaciIsplate.naslov')}</h2> */}
         <table className='table table-striped table-sm'>
            <tbody>
               <tr>
                  <td>
                     {intl.getTranslate('pregledListica.podaciIsplate.igra')}
                  </td>
                  <td>{igra}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciIsplate.indikatorDobitka'
                     )}
                  </td>
                  <td>{indikatorDobitka}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciIsplate.indikatorZastare'
                     )}
                  </td>
                  <td>{indikatorZastare}</td>
               </tr>

               {dobici.map((d) => (
                  <tr key={uuid()}>
                     <td>{`${intl.getTranslate(
                        'pregledListica.podaciIsplate.dobitak'
                     )} '${d.text}'`}</td>
                     <td>{d.value}</td>
                  </tr>
               ))}

               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciIsplate.iznosDobitkaUkupno'
                     )}
                  </td>
                  <td>{iznosDobitkaUkupno}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciIsplate.iznosPoreza'
                     )}
                  </td>
                  <td>{iznosPoreza}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciIsplate.iznosIsplacenIgracu'
                     )}
                  </td>
                  <td>{iznosIsplacenIgracu}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciIsplate.operaterIsplate'
                     )}
                  </td>
                  <td>{operaterIsplate}</td>
               </tr>
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciIsplate.datumiVrijemeIsplate'
                     )}
                  </td>
                  <td>{`${datumIsplate}/${vrijemeIsplate}`}</td>
               </tr>
               {/* <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciIsplate.vrijemeIsplate'
                     )}
                  </td>
                  <td>{vrijemeIsplate}</td>
               </tr> */}
               <tr>
                  <td>
                     {intl.getTranslate(
                        'pregledListica.podaciIsplate.brojTerminalaIsplate'
                     )}
                  </td>
                  <td>{brojTerminalaIsplate}</td>
               </tr>
            </tbody>
         </table>
         <button
            autoFocus
            className='button save mt-3 float-right'
            onClick={onClick}
            type='button'
         >
            {intl.getTranslate('pregledListica.btnZatvoriUplateIsplate')}
         </button>
      </div>
   );
};

export default PodaciIsplata;
