import { prodajnoMjestoService as service } from '../../services/a02-prodajno-mjesto-service';

const initialState = {
   prodajnaMjesta: [],
   isLoading: false
};

export const setIsLoading = () => ({
   type: 'IS_LOADING',
   payload: {
      isLoading: true
   }
});

export const ucitajProdajnaMjesta = () => async (dispatch, getState) => {
   try {
      const { data: response } = await service.list();
      const { prodajnaMjesta, isSuccess } = response;

      const payload = {
         prodajnaMjesta
      };

      dispatch({ type: 'LOAD_PRODAJNA_MJESTA', payload });
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         const payload = {
            error: ex.response.data.Message
         };
      }
   }
};

//  REDUCER
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case 'LOAD_PRODAJNA_MJESTA':
         console.log('action.payload', action.payload);
         return {
            ...state,
            ...action.payload
         };
      default:
         return state;
   }
}
