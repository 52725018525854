import http from './http';
import api from '../config/auth.json';

export const authService = {
   login: (operativniBroj, password) =>
      http.post(`${api.login}`, { operativniBroj, password })
};

export const saveUserData = (data) => {
   sessionStorage.setObject('userdata', data);
};

export const removeUserData = () => {
   sessionStorage.removeItem('userdata');
};

export const getCurrentUser = () => sessionStorage.getObject('userdata');
export const getUserId = () =>
   sessionStorage.getObject('userdata').operativniBroj;
export const getOperaterUpisaObject = () => ({
   operaterUpisa: sessionStorage.getObject('userdata').operativniBroj
});
