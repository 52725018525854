import { menuPregled } from './../components/navbar/submenus/menu-pregled';
import { menuAzuriranja } from './../components/navbar/submenus/menu-azuriranja';
import { filteredPublishSubmenuRoutes } from '../services/app-service';
import { paths } from './routes';

export const initPageRoutes = [
   {
      path: paths.popisLinkovaZaPregled,
      submenuOptions: filteredPublishSubmenuRoutes(menuPregled),
      protected: true
   },
   {
      path: paths.popisLinkovaZaAzuriranje,
      submenuOptions: filteredPublishSubmenuRoutes(menuAzuriranja),
      protected: true
   }
];
