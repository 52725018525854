import http from '../http';
import api from '../../config/SierraLeone/A01-teritorijalna-jedinica.json';
import { getOperaterUpisaObject } from '../auth-service';

export const teritorijalnaJedinicaService = {
   list: (nivo) => http.post(`${api.getMjestaSva}`, { nivo }),
   get: (nivo, sifraMjesta) => http.post(`${api.get}`, { nivo, sifraMjesta }),
   getNivoData: (nivo) => http.post(`${api.getMjestaSva}`, { nivo }),
   addOrUpdate: (teritorijalnaJedinica) =>
      http.post(`${api.post}`, {
         ...teritorijalnaJedinica,
         ...getOperaterUpisaObject()
      }),
   getNazivNadredjenogMjesta: (nivo, sifraNadredjenogMjesta) =>
      http.post(`${api.getNazivNadredjenogMjesta}`, {
         nivo,
         sifraNadredjenogMjesta
      }),
   nadredjenaMjesta: (nivo) => http.post(`${api.getPodaciZaComboBox}`, { nivo })
};
