import { LANGUAGES } from '../languages';

const translationObject = {
   [LANGUAGES.SIERRALEONE]: {
      pozdrav: 'Hello',
      placeholder: 'Web address: {path} :)',
      common: {
         add: 'Add',
         azurirati: 'Edit',
         cancel: 'Cancel',
         confirmTitle: 'Confirm',
         datepicker: 'Chose a date',
         datumUpisa: 'Date',
         ddlNotFound: 'Not finding the required option',
         ddlPlaceholder: 'Start typing or choose an option',
         edit: 'Edit',
         error: 'Error occured :/',
         errorLoading: 'Error loading data',
         greskaPrilikomAzuriranja: 'An error occurred while updating the data',
         inicijalizacijaPodataka: 'Inicijalizacija podataka...',
         loading: 'Loading data...',
         login: 'Login',
         noData: 'No data currently available',
         notFound: '404 - not found',
         notFoundLink: 'Go to HomePage',
         operaterUpisa: 'Enrollment operator',
         podaciUspjesnoAzurirani: 'Data updated successfully',
         podaciUspjesnoSpremljeni: 'Saved successfully',
         pretraga: 'Search',
         pretragaPlaceholder: 'Start entering a term that interests you',
         print: 'Print...',
         printOrientation: 'Print orientation',
         returToHomepage: '< Return to HomePage',
         sumaTrenutnogPrikaza: 'Ukupno za trenutni prikaz',
         sumaSveSkupa: 'Ukupno svi podaci',
         zaUneseniPojam: 'For the entered term',
         nemaRezultataPretrage: 'there are no search results',
         save: 'Save',
         update: 'Update',
         vrijemeUpisa: 'Time',
         warning: 'Warning',
         gubitakPodataka:
            'If you leave this page you will lose the entered data. Do you really want to leave this page?',
         ddl: {
            aktivan: 'Active',
            neaktivan: 'Inactive',
            da: 'Yes',
            ne: 'No',
            agent: 'Agent',
            ['-----']: '-----',
            ['level 1 (agent)']: 'Level 1 (Agent)',
            ['level 2 (pos manager)']: 'Level 2 (POS Manager)',
            ['level 3  (chain)']: 'Level 3  (Chain)',
            ['level 4  (director)']: 'Level 4  (Director)',
            ['operater']: 'Operator',
            ['serviser']: 'Service',
            ['admin']: 'Administrator'
         }
      },
      navigacija: {
         pregled: 'Overview',
         azuriranje: 'Update',
         cachFlow: 'Cach flow',
         keno: 'Keno',
         quick: 'Quick',
         login: 'Login',
         registerUser: 'Register',
         dodajZastupnika: 'Add representative',
         zastupnici: 'Representatives',
         zastupniciAzuriranje: 'Edit',
         zastupniciDodavanje: 'Add',
         pregledListica: 'Ticket list',
         postavke: 'Setup'
      },

      stabloZastupnika: {
         dialogtitle: 'SALES NETWORK OVERVIEW',
         linklabel: 'SALES NETWORK OVERVIEW',
         opbr: 'AGENT/REP ID',
         provizija: 'SALES FEE',
         imePrezime: 'GIVEN NAMES AND SURNAME'
      },
      naseljenaMjesta: {
         dialogtitle: 'Cities overview',
         linklabel: 'Add new city',
         btnAddNew: 'Add new city',
         navigacijaLabel: 'Populated city',
         pretraga: 'Search by place name',
         pretragaPlaceholder: 'Start entering the place name',
         sifraMjesta: 'City code',
         sifraMjestaPlaceholder: 'Enter a city code (e.g. 10000)...',
         sifraMjestaValidator: 'must be a five-digit number',
         nazivMjesta: 'Name of the city',
         nazivMjestaPlaceholder: 'Enter name of the city',
         nazivMjestaValidator: ' is required',
         confirmTitle: 'Updating a city name',
         confirmMessage: 'Update city name',
         addMessage: 'Add city',
         preusmjeravanje: 'Redirecting to list of places...'
      },
      teritorijalnaJedinica: {
         dialogtitle: 'ADD/UPDATE TERRIRORIAL UNIT',
         linklabel: 'ADD/UPDATE TERRIRORIAL UNIT',
         nivo: 'LEVEL',
         tableHint: 'Selecting an option in the table will complete the form',
         sifraMjesta: 'LOCATION ID',
         sifraMjestaPlaceholder: 'Enter location ID [1-99999]',
         sifraMjestaValidator: 'is numerical value [1-99999]',
         sifraNadredjenogMjesta: 'SUPERIOR ID',
         sifraNadredjenogMjestaPlaceholder:
            'Unesite šifra nadređene teritorijalne jedinice [1-99999]',
         sifraNadredjenogMjestaValidator: 'is numerical value [1-99999]',
         nazivMjesta: 'LOCATION LABEL',
         nazivMjestaPlaceholder: 'Enter location label',
         nazivMjestaValidator: 'is required (max 64 chars)',
         confirmMessage: 'Update superior data?',
         nadredjenaTeritorijalnaJedinica: 'SUPERIOR ID'
      },
      opcina: {
         dialogtitle: 'Add county',
         linklabel: 'Add county',
         btnAddNew: 'Add new county',
         confirmDodajTitle: 'Adding county',
         confirmDodajMessage: 'Add county',
         azuriranjeOpcine: 'Updating county',
         pretraga: 'Search by county name',
         pretragaPlaceholder: 'Start entering the name of the county',
         sifraOpcine: 'County code',
         sifraOpcinePlaceholder:
            'Enter county code, number in the range [1-9500]...',
         sifraOpcineValidator: 'is a number in the range [1-9500]',
         nazivOpcine: 'Name of the county',
         nazivOpcinePlaceholder: 'Enter county name',
         nazivOpcineValidator: 'is required',
         confirmTitle: 'Updating the name of the municipality',
         confirmMessage: 'Update county name',
         preusmjeravanje: 'Redirecting to county list...'
      },
      regija: {
         dialogtitle: 'Region list',
         linklabel: 'Add new region',
         azuriranjeRegije: 'Updating region',
         navigacijaLabel: 'Region',
         btnAddNew: 'Add new region',
         pretraga: 'Search by region name',
         pretragaPlaceholder: 'Start entering the region name',
         sifraRegije: 'Region code',
         sifraRegijePlaceholder:
            'Enter region code, number in the range [1-99]...',
         sifraRegijeValidator: 'is required numeric value in range [1-99]',
         nazivRegije: 'Name of the region',
         nazivRegijePlaceholder: 'Enter region name',
         nazivRegijeValidator: 'is required',
         confirmTitle: 'Updating region name',
         confirmMessage: 'Update region name',
         confirmAddingTitle: 'Confirm adding',
         confirmAddingMessage: 'Add region',
         preusmjeravanje: 'Redirecting to region list...'
      },
      terminal: {
         aktivan: 'Active',
         aktivnost: 'TERMINAL ACTIVITY',
         broj: 'TERMINAL ID',
         brojTerminalaPlaceholder: 'Enter the terminal number [1-9999]...',
         brojTerminalaValidator: 'is numeric value in the range [1-9999]',
         confirmMessage: 'Update terminal activity',
         confirmTitle: 'Updateing terminal activity',
         datumUpisa: 'FIRST ACTIVATION DATE',
         naslov: 'Update terminal activity',
         dialogtitle: 'TERMINALS OVERVIEW',
         linklabel: 'TERMINALS OVERVIEW',
         neaktivan: 'Inactive',
         operaterUpisa: 'AGENT OPERATOR',
         pregledTerminalaTitle: 'Terminal overview',
         pretraga: 'SEARCH BY TERMINAL ID',
         pretragaPlaceholder: 'Enter terminal ID here...',
         vrijemeUpisa: 'Time added'
      },
      terminalAktivnost: {
         dialogtitle: 'MANAGE TERMINAL ACTIVITY',
         linklabel: 'MANAGE TERMINAL ACTIVITY',
         addConfirmMessage: 'Save terminal',
         addNew: 'ADD NEW TERMINAL ID',
         addSuccess: 'The terminal was successfully added',
         adresa: 'Address',
         azurirajLokacijuTerminala: 'MANAGE TERMINAL LOCATION',
         brojTerminala: 'Terminal',
         datumPrijaveOdjave: 'Date and time',
         ddlPrikaziTerminale: 'Show',
         doDatuma: 'Date to',
         noData: 'There is no data according to the given parameters',
         odDatuma: 'Date from',
         odjava: 'Inactive',
         operativniBroj: 'Operating number',
         prijava: 'Active',
         printHeader: 'Terminal overview',
         prodajnoMjesto: 'Selling space',
         search: 'Search',
         searchPlaceholder: 'Start entering operating number or name',
         statusPrijaveOdjave: 'Status',
         submitSave: 'Add new terminal',
         submitUpdate: 'Update existing terminal',
         updateConfirmMessage: 'Update terminal activity',
         updateSuccess: 'The terminal was successfully updated',
         vrijeme: 'Time',
         vrijemePrijaveOdjave: 'Time',
         zastupnik: 'Representative',
         filtarPrikaza: {
            0: 'All terminals',
            1: 'Active terminals',
            2: 'Inactive terminals',
            3: 'Terminals not working'
         }
      },
      terminalPripadnost: {
         dialogtitle: 'MANAGE TERMINAL LOCATION',
         linklabel: 'MANAGE TERMINAL LOCATION',
         brojTerminala: 'TERMINAL ID',
         sifraProdajnogMjesta: 'LOCATION ID',
         indikator: 'Indicator',
         potrebanDownload: 'Download required',
         brojTerminalaPlaceholder: 'Numerical value [1-9999]',
         sifraProdajnogMjestaPlaceholder: ' numerical value [0-9999]...',
         brojTerminalaError: ' is required [1-9999]',
         sifraProdajnogMjestaError: ' is required [0-9999]',
         indikatorValidator: 'is required numeric value',
         confirmMessage: 'Update terminal affiliation'
      },
      prodajnaMjesta: {
         adresaProdajnogMjesta: 'ADDRESS',
         adresaProdajnogMjestaPlaceholder: '64 max char',
         adresaProdajnogMjestaValidator: ' 64 chars max',
         aktivnoProdajnoMjesto: 'ACTIVE POS',
         aktivnostProdajnogMjesta: 'Activity',
         aktivnostProdajnogMjestaPlaceholder: 'Numeric value [0-3]',
         aktivnostProdajnogMjestaValidator: 'is in range [0-3]',
         brojTelefon: 'Telefon',
         brojTelefona: 'Telephone number',
         brojTelefonaPlaceholder: 'Numeric value [0-9999999]',
         brojTelefonaValidator: 'is in range [0-9999999]',
         brojTelefonPlaceholder: 'Start with area code in brackets e.g.(+385)',
         brojTelefonValidator: 'max 47 chars',
         brojTerminala: 'TERMINAL ID',
         brojTerminalaPlaceholder: 'Numerical value [0-99]',
         brojTerminalaValidator: 'numerical value [1-9999]',
         btnOdaberiKoordinate: 'CHOOSE MAP LOCATION',
         confirmMessageAdd: 'Dodati prodajnog mjesto?',
         confirmMessageUpdate: 'Ažurirati podatke prodajnog mjesta?',
         geografskaDuzina: 'GPS LONGITUDE (13…W)',
         geografskaDuzinaPlaceholder: 'Enter cordinates',
         geografskaDuzinaValidator: 'numerical value [-180 - 180]',
         geografskaSirina: 'GPS LATITUDE (8…N)',
         geografskaSirinaPlaceholder: 'Enter cordinates',
         geografskaSirinaValidator: ' numerical value [-90 - 90]',
         kategorija: 'CATEGORY',
         kategorijaPlaceholder: 'Numerical value [0-9999999]',
         kategorijaProdajnogMjesta: 'CATEGORY',
         kategorijaProdajnogMjestaPlaceholder: 'Numerical value [0-49]',
         kategorijaProdajnogMjestaValidator: 'numerical value [0-49]',
         kategorijaValidator: 'numerical value [0-9999999]',
         dialogtitle: 'MANAGE POS/SHOPS',
         linklabel: 'MANAGE POS/SHOPS',
         navigacijaLabel: 'MANAGE POS/SHOPS',
         navigacijaLabelDodavanje: 'Dodaj prodajno mjesto',
         nazivProdajnogMjesta: 'BUSINESS TITLE',
         nazivProdajnogMjestaPlaceholder: '64 char max',
         nazivProdajnogMjestaValidator: 'is a maximum length of 64 chars',
         noData: 'Trenutno nema unesenih prodajnih mjesta',
         odaberiProdajnoMjesto: 'SELECT POS',
         odaberiProdajnoMjestoPlaceholder:
            'Enter ID here or pick a dropdown item',
         podnaslovAdd: 'Dodavanje prodajnog mjesta',
         podnaslovEdit: 'Ažuriranje prodajnog mjesta',
         pozivniBrojTelefona: 'Area code',
         pozivniBrojTelefonaPlaceholder: 'Numeric value [0-999]',
         pozivniBrojTelefonaValidator: 'is in range [0-999]',
         sifraNaseljenogMjesta: 'Place code',
         sifraNaseljenogMjestaPlaceholder: 'Numeric value [10000-99999]',
         sifraNaseljenogMjestaValidator: 'is in range [10000-99999]',
         sifraOpcine: 'Šifra općine',
         sifraOpcinePlaceholder: 'Numerical value [0-9500]',
         sifraOpcineValidator: 'numerical value [0-9500]',
         sifraPMwarning: 'Prodajno mjesto sa željenom šifrom već postoji!',
         sifraProdajnogMjesta: 'POS ID',
         sifraProdajnogMjestaPlaceholder: 'Numeric value [1-9999]',
         sifraProdajnogMjestaValidator: 'is required value in range [1-9999]',
         sifraRegije: 'Šifra regije',
         sifraRegijePlaceholder: 'Numerical value [0-99]',
         sifraRegijeValidator: 'numerical value [0-99]',
         sifraTeritorijalneJediniceN1: 'TERRITORIAL UNIT ID',
         sifraTeritorijalneJediniceN1Placeholder: 'Numeric value [0-99999]',
         sifraTeritorijalneJediniceN1Validator: 'is in range [0-99999]',
         telefon: 'PHONE NO.',
         telefonPlaceholder: 'Start with area code in brackets e.g.(+385)',
         telefonValidator: ' max 32 chars',
         tipLokala: 'POS TYPE',
         tipLokalaPlaceholder: 'Numerical value [0-999]',
         tipLokalaValidator: 'numerical value [0-999]',
         titleOdaberiKoordinate: 'Select coordinates by clicking on the map',
         zabranaIsplateBingo: 'SPORTS BETTING PAYOUTS PROHIBITED',
         zabranaIsplateKeno: 'KENO PAYOUTS PROHIBITED',
         zabranaProdajeBingo: 'SPORTS BETTING SALES PROHIBITED',
         zabranaProdajeKeno: 'KENO SALES PROHIBITED',
         openstreetInfoCity: 'CITY',
         openstreetInfoAddress: 'ADDRESS',
         saveUpdate: 'Update existing pos/shop',
         saveAdd: 'Save new pos/shop'
      },
      pregledKenoListica: {
         adresaProdajnogMjestaIsplate: 'Adresa prodajnog mjesta isplate',
         adresaProdajnogMjestaUplate: 'Adresa prodajnog mjesta uplate',
         brojListica: 'TICKET NO.',
         brojListicaPlaceholder: 'Numerical value [1-99999]',
         brojListicaValidator: 'is in range [1-99999]',
         btnPrikazKenoListica: 'Prikaži',
         datumIsplate: 'Datum isplate',
         datumStorniranja: 'Datum storniranja',
         datumUplate: 'Datum uplate',
         dobitniBrojevi: 'Dobitni brojevi',
         imePrezimeIsplate: 'Ime i prezime isplate',
         imePrezimeUplate: 'Ime i prezime uplate',
         iznosDobitka: 'Dobitak',
         iznosIsplate: 'Isplata',
         iznosJackpota: 'Jackpot',
         iznosPoreza: 'Porez',
         iznosUplate: 'Uplata',
         kolo: 'DRAW NO.',
         koloPlaceholder: 'Numerical value [1-9999999]',
         koloValidator: 'is in range [1-9999999]',
         mjestoProdajnogMjestaIsplate: 'Mjesto isplate',
         mjestoProdajnogMjestaUplate: 'Mjesto uplate',
         dialogtitle: 'KENO – SINGLE TICKET OVERVIEW',
         linklabel: 'KENO – SINGLE TICKET OVERVIEW',
         nazivProdajnogMjestaIsplate: 'Prodajno mjesto isplate',
         nazivProdajnogMjestaUplate: 'Prodajno mjesto uplate',
         odigraniBrojevi: 'Odigrani brojevi',
         operativniBrojIsplate: 'OPB isplate',
         operativniBrojUplate: 'OPB uplate',
         h2OdigraniBrojevi: 'Played numbers',
         h2DobitniBrojevi: 'Winning numbers',
         sifraProdajnogMjestaIsplate: 'Šifra prodajnog mjesta isplate',
         sifraProdajnogMjestaUplate: 'Šifra prodajnog mjesta uplate',
         vrijemeIsplate: 'Vrijeme isplate',
         vrijemeStorniranja: 'Vriejme storniranja',
         vrijemeUplate: 'Vrijeme uplate'
      },
      pregledSvihKenoListica: {
         closeData: 'Close',
         confirmTitle:
            'View all Keno tickets according to the selected filters',
         doDatuma: 'END-DATE',
         doDatumaPlaceholder: 'Choose date',
         doDatumaValidator: 'must be in date format',
         doKola: 'END-DRAW',
         doKolaPlaceholder: 'Numerical value 0-999999999',
         doKolaValidator: 'numerical value [0-999999999]',
         doProdajnogMjesta: 'END-POS.ID',
         doProdajnogMjestaPlaceholder: 'Numerical value 0-999999',
         doProdajnogMjestaValidator: 'numerical value [0-999999]',
         doProdavaca: 'END-AGENT.ID',
         doProdavacaPlaceholder: 'Numerical value 0-999999',
         doProdavacaValidator: 'numerical value [0-99999]',
         doVremena: 'END-TIME',
         doVremenaPlaceholder: 'Time field',
         doVremenaValidator: 'must be in time format HH:MM:SS',
         dialogtitle: 'KENO – ALL TICKETS OVERVIEW',
         linklabel: 'KENO – ALL TICKETS OVERVIEW',
         noDataMessage: 'No data according to the selected filters.',
         odDatuma: 'START-DATE',
         odDatumaPlaceholder: 'Choose date',
         odDatumaValidator: 'must be in date format',
         odKola: 'START-DRAW',
         odKolaPlaceholder: 'Numerical value 0-999999',
         odKolaValidator: 'numerical value [0-999999999]',
         odProdajnogMjesta: 'START-POS.ID',
         odProdajnogMjestaPlaceholder: 'Numerical value 0-999999',
         odProdajnogMjestaValidator: 'numerical value [0-999999]',
         odProdavaca: 'START-AGENT.ID',
         odProdavacaPlaceholder: 'Numerical value 0-999999',
         odProdavacaValidator: 'numerical value [0-99999]',
         odVremena: 'START-TIME',
         odVremenaPlaceholder: 'Time field',
         odVremenaValidator: 'must be in time format HH:MM:SS',
         pretraga: 'Search',
         pretragaPlaceholder: 'Započnite unos kola ili prodavača',
         statusListica: 'TICKET STATUS',
         dataTable: {
            kolo: 'Kolo',
            operativniBrojUplate: 'OPBRU',
            operativniBrojIsplate: 'OPBRI',
            imePrezimeUplate: 'Prodavač',
            brojlistica: 'Broj listića',
            sifraProdajnogMjestaUplate: 'Prodajno mjesto uplate',
            sifraProdajnogMjestaIsp: 'Prodajno mjesto isplate',
            iznosUplate: 'Uplata',
            iznosDobitka: 'Dobitak',
            iznosIsplate: 'Isplata',
            iznosJackpota: 'Jackpot',
            iznosPoreza: 'Porez',
            datumUplate: 'Datum i vrijeme uplate',
            vrijemeUplate: 'Vrijeme uplate',
            datumIsplate: 'Datum i vrijeme isplate',
            vrijemeIsplate: 'Vrijeme isplate',
            odigraniBrojevi: 'Odigrani brojevi'
         }
      },
      pregledKolaPoDanu: {
         dialogtitle: 'KENO – DRAWS PER DAY OVERVIEW',
         linklabel: 'KENO – DRAWS PER DAY OVERVIEW',
         datum: 'Date',
         pretraga: 'SEARCH BY DRAW NO.',
         pretragaPlaceholder: 'Enter draw no. here...',
         noDataMessage: 'No data',
         dataTable: {
            datumPocetkaIzvlacenja: 'DRAW START-DATE',
            datumPocetkaKola: 'DRAW START-DATE',
            kolo: 'DRAW NO.',
            vrijemePocetkaIzvlacenja: 'DRAW START-TIME',
            vrijemePocetkaKola: 'DRAW START-TIME'
         }
      },
      pregledListica: {
         brojListica: 'Broj listića',
         brojListicaPlaceholder: 'Brojčana vrijednost [1-9999]',
         brojListicaValidator: 'is in range [1-9999]',
         btnListic: 'Listić',
         btnPrikaziIsplate: 'Prikaži isplate',
         btnPrikaziUplate: 'Prikaži uplate',
         btnSlog: 'Slog',
         btnZatvoriUplateIsplate: 'Zatvori',
         dan: 'Dan',
         danPlaceholder: 'Brojčana vrijednost [1-7]',
         danValidator: 'is in range [1-7]',
         godina: 'Godina',
         godinaPlaceholder: 'Unesite godinu',
         godinaValidator: 'je brojčana vrijednost',
         kombinacija: 'Kombinacija',
         dialogtitle: 'Pregled listića',
         linklabel: 'Listići',
         operativniBroj: 'Operativni broj',
         operativniBrojPlaceholder: 'Brojčana vrijednost [1-9999]',
         operativniBrojValidator: 'is in range [1-9999]',
         sedmica: 'Sedmica',
         sedmicaPlaceholder: 'Brojčana vrijednost [1-53]',
         sedmicaValidator: 'is in range [1-53]',
         slog: 'Slog',
         slogPlaceholder: 'Unesite broj...',
         slogValidator: 'je broj',
         podaciUplate: {
            naslov: 'Podaci uplate',
            operativniBroj: 'Operativni broj',
            godinaSedmicaDanBrojListica: 'Sedmica/dan/broj listića',
            prodajnoMjesto: 'Prodajno mjesto',
            brojTerminala: 'Broj terminala',
            sifraIgre: 'Šifra igre',
            kodIgre: 'Kod igre',
            kolo: 'Kolo igre listića',
            pretplata: 'Pretplata za kola',
            indikatorStorna: 'Indikator storna/Operater',
            brojKombinacija: 'Broj kombinacija',
            ukupnaUplata: 'Ukupna uplata',
            vrijemeUplateNaTerminalu: 'Vrijeme uplate na terminalu',
            vrijemeUplateNaServeru: 'Vrijeme spremanja',
            vrijemeStorna: 'Vrijeme storna',
            brojBingoListica: 'Broj Bingo listića'
         },
         podaciIsplate: {
            naslov: 'Podaci isplate',
            igra: 'Igra',
            indikatorDobitka: 'Indikator dobitka',
            indikatorZastare: 'Indikator zastare',
            dobici: 'Dobici',
            dobitak: 'Dobitak',
            iznosDobitkaUkupno: 'Ukupan iznos dobitaka',
            iznosPoreza: 'Iznos poreza',
            iznosIsplacenIgracu: 'Iznos dobitka isplaćen igraču',
            operaterIsplate: 'Operater isplate',
            datumiVrijemeIsplate: 'Datum i vrijeme isplate',
            brojTerminalaIsplate: 'Broj terminala isplate'
         }
      },
      pregledKumulativa: {
         brojKombinacija: 'Broj kombinacija',
         brojListica: 'Broj listića',
         filter: 'Filter prikaza',
         igra: 'Igra',
         isplata: 'Isplata',
         dialogtitle: 'FINANCIAL REPORTS OVERVIEW',
         linklabel: 'FINANCIAL REPORTS OVERVIEW',
         nepostojeciOPBR: 'Za traženi operativni broj zastupnika nema podataka',
         operativniBroj: 'Operativni broj zastupnika',
         operativniBrojPlaceholder: 'Brojčana vrijednost [0-9999]',
         operativniBrojValidator: 'je broj u rasponu [0-9999]',
         storno: 'Storniranih listića',
         uplata: 'Uplata',
         termin: {
            sve: 'Sve',
            ponedjeljak: 'Ponedjeljak',
            utorak: 'Utorak',
            srijeda: 'Srijeda',
            četvrtak: 'Četvrtak',
            petak: 'Petak',
            subota: 'Subota',
            nedjelja: 'Nedjelja',
            tekucaSedmica: 'Tekuća sedmica',
            tekuciObracun: 'Tekući obračun',
            tekuceKolo: 'Tekuće kolo',
            prethodnaSedmica: 'Prethodna sedmica',
            prethodniObracun: 'Prethodni obračun',
            prethodnoKolo: 'Prethodno kolo'
         }
      },

      kenoKumulativiSE: {
         datum: 'Datum',
         dobitnihListica: 'Dobitnih',
         doDatuma: 'END-DATE',
         display: 'SHOW SUMMARY OR ALL DATA',
         imePrezime: 'Ime i prezime',
         isplacenoListica: 'Isplaćenih',
         iznosDobitka: 'Dobitak',
         iznosIsplate: 'Isplaćen iznos',
         iznosJackpota: 'Jackpot',
         iznosUplate: 'Uplaćen iznos',
         nadnadredjeni: 'Nadnadređeni',
         nadredjeni: 'Nadređeni',
         odDatuma: 'START-DATE',
         operativniBroj: 'OPBR',
         search: 'Filtriraj podatke',
         searchPlaceholder: 'Filtriraj prema imenu i prezimenu ili OPBR-u',
         searchSummary: 'Search',
         searchSummaryPlaceholder:
            'Pretraži po imenu i prezimenu ili po OPBR-u',
         storniranoListica: 'Stornirano',
         showSummaryData: 'Show a summary of the data',
         showAllData: 'Show all data',
         uplacenoListica: 'Uplaćeno listića',
         uplata: 'Payment',
         zastupnik: 'AGENT ID',
         zastupnikPlaceholder: 'Enter ID here or pick a dropdown item'
      },

      zastupnici: {
         dialogtitleoverview: 'AGENTS OVERVIEW',
         linklabeloverview: 'AGENTS OVERVIEW',
         dialogtitlemanage: 'ADD AGENT',
         linklabelmanage: 'ADD AGENT',
         adresaProdajnogMjesta: 'POS ADDRESS',
         adresaProdajnogMjestaPlaceholder: ' ',
         additionalFilter: 'AGENT ACTIVITY',
         aktivnostZastupnika: 'AGENT ACTIVITY',
         brojTerminalaZaduzen: 'ASSIGNED TERMINAL ID',
         brojTerminalaZaduzenPlaceholder: ' ',
         brojTerminalaZaduzenValidator: ' numerical value [0 - 99999]',
         brojTerminala: 'Broj terminala',
         confirmMessage: 'Dodati zastupnika',
         confirmTitle: 'Dodavanje zastupnika',
         datumPrijave: 'Datum prijave',
         datumRodjenja: 'DATE OF BIRTH',
         datumUpisa: 'Datum upisa',
         depozit: 'DEPOSIT',
         depozitPlaceholder: 'Enter deposit...',
         depozitValidator: '...',
         detaljiZastupnikaTitle: 'AGENT DETAILS',
         dodajNovog: 'ADD NEW AGENT',
         gradProdajnogMjesta: 'POS TOWN',
         gradProdajnogMjestaPlaceholder: ' ',
         grupaKorisnika: 'GROUP',
         grupaKorisnikaPlaceholder: 'Enter a group...',
         grupaKorisnikaValidator: ' numerical value [0-50]',
         igra: 'Igra',
         punoIme: 'GIVEN NAMES AND SURNAME',
         ime: 'GIVEN NAMES',
         imeNadredjenogZastupnika: 'Ime nadređenog zastupnika',
         imeNadredjenogZastupnikaPlaceholder: ' ',
         imePlaceholder: 'Enter given names',
         imePrezimeNadredjenogZastupnika: 'SUBORDINATE TO',
         imeValidator: ' required, max 31 char',
         kategorija: 'CATEGORY',
         kategorijaPlaceholder: '1-9999',
         kategorijaValidator: ' numerical value [1-9999]',
         napomena: 'Napomena',
         napomenaPlaceholder: ' ',
         nazivProdajnogMjesta: 'Opis prodajnog mjesta',
         nazivProdajnogMjestaPlaceholder: ' ',
         opbrWarning: 'Agent with given ID already exsists',
         operaterUpisa: 'Operater upisa',
         operativniBrojNadredjenogZastupnika: 'SUPERIOR ID',
         operativniBrojNadredjenogZastupnikaPlaceholder:
            'Unesite operativni broj nadređenog zastupnika',
         operativniBrojNadredjenogZastupnikaTH: 'OPB nadređenog zastupnika',
         operativniBrojNadredjenogZastupnikaValidator: ' is numerical value',
         operativniBrojZastupnika: 'AGENT ID',
         operativniBrojZastupnikaAkronim: 'ID (AGENT)',
         operativniBrojZastupnikaPlaceholder: '1-9999',
         operativniBrojZastupnikaValidator: ' [1-9999]',
         osobniID: 'NIN',
         osobniIDPlaceholder: ' ',
         osobniIDValidator: ' max 14 chars',
         password: 'PASSWORD',
         passwordPlaceholder: 'Enter password',
         passwordValidator: '  numerical value [1-999999]',
         postotakProvizije: 'Sales fee',
         postotakProvizijeBingo: 'SPORTS BETTING SALES FEE',
         postotakProvizijeBingoPlaceholder: 'Enter Bingo sales fee',
         postotakProvizijeBingoValidator: ' numerical value [0-9999]',
         postotakProvizijeKeno: 'KENO SALES FEE',
         postotakProvizijeKenoPlaceholder: 'Enter Keno sales fee',
         postotakProvizijeKenoValidator: ' numerical value [0-9999]',
         postotakProvizijePlaceholder: 'Enter sales fee',
         postotakProvizijeValidator: ' numerical value [1-9999]',
         pravoIsplateBingo: 'SPORTS BETTING PAYOUT RIGHTS',
         pravoIsplateKeno: 'KENO PAYOUT RIGHTS',
         pravoIsplateVelikihDobitaka: 'TAXABLE / HIGH TIER PAYOUT RIGHTS',
         pravoUplateBingo: 'SPORTS BETTING RIGHTS',
         pravoUplateBingoIsplateNotFoundPlaceholder: ' ',
         pravoUplateBingoIsplatePlaceholder: ' ',
         pravoUplateKeno: 'KENO SALES RIGHTS',
         praznaLista: 'No data',
         pretraga: 'SEARCH BY AGENT ID',
         pretragaPlaceholder: 'Enter agent Id here',
         preusmjeravanje: 'Redirecting...',
         prezime: 'SURNAME',
         prezimeIme: 'NAME AND SURNAME',
         prezimeImeNadredjenogZastupnika: 'Prezime i ime nadređenog zastupnika',
         prezimeImeNadredjenogZastupnikaPlaceholder: ' ',
         prezimeImeNadredjenogZastupnikaValidator: ' je obavezan unos',
         prezimeImePlaceholder: 'Enter surname',
         prezimeImeValidator: ' required (max 21 caharacters)',
         prezimeNadredjenogZastupnika: 'Prezime nadređenog zastupnika',
         prezimeNadredjenogZastupnikaPlaceholder: ' ',
         prezimePlaceholder: 'Enter surname',
         prezimeValidator: ' required, max 31 char',
         sifraProdajnogMjesta: 'POS',
         sifraProdajnogMjestaPlaceholder: 'Enter POS',
         sifraProdajnogMjestaValidator: ' numerical value [1-9999]',
         skracenoPrezimeIme: 'SHORT NAME',
         skracenoPrezimeImePlaceholder: 'Enter short name',
         skracenoPrezimeImeValidator: ' max 31 char',
         telefon: 'PHONE',
         telefonPlaceholder: '123-4567',
         telefonValidator: ' max 31 char',
         tipNadredjenogZastupnika: 'SUPERIOD ID TYPE',
         tipZastupnika: 'ID LEVEL',
         title: 'MR. / MS.',
         titlePlaceholder: ' ',
         titleValidator: 'Max 7 chars',
         uredjivanjeTitle: 'AGENT UPDATE',
         vrijemePrijave: 'Vrijeme prijave',
         vrijemeUpisa: 'Vrijeme zadnjeg upisa',
         vrijemeZadnjeOdjave: 'Vrijeme zadnje odjave',
         vrijemeZadnjePrijave: 'Vrijeme zadnje prijave'
      },
      azuriranjeProvizije: {
         confirmMessage: 'Ažurirati postotak provizije?',
         imePrezime: 'Name and surname',
         nadredjeni: 'Superior agent',
         dialogtitle: 'UPDATE SALES FEES',
         linklabel: 'UPDATE SALES FEES',
         navigacijaLabel: 'Azuriranje provizije',
         nemaPodatakaZaDatum: 'No data for chosen date',
         operativniBroj: 'Agent ID',
         operativniBrojPlaceholder: 'Enter agent ID',
         operativniBrojValidator: ' is numerical value [1-999999]',
         postotakProvizije: 'Commission percentage',
         postotakProvizijeKeno: 'Novi postotak provizije',
         postotakProvizijeKenoPlaceholder: 'Unesite postotak',
         postotakProvizijeKenoValidator: ' is required',
         pregledPlaceholder: 'CHOOSE A DATE',
         pregledZaDatum: 'SALES FEE UPDATES BY DATE',
         noData: 'No data',
         zastupnik: 'AGENT ID',
         pregled: {
            operativniBroj: 'AGENT ID',
            zastupnik: 'AGENT',
            novaProvizija: 'NEW COMMISSION',
            staraProvizija: 'OLD COMMISSION',
            operater: 'AGENT OPERATOR',
            vrijeme: 'TIME'
         }
      },
      azuriranjeNadredjenog: {
         confirmMessage: 'Ažurirati nadređenog zastupnika?',
         imePrezime: 'Ime i prezime',
         nadredjeni: 'Nadređeni',
         nadredjeniZastupnik: 'SUPERIOR ID',
         dialogtitle: 'UPDATE POS SUPERIOR MANAGERS',
         linklabel: 'UPDATE POS SUPERIOR MANAGERS',
         navigacijaLabel: 'UPDATE POS SUPERIOR MANAGERS',
         noData: 'No data',
         nemaPodatakaZaDatum: 'No data for chosen date',
         operativniBroj: 'Operativni broj',
         operativniBrojNadredjenog: 'Operativni broj',
         operativniBrojNadredjenogPlaceholder: 'Enter agent ID',
         operativniBrojNadredjenogValidator: ' is numerical value [1-999999]',
         operativniBrojPlaceholder: 'Enter agent ID',
         operativniBrojValidator: ' is numerical value [1-999999]',
         postotakProvizije: 'Commission percentage',
         pregledPlaceholder: 'Choose a date',
         pregledZaDatum: 'SUPERIOR AGENT ID UPDATE BY DATE',
         zastupnik: 'AGENT ID',
         pregled: {
            operativniBroj: 'AGENT ID',
            zastupnik: 'AGENT NAME',
            opbrNadredjenogStari: 'OLD AGENT ID',
            opbrNadredjenogNovi: 'NEW AGENT ID',
            operater: 'AGENT OPERATOR',
            vrijeme: 'TIME'
         }
      },
      azuriranjeObavijesti: {
         dialogtitle: 'UPDATE POS NOTIFICATION',
         linklabel: 'UPDATE POS NOTIFICATION',
         navigacijaLabel: 'UPDATE POS NOTIFICATION',
         redakLabel: 'Redak',
         redakLabelPlaceholder: ' ',
         confirmMessage: 'Update notifications'
      },
      updating: {
         azuriranjeMjesta: {
            sifraMjesta: '10000',
            telefon: '1234567',
            nazivMjesta: 'Zagreb'
         }
      },
      login: {
         operativniBroj: 'AGENT ID',
         operativniBrojPlaceholder: ' ',
         operativniBrojRequiredValidator: 'is required numerical value',
         operativniBrojEmailErrorValidator: 'Wrong e-mail address',
         password: 'PASSWORD',
         passwordPlaceholder: ' ',
         passwordValidator: 'is required',
         invalidAgentId: 'Invalid AGENT ID',
         invalidPassword: 'Invalid password or access denied',
         naslov: 'Log in',
         btnLogin: 'Sign in'
      },
      registerUser: {
         avatarHeader: 'Odabir avatara',
         avatarOdabirSlike: 'Odaberite sliku',
         name: 'Ime',
         password: 'Lozinka',
         passwordPlaceholder: 'Unesite e-mail adresu...',
         registerButton: 'Registriraj se',
         registerUserHeader: 'Registracija novog korisnika',
         username: 'Korisničko ime'
      },
      settings: {
         choseLanguage: 'Jezik',
         croatian: 'HRVATSKI',
         darkTheme: 'DARK',
         english: 'ENGLESKI',
         lightTheme: 'LIGHT',
         themeLabel: 'THEME'
      }
   }
};
export default translationObject;
