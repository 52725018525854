import UrediProdajnoMjesto from '../components/a02-uredjivanje-prodajnog-mjesta/uredi-prodajno-mjesto';
import PregledTerminala from '../components/a10-terminali/pregled-terminala';
import UrediPripadnostTerminala from '../components/a10-terminali/uredi-pripadnost-terminala';
import PregledListica from '../components/a16-pregled-listica/pregled-listica';
import PregledKumulativa from '../components/a20-pregled-kumulativa/pregled-kumulativa';
import AktivnostTerminala from '../components/a21-pregled-aktivnosti-terminala/aktivnost-terminala';
import AzuriranjeObavijesti from '../components/azuriranje-obavijesti/azuriranje-obavijesti';
import DodajMjesto from '../components/m-o-r/dodaj-mjesto';
import DodajOpcinu from '../components/m-o-r/dodaj-opcinu';
import DodajRegiju from '../components/m-o-r/dodaj-regiju';
import PregledMjesta from '../components/m-o-r/pregled-mjesta';
import DodajZastupnika from '../components/zastupnici/dodaj-zastupnika';
import DodajZastupnikaSierraLeone from '../components/SierraLeone/A03/dodaj-zastupnika';
import PregledZastupnika from '../components/SierraLeone/A03/pregled-zastupnika';
import PregledOpcina from './../components/m-o-r/pregled-opcina';
import PregledRegija from './../components/m-o-r/pregled-regija';
import Login from '../components/login/login';
import { publishVersions } from './../services/app-service';
import AzuriranjeTeritorijalneJedinice from '../components/SierraLeone/A01/azuriranje-teritorijalne-jedinice';
import AzuriranjeProdajnogMjestaSierraLeone from '../components/SierraLeone/A02/azuriranje-prodajnog-mjesta';
import PregledKenoListica from '../components/SierraLeone/KE03/pregled-keno-listica';
import PregledSvihKenoListica from './../components/SierraLeone/KE05/pregled-svih-keno-listica';
import PregledKolaPoDanu from './../components/SierraLeone/KE10/pregled-kola-po-danu';
import AzuriranjeProvizije from '../components/SierraLeone/A03POSTO/azuriranje-provizije';
import AzuriranjeNadredjenog from '../components/SierraLeone/A03NADREDJENI/azuriranje-nadredjenog';
import UrediAktivnostTerminal from '../components/a09-terminali/upravljanje-terminalima';
import StabloZastupnika from '../components/SierraLeone/A03/stablo-zastupnika';
import KenoKumulativiSL from './../components/SierraLeone/KE06/keno-kumulativi';

export const paths = {
   login: '/login',
   popisLinkovaZaAzuriranje: '/update-links',
   popisLinkovaZaPregled: '/overview-links',
   manage_updatePosSuperiorManagers: {
      path: '/update-pos-superior-managers',
      dialogtitle: 'azuriranjeNadredjenog.dialogtitle',
      linklabel: 'azuriranjeNadredjenog.linklabel'
   },
   manage_updatePosNotofocation: {
      path: '/update-pos-notofocation',
      dialogtitle: 'azuriranjeObavijesti.dialogtitle',
      linklabel: 'azuriranjeObavijesti.linklabel'
   },
   manage_posShops: {
      path: '/manage-pos-shops',
      dialogtitle: 'prodajnaMjesta.dialogtitle',
      linklabel: 'prodajnaMjesta.linklabel'
   },
   manage_updateSalesFees: {
      path: '/update-sales-fees',
      dialogtitle: 'azuriranjeProvizije.dialogtitle',
      linklabel: 'azuriranjeProvizije.linklabel'
   },
   manage_addUpdateTerritorialUnit: {
      path: '/add-update-territorial-unit',
      dialogtitle: 'teritorijalnaJedinica.dialogtitle',
      linklabel: 'teritorijalnaJedinica.linklabel'
   },
   manage_Region: {
      path: '/add-region',
      dialogtitle: 'regija.dialogtitle',
      linklabel: 'regija.linklabel'
   },
   manage_City: {
      path: '/add-populated-area',
      dialogtitle: 'naseljenaMjesta.dialogtitle',
      linklabel: 'naseljenaMjesta.linklabel'
   },
   manage_county: {
      path: '/add-county',
      dialogtitle: 'opcina.dialogtitle',
      linklabel: 'opcina.linklabel'
   },
   manage_addAgent: {
      path: '/add-agent',
      dialogtitle: 'zastupnici.dialogtitlemanage',
      linklabel: 'zastupnici.linklabelmanage'
   },
   manage_terminalLocation: {
      path: '/manage-terminal-location/:brojTerminala?',
      dialogtitle: 'terminalPripadnost.dialogtitle',
      linklabel: 'terminalPripadnost.linklabel'
   },
   manage_manageTerminalActivityForm: {
      path: '/manage-terminal-activity-form',
      dialogtitle: 'terminalAktivnost.dialogtitle',
      linklabel: 'terminalAktivnost.linklabel'
   },
   overview_terminals: {
      path: '/terminals-overview',
      dialogtitle: 'terminal.dialogtitle',
      linklabel: 'terminal.linklabel'
   },
   overview_kenoSingleTicket: {
      path: '/keno-single-ticket-overview',
      dialogtitle: 'pregledKenoListica.dialogtitle',
      linklabel: 'pregledKenoListica.linklabel'
   },
   overview_kenoDrawsPerDay: {
      path: '/keno-draws-per-day-overview',
      dialogtitle: 'pregledKolaPoDanu.dialogtitle',
      linklabel: 'pregledKolaPoDanu.linklabel'
   },
   overview_financialReports: {
      path: '/financial-reports-overview',
      dialogtitle: 'pregledKumulativa.dialogtitle',
      linklabel: 'pregledKumulativa.linklabel'
   },
   overview_tickets: {
      path: '/tickets-overview',
      dialogtitle: 'pregledListica.dialogtitle',
      linklabel: 'pregledListica.linklabel'
   },
   overview_Cities: {
      path: '/populated-areas',
      dialogtitle: 'naseljenaMjesta.dialogtitle',
      linklabel: 'naseljenaMjesta.linklabel'
   },
   overview_County: {
      path: '/county-list',
      dialogtitle: 'opcina.dialogtitle',
      linklabel: 'opcina.linklabel'
   },
   overview_Region: {
      path: '/region-list',
      dialogtitle: 'regija.dialogtitle',
      linklabel: 'regija.linklabel'
   },
   overview_kenoAllTickets: {
      path: '/keno-all-tickets-overview',
      dialogtitle: 'pregledSvihKenoListica.dialogtitle',
      linklabel: 'pregledSvihKenoListica.linklabel'
   },
   overview_manageTerminalActivity: {
      path: '/manage-terminal-activity',
      dialogtitle: 'terminalAktivnost.dialogtitle',
      linklabel: 'terminalAktivnost.linklabel'
   },
   overview_agents: {
      path: '/agents-overview',
      dialogtitle: 'zastupnici.dialogtitleoverview',
      linklabel: 'zastupnici.linklabeloverview'
   },
   overview_salesNetwork: {
      path: '/sales-network-overview',
      dialogtitle: 'stabloZastupnika.dialogtitle',
      linklabel: 'stabloZastupnika.linklabel'
   }
};

/*
    {
        path: '/representatives-overview',
        component: [
            {
                title: 'zastupnici.listaZastupnika',
                name: <PregledZastupnika />,
                publishVersion: publishVersions.BiH
            },
            {
                title: 'terminalAktivnost.naslov',
                name: <PregledTerminala />,
                publishVersion: publishVersions.SierraLeone
            }
        ],
        protected: true
    },
*/

export const pageRoutes = [
   {
      path: paths.login,
      component: [
         {
            title: 'login.naslov',
            name: <Login />
         }
      ],
      style: { width: '50rem' },
      protected: false
   },
   {
      path: paths.overview_agents.path,
      component: [
         {
            title: paths.overview_agents.dialogtitle,
            name: <PregledZastupnika />
         }
      ],
      protected: true
   },
   {
      path: paths.manage_addUpdateTerritorialUnit.path,
      component: [
         {
            title: paths.manage_addUpdateTerritorialUnit.dialogtitle,
            name: <AzuriranjeTeritorijalneJedinice />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_manageTerminalActivity.path,
      component: [
         {
            title: paths.overview_manageTerminalActivity.dialogtitle,
            additionalTitleLink: {
               label: paths.overview_terminals.linklabel,
               path: paths.overview_terminals.path
            },
            name: <PregledTerminala />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_terminals.path,
      component: [
         {
            title: paths.overview_terminals.dialogtitle,
            additionalTitleLink: {
               label: paths.overview_manageTerminalActivity.linklabel,
               path: paths.overview_manageTerminalActivity.path
            },
            name: <AktivnostTerminala />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_salesNetwork.path,
      component: [
         {
            title: paths.overview_salesNetwork.dialogtitle,
            name: <StabloZastupnika />
         }
      ],
      protected: true
   },
   {
      path: paths.manage_manageTerminalActivityForm.path,
      component: [
         {
            title: paths.manage_manageTerminalActivityForm.dialogtitle,
            additionalTitleLink: {
               label: paths.overview_manageTerminalActivity.linklabel,
               path: paths.overview_manageTerminalActivity.path
            },
            name: <UrediAktivnostTerminal />
         }
      ],
      protected: true
   },
   {
      path: paths.manage_addAgent.path,
      component: [
         {
            title: paths.manage_addAgent.dialogtitle,
            name: <DodajZastupnika />,
            publishVersion: publishVersions.BiH
         },
         {
            title: paths.manage_addAgent.dialogtitle,
            additionalTitleLink: {
               label: paths.overview_agents.linklabel,
               path: paths.overview_agents.path
            },
            name: <DodajZastupnikaSierraLeone />,
            publishVersion: publishVersions.SierraLeone
         }
      ],
      style: { width: '140rem' },
      protected: true
   },
   {
      path: paths.manage_posShops.path,
      component: [
         {
            title: paths.manage_posShops.dialogtitle,
            name: <UrediProdajnoMjesto />,
            publishVersion: publishVersions.BiH
         },
         {
            title: paths.manage_posShops.dialogtitle,
            name: <AzuriranjeProdajnogMjestaSierraLeone />,
            publishVersion: publishVersions.SierraLeone
         }
      ],
      protected: true
   },
   {
      path: paths.manage_updateSalesFees.path,
      component: [
         {
            title: paths.manage_updateSalesFees.dialogtitle,
            additionalTitleLink: {
               label: paths.manage_updatePosSuperiorManagers.linklabel,
               path: paths.manage_updatePosSuperiorManagers.path
            },
            name: <AzuriranjeProvizije />
         }
      ],
      protected: true
   },
   {
      path: paths.manage_updatePosSuperiorManagers.path,
      component: [
         {
            title: paths.manage_updatePosSuperiorManagers.dialogtitle,
            additionalTitleLink: {
               label: paths.manage_updateSalesFees.linklabel,
               path: paths.manage_updateSalesFees.path
            },
            name: <AzuriranjeNadredjenog />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_Cities.path,
      component: [
         {
            title: paths.overview_Cities.dialogtitle,
            name: <PregledMjesta />
         }
      ],
      protected: true
   },
   {
      path: paths.manage_City.path,
      component: [
         {
            title: paths.manage_City.dialogtitle,
            name: <DodajMjesto />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_County.path,
      component: [
         {
            title: paths.overview_County.dialogtitle,
            name: <PregledOpcina />
         }
      ],
      protected: true
   },
   {
      path: paths.manage_county.path,
      component: [
         {
            title: paths.manage_county.dialogtitle,
            name: <DodajOpcinu />
         }
      ],
      protected: true
   },
   {
      path: paths.manage_Region.path,
      component: [
         {
            title: paths.manage_Region.dialogtitle,
            name: <DodajRegiju />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_Region.path,
      component: [
         {
            title: paths.overview_Region.dialogtitle,
            name: <PregledRegija />
         }
      ],
      protected: true
   },
   {
      path: paths.manage_terminalLocation.path,
      component: [
         {
            title: paths.manage_terminalLocation.dialogtitle,
            additionalTitleLink: {
               label: paths.overview_terminals.linklabel,
               path: paths.overview_terminals.path
            },
            name: <UrediPripadnostTerminala />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_tickets.path,
      component: [
         {
            title: paths.overview_tickets.dialogtitle,
            name: <PregledListica />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_kenoSingleTicket.path,
      component: [
         {
            title: paths.overview_kenoSingleTicket.dialogtitle,
            additionalTitleLink: {
               label: paths.overview_kenoAllTickets.linklabel,
               path: paths.overview_kenoAllTickets.path
            },
            name: <PregledKenoListica />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_kenoAllTickets.path,
      component: [
         {
            title: paths.overview_kenoAllTickets.dialogtitle,
            additionalTitleLink: {
               label: paths.overview_kenoSingleTicket.linklabel,
               path: paths.overview_kenoSingleTicket.path
            },
            name: <PregledSvihKenoListica />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_kenoDrawsPerDay.path,
      component: [
         {
            title: paths.overview_kenoDrawsPerDay.dialogtitle,
            additionalTitleLink: {
               label: paths.overview_kenoAllTickets.linklabel,
               path: paths.overview_kenoAllTickets.path
            },
            name: <PregledKolaPoDanu />
         }
      ],
      protected: true
   },
   {
      path: paths.overview_financialReports.path,
      component: [
         {
            title: paths.overview_financialReports.dialogtitle,
            name: <PregledKumulativa />,
            publishVersion: publishVersions.BiH
         },
         {
            title: paths.overview_financialReports.dialogtitle,
            name: <KenoKumulativiSL />,
            publishVersion: publishVersions.SierraLeone
         }
      ],
      style: { width: '150rem' },
      protected: true
   },
   {
      path: paths.manage_updatePosNotofocation.path,
      component: [
         {
            title: paths.manage_updatePosNotofocation.dialogtitle,
            name: <AzuriranjeObavijesti />
         }
      ],
      protected: true
   }
];
