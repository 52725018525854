import React from 'react';
import intl from '../../services/intl-service';

const AppSettingsTheme = ({ currentTheme, onChange }) => {
   return (
      <div className='theme'>
         <label>{intl.getTranslate('settings.themeLabel')}</label>
         <select
            name='language'
            id='language'
            className='form-control form-control-sm'
            value={currentTheme}
            style={{ width: '15rem' }}
            onChange={onChange}
         >
            <option value='dark'>
               {intl.getTranslate('settings.darkTheme')}
            </option>
            <option value='light'>
               {intl.getTranslate('settings.lightTheme')}
            </option>
         </select>
      </div>
   );
};

export default AppSettingsTheme;
