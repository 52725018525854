import React from 'react';
import ReactDOM from 'react-dom';
import './services/prototype-service';
import App from './app';
import { Provider } from 'react-redux';
import configuredStore from './store/configureStore';
import { createBrowserHistory } from 'history';
import { HashRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.scss';
import 'antd/dist/antd.css';

//  Način koji mi omogučava korištenje history objekta svugdje pa i u *.js datotekama
export const history = createBrowserHistory();
const store = configuredStore();
window.store = store;

ReactDOM.render(
   <Provider store={store}>
      <HashRouter history={history}>
         <App />
      </HashRouter>
   </Provider>,
   document.getElementById('root')
);
