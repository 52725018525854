import React from 'react';
import { motion } from 'framer-motion';

const AnimatedPageLink = ({ children, className, style, index }) => {
   const linkAnimation = {
      hidden: {
         x: index % 2 === 0 ? 30 : -30,
         opacity: 0
      },
      visible: {
         x: 0,
         opacity: 1,
         transition: {
            duration: 0.25,
            delay: index ? index * 0.1 : 0
         }
      },
      //što se dešava s komponentom kada se unmounta, kada se odlazi sa neke stranice
      exit: {
         opacity: 0,
         transition: {
            duration: 0.3,
            ease: 'easeOut'
         }
      }
   };

   return (
      <motion.div
         className={className ? className : ''}
         style={style ? style : ''}
         variants={linkAnimation}
         initial='hidden'
         animate='visible'
         exit='exit'
         // whileHover={{
         //    y: -25,
         //    transition: {
         //       duration: 0.1,
         //       ease: 'easeOut'
         //    }
         // }}
      >
         {children}
      </motion.div>
   );
};

export default AnimatedPageLink;
