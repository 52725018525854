import React from 'react';
import Form from '../../common/form';
import intl from '../../../services/intl-service';
import { azuriranjeTeritorijalneJediniceSchema } from './azuriranje-teritorijalne-jedinice-validacije';
import { azuriranjeTeritorijalneJediniceNivo7Schema } from './azuriranje-teritorijalne-jedinice-nivo7-validacije';
import CustomAlertNoForm from '../../common/custom-alert-no-form';
import InfoVrijemeUpisa from '../../common/info-vrijeme-upisa';
import FramerConditionalCollapsableContainer from '../../common/framer-conditional-collapsable-container';
import DDL from '../../common/ant-ddl-translate';
import { teritorijalnaJedinicaService as service } from '../../../services/SierraLeone/a01-teritorijalna-jedinica-service';
import { DataLoader } from '../../../services/data-loader-service';
import { toastSuccessSave } from '../../../services/toast-service';
import { toastError } from './../../../services/toast-service';
import DataTable from './../../common/table/datatable';
import { getUserId } from './../../../services/auth-service';

const ddlOptions = [
   { text: 'Level 1', value: 1 },
   { text: 'Level 2', value: 2 },
   { text: 'Level 3', value: 3 },
   { text: 'Level 4', value: 4 },
   { text: 'Level 5', value: 5 },
   { text: 'Level 6', value: 6 },
   { text: 'Level 7', value: 7 }
];

const columns = [
   {
      sortable: true,
      center: true,
      label: intl.getTranslate('teritorijalnaJedinica.sifraMjesta'),
      path: 'sifraMjesta'
   },
   {
      sortable: true,
      label: intl.getTranslate('teritorijalnaJedinica.nazivMjesta'),
      path: 'nazivMjesta'
   },
   {
      sortable: true,
      label: intl.getTranslate(
         'teritorijalnaJedinica.nadredjenaTeritorijalnaJedinica'
      ),
      path: 'nazivNadredjenogMjesta'
   }
];
const translationKey = 'teritorijalnaJedinica';
class AzuriranjeTeritorijalneJedinice extends Form {
   state = {
      validationData: {
         nivo: 0,
         sifraMjesta: '',
         nazivMjesta: '',
         sifraNadredjenogMjesta: ''
      },
      nivoData: [],
      nadredjenaMjestaNivoa: [],
      odabranoNadredjenoMjesto: '',
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      operaterUpisa: '',
      showConfirm: false
   };

   schema = azuriranjeTeritorijalneJediniceSchema;

   doSubmit = async () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const postData = this.state.validationData;

      const { data: response } = await service.addOrUpdate(postData);
      const { isSuccess, msg } = response;

      if (isSuccess) {
         toastSuccessSave();
         this.resetForm();
      } else toastError(msg);

      this.setState({ showConfirm: false });
   };

   handleNivoSelected = (e) => {
      const nivo = e.value;
      const validationData = { ...this.state.validationData };
      validationData.nivo = nivo;
      validationData.sifraMjesta = '';
      validationData.nazivMjesta = '';
      validationData.sifraNadredjenogMjesta = '';

      this.setState({ validationData }, () => {
         this.getNivoData();

         if (nivo !== 7) this.ucitajNadredjenaMjestaNivoa();

         if (validationData.sifraMjesta) {
            this.ucitajOstalePodatkeNaOsnovuSifreMjesta();
         }
      });
   };

   getNivoData = async () => {
      const { nivo } = this.state.validationData;
      const { data: response } = await service.getNivoData(nivo);
      const { data: nivoData } = response;
      this.setState({ nivoData });
   };

   handleNadredjenaTJSelected = (e) => {
      const validationData = { ...this.state.validationData };
      validationData.sifraNadredjenogMjesta = e.value;

      this.setState({ validationData });
   };

   ucitajNadredjenaMjestaNivoa = () => {
      const { nivo } = this.state.validationData;
      DataLoader(
         service.nadredjenaMjesta,
         [nivo],
         this.onNadredjenaMjestaNivoaLoaded
      );
   };

   onNadredjenaMjestaNivoaLoaded = ({ data: nadredjenaMjestaNivoa }) => {
      this.setState({ nadredjenaMjestaNivoa });
   };

   onMjestoLoaded = ({ data }) => {
      const {
         nazivMjesta,
         sifraNadredjenogMjesta,
         datumUpisa,
         vrijemeUpisa,
         operaterUpisa
      } = data;

      const { validationData } = this.state;
      validationData.nazivMjesta = nazivMjesta;
      validationData.sifraNadredjenogMjesta = sifraNadredjenogMjesta;

      this.setState({
         datumUpisa,
         vrijemeUpisa,
         operaterUpisa,
         validationData
      });
   };

   ucitajOstalePodatkeNaOsnovuSifreMjesta = () => {
      const { nivo, sifraMjesta } = this.state.validationData;
      DataLoader(service.get, [nivo, sifraMjesta], this.onMjestoLoaded);
   };

   handleSifraMjestaChange = async ({ target: input }) => {
      if (input.value === '') {
         const validationData = { ...this.state.validationData };
         validationData.sifraMjesta = '';
         this.setState({ validationData });
         return;
      }

      if (isNaN(input.value)) return;

      const sifraMjesta = Number(input.value);
      if (sifraMjesta >= 1 && sifraMjesta <= 99_999) {
         this.ucitajOstalePodatkeNaOsnovuSifreMjesta();
      } else {
         const validationData = { ...this.state.validationData };
         validationData.nazivMjesta = '';
         validationData.sifraNadredjenogMjesta = '';
         this.setState({ validationData });
      }
   };

   resetForm = () => {
      const validationData = { ...this.state.validationData };
      validationData.nivo = 0;
      validationData.nazivMjesta = '';
      validationData.sifraMjesta = '';
      validationData.sifraNadredjenogMjesta = '';

      this.setState({ validationData });
   };

   handleOnLevelClick = ({ sifraMjesta }) => {
      const validationData = { ...this.state.validationData };
      validationData.sifraMjesta = sifraMjesta;
      this.setState({ validationData }, () => {
         this.ucitajOstalePodatkeNaOsnovuSifreMjesta();
      });
   };

   render() {
      const {
         nadredjenaMjestaNivoa,
         validationData,
         datumUpisa,
         vrijemeUpisa,
         operaterUpisa,
         showConfirm,
         nivoData
      } = this.state;

      const { nivo, sifraNadredjenogMjesta } = validationData;
      const jeOdabranNivo = nivo > 0 && nivo <= 7;

      if (nivo === 7) this.schema = azuriranjeTeritorijalneJediniceNivo7Schema;
      else this.schema = azuriranjeTeritorijalneJediniceSchema;

      return (
         <>
            <CustomAlertNoForm
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               onOk={() => this.doSubmitOnConfirm()}
               btnOk={intl.getTranslate('common.save')}
            >
               <div>
                  {intl.getTranslate('teritorijalnaJedinica.confirmMessage')}
               </div>
            </CustomAlertNoForm>
            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-opcinu'>
                  <div className='row'>
                     <div className='col-4'>
                        <DDL
                           options={ddlOptions}
                           translationKey='teritorijalnaJedinica'
                           translationSubKey='nivo'
                           onChange={this.handleNivoSelected}
                           selectedOption={nivo}
                           allowClear={false}
                        />
                     </div>
                     <div className='col'>
                        <h2 className='mt-4'>
                           {nivo > 0 && nivoData.length > 0 && (
                              <span>
                                 {intl.getTranslate(
                                    'teritorijalnaJedinica.nivo'
                                 )}
                                 {nivo}{' '}
                                 <div
                                    style={{ fontSize: '1rem', opacity: 0.4 }}
                                 >
                                    {intl.getTranslate(
                                       'teritorijalnaJedinica.tableHint'
                                    )}
                                 </div>
                              </span>
                           )}
                        </h2>
                     </div>
                  </div>

                  <FramerConditionalCollapsableContainer
                     condition={jeOdabranNivo}
                     height='29rem'
                  >
                     <div className='row'>
                        <div className='col-4'>
                           {this.formInput({
                              name: 'sifraMjesta',
                              translationKey,
                              fn: this.handleSifraMjestaChange,
                              isNumber: true
                           })}

                           {this.formInput({
                              name: 'nazivMjesta',
                              translationKey
                           })}

                           {nivo !== 7 && (
                              <DDL
                                 options={nadredjenaMjestaNivoa}
                                 translationKey='teritorijalnaJedinica'
                                 translationSubKey='nadredjenaTeritorijalnaJedinica'
                                 onChange={this.handleNadredjenaTJSelected}
                                 selectedOption={sifraNadredjenogMjesta}
                              />
                           )}
                        </div>
                        <div className='col'>
                           {nivoData && nivoData.length > 0 && (
                              <DataTable
                                 columns={columns}
                                 data={nivoData}
                                 hideSearch={true}
                                 pageSize={6}
                                 onRowClick={this.handleOnLevelClick}
                                 style={{ minHeight: '10rem' }}
                              />
                           )}
                        </div>
                     </div>

                     <hr />

                     <InfoVrijemeUpisa
                        datumUpisa={datumUpisa}
                        vrijemeUpisa={vrijemeUpisa}
                        operaterUpisa={operaterUpisa}
                     />
                     {this.formSubmit({
                        label: `${intl.getTranslate('common.save')}`
                     })}
                  </FramerConditionalCollapsableContainer>
               </div>
            </form>
         </>
      );
   }
}

export default AzuriranjeTeritorijalneJedinice;
