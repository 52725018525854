import React from 'react';
import PageContainer from '../components/common/animated-page-container';
import PageLinks from '../components/common/page-links';

const InitPage = ({ submenuOptions }) => {
   return (
      <PageContainer className='page'>
         <PageLinks linksCollection={submenuOptions} />
      </PageContainer>
   );
};

export default InitPage;
