import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPlaceholderData } from './../../../store/playground/placeholder';

const ShowPlaceholderData = (props) => {
   const placeholderData = useSelector((state) => state.placeholder);
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getPlaceholderData());
   }, [dispatch]);

   return (
      <div className='show-placeholder-data'>
         <table className='table table-sm table-striped'>
            <thead>
               <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>E-mail</th>
                  <th>Website</th>
               </tr>
            </thead>
            <tbody>
               {placeholderData.map((user) => (
                  <tr key={user.id}>
                     <td>{user.id}</td>
                     <td>{user.name}</td>
                     <td>{user.username}</td>
                     <td>
                        <a href={`mailto:${user.email}`}>{user.email}</a>
                     </td>
                     <td>{user.website}</td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default ShowPlaceholderData;
