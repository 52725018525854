import http from '../http';
import api from '../../config/SierraLeone/ke05-pregled-svih-keno-listica.json';

export const pregledSvihKenoListicaService = {
   get: (
      odDatuma,
      doDatuma,
      odVremena,
      doVremena,
      odKola,
      doKola,
      odProdajnogMjesta,
      doProdajnogMjesta,
      odProdavaca,
      doProdavaca,
      statusListica
   ) =>
      http.post(`${api.get}`, {
         odDatuma,
         doDatuma,
         odVremena,
         doVremena,
         odKola,
         doKola,
         odProdajnogMjesta,
         doProdajnogMjesta,
         odProdavaca,
         doProdavaca,
         statusListica
      })
};
