import React from 'react';
import { motion } from 'framer-motion';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const CustomAlert = (props) => {
   const {
      onOk,
      onCancel,
      title,
      btnCancel = 'Odustani',
      btnOk,
      btnOkDisabled,
      alertMode = false,
      printMode = false,
      style,
      btnOkStyle,
      btnCancelStyle,
      showCondition = true
   } = props;

   const onFormSubmit = (e) => {
      e.preventDefault();
      onOk();
   };

   if (!showCondition) return null;

   return (
      <>
         {ReactDOM.createPortal(
            <motion.div
               className='custom-alert'
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 0.25 }}
               exit={{ opacity: 0 }}
            >
               <form method='post' onSubmit={onFormSubmit}>
                  <motion.div
                     className='custom-alert-popup'
                     style={style ? style : {}}
                     initial={{ opacity: 1, scale: 0.5 }}
                     animate={{ opacity: 1, scale: 1 }}
                     exit={{ opacity: 0, scale: 0.5 }}
                     transition={{
                        duration: 2,
                        delay: 0,
                        type: 'spring',
                        stiffness: 200
                     }}
                  >
                     {printMode && (
                        <div className='custom-alert-title' onClick={onCancel}>
                           <div className='print-title'>
                              <span>Print</span>
                              <span>
                                 <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className='mr-3'
                                    style={{
                                       fontSize: '2rem',
                                       verticalAlign: 'middle'
                                    }}
                                 />
                              </span>
                           </div>
                        </div>
                     )}
                     {!printMode && (
                        <div className='custom-alert-title'>{title}</div>
                     )}
                     <div className='custom-alert-content'>
                        {props.children}
                     </div>
                     <hr />
                     {!printMode && (
                        <div className='custom-alert-footer'>
                           {!alertMode && (
                              <button
                                 className='button cancel'
                                 onClick={onCancel}
                                 style={btnCancelStyle ? btnCancelStyle : {}}
                                 type='button'
                              >
                                 {btnCancel}
                              </button>
                           )}
                           <motion.button
                              initial={{ opacity: btnOkDisabled ? 0.5 : 1 }}
                              animate={{ opacity: btnOkDisabled ? 0.5 : 1 }}
                              style={btnOkStyle ? btnOkStyle : {}}
                              className='button save'
                              disabled={btnOkDisabled}
                              autoFocus
                              type='submit'
                           >
                              {btnOk}
                           </motion.button>
                        </div>
                     )}
                  </motion.div>
               </form>
            </motion.div>,
            document.querySelector('#confirm')
         )}
      </>
   );
};

export default CustomAlert;
