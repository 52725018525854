import moment from 'moment';
import intl from './intl-service';

export const formatToMomentDate = (date) => {
   const [DD, MM, YYYY] = date.split('.');
   return `${YYYY.trim()}-${MM.trim()}-${DD.trim()}`;
};

export const formatDateToInt = (date) => {
   const arr = date.split('.');
   let [DD, MM, YYYY] = arr;
   DD = Number(DD);
   MM = Number(MM);
   YYYY = Number(YYYY);
   return YYYY * 10_000 + MM * 100 + DD;
};

export const formatTimeToInt = (time) => {
   const arr = time.split(':');
   let [HH, mm, ss] = arr;
   HH = Number(HH);
   mm = Number(mm);
   ss = Number(ss);
   return HH * 10_000 + mm * 100 + ss;
};

export const dateObjectToInteger = (dateObject) => {
   const formatedDate = moment(dateObject).format('DD.MM.YYYY');

   const arr = formatedDate.split('.');
   let [DD, MM, YYYY] = arr;
   DD = Number(DD);
   MM = Number(MM);
   YYYY = Number(YYYY);
   return YYYY * 10_000 + MM * 100 + DD;
};

export const integerToMomentDate = (int) => {
   const YYYY = parseInt(int / 10_000);
   const MM = parseInt((int % 10_000) / 100);
   const DD = (int % 10_000) % 100;

   return moment(new Date(YYYY, MM - 1, DD));
};

export const todayDateDDMMYYYY = () => moment(new Date()).format('DD.MM.YYYY');

export const todayInt = () =>
   formatDateToInt(moment(new Date()).format('DD.MM.YYYY'));

export const dayDifferenceInt = (days) => {
   return formatDateToInt(
      moment(new Date().addDays(days)).format('DD.MM.YYYY')
   );
};

export const todayMoment = () => moment(new Date());

export const formatIntToTimeString = (int) => {
   const s = int % 100;
   const ss = s < 10 ? `0${s}` : s;

   const m = Math.trunc((int % 10_000) / 100);
   const mm = m < 10 ? `0${m}` : m;

   const h = Math.trunc(int / 10_000);
   const HH = h < 10 ? `0${h}` : h;

   return `${HH}:${mm}:${ss}`;
};

export const getMomentTimeFromString = (timeStringHHmmss) =>
   moment(timeStringHHmmss, 'HH:mm:ss');

export const getTimeStringFromMomentObject = (momentObj) =>
   moment(momentObj).format('HH:mm:ss');

export const getMomentObjFromNumber = (numberValue) =>
   getMomentTimeFromString(formatIntToTimeString(numberValue));

export const dateToMoment = (date) => moment(date);

export const getTranslatedDDLoptions = (options) => {
   let translationKey = 'common.ddl';

   return options.map((data) => {
      const text = intl.getTranslate(
         `${translationKey}.${data.text.toLowerCase()}`
      );

      return {
         ...data,
         text
      };
   });
};
