import React from 'react';
import { FormOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import { FcAddDatabase } from 'react-icons/fc';

const ButtonAddNew = (props) => {
   const { title, path } = props;
   return (
      <button
         className='button save'
         onClick={() => props.history.push(path)}
         style={{
            fontSize: '1.2rem',
            marginTop: '2rem',
            padding: '.6rem 1rem',
            boxShadow: '0 0 .2rem rgba(0,0,0,.5)'
         }}
      >
         <FcAddDatabase
            style={{
               marginRight: '1rem',
               fontSize: '1.8rem'
            }}
         />
         {/* <FormOutlined
            style={{
               verticalAlign: 0,
               marginRight: '1rem',
               fontSize: '1.4rem'
            }}
         /> */}
         {title}
      </button>
   );
};

export default withRouter(ButtonAddNew);
