import React from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

const UserInfoPanel = ({ setShowUserInfo, operativniBroj }) => {
   return (
      <motion.div
         className='user-info-panel'
         initial={{ opacity: 0, y: 60 }}
         animate={{ opacity: 1, y: 90 }}
         transition={{ duration: 0.25 }}
         exit={{
            opacity: 0,
            y: 120,
            transition: { duration: 0.2, delay: 0.3 }
         }}
      >
         <div className='panel-arrow'></div>
         {/* <h2>{`${ime} ${prezime}`}</h2> */}
         <p>
            AGENT ID: <span>{`${operativniBroj}`}</span>
         </p>
         <div>
            <NavLink to='/logout' onClick={() => setShowUserInfo(false)}>
               Logout
            </NavLink>
         </div>
      </motion.div>
   );
};

export default UserInfoPanel;
