import React from 'react';
import Form from '../common/form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import intl from '../../services/intl-service';
import { azuriranjePripadnostTerminalaSchema } from './terminal-pripadnost-validacije';
import { terminalaService as service } from '../../services/a10-terminal-service';
import { prodajnoMjestoService } from '../../services/a02-prodajno-mjesto-service';
import { ucitajAktivnostiTerminala } from '../../store/common';
import { Checkbox } from 'antd';
import CustomAlertNoForm from './../common/custom-alert-no-form';
import { toastError, toastSuccess } from './../../services/toast-service';
import InfoVrijemeUpisa from './../common/info-vrijeme-upisa';
import FramerConditionalCollapsableContainer from './../common/framer-conditional-collapsable-container';
import { DataLoader } from './../../services/data-loader-service';
import DDL from '../common/ant-ddl-translate';
import { withRouter } from 'react-router-dom';
import validator from 'validator';

class UrediPripadnostTerminal extends Form {
   state = {
      validationData: {
         brojTerminala: '',
         sifraProdajnogMjesta: '',
         indikator: 1,
         download: 1
      },
      errors: {},
      prodajnaMjesta: [],
      operaterUpisa: '',
      datumUpisa: '',
      vrijemeUpisa: '',
      showConfirm: false
   };

   schema = azuriranjePripadnostTerminalaSchema;

   loadProdajnaMjesta = async () => {
      const { data: responseProdajnaMjesta } =
         await prodajnoMjestoService.list();
      const { prodajnaMjesta } = responseProdajnaMjesta;
      return prodajnaMjesta;
   };

   async componentDidMount() {
      const prodajnaMjesta = await this.loadProdajnaMjesta();

      this.setState({ prodajnaMjesta }, () => {
         let { brojTerminala } = this.props.match.params;
         if (brojTerminala) this.showTerminal(brojTerminala);
      });
   }

   showTerminal = (brojTerminala) => {
      if (validator.isNumeric(brojTerminala)) {
         brojTerminala = parseInt(brojTerminala);

         const validationData = { ...this.state.validationData };
         validationData.brojTerminala = brojTerminala;
         this.setState({ validationData });
         this.ucitajPodatkeTerminala(brojTerminala);
      }
   };

   doSubmit = async () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const { brojTerminala, sifraProdajnogMjesta, indikator, download } =
         this.state.validationData;

      const azuriraniPodaci = {
         brojTerminala,
         sifraProdajnogMjesta,
         indikator,
         download,
         operaterUpisa: 14
      };

      const { data: response } = await service.addOrUpdate(azuriraniPodaci);

      if (response.isSuccess === 1) {
         toastSuccess();
         this.resetForm('brojTerminala');
      } else toastError();

      this.setState({ showConfirm: false });
   };

   onDataLoad = (response) => {
      const validationData = { ...this.state.validationData };
      const {
         brojTerminala,
         download,
         indikator,
         sifraProdajnogMjesta,
         datumUpisa,
         vrijemeUpisa,
         operaterUpisa
      } = response;

      validationData.brojTerminala = brojTerminala;
      validationData.download = download;
      validationData.indikator = indikator;
      validationData.sifraProdajnogMjesta = sifraProdajnogMjesta;

      this.setState({
         validationData,
         datumUpisa,
         vrijemeUpisa,
         operaterUpisa
      });
   };

   ucitajPodatkeTerminala = async (_brojTerminala) => {
      DataLoader(service.get, [_brojTerminala], this.onDataLoad);
   };

   handleBrojTerminalaChange = async ({ target: input }) => {
      if (isNaN(input.value)) return;

      const brojTerminala = Number(input.value);
      if (brojTerminala >= 1 && brojTerminala <= 9999)
         this.ucitajPodatkeTerminala(brojTerminala);
   };

   ucitajProdajnoMjesto = async (sifraProdajnogMjesta) => {
      const validationData = { ...this.state.validationData };
      const { data: response } = await service.getNazivProdajnogMjesta(
         sifraProdajnogMjesta
      );

      this.setState({ validationData });
   };

   handleSifraProdajnogMjestaChange = async ({ target: input }) => {
      if (isNaN(input.value)) return;

      const sifraProdajnogMjesta = Number(input.value);
      if (sifraProdajnogMjesta > 0 && sifraProdajnogMjesta <= 9999)
         this.ucitajProdajnoMjesto(sifraProdajnogMjesta);
   };

   handlePotrebanDownloadCheck = ({ target: input }) => {
      const { name } = input;
      const validationData = { ...this.state.validationData };
      const value = input.checked;

      switch (name) {
         case 'indikator':
            validationData.indikator = value ? 1 : 0;
            break;
         case 'download':
            validationData.download = value ? 9 : 0;
            break;
         default:
            break;
      }

      this.setState({ validationData });
   };

   handleDDLSelected = (e, propertyName) => {
      const { value } = e;
      const validationData = { ...this.state.validationData };
      validationData[propertyName] = value;

      this.setState({
         validationData
      });
   };

   getProdajnaMjesta = () => {
      const { prodajnaMjesta } = this.state;
      if (prodajnaMjesta.length === 0) return [];

      return prodajnaMjesta.map((pm) => ({
         text: `[${pm.sifraProdajnogMjesta}] ${pm.nazivProdajnogMjesta} - ${pm.adresaProdajnogMjesta}`,
         value: pm.sifraProdajnogMjesta
      }));
   };

   render() {
      const {
         validationData,
         prodajnaMjesta,
         datumUpisa,
         vrijemeUpisa,
         operaterUpisa,
         showConfirm
      } = this.state;
      const { download, indikator } = validationData;

      const { brojTerminala, sifraProdajnogMjesta } = validationData;
      const brojTerminalaJeUpisan = brojTerminala >= 1 && brojTerminala <= 9999;

      return (
         <>
            <CustomAlertNoForm
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               onOk={() => this.doSubmitOnConfirm()}
            >
               <div>
                  {intl.getTranslate('terminalPripadnost.confirmMessage')}
                  {'?'}
               </div>
            </CustomAlertNoForm>
            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-opcinu'>
                  <div className='row'>
                     <div className='col-4'>
                        {this.formInput({
                           name: 'brojTerminala',
                           translationKey: 'terminalPripadnost',
                           isNumber: true,
                           fn: this.handleBrojTerminalaChange,
                           debounceDelay: 250
                        })}
                     </div>
                  </div>
                  <FramerConditionalCollapsableContainer
                     condition={brojTerminalaJeUpisan}
                     height='23rem'
                  >
                     <div className='row'>
                        <div className='col-4'>
                           <DDL
                              options={this.getProdajnaMjesta()}
                              translationKey='terminalPripadnost'
                              translationSubKey='sifraProdajnogMjesta'
                              onChange={this.handleDDLSelected}
                              selectedOption={sifraProdajnogMjesta}
                           />
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-4'>
                           <Checkbox
                              name='indikator'
                              onChange={this.handlePotrebanDownloadCheck}
                              checked={indikator ? true : false}
                           >
                              {intl.getTranslate(
                                 'terminalPripadnost.indikator'
                              )}
                           </Checkbox>
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-4'>
                           <Checkbox
                              name='download'
                              onChange={this.handlePotrebanDownloadCheck}
                              checked={download ? true : false}
                           >
                              {intl.getTranslate(
                                 'terminalPripadnost.potrebanDownload'
                              )}
                           </Checkbox>
                        </div>
                     </div>

                     <hr />

                     <InfoVrijemeUpisa
                        datumUpisa={datumUpisa}
                        vrijemeUpisa={vrijemeUpisa}
                        operaterUpisa={operaterUpisa}
                     />

                     {this.formSubmit({
                        label: `${intl.getTranslate('common.update')}`
                     })}
                  </FramerConditionalCollapsableContainer>
               </div>
            </form>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   aktivnostTerminala: state.common.aktivnostTerminala
});

const mapDispatchToProps = (dispatch) => ({
   ucitajAktivnostiTerminala: () => dispatch(ucitajAktivnostiTerminala())
});

export default withRouter(
   injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(UrediPripadnostTerminal)
   )
);
