import React, { useEffect, useState } from 'react';
import intl from '../../../services/intl-service';
import DataTable from '../../common/table/datatable';
import { a03nadredjeniService as service } from '../../../services/SierraLeone/A03NADREDJENI-service';
import FramerConditionalCollapsableContainer from '../../common/framer-conditional-collapsable-container';
import FramerConditionalOpacityContainer from '../../common/framer-conditional-opacity-container';
import NoDataMessage from './../../common/no-data-message';

const PregledAzuriranjaNadredjenog = ({ date }) => {
   const columns = [
      {
         sortable: true,
         center: true,
         label: intl.getTranslate(
            'azuriranjeNadredjenog.pregled.operativniBroj'
         ),
         path: 'opbr'
      },
      {
         sortable: true,
         label: intl.getTranslate('azuriranjeNadredjenog.pregled.zastupnik'),
         path: 'zastupnik'
      },
      {
         sortable: true,
         label: intl.getTranslate(
            'azuriranjeNadredjenog.pregled.opbrNadredjenogStari'
         ),
         path: 'opbrNadredjenogStari',
         center: true
      },
      {
         sortable: true,
         label: intl.getTranslate(
            'azuriranjeNadredjenog.pregled.opbrNadredjenogNovi'
         ),
         path: 'opbrNadredjenogNovi',
         center: true
      },
      {
         sortable: true,
         label: intl.getTranslate('azuriranjeNadredjenog.pregled.operater'),
         path: 'operater',
         center: true
      },
      {
         sortable: true,
         label: intl.getTranslate('azuriranjeNadredjenog.pregled.vrijeme'),
         path: 'vrijeme'
      }
   ];

   const [data, setData] = useState([]);

   useEffect(() => {
      if (date) loadData();
   }, [date]); // eslint-disable-line react-hooks/exhaustive-deps

   const loadData = async () => {
      const { data: response } = await service.getUpisani(date);

      const data = response.data.map((d) => {
         return {
            opbr: d.operativniBroj,
            zastupnik: `${d.ime} ${d.prezime}`,
            opbrNadredjenogStari: d.operativniBrojNadredjenogStari,
            opbrNadredjenogNovi: d.operativniBrojNadredjenogNovi,
            operater: d.operaterUpisa,
            vrijeme: d.vrijemeUpisa
         };
      });

      setData(data);
   };

   const handleOnLevelClick = (rowData) => {
      // console.log(rowData);
   };

   return (
      <>
         <FramerConditionalCollapsableContainer
            condition={data.length > 0}
            delay={0.2}
            height='25rem'
         >
            <DataTable
               columns={columns}
               data={data}
               hideSearch={true}
               pageSize={6}
               onRowClick={handleOnLevelClick}
            />
         </FramerConditionalCollapsableContainer>
         <NoDataMessage
            messageKey='azuriranjeNadredjenog.nemaPodatakaZaDatum'
            data={data}
         />
      </>
   );
};

export default PregledAzuriranjaNadredjenog;
