import intl from '../../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjeUplatnihMjestaSchema = {
   sifraProdajnogMjesta: Joi.number()
      .integer()
      .min(1)
      .max(9999)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.sifraProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.sifraProdajnogMjestaValidator'
            )}`
         };
      }),
   nazivProdajnogMjesta: Joi.string()
      .max(63)
      .required()
      .label(intl.getTranslate('prodajnaMjesta.nazivProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.nazivProdajnogMjestaValidator'
            )}`
         };
      }),
   adresaProdajnogMjesta: Joi.string()
      .allow('')
      .max(63)
      .label(intl.getTranslate('prodajnaMjesta.adresaProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.adresaProdajnogMjestaValidator'
            )}`
         };
      }),
   sifraTeritorijalneJediniceN1: Joi.number()
      .integer()
      .min(0)
      .max(99999)
      .label(intl.getTranslate('prodajnaMjesta.sifraTeritorijalneJediniceN1'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.sifraTeritorijalneJediniceN1Validator'
            )}`
         };
      }),
   geografskaDuzina: Joi.number()
      .required()
      .min(-180)
      .max(180)
      .label(intl.getTranslate('prodajnaMjesta.geografskaDuzina'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.geografskaDuzinaValidator'
            )}`
         };
      }),
   geografskaSirina: Joi.number()
      .required()
      .min(-90)
      .max(90)
      .label(intl.getTranslate('prodajnaMjesta.geografskaSirina'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.geografskaSirinaValidator'
            )}`
         };
      }),
   brojTelefona: Joi.string()
      .allow('')
      .max(47)
      .label(intl.getTranslate('prodajnaMjesta.telefon'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.telefonValidator'
            )}`
         };
      }),
   brojTerminala: Joi.number()
      .integer()
      .min(1)
      .max(9_999)
      .label(intl.getTranslate('prodajnaMjesta.brojTerminala'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.brojTerminalaValidator'
            )}`
         };
      }),
   tipLokala: Joi.number()
      .integer()
      .min(0)
      .max(99)
      .label(intl.getTranslate('prodajnaMjesta.tipLokala'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.tipLokalaValidator'
            )}`
         };
      }),
   kategorija: Joi.number()
      .integer()
      .min(0)
      .max(99)
      .label(intl.getTranslate('prodajnaMjesta.kategorija'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'prodajnaMjesta.kategorijaValidator'
            )}`
         };
      })
};
