import React from 'react';
import PageContainer from '../components/common/animated-page-container';
import DialogTitle from '../components/common/dialog-title';
import { getUsedTheme } from '../services/app-service';
import intl from '../services/intl-service';
import AdditionalTitleLink from '../components/common/btn-additional-title-link';
import DialogAdditionalLink from '../components/common/dialog-additional-link';

const Page = ({ title, component, style, additionalTitleLink: atl = null }) => {
   const theme = getUsedTheme();
   const classBase = 'page-content page-content-';
   const className =
      theme === 'dark' ? `${classBase}dark` : `${classBase}light`;
   return (
      <>
         <PageContainer className='page'>
            <div className={className} style={style ? style : {}}>
               <div className='page-header'>
                  <DialogTitle title={intl.getTranslate(title)} />
                  {atl && (
                     <DialogAdditionalLink>
                        <AdditionalTitleLink
                           label={intl.getTranslate(atl.label)}
                           path={atl.path}
                        />
                     </DialogAdditionalLink>
                  )}
               </div>
               {component}
            </div>
         </PageContainer>
      </>
   );
};

export default Page;
