import axios from 'axios';
import { toastAxiosError, toastError } from './toast-service';
import api from '../config/api-config.json';
import { history } from './../index';
import { getCurrentUser } from './auth-service';

const baseURL = api.omonia;

axios.defaults.baseURL = `${baseURL}/${api.clientPath}`;

//AXIOS can intecept errors
// success - error are functions
//axios.interceptors.response.use(success, error)
/*
(response) => {
      const { isSuccess } = response.data;
      if (isSuccess === 0) {
         toastError(response.data.msg);
      }
   }
*/
axios.interceptors.response.use(
   (response) => {
      const { isSuccess } = response.data;
      //response.headers.genericKey = "someGenericValue";
      //In case it's a GET request, the query parameters being sent can be found in config.params object.
      if (isSuccess === 0) {
         const { request } = response;
         // toastError(`${response.data.msg} - ${request.responseURL}`);
         toastError(`${response.data.msg}`);
      }

      return response;
   },
   (error) => {
      //expected error - in 400 range
      // eslint-disable-next-line
      const expectedError =
         error.response && error.response.status >= 400 && error.status < 500;

      if (axios.isCancel(error)) {
         console.log('axios request cancelled', error.response);
      }

      console.log('error.response', error.response);

      if (error.response) {
         const { status, data, config } = error.response;
         switch (status) {
            case 400:
               console.log('400:bad request');
               break;
            case 401:
               console.log('401:unauthorized user');
               break;
            case 404:
               history.push(`/not-found/${encodeURIComponent(config.url)}`);
               break;
            case 500:
               console.log('500:internal server error');
               break;
            case 503:
               console.log('503:Service Unavailable');
               break;
            default:
               break;
         }
      }
      toastAxiosError(error.toJSON());
      //to pass control to catch() block we need to return a rejected Promise with error included
      return Promise.reject(error);
   }
);

const get = (url, cancelToken) => {
   let config = { cancelToken };
   return axios.get(url, config);
};

const post = (url, data, cancelToken) => {
   let config = { cancelToken };
   return axios.post(url, data, config);
};

const responseData = (response) => response.data;

const requestsDefinition = {
   getData: (url) => axios.get(url).then(responseData),
   postData: (url, payload) => axios.post(url, payload).then(responseData),
   post: (url, payload) => axios.post(url, payload).then(responseData),
   put: (url, payload) => axios.put(url, payload).then(responseData),
   del: (url) => axios.delete(url).then(responseData)
};

const { getData, postData } = requestsDefinition;

export function setJWTtoHeader(token) {
   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const { token } = getCurrentUser() ?? {};
if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

const exportObj = {
   getData,
   postData,
   get,
   post,
   put: axios.put,
   delete: axios.delete,
   all: axios.all,
   setJWTtoHeader
};
export default exportObj;

// https://stackoverflow.com/questions/52737078/how-can-you-use-axios-interceptors
