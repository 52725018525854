import 'leaflet/dist/leaflet.css';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

// import './styles.css';
import 'leaflet/dist/leaflet.js';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.js';

import './Map.css';

import React, { useState } from 'react';
import 'leaflet/dist/leaflet.css';
import {
   MapContainer,
   TileLayer,
   useMap,
   Marker,
   Popup,
   useMapEvents
} from 'react-leaflet';
import * as ELG from 'esri-leaflet-geocoder';
//https://www.npmjs.com/package/esri-leaflet-geocoder

//https://github.com/PaulLeCam/react-leaflet/issues/453
import L from 'leaflet';
// L.Icon.Default.imagePath = 'resources/';

// import marker icons
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
   iconRetinaUrl:
      'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon-2x.png',
   iconUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png',
   shadowUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png'
});

const zoom = 14;
//const center = '';
const lat = -13.253942008270531;
const lng = 8.474564375135573;

const Openstreet = ({ center, style, disabled = false, onOpenstreetClick }) => {
   const defaultStyle = {
      width: '100%',
      height: '30rem',
      margin: '.5rem 0',
      zIndex: 1
   };
   const combinedStyles = { ...defaultStyle, ...style };
   const [position, setPosition] = useState(center ? center : [lat, lng]);
   const [markerposition, setMarkerPosition] = useState(
      center ? center : [lat, lng]
   );

   return (
      <div className='openstreet'>
         <MapContainer
            className='map'
            center={position}
            zoom={zoom}
            style={combinedStyles}
            scrollWheelZoom={true}
            dragging={disabled ? false : true}
            zoomControl={false}
         >
            <Geocoder
               address={center ? center : ''}
               setPosition={setPosition}
            />
            <MyComponent
               setPosition={setPosition}
               setMarkerPosition={setMarkerPosition}
               disabled={disabled}
            />
            <Marker position={markerposition}>
               <Popup>Marker popup</Popup>
            </Marker>
            <TileLayer
               attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
               url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
         </MapContainer>
      </div>
   );

   function MyComponent({ setPosition, setMarkerPosition, disabled }) {
      const service = new ELG.geocodeService();
      const map = useMapEvents({
         click: ({ latlng }) => {
            if (disabled) return;
            const { lat, lng } = latlng;
            map.setView([lat, lng], zoom);
            setPosition([lat, lng]);
            setMarkerPosition([lat, lng]);

            service
               .reverse()
               .latlng(latlng)
               .run((error, result) => {
                  if (error) console.log('ERROR');
                  if (onOpenstreetClick) onOpenstreetClick(result);
               });
         }
      });
      return null;
   }

   function Geocoder({ address }) {
      const map = useMap();

      ELG.geocode()
         .text(address)
         .run((err, { results }, response) => {
            if (results) {
               if (results.length === 0) return;
               const { lat, lng } = results[0].latlng;
               //ovdje ide obrnuto lng, lat umjesto [lat, lng]
               map.setView([lng, lat], zoom + 2);
            }
         });

      return null;
   }
};

export default Openstreet;

// useEffect(()=>{
//     const fetchCityCoordinates = async () => {
//         const result = await axios(
//           'https://hn.algolia.com/api/v1/search?query=redux',
//         );

//         setData(result.data);
//       };

//       fetchCityCoordinates();

// },[]);
