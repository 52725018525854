import intl from '../../services/intl-service';
import Joi from 'joi-browser';

export const azuriranjePripadnostTerminalaSchema = {
   brojTerminala: Joi.number()
      .integer()
      .min(1)
      .max(9999)
      .required()
      .label(intl.getTranslate('terminalPripadnost.brojTerminala'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'terminalPripadnost.brojTerminalaError'
            )}`
         };
      }),
   sifraProdajnogMjesta: Joi.number()
      .integer()
      .min(1)
      .max(9999)
      .required()
      .label(intl.getTranslate('terminalPripadnost.sifraProdajnogMjesta'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'terminalPripadnost.sifraProdajnogMjestaError'
            )}`
         };
      }),
   indikator: Joi.number()
      .integer()
      .required()
      .label(intl.getTranslate('terminalPripadnost.indikator'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'terminalPripadnost.indikatorValidator'
            )}`
         };
      }),
   download: Joi.any().optional()
};
