import React from 'react';
import Form from './common/form';
import Joi from 'joi-browser';
import { connect } from 'react-redux';
import { registerUser, deleteError } from '../store/users';
import { Animated } from 'react-animated-css';
import AvatarEditorPopup from './avatar-editor-popup';
import genericAvatar from '../resources/pic/avatar-generic.svg';
import translate from '../i18n/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

/*
   wrapper HOC oko komponente kako bi mogao direktno vaditi
   prijevode i dodjeljivat ih kao vrijednosti atributa na komponentama
   getTranslation()
*/
import { injectIntl } from 'react-intl';

/*
    podaci koje treba validirati idu u validationData
    validationData svojstva se mapiraju na schema objekt

    ostali podaci se neovisno pohranjuju na state objektu
*/

class Register extends Form {
   state = {
      showAvatarEditor: false,
      avatar: genericAvatar,
      validationData: {
         username: '',
         password: '',
         name: ''
      },
      errors: {}
   };

   schema = {
      username: Joi.string().email().required().label('User name'),
      password: Joi.string().required().label('Password'),
      name: Joi.string().required().label('Name')
   };

   render() {
      return (
         <div className='rgister-users'>
            <form onSubmit={this.handleFormSubmit}>
               {this.props.registeredUser.error && (
                  <div className='alert alert-danger mb-2'>
                     {this.props.registeredUser.error}
                  </div>
               )}
               <div className='user-data-container'>
                  <header>
                     <h1>
                        <FontAwesomeIcon
                           icon={faSignInAlt}
                           className='mr-2'
                           style={{ color: '#007bff' }}
                        />
                        {translate('registerUser.registerUserHeader')}
                     </h1>
                  </header>
                  <div className='data'>
                     <div className='user-data'>
                        {this.renderInput(
                           'name',
                           this.getTranslation('registerUser.name'),
                           {
                              autoFocus: true
                           }
                        )}
                        {this.renderInput(
                           'username',
                           this.getTranslation('registerUser.username'),
                           {
                              placeholder: this.getTranslation(
                                 'registerUser.passwordPlaceholder'
                              )
                           }
                        )}
                        {this.renderInput(
                           'password',
                           this.getTranslation('registerUser.password'),
                           {
                              type: 'password'
                           }
                        )}
                        {this.renderSubmitButton(
                           this.getTranslation('registerUser.registerButton')
                        )}
                     </div>
                     <div className='user-avatar'>
                        <button
                           type='button'
                           onClick={this.handleShowHideAvatarEditor}
                        >
                           <img src={this.state.avatar} alt='logo' />
                        </button>
                     </div>
                  </div>
               </div>

               <Animated
                  animationIn='fadeIn'
                  animationOut='fadeOut'
                  animationInDuration={300}
                  animationOutDuration={10}
                  isVisible={this.state.showAvatarEditor}
               >
                  <AvatarEditorPopup
                     onBackDropClick={this.handleShowHideAvatarEditor}
                     onAvatarSet={this.handleAvatarImg}
                     onAvatarCancel={this.handleAvatarCancel}
                  />
               </Animated>
            </form>
         </div>
      );
   }

   getTranslation = (id) => {
      const { intl } = this.props;

      return intl.formatMessage({
         id
      });
   };

   componentWillUnmount() {
      this.props.deleteError();
   }

   handleAvatarImg = (avatar) => {
      this.setState({ avatar });
   };

   handleAvatarCancel = () => {
      this.setState({ avatar: genericAvatar });
   };

   handleShowHideAvatarEditor = () => {
      let { showAvatarEditor } = this.state;
      showAvatarEditor = !showAvatarEditor;
      this.setState({ showAvatarEditor });
   };

   doSubmit = () => {
      const { registerUser, deleteError } = this.props;
      const { validationData, avatar: img } = this.state;
      deleteError();
      const newUser = { ...validationData, img };

      registerUser(newUser);
   };

   componentDidMount() {
      const { users } = this.props;
      this.setState({ users });
   }
}

const mapStateToProps = (state) => ({
   registeredUser: state.registeredUser
});

const mapDispatchToProps = (dispatch) => ({
   registerUser: (user) => dispatch(registerUser(user)),
   deleteError: () => dispatch(deleteError())
});

export default injectIntl(
   connect(mapStateToProps, mapDispatchToProps)(Register)
);
