import React, { useEffect, useState } from 'react';
import { service } from '../../../services/SierraLeone/ke06-keno-kumulativi';
import {
   getItemPropsToString,
   getSumiranePodatkePoZastupniku
} from './../../../services/zastupnici-service';
import AntDatepicker from './../../common/ant-datepicker';
import {
   dayDifferenceInt,
   formatDateToInt,
   integerToMomentDate
} from './../../../services/form-components-service';
import intl from '../../../services/intl-service';
import { useSelector, useDispatch } from 'react-redux';
import { ucitajSveZastupnike } from '../../../store/zastupnici';
import DDL from '../../common/ant-ddl-translate';
import DataTable from './../../common/table/datatable';
import AntPieChart from './../../common/chart-ant-pie';
import AntGroupedBarChart from './../../common/chart-ant-grouped-bar';
import { groupBy } from './../../../services/array-service';
import AntBasicBarChart from './../../common/chart-ant-basic-bar';

const emptyColumn = [
   {
      label: '',
      path: 'emptyColumn',
      width: '24rem'
   }
];

const KenoKumulativiSL = (props) => {
   const DISPLAY = {
      allAgents: 0,
      summary: 1,
      all: 2
   };
   const defaultDisplay = DISPLAY.allAgents;

   const viewOptions = [
      {
         text: intl.getTranslate('kenoKumulativiSE.showSummaryData'),
         value: 1
      },
      {
         text: intl.getTranslate('kenoKumulativiSE.showAllData'),
         value: 2
      }
   ];

   const [odDatuma, setOdDatuma] = useState(dayDifferenceInt(-5));
   const [doDatuma, setDoDatuma] = useState(dayDifferenceInt(0));
   const [opbrOdabranogZastupnika, setOPBRodabranogZastupnika] = useState(0);
   const [tableData, setTableData] = useState([]);
   const [zastupniciOptions, setZastupniciOptions] = useState([]);
   const [display, setDisplay] = useState(defaultDisplay);

   const dispatch = useDispatch();
   const { sviZastupnici } = useSelector((state) => state.zastupnici);
   const { currencyData } = useSelector((state) => state.xlsx);

   const zastupniciUcitani = sviZastupnici.length > 0;
   const allAgentsSelected = opbrOdabranogZastupnika === 0;

   const columns = [
      {
         width: '4rem',
         sortable: true,
         center: true,
         label: intl.getTranslate('kenoKumulativiSE.operativniBroj'),
         path: 'operativniBroj'
      },
      {
         sortable: true,
         label: intl.getTranslate('kenoKumulativiSE.imePrezime'),
         path: 'imePrezime'
      },
      {
         sortable: true,
         label: intl.getTranslate('kenoKumulativiSE.nadredjeni'),
         path: 'nadredjeni'
      },
      {
         sortable: true,
         label: intl.getTranslate('kenoKumulativiSE.nadnadredjeni'),
         path: 'nadnadredjeni'
      },
      {
         width: allAgentsSelected ? '17rem' : '7rem',
         sortable: true,
         label: intl.getTranslate('kenoKumulativiSE.datum'),
         path: 'datum'
      },
      {
         width: '10rem',
         sortable: true,
         style: { textAlign: 'right' },
         label: intl.getTranslate('kenoKumulativiSE.uplacenoListica'),
         path: 'uplacenoListica'
      },
      {
         currency: 'Le',
         sortable: true,
         width: '12rem',
         style: { textAlign: 'right' },
         isDecimal: true,
         label: intl.getTranslate('kenoKumulativiSE.iznosUplate'),
         path: 'iznosUplate'
      },
      {
         sortable: true,
         style: { textAlign: 'right' },
         label: intl.getTranslate('kenoKumulativiSE.storniranoListica'),
         path: 'storniranoListica'
      },
      {
         sortable: true,
         style: { textAlign: 'right' },
         label: intl.getTranslate('kenoKumulativiSE.dobitnihListica'),
         path: 'dobitnihListica'
      },
      {
         sortable: true,
         currency: 'Le',
         width: '11rem',
         style: { textAlign: 'right' },
         isDecimal: true,
         label: intl.getTranslate('kenoKumulativiSE.iznosJackpota'),
         path: 'iznosJackpota'
      },
      {
         sortable: true,
         currency: 'Le',
         width: '11rem',
         style: { textAlign: 'right' },
         isDecimal: true,
         label: intl.getTranslate('kenoKumulativiSE.iznosDobitka'),
         path: 'iznosDobitka'
      },
      {
         sortable: true,
         style: { textAlign: 'right' },
         label: intl.getTranslate('kenoKumulativiSE.isplacenoListica'),
         path: 'isplacenoListica'
      },
      {
         sortable: true,
         currency: 'Le',
         width: '11rem',
         style: { textAlign: 'right' },
         isDecimal: true,
         label: intl.getTranslate('kenoKumulativiSE.iznosIsplate'),
         path: 'iznosIsplate'
      },
      {
         sortable: true,
         currency: '$',
         width: '8rem',
         style: { textAlign: 'right' },
         isDecimal: true,
         label: 'Uplata $',
         path: 'iznosUplateDolar'
      },
      {
         sortable: true,
         currency: '€',
         width: '8rem',
         style: { textAlign: 'right' },
         isDecimal: true,
         label: 'Uplata €',
         path: 'iznosUplateEuro'
      },
      {
         sortable: true,
         currency: 'kn',
         width: '8rem',
         style: { textAlign: 'right' },
         isDecimal: true,
         label: 'Uplata KN',
         path: 'iznosUplateKuna'
      },
      {
         label: '',
         path: '-'
      }
   ];

   useEffect(() => {
      if (!zastupniciUcitani) dispatch(ucitajSveZastupnike());
      loadData();
   }, []);

   useEffect(() => {
      if (zastupniciUcitani) {
         const options = sviZastupnici.map((z) => ({
            text: `[${z.operativniBrojZastupnika}] ${z.ime} ${z.prezime}`,
            value: `${z.operativniBrojZastupnika}`
         }));
         setZastupniciOptions([
            { text: '[0] ALL AGENTS', value: 0 },
            ...options
         ]);
      }
   }, [sviZastupnici]);

   const loadData = async () => {
      const { data: response } = await service.get(
         odDatuma,
         doDatuma,
         opbrOdabranogZastupnika
      );
      const { data } = response;

      const remapedData = data.map((d) => ({
         ...d,
         iznosUplate: d.iznosUplate / 100,
         iznosDobitka: d.iznosDobitka / 100,
         iznosIsplate: d.iznosIsplate / 100,
         iznosPoreza: d.iznosPoreza / 100,
         iznosJackpota: d.iznosJackpota / 100,
         iznosUplateDolar: d.iznosUplate / 100 / currencyData.dolar,
         iznosUplateEuro: d.iznosUplate / 100 / currencyData.euro,
         iznosUplateKuna: (d.iznosUplate / 100) * currencyData.kuna
      }));

      setTableData(remapedData);
   };

   const odabraniZastupnikImaUplate = () =>
      tableData.length !== 0
         ? tableData.find((x) => x.operativniBroj === opbrOdabranogZastupnika)
         : false;

   const getRemapedTableData = (tableData) =>
      tableData.map((x) => {
         const nadredjeni = getItemPropsToString(
            x.nadredjeniOpertivniBroj,
            'operativniBrojZastupnika',
            sviZastupnici,
            ['ime', 'prezime']
         );

         const nadnadredjeni = getItemPropsToString(
            x.nadNadredjeniOpertivniBroj,
            'operativniBrojZastupnika',
            sviZastupnici,
            ['ime', 'prezime']
         );

         return {
            ...x,
            nadredjeni,
            nadnadredjeni
         };
      });
   const handleDatumOdChange = (e) => {
      if (!e) return;

      const dateInt = formatDateToInt(e.format('DD.MM.YYYY'));
      setOdDatuma(dateInt);
   };

   const handleDatumDoChange = (e) => {
      if (!e) return;

      const dateInt = formatDateToInt(e.format('DD.MM.YYYY'));
      setDoDatuma(dateInt);
   };

   useEffect(() => {
      if (opbrOdabranogZastupnika < 0) return;
      if (allAgentsSelected) setDisplay(DISPLAY.allAgents);
      loadData();
   }, [opbrOdabranogZastupnika, odDatuma, doDatuma]);

   useEffect(() => {
      setDisplay(
         allAgentsSelected
            ? DISPLAY.allAgents
            : odabraniZastupnikImaUplate()
            ? DISPLAY.all
            : DISPLAY.summary
      );
   }, [tableData]);

   const chartData = tableData.map((d) => ({
      Uplata: d.iznosUplate,
      Isplata: d.iznosIsplate,
      Jackpot: d.iznosJackpota,
      Dobici: d.iznosDobitka,
      datum: d.datum
   }));

   const getBarChartData = () => {
      const barChartData = [];
      chartData.forEach((d) => {
         for (const key in d) {
            const value = d[key];
            if (key !== 'datum' && key !== 'Jackpot' && key !== 'Dobici')
               barChartData.push({ label: key, value, datum: d.datum });
         }
      });
      return barChartData;
   };

   const sumiraniPodaci = getSumiranePodatkePoZastupniku(
      getRemapedTableData(tableData)
   );

   /*
   ALL AGENTS SUMMARY
   */
   const getSum = (datum, arr) => {
      let uplacenoListica = 0;
      let iznosUplate = 0;
      let storniranoListica = 0;
      let dobitnihListica = 0;
      let iznosJackpota = 0;
      let iznosDobitka = 0;
      let isplacenoListica = 0;
      let iznosIsplate = 0;
      let iznosUplateDolar = 0;
      let iznosUplateEuro = 0;
      let iznosUplateKuna = 0;

      arr.forEach((x) => {
         uplacenoListica += x.uplacenoListica;
         iznosUplate += x.iznosUplate;
         storniranoListica += x.storniranoListica;
         dobitnihListica += x.dobitnihListica;
         iznosJackpota += x.iznosJackpota;
         iznosDobitka += x.iznosDobitka;
         isplacenoListica += x.isplacenoListica;
         iznosIsplate += x.iznosIsplate;
         iznosUplateDolar += x.iznosUplateDolar;
         iznosUplateEuro += x.iznosUplateEuro;
         iznosUplateKuna += x.iznosUplateKuna;
      });

      return {
         datum,
         uplacenoListica,
         iznosUplate,
         storniranoListica,
         dobitnihListica,
         iznosJackpota,
         iznosDobitka,
         isplacenoListica,
         iznosIsplate,
         iznosUplateDolar,
         iznosUplateEuro,
         iznosUplateKuna
      };
   };

   const getAllAgentsData = () => {
      const dataGroupedByDate = groupBy(tableData, 'datum');
      const allAgentsData = [];

      for (const datum in dataGroupedByDate) {
         const transakcijeZaDatum = dataGroupedByDate[datum];
         allAgentsData.push(getSum(datum, transakcijeZaDatum));
      }
      return allAgentsData;
   };

   const imaPodataka = tableData.length > 0;
   const pieChartData = allAgentsSelected
      ? getAllAgentsData()
      : sumiraniPodaci.slice(0, 4);
   const showBarChart = odabraniZastupnikImaUplate();

   return (
      <>
         {/* <XLSXParser /> */}
         <div className='form mb-5'>
            <div className='row'>
               <div className='col-4'>
                  <div className='row'>
                     <div className='col'>
                        <DDL
                           options={zastupniciOptions}
                           translationKey='kenoKumulativiSE'
                           translationSubKey='zastupnik'
                           onChange={({ value }) => {
                              setOPBRodabranogZastupnika(+value);
                           }}
                           selectedOption={opbrOdabranogZastupnika}
                           placeholder={intl.getTranslate(
                              'kenoKumulativiSE.zastupnikPlaceholder'
                           )}
                        />
                     </div>
                  </div>
                  {!allAgentsSelected && (
                     <div className='row'>
                        <div className='col'>
                           <DDL
                              options={viewOptions}
                              translationKey='kenoKumulativiSE'
                              translationSubKey='display'
                              onChange={({ value }) => setDisplay(value)}
                              selectedOption={display}
                           />
                        </div>
                     </div>
                  )}
                  <div className='row'>
                     <div className='col-6'>
                        <AntDatepicker
                           label={intl.getTranslate(
                              'kenoKumulativiSE.odDatuma'
                           )}
                           onChange={handleDatumOdChange}
                           defaultDate={integerToMomentDate(odDatuma)}
                        />
                     </div>
                     <div className='col-6'>
                        <AntDatepicker
                           label={intl.getTranslate(
                              'kenoKumulativiSE.doDatuma'
                           )}
                           onChange={handleDatumDoChange}
                           defaultDate={integerToMomentDate(doDatuma)}
                        />
                     </div>
                  </div>
               </div>
               <div className='col-8'>
                  {!allAgentsSelected && imaPodataka && showBarChart && (
                     <AntGroupedBarChart
                        data={getBarChartData()}
                        currency='Le'
                     />
                  )}
                  <div className='pie-chart-containerr'>
                     {!allAgentsSelected && imaPodataka && !showBarChart && (
                        <AntPieChart
                           data={pieChartData}
                           angleField='iznosUplate'
                           colorField='imePrezime'
                           innerRadius={0.7}
                           currency='Le'
                           height={220}
                        />
                     )}
                     {allAgentsSelected && (
                        <AntBasicBarChart data={pieChartData} />
                        // <AntPieChart
                        //    data={pieChartData}
                        //    angleField='iznosUplate'
                        //    colorField='datum'
                        //    innerRadius={0.7}
                        //    currency='Le'
                        //    height={220}
                        // />
                     )}
                  </div>
               </div>
            </div>
         </div>

         {display === DISPLAY.summary && imaPodataka && (
            <DataTable
               columns={[...columns.slice(0, 4), ...columns.slice(5)]}
               data={sumiraniPodaci}
               pageSize={8}
               printEnable={true}
               searchBackground={false}
               searchLabel={intl.getTranslate('kenoKumulativiSE.searchSummary')}
               searchLabelPlaceholder={intl.getTranslate(
                  'kenoKumulativiSE.searchSummaryPlaceholder'
               )}
               searchColumns={['operativniBroj', 'imePrezime']}
               sumColumns={[
                  'uplacenoListica',
                  'iznosUplate',
                  'storniranoListica',
                  'dobitnihListica',
                  'iznosJackpota',
                  'iznosDobitka',
                  'isplacenoListica',
                  'iznosIsplate',
                  'iznosUplateDolar',
                  'iznosUplateEuro',
                  'iznosUplateKuna'
               ]}
            />
         )}

         {display === DISPLAY.all && imaPodataka && (
            <DataTable
               columns={columns}
               data={getRemapedTableData(tableData)}
               pageSize={8}
               printEnable={true}
               searchBackground={false}
               searchLabel={intl.getTranslate('kenoKumulativiSE.search')}
               searchLabelPlaceholder={intl.getTranslate(
                  'kenoKumulativiSE.searchPlaceholder'
               )}
               searchColumns={['operativniBroj', 'imePrezime']}
               sumColumns={[
                  'uplacenoListica',
                  'iznosUplate',
                  'storniranoListica',
                  'dobitnihListica',
                  'iznosJackpota',
                  'iznosDobitka',
                  'isplacenoListica',
                  'iznosIsplate',
                  'iznosUplateDolar',
                  'iznosUplateEuro',
                  'iznosUplateKuna'
               ]}
            />
         )}
         {display === DISPLAY.allAgents && (
            <DataTable
               columns={[...columns.slice(4), ...emptyColumn]}
               data={getAllAgentsData()}
               pageSize={8}
               printEnable={true}
               searchBackground={false}
               searchLabel={intl.getTranslate('kenoKumulativiSE.search')}
               searchLabelPlaceholder={intl.getTranslate(
                  'kenoKumulativiSE.searchPlaceholder'
               )}
               searchColumns={['datum']}
               sumColumns={[
                  'uplacenoListica',
                  'iznosUplate',
                  'storniranoListica',
                  'dobitnihListica',
                  'iznosJackpota',
                  'iznosDobitka',
                  'isplacenoListica',
                  'iznosIsplate',
                  'iznosUplateDolar',
                  'iznosUplateEuro',
                  'iznosUplateKuna'
               ]}
            />
         )}
      </>
   );
};

export default KenoKumulativiSL;
