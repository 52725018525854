import { combineReducers } from 'redux';
import zastupniciReducer from './zastupnici';
import commonReducer from './common';
import a02Reducer from './SierraLeone/A02-prodajna-mjesta';
import xlsxReducer from './SierraLeone/xlsx';

export default combineReducers({
   zastupnici: zastupniciReducer,
   prodajnaMjesta: a02Reducer,
   xlsx: xlsxReducer,
   common: commonReducer
});
