import React, { useEffect, useState } from 'react';
import intl from '../../../services/intl-service';
import DataTable from './../../common/table/datatable';
import { a03postoService as service } from './../../../services/SierraLeone/A03POSTO-service';
import FramerConditionalCollapsableContainer from './../../common/framer-conditional-collapsable-container';
import FramerConditionalOpacityContainer from './../../common/framer-conditional-opacity-container';
import NoDataMessage from './../../common/no-data-message';

const PregledAzuriranjaProvizije = ({ date }) => {
   const columns = [
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('azuriranjeProvizije.pregled.operativniBroj'),
         path: 'opbr'
      },
      {
         sortable: true,
         label: intl.getTranslate('azuriranjeProvizije.pregled.zastupnik'),
         path: 'zastupnik'
      },
      {
         sortable: true,
         label: intl.getTranslate('azuriranjeProvizije.pregled.novaProvizija'),
         path: 'novaProvizija',
         center: true
      },
      {
         sortable: true,
         label: intl.getTranslate('azuriranjeProvizije.pregled.staraProvizija'),
         path: 'staraProvizija',
         center: true
      },
      {
         sortable: true,
         label: intl.getTranslate('azuriranjeProvizije.pregled.operater'),
         path: 'operater',
         center: true
      },
      {
         sortable: true,
         label: intl.getTranslate('azuriranjeProvizije.pregled.vrijeme'),
         path: 'vrijeme'
      }
   ];

   const [data, setData] = useState([]);

   useEffect(() => {
      if (date) loadData();
   }, [date]); // eslint-disable-line react-hooks/exhaustive-deps

   const loadData = async () => {
      const { data: response } = await service.getUpisani(date);

      if (!response.data) {
         setData([]);
         return;
      }

      const data = response.data.map((d) => {
         return {
            opbr: d.operativniBroj,
            zastupnik: `${d.ime} ${d.prezime}`,
            novaProvizija: d.novaProvizijeKeno,
            staraProvizija: d.staraProvizijeKeno,
            operater: d.operaterUpisa,
            vrijeme: d.vrijemeUpisa
         };
      });

      setData(data);
   };

   const handleOnLevelClick = (rowData) => {
      // console.log(rowData);
   };

   return (
      <>
         <NoDataMessage
            messageKey='azuriranjeProvizije.nemaPodatakaZaDatum'
            data={data}
         />
         <FramerConditionalCollapsableContainer
            condition={data.length > 0}
            delay={0.2}
            height='25rem'
         >
            <DataTable
               columns={columns}
               data={data}
               hideSearch={true}
               pageSize={6}
               onRowClick={handleOnLevelClick}
            />
         </FramerConditionalCollapsableContainer>
      </>
   );
};

export default PregledAzuriranjaProvizije;
