import React from 'react';
import intl from '../../../services/intl-service';
import Input from './../input';
import {
   debounceFormInput,
   debounce
} from './../../../services/utilities-service';

const SearchTerm = ({
   searchTerm,
   setSearchTerm,
   data,
   filteredData,
   debounceDelay = 50,
   setCurrentPage,
   noSearchDataCondition,
   label = intl.getTranslate('common.pretraga'),
   placeholder = intl.getTranslate('common.pretragaPlaceholder')
}) => {
   const updateStateDebounce = debounce((searchTerm) => {
      setCurrentPage(1);
      setSearchTerm(searchTerm);
   }, debounceDelay);

   const handleOnChange = ({ target: input }) => {
      updateStateDebounce(input.value);
   };

   return (
      <div className='search-term'>
         <Input
            label={`${label} ${
               searchTerm.length !== 0
                  ? filteredData.length > 0
                     ? `- ${filteredData.length}/${data.length}`
                     : ''
                  : ''
            }`}
            name='search'
            onChange={handleOnChange}
            placeholder={placeholder}
         />
         {noSearchDataCondition && (
            <div className='mb-5'>
               {intl.getTranslate('common.zaUneseniPojam')}{' '}
               <span
                  style={{
                     fontWeight: 700,
                     color: 'white',
                     backgroundColor: '#dc136c'
                  }}
               >{`${searchTerm}`}</span>{' '}
               {intl.getTranslate('common.nemaRezultataPretrage')}
            </div>
         )}
      </div>
   );
};

export default SearchTerm;
