import http from './http';
import api from '../config/a21-terminal-config.json';

export const pregledAktivnostiTerminalaService = {
    get: (odDatuma, doDatuma, tipPrikazaTerminala) =>
        http.post(`${api.getTerminaliSvi}`, {
            odDatuma,
            doDatuma,
            tipPrikazaTerminala
        }),
    staticData: () =>
        http
            .postData(`${api.getPodaciZaComboBox}`)
            .then((response) => response.tipPrikazaTerminala)
};
