import React from 'react';
import Form from '../common/form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import intl from '../../services/intl-service';
import { regijaService } from '../../services/m-o-r-service';
import { toastError, toastSuccess } from '../../services/toast-service';
import { azuriranjeRegijeSchema } from './regije-validacije';
import CustomAlertNoForm from './../common/custom-alert-no-form';
import InfoVrijemeUpisa from './../common/info-vrijeme-upisa';
import { DataLoader } from './../../services/data-loader-service';
import CancelButton from '../common/cancel-button';
//Debounce: https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js

class UrediRegiju extends Form {
   state = {
      validationData: {
         sifraRegije: '',
         nazivRegije: ''
      },
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      showConfirm: false
   };

   schema = azuriranjeRegijeSchema;

   componentDidMount() {
      const { odabranaRegija } = this.props;
      if (!odabranaRegija) return;

      const validationData = { ...this.state.validationData };
      const { sifraRegije, nazivRegije } = odabranaRegija;

      validationData.sifraRegije = sifraRegije;
      validationData.nazivRegije = nazivRegije;

      this.setState({ validationData });
   }

   doSubmit = () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const { sifraRegije, nazivRegije } = this.state.validationData;
      const azuriraniPodaci = { sifraRegije, nazivRegije, operaterUpisa: 14 };
      const { data: response } = await regijaService.addOrUpdate(
         azuriraniPodaci
      );

      if (response.isSuccess === 1) {
         toastSuccess();
         this.props.onSubmit();
         this.setState({ showConfirm: false });
      } else toastError();
   };

   onDataLoad = (response) => {
      const validationData = { ...this.state.validationData };
      const { nazivRegije, datumUpisa, vrijemeUpisa } = response;
      validationData.nazivRegije = nazivRegije;

      this.setState({ validationData, datumUpisa, vrijemeUpisa });
   };

   ucitajPodatkeRegije = async (sifraRegije) => {
      DataLoader(regijaService.get, [sifraRegije], this.onDataLoad);
   };

   handleSifraRegijeChange = async ({ target: input }) => {
      if (isNaN(input.value)) return;

      const sifraRegije = Number(input.value);
      if (sifraRegije >= 0 && sifraRegije <= 9999)
         this.ucitajPodatkeRegije(sifraRegije);
   };

   render() {
      const { validationData, showConfirm, datumUpisa, vrijemeUpisa } =
         this.state;
      const { nazivRegije } = validationData;

      return (
         <>
            <CustomAlertNoForm
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               onOk={() => this.doSubmitOnConfirm()}
            >
               <div>
                  {intl.getTranslate('regija.confirmMessage')}{' '}
                  <span className='confirm-highlight'>{nazivRegije}</span>?
               </div>
            </CustomAlertNoForm>

            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-opcinu'>
                  <div className='row'>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'sifraRegije',
                           'regija',
                           this.handleSifraRegijeChange,
                           true,
                           null,
                           { disabled: 'disabled' }
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-4'>
                        {this.renderTranslateInput('nazivRegije', 'regija')}
                     </div>
                  </div>

                  <hr />

                  <InfoVrijemeUpisa
                     datumUpisa={datumUpisa}
                     vrijemeUpisa={vrijemeUpisa}
                  />

                  {this.renderSubmitButton(
                     `${intl.getTranslate('common.edit')}`
                  )}
                  <CancelButton onClick={this.props.onCancel} />
               </div>
            </form>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   //zastupnici: state.zastupnici
});

const mapDispatchToProps = (dispatch) => ({
   //saveToStore: (data) => dispatch(setUserToStore(data))
});

export default injectIntl(
   connect(mapStateToProps, mapDispatchToProps)(UrediRegiju)
);
