export const first = (arr, count = 1) => {
   if (count === 1) return arr[0];
   return arr.filter((_, index) => index < count);
};

export const last = (arr, count = 1) => {
   if (count === 1) return arr[arr.length - 1];
   return arr.filter((_, index) => arr.length - index <= count);
};

export const groupBy = (arr, key) =>
   arr.reduce((group, element) => {
      const keyValue = element[key];
      return { ...group, [keyValue]: [...(group[keyValue] ?? []), element] };
   }, {});

export const mapToKeyies = (arr, keyies) =>
   arr.map((item) => {
      const obj = {};
      keyies.forEach((key) => {
         obj[key] = item[key];
      });
      return obj;
   });

export const sum = (arr, initialValue = 0) =>
   arr.reduce((sum, current) => sum + current, initialValue);

export const sumByKey = (arr, key, initialValue = 0) =>
   arr.reduce((sum, current) => sum + current[key], initialValue);

const exportObj = {
   sum,
   sumByKey,
   first,
   last,
   groupBy,
   mapToKeyies
};

export default exportObj;
