import React from 'react';
import Form from '../common/form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import intl from '../../services/intl-service';
import { pregledKumulativaSchema } from './pregled-kumulativa-validacije';
import { pregledKumulativaService as service } from '../../services/a20-pregled-kumulativa-service';
//import { toastError } from '../../services/toast-service';
import PrikazPodatakaKumulativa from './prikaz-podataka-kumulativa';
import { v4 as uuid } from 'uuid';
import FramerConditionalCollapsableContainer from './../common/framer-conditional-collapsable-container';
import AntDropDownListTranslate from './../common/ant-ddl-translate';
import Chart from '../common/chart';
import { DataLoader } from './../../services/data-loader-service';

const showAllKeyValue = 'sve';
class PregledKumulativa extends Form {
   state = {
      validationData: {
         operativniBroj: ''
      },
      podaci: [],
      igreZastupnika: [],
      odabranaIgra: '',
      podaciOdabraneIgre: [],
      ddlFilteropcije: [],
      chartData: [],
      filter: '',
      errors: {}
   };

   schema = pregledKumulativaSchema;

   onDataLoad = (response) => {
      const { data: podaci } = response;

      if (podaci && podaci.length > 0) {
         const { errors } = this.state;
         const _errors = { ...errors };
         delete _errors.operativniBroj;

         this.setState({ podaci, errors: _errors }, () => {
            this.prikaziIgre_u_DDL(podaci);
         });
      } else {
         const { errors } = this.state;
         const _errors = { ...errors };
         _errors.operativniBroj = intl.getTranslate(
            'pregledKumulativa.nepostojeciOPBR'
         );
         this.setState({ errors: _errors });
      }
      // showToast(
      //    intl.getTranslate('pregledKumulativa.nepostojeciOPBR'),
      //    enumToastType.INFO,
      //    { autoClose: 5000 }
      // );
   };

   ucitajPodatke = (operativniBroj) => {
      DataLoader(service.get, [operativniBroj], this.onDataLoad);
   };

   /*
      Kreiraj polje opcija za padajuci izbornik odabira igre
   */
   prikaziIgre_u_DDL = (podaci) => {
      const igreZastupnika = podaci.map((a, i) => {
         return {
            text: a.igra,
            value: i
         };
      });
      // prvu igru automatski selektiraj
      const odabranaIgra = igreZastupnika[0].text;

      this.setState({ igreZastupnika, odabranaIgra }, () => {
         this.izdvojiPodatkeOdabraneIgre();
      });
   };

   izdvojiPodatkeOdabraneIgre = () => {
      const { odabranaIgra, podaci } = this.state;
      const filtriraniPodaci = podaci.filter((d) => d.igra === odabranaIgra);

      if (filtriraniPodaci.length > 0) {
         const podaciOdabraneIgre = filtriraniPodaci[0].podaciZaIgru;
         this.setState({ podaciOdabraneIgre }, () => {
            this.prikaziOpcijePadajucegIzbornikaFilter();
            this.setState({ chartData: this.getChartData() });
         });
      }
   };

   prikaziOpcijePadajucegIzbornikaFilter = () => {
      const { podaciOdabraneIgre } = this.state;

      // Kao prvu opciju dodajem [showAllKeyValue]
      const prvaOpcijaFiltra = {
         text: intl.getTranslate('pregledKumulativa.termin.sve'),
         value: showAllKeyValue
      };

      const ddlFilteropcije = [
         prvaOpcijaFiltra,
         ...podaciOdabraneIgre.map((p, i) => {
            return {
               text: intl.getTranslate(`pregledKumulativa.termin.${p.termin}`),
               value: p.termin
            };
         })
      ];

      const filter = showAllKeyValue;

      this.setState({ ddlFilteropcije, filter });
   };

   handleOperativniBrojChange = async ({ target: input }) => {
      if (isNaN(input.value)) return;

      const operativniBroj = Number(input.value);
      if (operativniBroj >= 0 && operativniBroj <= 9999) {
         this.ucitajPodatke(operativniBroj);
      }
   };

   handleIgraChange = ({ text, value }) => {
      this.setState({ odabranaIgra: text }, () => {
         this.izdvojiPodatkeOdabraneIgre();
      });
   };

   handleFilterChange = ({ text, value }) => {
      this.setState({
         filter: value === 0 ? text.toLowerCase() : value
      });
   };

   getPodigreData = (arrPodigre) => {
      return arrPodigre.map((a) => ({
         naziv: a.nazivPodigre,
         uplata: a.uplata,
         isplata: a.isplata
      }));
   };

   getChartData = () => {
      const { podaciOdabraneIgre } = this.state;
      const _data = podaciOdabraneIgre.slice(0, 7);

      const data = _data.map((d) => {
         const podigre = this.getPodigreData(d.podaciZaPodigre);

         const returnObj = {};

         podigre.forEach((p) => {
            returnObj[p.naziv] = parseFloat(p.uplata);
         });
         returnObj.dan = intl.getTranslate(
            `pregledKumulativa.termin.${d.termin}`
         );
         return returnObj;
      });
      return data;
   };

   // Vrati sirove podatke, bez X osi [dan]
   getRawChartData = () => {
      //Izdvoji X os (dan) iz kolekcije
      return this.getChartData().map((c) => {
         delete c.dan;
         return {
            ...c
         };
      });
   };

   getNaziviPodigara = () => {
      const rawData = this.getRawChartData();
      return rawData.length > 0 ? Object.keys(rawData[0]) : [];
   };

   getPodigraMaxValue = () => {
      const rawData = this.getRawChartData();
      const podigraMaxValues = [];
      this.getNaziviPodigara().forEach((podigra, i) => {
         const podigraValues = rawData.map((d) => {
            return d[podigra];
         });
         podigraMaxValues.push(Math.max(...podigraValues));
      });
      // sortiraj od vece prema manjoj vrijednosti
      return podigraMaxValues.sort((a, b) => a + b)[0];
   };

   // Vrati naziv podigre koja ima najveću vrijednost.
   // Prema toj vrijednosti treba definirat Y os [YAxisDataKey]
   getNazivMaxValuePodigra = () => {
      const rawData = this.getRawChartData();
      const naziviPodigara = this.getNaziviPodigara();
      const max = this.getPodigraMaxValue();
      let nazivMax;

      rawData.forEach((data) => {
         naziviPodigara.forEach((naziv) => {
            if (data[naziv] === max) {
               nazivMax = naziv;
               return;
            }
         });
         if (nazivMax) return;
      });

      return nazivMax;
   };

   render() {
      const {
         igreZastupnika,
         odabranaIgra,
         podaciOdabraneIgre,
         ddlFilteropcije,
         filter,
         chartData,
         podaci
      } = this.state;

      const { operativniBroj } = this.state.validationData;
      const unesenIspravanOB =
         operativniBroj !== '' && operativniBroj >= 0 && podaci.length > 0;

      const ddlOdabranaIgraAttributes =
         igreZastupnika.length > 0 ? { value: odabranaIgra } : null;

      const filtriraniPodaci = () => {
         if (podaciOdabraneIgre.length === 0) return [];
         if (filter === showAllKeyValue) return podaciOdabraneIgre;

         return podaciOdabraneIgre.filter(
            (i) => i.termin.toLowerCase() === filter.toLowerCase()
         );
      };

      const ddlFilterAttributes = filter
         ? filter === 0
            ? { value: intl.getTranslate('pregledKumulativa.termin.sve') }
            : {
                 value: intl.getTranslate(`pregledKumulativa.termin.${filter}`)
              }
         : { value: null };

      return (
         <form onSubmit={this.handleFormSubmit} className='form'>
            <FramerConditionalCollapsableContainer
               condition={chartData.length > 0}
               height={'40rem'}
               delay={0.5}
            >
               <Chart
                  data={chartData}
                  values={this.getNaziviPodigara()}
                  xAxisDataKey='dan'
                  yAxisDataKey={this.getNazivMaxValuePodigra()}
                  colors={['#2069ad', '#ff2d2d', '#41af32']}
                  height={400}
               />
            </FramerConditionalCollapsableContainer>
            <div className='uredi-opcinu mt-2'>
               <div className='row'>
                  <div className='col-4'>
                     {this.renderTranslateInput(
                        'operativniBroj',
                        'pregledKumulativa',
                        this.handleOperativniBrojChange,
                        true
                     )}
                  </div>
                  {unesenIspravanOB && (
                     <>
                        <div className='col-4'>
                           <AntDropDownListTranslate
                              options={igreZastupnika}
                              translationKey='pregledKumulativa'
                              translationSubKey='igra'
                              onChange={this.handleIgraChange}
                              attributes={ddlOdabranaIgraAttributes}
                           />
                        </div>
                        <div className='col-4'>
                           <AntDropDownListTranslate
                              options={ddlFilteropcije}
                              translationKey='pregledKumulativa'
                              translationSubKey='filter'
                              onChange={this.handleFilterChange}
                              attributes={ddlFilterAttributes}
                           />
                        </div>
                     </>
                  )}
               </div>
            </div>
            <FramerConditionalCollapsableContainer
               condition={odabranaIgra && unesenIspravanOB}
               height={filter === showAllKeyValue ? '80rem' : '17rem'}
            >
               <hr />
               <div className='row'>
                  {filtriraniPodaci().map((p, i) => (
                     <div className='col-4' key={uuid()}>
                        <PrikazPodatakaKumulativa data={p} delay={i / 20} />
                     </div>
                  ))}
               </div>
            </FramerConditionalCollapsableContainer>
         </form>
      );
   }
}

const mapStateToProps = (state) => ({
   //aktivnostTerminala: state.common.aktivnostTerminala
});

const mapDispatchToProps = (dispatch) => ({
   //ucitajAktivnostiTerminala: () => dispatch(ucitajAktivnostiTerminala())
});

export default injectIntl(
   connect(mapStateToProps, mapDispatchToProps)(PregledKumulativa)
);
