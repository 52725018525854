import React from 'react';
import NirvanaLogo from '../../resources/pic/playnirvana_group.png';
import { NavLink } from 'react-router-dom';
import translate from '../../i18n/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { menuPregled } from './submenus/menu-pregled';
import { menuAzuriranja } from './submenus/menu-azuriranja';
import NavbarDropdownLink from './navbar-dropdown-link';
import { getCurrentUser } from './../../services/auth-service';
import intl from '../../services/intl-service';
import { motion } from 'framer-motion';
import { paths } from './../../routes/routes';
import { filteredPublishSubmenuRoutes } from './../../services/app-service';
import UserAvatar from './user-avatar';

const Navbar = (props) => {
   const handleButtonClick = () => {
      const { settingsVisible, setSettingsVisible } = props;
      setSettingsVisible(!settingsVisible);
   };

   const submenuPaths = (routes) => routes.map((route) => route.path);

   return (
      <motion.div
         className='navbar'
         initial={{ top: '-7rem', opacity: 0 }}
         animate={{
            opacity: getCurrentUser() ? 1 : 0,
            top: getCurrentUser() ? 0 : '-7rem',
            transition: { duration: 0.5, ease: 'easeOut' }
         }}
      >
         <div className='logo'>
            <NavLink to='/'>
               <img src={NirvanaLogo} alt='playnirvana_group.png' />
            </NavLink>
         </div>
         <div className='navigationLinks'>
            {getCurrentUser() && (
               <ul>
                  <NavbarDropdownLink
                     to={paths.popisLinkovaZaPregled}
                     translationKey='navigacija.pregled'
                     submenu={filteredPublishSubmenuRoutes(menuPregled)}
                     subpaths={submenuPaths(
                        filteredPublishSubmenuRoutes(menuPregled)
                     )}
                  />
                  <NavbarDropdownLink
                     to={paths.popisLinkovaZaAzuriranje}
                     translationKey='navigacija.azuriranje'
                     submenu={filteredPublishSubmenuRoutes(menuAzuriranja)}
                     subpaths={submenuPaths(
                        filteredPublishSubmenuRoutes(menuAzuriranja)
                     )}
                  />
                  <li>
                     <button onClick={handleButtonClick}>
                        {translate('navigacija.postavke')}
                     </button>
                  </li>
               </ul>
            )}
         </div>
         <div className='login'>
            <UserAvatar />
         </div>
      </motion.div>
   );
};

export default Navbar;
