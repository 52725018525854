import React from 'react';
import { FormOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import { GoChevronLeft } from 'react-icons/go';

const ButtonAdditionalTitleLink = ({ label, path, history }) => {
   return (
      <div style={{ textAlign: 'right', marginTop: '-2rem' }} className='mb-3'>
         <button className='button link' onClick={() => history.push(path)}>
            <GoChevronLeft />
            <span className='ml-2'>{label}</span>
         </button>
      </div>
   );
};

export default withRouter(ButtonAdditionalTitleLink);
