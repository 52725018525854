import React, { useEffect, useState } from 'react';
import DataTable from './../../common/table/datatable';
import intl from '../../../services/intl-service';
import { zastupniciService as service } from './../../../services/zastupnici-service';
import { DataLoader } from '../../../services/data-loader-service';
import { Steps, Divider } from 'antd';

const columns = [
   {
      sortable: true,
      center: true,
      label: intl.getTranslate('stabloZastupnika.opbr'),
      path: 'opbr'
   },
   {
      sortable: true,
      center: true,
      label: intl.getTranslate('stabloZastupnika.provizija'),
      path: 'provizija'
   },
   {
      sortable: true,
      label: intl.getTranslate('stabloZastupnika.imePrezime'),
      path: 'imePrezime'
   }
];
const StabloZastupnika = (props) => {
   const { Step } = Steps;
   const [data, setData] = useState([]);
   const [primarni, setPrimarni] = useState([]);
   const [sekundarni, setSekundarni] = useState([]);
   const [tercijarni, setTercijarni] = useState([]);

   const [odabraniPrimarni, setOdabraniPrimarni] = useState('');
   const [odabraniSekundarni, setOdabraniSekundarni] = useState('');
   const [stepCount, setStepCount] = useState(0);

   const onDataLoad = (response) => {
      const { data } = response;
      const { primarni } = data;

      setData(data);
      setPrimarni(primarni);
   };

   useEffect(() => {
      DataLoader(service.getStablo, null, onDataLoad);
   }, []);

   return (
      <div className='stablo-zastupnika'>
         <div
            className='mt-5 mb-5'
            style={{ padding: '0 10rem', paddingTop: '5rem' }}
         >
            <Steps progressDot current={stepCount}>
               <Step
                  title='Level 1 representative'
                  description='Select representative'
               />
               <Step
                  title={odabraniPrimarni && `${odabraniPrimarni}`}
                  description={odabraniPrimarni && 'Assigned representatives'}
               />

               <Step
                  title={odabraniSekundarni && `${odabraniSekundarni}`}
                  description={odabraniSekundarni && 'Assigned representatives'}
               />
            </Steps>
         </div>
         {/* <div className='row'>
            <div className='col'>
               <h2>Level 1</h2>
            </div>
            <div className='col'>
               <h2>{odabraniPrimarni && `${odabraniPrimarni} >`}</h2>
            </div>
            <div className='col'>
               <h2>{odabraniSekundarni && `${odabraniSekundarni} >`}</h2>
            </div>
         </div> */}
         <div className=''>
            <div className='row mt-5'>
               <div className={odabraniPrimarni ? 'col border-right' : 'col'}>
                  <DataTable
                     columns={columns}
                     pageSize={15}
                     data={primarni}
                     hideSearch={true}
                     searchLabel={intl.getTranslate(
                        'pregledSvihKenoListica.pretraga'
                     )}
                     searchLabelPlaceholder={intl.getTranslate(
                        'pregledSvihKenoListica.pretragaPlaceholder'
                     )}
                     searchColumns={['opbr', 'imePrezime']}
                     onRowClick={({ imePrezime, sekundarni }) => {
                        setStepCount(1);
                        setOdabraniPrimarni(imePrezime);
                        setSekundarni(sekundarni);
                     }}
                     markRowOnClick={true}
                  />
               </div>
               <div className={odabraniSekundarni ? 'col border-right' : 'col'}>
                  <DataTable
                     columns={columns}
                     pageSize={15}
                     data={sekundarni}
                     hideSearch={true}
                     hideNoDataMessage={true}
                     searchLabel={intl.getTranslate(
                        'pregledSvihKenoListica.pretraga'
                     )}
                     searchLabelPlaceholder={intl.getTranslate(
                        'pregledSvihKenoListica.pretragaPlaceholder'
                     )}
                     searchColumns={['opbr', 'imePrezime']}
                     onRowClick={({ imePrezime, tercijarni }) => {
                        setStepCount(2);
                        setOdabraniSekundarni(imePrezime);
                        setTercijarni(tercijarni);
                     }}
                     markRowOnClick={true}
                  />
               </div>
               <div className='col'>
                  <DataTable
                     columns={columns}
                     pageSize={15}
                     data={tercijarni}
                     hideNoDataMessage={true}
                     hideSearch={true}
                     searchLabel={intl.getTranslate(
                        'pregledSvihKenoListica.pretraga'
                     )}
                     searchLabelPlaceholder={intl.getTranslate(
                        'pregledSvihKenoListica.pretragaPlaceholder'
                     )}
                     searchColumns={['opbr', 'imePrezime']}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default StabloZastupnika;
