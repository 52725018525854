import messages from '../i18n/messages';
import { createIntl, createIntlCache } from 'react-intl';
import { flattenMessages } from '../utils/flatten-messages';
import { getUsedLanguage } from './app-service';

const cache = createIntlCache();

const intl = createIntl(
   {
      locale: 'hr',
      messages: flattenMessages(messages[getUsedLanguage()])
   },
   cache
);

const getTranslate = (id) => intl.formatMessage({ id });

const getMessageUpdateOK = getTranslate('common.podaciUspjesnoAzurirani');
const getMessageUpdateError = getTranslate('common.greskaPrilikomAzuriranja');

const exportObj = {
   intl,
   getTranslate,
   getMessageUpdateOK,
   getMessageUpdateError
};

export default exportObj;
//  https://stackoverflow.com/questions/42414416/react-intl-define-messages-outside-of-react
