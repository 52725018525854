import http from '../http';
import api from '../../config/SierraLeone/ke10-pregled-kola-po-danu.json';

export const pregledKolaPoDanuService = {
   get: (odDatuma, doDatuma) => {
      return http.post(`${api.get}`, {
         odDatuma,
         doDatuma
      });
   }
};
