import React from 'react';
import Form from '../common/form';
import { injectIntl } from 'react-intl';
import intl from '../../services/intl-service';
import { azuriranjeProdajnogMjestaSchema } from './uredi-prodajno-mjesto-validacije';
import { prodajnoMjestoService } from './../../services/a02-prodajno-mjesto-service';
import { toastError, toastSuccess } from '../../services/toast-service';
import {
   mjestoService,
   opcinaService,
   regijaService
} from './../../services/m-o-r-service';
import CustomAlertNoForm from './../common/custom-alert-no-form';
import InfoVrijemeUpisa from '../common/info-vrijeme-upisa';
import FramerConditionalCollapsableContainer from './../common/framer-conditional-collapsable-container';
import { DataLoader } from './../../services/data-loader-service';
import { getUserId } from './../../services/auth-service';

class UrediProdajnoMjesto extends Form {
   state = {
      validationData: {
         sifraProdajnogMjesta: '',
         sifraNaseljenogMjesta: '',
         nazivProdajnogMjesta: '',
         adresaProdajnogMjesta: '',
         aktivnostProdajnogMjesta: '',
         pozivniBrojTelefona: '',
         brojTelefona: '',
         sifraOpcine: '',
         sifraRegije: '',
         tipLokala: '',
         brojTerminala: '',
         kategorijaProdajnogMjesta: '',
         zabranaProdajeBingo: '',
         zabranaIsplateBingo: '',
         operaterUpisa: ''
      },
      nazivMjesta: '',
      nazivRegije: '',
      nazivOpcine: '',
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      showConfirm: false
   };

   schema = azuriranjeProdajnogMjestaSchema;

   doSubmit = async () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const {
         sifraProdajnogMjesta,
         sifraNaseljenogMjesta,
         nazivProdajnogMjesta,
         adresaProdajnogMjesta,
         aktivnostProdajnogMjesta,
         pozivniBrojTelefona,
         brojTelefona,
         sifraOpcine,
         sifraRegije,
         tipLokala,
         brojTerminala,
         kategorijaProdajnogMjesta,
         zabranaProdajeBingo,
         zabranaIsplateBingo
      } = this.state.validationData;

      const auriranoProdajnoMjesto = {
         sifraProdajnogMjesta,
         sifraNaseljenogMjesta,
         nazivProdajnogMjesta,
         adresaProdajnogMjesta,
         aktivnostProdajnogMjesta,
         pozivniBrojTelefona,
         brojTelefona,
         sifraOpcine,
         sifraRegije,
         tipLokala,
         brojTerminala,
         kategorijaProdajnogMjesta,
         zabranaProdajeBingo,
         zabranaIsplateBingo,
         operaterUpisa: getUserId()
      };

      const response = await prodajnoMjestoService.addOrUpdate(
         auriranoProdajnoMjesto
      );

      if (response.isSuccess === 1) {
         toastSuccess();
         this.resetForm('sifraProdajnogMjesta');
      } else toastError();

      this.setState({ showConfirm: false });
   };

   onDataLoad = (response) => {
      const validationData = { ...this.state.validationData };
      const {
         sifraProdajnogMjesta,
         sifraNaseljenogMjesta,
         nazivProdajnogMjesta,
         adresaProdajnogMjesta,
         aktivnostProdajnogMjesta,
         pozivniBrojTelefona,
         brojTelefona,
         sifraOpcine,
         sifraRegije,
         tipLokala,
         brojTerminala,
         kategorijaProdajnogMjesta,
         zabranaProdajeBingo,
         zabranaIsplateBingo,
         operaterUpisa,
         datumUpisa,
         vrijemeUpisa
      } = response;

      validationData.sifraProdajnogMjesta = sifraProdajnogMjesta;
      validationData.sifraNaseljenogMjesta = sifraNaseljenogMjesta;
      validationData.nazivProdajnogMjesta = nazivProdajnogMjesta;
      validationData.adresaProdajnogMjesta = adresaProdajnogMjesta;
      validationData.aktivnostProdajnogMjesta = aktivnostProdajnogMjesta;
      validationData.pozivniBrojTelefona = pozivniBrojTelefona;
      validationData.brojTelefona = brojTelefona ? brojTelefona : '';
      validationData.sifraOpcine = sifraOpcine;
      validationData.sifraRegije = sifraRegije;
      validationData.tipLokala = tipLokala;
      validationData.brojTerminala = brojTerminala;
      validationData.kategorijaProdajnogMjesta = kategorijaProdajnogMjesta;
      validationData.zabranaProdajeBingo = zabranaProdajeBingo;
      validationData.zabranaIsplateBingo = zabranaIsplateBingo;
      validationData.operaterUpisa = operaterUpisa;

      this.setState({ validationData, datumUpisa, vrijemeUpisa }, () => {
         this.ucitajNaziveMjestaRegijeOpcine(
            sifraNaseljenogMjesta,
            sifraRegije,
            sifraOpcine
         );
      });
   };

   ucitajPodatkeProdajnogMjesta = async (_sifraProdajnogMjesta) => {
      DataLoader(
         prodajnoMjestoService.get,
         [_sifraProdajnogMjesta],
         this.onDataLoad
      );
      // DataLoader(
      //    getProdajnoMjestoData,
      //    [_sifraProdajnogMjesta],
      //    this.onDataLoad
      // );
   };

   onMjestoLoaded = (response) => {
      const { nazivMjesta } = response;
      this.setState({ nazivMjesta });
   };

   onRegijaLoaded = (response) => {
      const { nazivRegije } = response;
      this.setState({ nazivRegije });
   };

   onOpcinaLoaded = (response) => {
      const { nazivOpcine } = response;
      this.setState({ nazivOpcine });
   };

   ucitajNaziveMjestaRegijeOpcine = async (mjestoID, regijaID, opcinaID) => {
      DataLoader(mjestoService.get, [mjestoID], this.onMjestoLoaded);
      DataLoader(regijaService.get, [regijaID], this.onRegijaLoaded);
      DataLoader(opcinaService.get, [opcinaID], this.onOpcinaLoaded);
   };

   handleSifraProdajnogMjestaChange = async ({ target: input }) => {
      if (isNaN(input.value)) return;

      const sifraProdajnogMjesta = Number(input.value);
      if (sifraProdajnogMjesta >= 0 && sifraProdajnogMjesta <= 9999)
         this.ucitajPodatkeProdajnogMjesta(sifraProdajnogMjesta);
   };

   handleSifraNaseljenogMjestaChange = async ({ target: input }) => {
      if (input.value === '') {
         this.setState({ nazivMjesta: '' });
         return;
      }
      if (isNaN(input.value)) return;

      const sifraNaseljenogMjesta = Number(input.value);
      if (sifraNaseljenogMjesta >= 10000 && sifraNaseljenogMjesta <= 99999) {
         const { data: mjesto } = await mjestoService.get(
            sifraNaseljenogMjesta
         );
         const { nazivMjesta } = mjesto;

         this.setState({ nazivMjesta });
      } else {
         this.setState({ nazivMjesta: '' });
      }
   };

   handleSifraRegijeChange = async ({ target: input }) => {
      if (input.value === '') {
         this.setState({ nazivRegije: '' });
         return;
      }
      if (isNaN(input.value)) return;

      const sifraRegije = Number(input.value);
      if (sifraRegije >= 0 && sifraRegije <= 99) {
         const { data: regija } = await regijaService.get(sifraRegije);
         const { nazivRegije } = regija;

         this.setState({ nazivRegije });
      } else {
         this.setState({ nazivRegije: '' });
      }
   };

   handleSifraOpcineChange = async ({ target: input }) => {
      if (input.value === '') {
         this.setState({ nazivOpcine: '' });
         return;
      }
      if (isNaN(input.value)) return;

      const sifraOpcine = Number(input.value);
      if (sifraOpcine >= 0 && sifraOpcine <= 9500) {
         const { data: opcina } = await opcinaService.get(sifraOpcine);
         const { nazivOpcine } = opcina;

         this.setState({ nazivOpcine });
      } else {
         this.setState({ nazivOpcine: '' });
      }
   };

   render() {
      const {
         validationData,
         datumUpisa,
         vrijemeUpisa,
         showConfirm,
         nazivMjesta,
         nazivRegije,
         nazivOpcine
      } = this.state;
      const { sifraProdajnogMjesta } = validationData;

      const sifraProdajnogMjestaJeUpisana =
         sifraProdajnogMjesta >= 1 && sifraProdajnogMjesta <= 9999;

      return (
         <>
            <CustomAlertNoForm
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               onOk={() => this.doSubmitOnConfirm()}
            >
               <div>{intl.getTranslate('prodajnaMjesta.confirmMessage')}</div>
            </CustomAlertNoForm>
            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-opcinu'>
                  <div className='row'>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'sifraProdajnogMjesta',
                           'prodajnaMjesta',
                           this.handleSifraProdajnogMjestaChange,
                           true
                        )}
                     </div>
                  </div>
                  <FramerConditionalCollapsableContainer
                     condition={sifraProdajnogMjestaJeUpisana}
                     height='37rem'
                  >
                     <div className='row'>
                        <div className='col-4'>
                           {this.renderTranslateInput(
                              'sifraNaseljenogMjesta',
                              'prodajnaMjesta',
                              this.handleSifraNaseljenogMjestaChange,
                              true,
                              nazivMjesta
                           )}

                           {this.renderTranslateInput(
                              'nazivProdajnogMjesta',
                              'prodajnaMjesta'
                           )}

                           {this.renderTranslateInput(
                              'adresaProdajnogMjesta',
                              'prodajnaMjesta'
                           )}

                           {this.renderTranslateInput(
                              'aktivnostProdajnogMjesta',
                              'prodajnaMjesta',
                              null,
                              true
                           )}
                        </div>
                        <div className='col-4'>
                           {this.renderTranslateInput(
                              'pozivniBrojTelefona',
                              'prodajnaMjesta',
                              null,
                              true
                           )}

                           {this.renderTranslateInput(
                              'brojTelefona',
                              'prodajnaMjesta',
                              null,
                              true
                           )}

                           {this.renderTranslateInput(
                              'sifraRegije',
                              'prodajnaMjesta',
                              this.handleSifraRegijeChange,
                              true,
                              nazivRegije
                           )}

                           {this.renderTranslateInput(
                              'sifraOpcine',
                              'prodajnaMjesta',
                              this.handleSifraOpcineChange,
                              true,
                              nazivOpcine
                           )}
                        </div>
                        <div className='col-4'>
                           {this.renderTranslateInput(
                              'tipLokala',
                              'prodajnaMjesta',
                              null,
                              true
                           )}

                           {this.renderTranslateInput(
                              'brojTerminala',
                              'prodajnaMjesta',
                              null,
                              true
                           )}

                           {this.renderTranslateInput(
                              'kategorijaProdajnogMjesta',
                              'prodajnaMjesta',
                              null,
                              true
                           )}
                        </div>
                     </div>

                     <hr />

                     <InfoVrijemeUpisa
                        datumUpisa={datumUpisa}
                        vrijemeUpisa={vrijemeUpisa}
                     />

                     {this.renderSubmitButton(
                        `${intl.getTranslate('common.edit')}`
                     )}
                  </FramerConditionalCollapsableContainer>
               </div>
            </form>
         </>
      );
   }
}

export default injectIntl(UrediProdajnoMjesto);
