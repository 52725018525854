import React from 'react';
import { PrinterOutlined } from '@ant-design/icons';

const ButtonPrint = ({ title, path, history, onClick }) => {
   return (
      <button
         className='button btn-print'
         onClick={() => {
            onClick();
         }}
         style={{
            padding: '.6rem .3rem',
            minWidth: '4rem'
         }}
      >
         <PrinterOutlined
            style={{
               verticalAlign: 0,
               marginRight: '1rem',
               fontSize: '1.4rem'
            }}
         />
         {title}
      </button>
   );
};

export default ButtonPrint;
