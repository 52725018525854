import React, { useEffect, useState } from 'react';
import { isPublishVersion } from '../../services/app-service';
import { DataLoader } from '../../services/data-loader-service';
import intl from '../../services/intl-service';
import { opcinaService } from '../../services/m-o-r-service';
import DataTable from '../common/table/datatable';
import { publishVersions } from '../../services/app-service';
import { AnimatePresence } from 'framer-motion';
import CustomAlertNoFormContainer from '../common/custom-alert-container';
import UrediOpcinu from './uredi-opcinu';
import ButtonAddNew from '../common/btn-add-new';
import ButtonEditTableRow from '../common/btn-edit-table-row';

const PregledOpcina = (props) => {
   const columns = [
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('opcina.sifraOpcine'),
         path: 'sifraOpcine'
      },
      {
         sortable: true,
         center: false,
         label: intl.getTranslate('opcina.nazivOpcine'),
         path: 'nazivOpcine'
      },
      {
         sortable: true,
         center: true,
         label: intl.getTranslate('common.operaterUpisa'),
         path: 'operaterUpisa'
      },
      {
         sortable: true,
         label: intl.getTranslate('common.datumUpisa'),
         path: 'datumUpisa'
      },
      {
         sortable: true,
         label: intl.getTranslate('common.vrijemeUpisa'),
         path: 'vrijemeUpisa'
      },
      {
         key: 'btnEdit',
         width: '10rem',
         content: (item) => {
            if (isPublishVersion(publishVersions.BiH)) return null;
            return (
               <ButtonEditTableRow onClick={handleOnEditClick} item={item} />
            );
         }
      }
   ];

   const [data, setData] = useState([]);
   const [showUredi, setShowUredi] = useState(false);
   const [odabranaOpcina, setOdabranaOpcina] = useState(null);

   const onDataLoad = (response) => {
      const { opcine } = response;
      setData(opcine);
   };

   const loadData = () => DataLoader(opcinaService.list, null, onDataLoad);

   useEffect(() => {
      loadData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleOnEditClick = (mjesto) => {
      setOdabranaOpcina(mjesto);
      setShowUredi(true);
   };

   const handleAfterAzurirajOpcinu = () => {
      loadData();
      setShowUredi(false);
   };
   const handleCancel = () => {
      setShowUredi(false);
   };

   return (
      <>
         <AnimatePresence>
            {showUredi && (
               <CustomAlertNoFormContainer
                  title={intl.getTranslate('opcina.confirmTitle')}
                  style={{ width: '80rem' }}
               >
                  <UrediOpcinu
                     odabranaOpcina={odabranaOpcina}
                     onSubmit={handleAfterAzurirajOpcinu}
                     onCancel={handleCancel}
                  />
               </CustomAlertNoFormContainer>
            )}
         </AnimatePresence>
         <DataTable
            columns={columns}
            data={data}
            searchLabel={intl.getTranslate('opcina.pretraga')}
            searchLabelPlaceholder={intl.getTranslate(
               'opcina.pretragaPlaceholder'
            )}
            searchColumn='nazivOpcine'
            onRowClick={handleOnEditClick}
            btnAdd={
               <ButtonAddNew
                  title={intl.getTranslate('opcina.btnAddNew')}
                  path='/add-county'
               />
            }
         />
      </>
   );
};

export default PregledOpcina;
