import React from 'react';
import intl from '../../services/intl-service';
import FramerConditionalOpacityContainer from '../common/framer-conditional-opacity-container';

const PrikazPodatakaKumulativa = ({ data, delay }) => {
   const translateTermin = (termin) =>
      intl.getTranslate(`pregledKumulativa.termin.${termin}`);

   return (
      <FramerConditionalOpacityContainer condition='true' delay={delay}>
         <div className='prikaz-podataka-kumulativa'>
            <table className='table table-striped table-sm'>
               <thead>
                  <tr>
                     <th>{translateTermin(data.termin)}</th>
                     {data.podaciZaPodigre.map((d, i) => (
                        <th key={`ppk_th_${i}`}>{d.nazivPodigre}</th>
                     ))}
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>
                        {intl.getTranslate(`pregledKumulativa.brojListica`)}
                     </td>
                     {data.podaciZaPodigre.map((p, i) => (
                        <td key={`td_brl_${i}`}>{p.brojListica}</td>
                     ))}
                  </tr>
                  <tr>
                     <td>
                        {intl.getTranslate(`pregledKumulativa.brojKombinacija`)}
                     </td>
                     {data.podaciZaPodigre.map((p, i) => (
                        <td key={`td_brk_${i}`}>{p.brojKombinacija}</td>
                     ))}
                  </tr>
                  <tr>
                     <td>{intl.getTranslate(`pregledKumulativa.storno`)}</td>
                     {data.podaciZaPodigre.map((p, i) => (
                        <td key={`td_storno_${i}`}>
                           {p.brojStorniraniListica}
                        </td>
                     ))}
                  </tr>
                  <tr>
                     <td>{intl.getTranslate(`pregledKumulativa.uplata`)}</td>
                     {data.podaciZaPodigre.map((p, i) => (
                        <td key={`td_uplata_${i}`}>{p.uplata}</td>
                     ))}
                  </tr>
                  <tr>
                     <td>{intl.getTranslate(`pregledKumulativa.isplata`)}</td>
                     {data.podaciZaPodigre.map((p, i) => (
                        <td key={`td_isplata_${i}`}>{p.isplata}</td>
                     ))}
                  </tr>
               </tbody>
            </table>
         </div>
      </FramerConditionalOpacityContainer>
   );
};

export default PrikazPodatakaKumulativa;
