import intl from '../../services/intl-service';
import Joi from 'joi-browser';

export const loginSchema = {
   operativniBroj: Joi.number()
      .required()
      .label(intl.getTranslate('login.operativniBroj'))
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} ${intl.getTranslate(
               'login.operativniBrojRequiredValidator'
            )}`
         };
      }),
   password: [
      Joi.string()
         .required()
         .label(intl.getTranslate('login.password'))
         .error((errors) => {
            return {
               message: `${errors[0].flags.label} ${intl.getTranslate(
                  'login.passwordValidator'
               )}`
            };
         }),
      Joi.number()
         .required()
         .label(intl.getTranslate('login.password'))
         .error((errors) => {
            return {
               message: `${errors[0].flags.label} ${intl.getTranslate(
                  'login.passwordValidator'
               )}`
            };
         })
   ]
};

//https://joi.dev/api/?v=17.4.2
// username: Joi.string()
//    .email()
//    .required()
//    .label(intl.getTranslate('login.username'))
//    .error((errors) => {
//       if (errors[0].type === 'any.empty')
//          return {
//             message: `${errors[0].flags.label} ${intl.getTranslate(
//                'login.usernameRequiredValidator'
//             )}`
//          };
//       if (errors[0].type === 'string.email')
//          return {
//             message: `${intl.getTranslate(
//                'login.usernameEmailErrorValidator'
//             )}`
//          };
//       return {
//          message: `${errors[0].flags.label} - error.`
//       };
//    }),
