import React from 'react';
import { Bar } from '@ant-design/plots';
import { getFormatedValue } from '../../services/app-service';

const AntBasicBarChart = ({
   data,
   width = '100%',
   height = 200,
   currency = 'Le',
   xField = 'iznosUplate',
   yField = 'datum'
}) => {
   const config = {
      data,
      xField,
      yField,
      color: ['#62daab', '#1383ab', '#b90000', '#f6c022', '#6395f9'],
      seriesField: 'datum',
      legend: {
         position: 'top-left'
      },
      style: {
         height,
         width
      },
      marginRatio: 0,
      label: {
         content: ({ value }) => '',
         // 'left', 'middle', 'right'
         position: 'middle',
         layout: [
            {
               type: 'interval-adjust-position'
            },
            {
               type: 'interval-hide-overlap'
            },
            {
               type: 'adjust-color'
            }
         ]
      },
      tooltip: {
         formatter: (data) => {
            return {
               name: 'Uplata',
               value: currency
                  ? `${getFormatedValue(data[xField])}${currency}`
                  : data[xField]
            };
         }
      }
   };

   return <Bar {...config} />;
};

export default AntBasicBarChart;
