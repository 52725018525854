import { useEffect } from 'react';
import PageContainer from './../common/animated-page-container';
import { Redirect } from 'react-router-dom';
import { removeUserData } from './../../services/auth-service';

const Logout = (props) => {
   useEffect(() => {
      removeUserData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps
   return (
      <PageContainer>
         <Redirect to='/login' />
      </PageContainer>
   );
};

export default Logout;
