import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { increment, decrement } from './../../../store/playground/counter';

const CounterWithRedux = (props) => {
   //DOHVATIM SA STORE-a STATE
   const counter = useSelector((state) => state.counters);
   //useDispatch() - dipatcha akcije na state
   const dispatch = useDispatch();

   return (
      <div className='counter-with-redux'>
         <div>COUNTER WITH REDUX: {counter.counterValue}</div>
         <button
            onClick={() => {
               dispatch(increment(5));
            }}
            className='btn btn-primary btn-sm mr-1'
         >
            Increment
         </button>
         <button
            onClick={() => {
               dispatch(decrement(1));
            }}
            className='btn btn-secondary btn-sm'
         >
            Decrement
         </button>
      </div>
   );
};

export default CounterWithRedux;
