import React from 'react';
import Form from '../common/form';
import { injectIntl } from 'react-intl';
import intl from '../../services/intl-service';
import { regijaService } from '../../services/m-o-r-service';
import {
   toastError,
   toastInfo,
   toastSuccessSave
} from '../../services/toast-service';
import { azuriranjeRegijeSchema } from './regije-validacije';
import CustomAlertNoForm from '../common/custom-alert-no-form';
import InfoVrijemeUpisa from '../common/info-vrijeme-upisa';
import FramerConditionalCollapsableContainer from '../common/framer-conditional-collapsable-container';
import { DataLoader } from '../../services/data-loader-service';
import { withRouter } from 'react-router';
import { getToasterIntervals } from '../../services/app-service';
import { paths } from '../../routes/routes';
//Debounce: https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js

class DodajRegiju extends Form {
   state = {
      validationData: {
         sifraRegije: '',
         nazivRegije: ''
      },
      errors: {},
      datumUpisa: '',
      vrijemeUpisa: '',
      showConfirm: false
   };

   schema = azuriranjeRegijeSchema;

   doSubmit = () => {
      this.setState({ showConfirm: true });
   };

   doSubmitOnConfirm = async () => {
      const { successAutoClose, redirectAutoClose, redirectDelay } =
         getToasterIntervals();

      const { sifraRegije, nazivRegije } = this.state.validationData;
      const azuriraniPodaci = { sifraRegije, nazivRegije, operaterUpisa: 14 };
      const { data: response } = await regijaService.addOrUpdate(
         azuriraniPodaci
      );

      if (response.isSuccess === 1) {
         toastSuccessSave(
            intl.getTranslate('common.podaciUspjesnoSpremljeni'),
            {
               autoClose: successAutoClose,
               onClose: () => this.props.history.push(paths.overview_Region)
            }
         );
         toastInfo(intl.getTranslate('regija.preusmjeravanje'), {
            autoClose: redirectAutoClose,
            delay: redirectDelay
         });
         this.resetForm('sifraRegije');
      } else toastError();

      this.setState({ showConfirm: false });
   };

   onDataLoad = (response) => {
      const validationData = { ...this.state.validationData };
      const { nazivRegije, datumUpisa, vrijemeUpisa } = response;
      validationData.nazivRegije = nazivRegije;

      this.setState({ validationData, datumUpisa, vrijemeUpisa });
   };

   ucitajPodatkeRegije = async (sifraRegije) => {
      DataLoader(regijaService.get, [sifraRegije], this.onDataLoad);
   };

   handleSifraRegijeChange = async ({ target: input }) => {
      if (isNaN(input.value)) return;

      const sifraRegije = Number(input.value);
      if (sifraRegije >= 0 && sifraRegije <= 9999)
         this.ucitajPodatkeRegije(sifraRegije);
   };

   render() {
      const { validationData, showConfirm, datumUpisa, vrijemeUpisa } =
         this.state;
      const { sifraRegije, nazivRegije } = validationData;

      const odabranaJeRegija =
         !isNaN(sifraRegije) && sifraRegije >= 1 && sifraRegije <= 99;

      return (
         <>
            <CustomAlertNoForm
               title={intl.getTranslate('regija.confirmAddingTitle')}
               btnOk={intl.getTranslate('common.save')}
               showCondition={showConfirm}
               onCancel={() => this.setState({ showConfirm: false })}
               onOk={() => this.doSubmitOnConfirm()}
            >
               <div>
                  {intl.getTranslate('regija.confirmAddingMessage')}{' '}
                  <span className='confirm-highlight'>{nazivRegije}</span>?
               </div>
            </CustomAlertNoForm>

            <form onSubmit={this.handleFormSubmit} className='form'>
               <div className='uredi-opcinu'>
                  <div className='row'>
                     <div className='col-4'>
                        {this.renderTranslateInput(
                           'sifraRegije',
                           'regija',
                           this.handleSifraRegijeChange,
                           true
                        )}
                     </div>
                  </div>
                  <FramerConditionalCollapsableContainer
                     condition={odabranaJeRegija}
                     height='18rem'
                  >
                     <div className='row'>
                        <div className='col-4'>
                           {this.renderTranslateInput('nazivRegije', 'regija')}
                        </div>
                     </div>

                     <hr />

                     <InfoVrijemeUpisa
                        datumUpisa={datumUpisa}
                        vrijemeUpisa={vrijemeUpisa}
                     />

                     {this.renderSubmitButton(
                        `${intl.getTranslate('common.save')}`
                     )}
                  </FramerConditionalCollapsableContainer>
               </div>
            </form>
         </>
      );
   }
}

export default withRouter(injectIntl(DodajRegiju));
